import React,{ Component, Fragment, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import edit from '../../../Public/Images/edit.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import AddEditMealDrinkPrice from './AddEditMealDrinkPrice';
import { ADD_MEAL_DRINK, DELETE_MEAL_DRINKS, GET_MEAL_DRINKS, UPDATE_MEAL_DRINK_PRIORITY } from '../Queries/mealDrink';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import move from '../../../Public/Images/move.svg';
import plus from '../../../Public/Images/plus_green.svg';
import remove from '../../../Public/Images/remove.svg';
import { DELETE_MEAL_DRINK_PRICE } from '../Queries/mealDrinkPrices';

const MealDrinks = (props) => {

    const helper = new Helper();
    let notify = new Notification();
    helper.checkAdminRights(props,["1","2"]);
    
    const listVariables = {
        mealID:props.mealID,
        lang:translate.locale
    }

    const [selectedItems,setSelectedItems]          = useState([]);
	const [showDeleteNotifi,setShowDeleteNotifi] 	= useState(false);
	const [showPriceDeleteNotifi,setShowPriceDeleteNotifi] 	= useState(false);
    
	const [showAddPrice,setShowAddPrice] 			            = useState(false);
    const [selectedMealDrinkPriceID,setSelectedMealDrinkPriceID] 	    = useState(0);
    const [selectedMealDrinkID,setSelectedMealDrinkID] 	    = useState(0);
    const [drinkID,setDrinkID]                      = useState(0);
    
    const {loading, error:getError, data:listData, refetch}   = useQuery(GET_MEAL_DRINKS,{
        variables:listVariables,
        fetchPolicy: 'cache-and-network'
    });

    const [addMealDrink,{data:addMealPricekData,loading:addLoading,error:addError}] = useMutation(ADD_MEAL_DRINK,{
        onCompleted(data){
            setDrinkID(0);
		},update(cache,response) {            
            
            notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            console.log(err);
            err             = helper.getApolloErrorText(err);
            notify.setNotification(null,err,false,true,props.client);
        },
        refetchQueries(data){
            return [{
                query:GET_MEAL_DRINKS,
                variables: listVariables
            }]
        }
    })

    const [deleteMeal, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_MEAL_DRINKS,{	
		update(cache,response) {  
            refetch();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });
    const [deleteMealPrice, { data:deletePriceData,loading: deletePriceLoading, error: deletePriceError }] = useMutation(DELETE_MEAL_DRINK_PRICE,{	
		update(cache,response) { 
            refetch();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    const [updateMealDrinkPriority,{data:updateData,loading:updateLoading,error:updateError}] = useMutation(UPDATE_MEAL_DRINK_PRIORITY,{
        update(){
            refetch();
        }
    })

    const addMealDrinkItem = () => {
        if(drinkID != 0){
            addMealDrink({
                variables:{
                    mealID:props.mealID,
                    drinkID,
                    lang:translate.locale
                }
            })
        }else{
            notify.setNotification(null,translate.t("NotSelectedDrink"),false,true,props.client);
            setShowDeleteNotifi(false);
        }
    }


    const showDelNotifi = () => {
        if(selectedItems.length > 0){
            setShowDeleteNotifi(true);
        }else{
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const delMeal = (action) => {

        if(action){

            let mealDrinkIDs = selectedItems.join(",");
            deleteMeal({variables:{mealDrinkIDs,mealID:props.mealID}});
        }

        setShowDeleteNotifi(false);
    }
    const delMealPrice = (action) => {

        if(action){

            deleteMealPrice({variables:{mealDrinkPriceID:selectedMealDrinkPriceID}});
        }

        setShowPriceDeleteNotifi(false);
    }

    const initiateDeleteMeal = (e,mealDrinkID) => {

        var checked = e.target.checked;
        var arr = [...selectedItems];

        if(!checked){
            for(let i in selectedItems){
                if(selectedItems[i] == mealDrinkID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [mealDrinkID,...arr];
        }

        setSelectedItems(arr);
  
    }
    const initiateDeleteMealDrinkPrice = (mealDrinkPriceID) => {

        setSelectedMealDrinkPriceID(mealDrinkPriceID);
        setShowPriceDeleteNotifi(true);
  
    }

    const openCloseModal = (open,mealDrinkPriceID,mealDrinkID) => {
        setSelectedMealDrinkID(mealDrinkID);
        setSelectedMealDrinkPriceID(mealDrinkPriceID);
        setShowAddPrice(open);
    }
    const onDragEnd = (result,updateDrinkPriority) => {

        const IDs = result.draggableId.split("-");

        updateMealDrinkPriority({
            variables:{
                mealID:props.mealID,
                mealDrinkID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    return (
        <div>
            
            <div className="d-flex flex-column" >
                        
                <div className="d-flex align-items-center mb-4">
                    <div className="form-inline ml-auto">
                        <label className="input-label mr-2">{translate.t("Drink")}:</label>
                        <div className="form-group mr-2">
                            <select className="form-control-sm" name="countryID"  value={drinkID} onChange={(e) => setDrinkID(e.target.value)}>
                                <option value={0}>-- {translate.t("ChooseDrink")} --</option>
                                {listData && listData.allDrinks && listData.allDrinks.map((it,ind) => (
                                    <option key={ind} value={it.drinkID}>{it.name}</option>
                                ))}
                            </select>
                        </div>
                    </div> 
                    <button className="btn btn-primary btn-thiner" onClick={() => addMealDrinkItem()}>{translate.t("Add")}</button>
                    <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                </div>
                <div>
                    <div className="data-list">
                       
                        {!loading ? 
                            <> 
                                <div className="data-list-item header">
                                    <div className="data-list-item-content">
                                        <div>{translate.t("Drink")}</div>
                                        <div className="">{translate.t("Price")} | {translate.t("Vat")}</div>
                                        <div className="text-right">{translate.t("Options")}</div>
                                    </div>
                                </div>
                                
                                {listData && listData.allMealDrinks && listData.allMealDrinks.length > 0 ?
                                   
                                   <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                       
                                                    {listData.allMealDrinks.map((item,index) => {
                                                            
                                                        var checked = false;
                                                        for(let i in selectedItems){
                                                            if(selectedItems[i] == item.mealDrinkID)checked = true;
                                                        }   
            
                                                        return(          
                                                            <Draggable key={index} draggableId={item.mealDrinkID} index={index}>
                                                                {(provided, snapshot) => (                                            
                                                                <div key={index} className="data-list-item" 
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}>
                                                                    <div className="data-list-item-content">
                                                                        <div className=""><img className="move-icon" src={move} />{item.drink && item.drink.name}</div>
                                                                        <div className="d-flex flex-row align-items-center">
                                                                            <div key={index} className="d-flex flex-column" style={{flex:"0 1 auto"}}>
                                                                                {item.prices && item.prices.length > 0 && item.prices.map((priceItem,index) => (
                                                                                    <div key={index} className="d-flex flex-row align-items-center">
                                                                                        {priceItem.drinkPrice} {priceItem.country.currency.code}
                                                                                        <div className="mx-2">|</div>
                                                                                        {priceItem.vat && priceItem.vat.value + " %"} 
                                                                                        <img onClick={() => openCloseModal(true,priceItem.mealDrinkPriceID,item.mealDrinkID)} className="edit-icon small ml-2" src={edit} title={translate.t("Edit")} />
                                                                                        <img onClick={() => initiateDeleteMealDrinkPrice(priceItem.mealDrinkPriceID)} className="remove-icon small" src={remove} title={translate.t("Remove")} />
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                            <img className="plus-icon small" title={translate.t("AddPrice")} src={plus} onClick={() => openCloseModal(true,0,item.mealDrinkID)} />
                                                                        </div>
                                                                        <div className="text-right">
                                                                            <input className="delete-checked" type="checkbox" name="deleteAthlete[]" checked={checked} onChange = {(e) => initiateDeleteMeal(e,item.mealDrinkID)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    })}   
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    :                                
                                    <NoItems text={translate.t("NoItemsAddedYet")} />
                                }
                            </>
                            :                                   
                            <Loading />                                    
                        }  
                    </div>                                                  
                </div>
            </div> 	
            {showAddPrice &&
                <AddEditMealDrinkPrice mealDrinkPriceID={selectedMealDrinkPriceID} mealDrinkID={selectedMealDrinkID} openCloseModal={openCloseModal} listVariables={listVariables}/>
            }	
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delMeal} /> }	
            {showPriceDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItem?")} callback={delMealPrice} /> }
        </div>
    )

}

export default withApollo(MealDrinks);