import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {REMOVE_CREDIT_FROM_CUSTOMER} from '../Queries/customer';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import Modal from 'react-modal';
import CustomerLib from '../Library/customer';


const RemoveCreditFromCustomer = (props) => {

    const customerLib = new CustomerLib(props);

    const [formData,setFormData] = useState({        
        customerID:props.customerID,
        countryID:props.countryID,
        creditValue:"",
        creditToUse:props.creditToUse
    });

    const [removeCreditFromCustomer,{data:removeCustomerData,loading:removeLoading,error:removeError}] = useMutation(REMOVE_CREDIT_FROM_CUSTOMER,{
        onCompleted(data){

            let notify = new Notification();
            notify.setNotification(null,translate.t("SuccessfullyAdded"),true,false,props.client);
			!!props.updated && props.updated();
            props.openCloseModal(false);
            
		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    })

    const removeCredit = () => {
        if(!!formData.creditValue && parseFloat(formData.creditValue) > 0){
            if(!!formData.creditValue && parseFloat(formData.creditValue) <= formData.creditToUse){
                removeCreditFromCustomer({
                    variables:{
                        customerID:formData.customerID,
                        countryID:formData.countryID,
                        credit:parseFloat(formData.creditValue)
                    }
                })
            }else{
                let notify = new Notification();
                notify.setNotification(null,translate.t("NotEnoughCredit"),false,true,props.client);
            }
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidCredit"),false,true,props.client);
        }
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{translate.t("AddCredit")}</h4>
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div>       
                        <div className="row">                    
                            <div className="col-12">
                                <div className="form-inline">
                                    <label className="input-label mr-2">{translate.t("Add")}</label>
                                    <input className="form-control mr-2" type="number" step={1} name="creditValue" value={formData.creditValue} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                    <div>{translate.t("Credits")}</div>
                                </div>                                                
                            </div>                
                        </div> 
                    </div>
                </div>
                <div className="modal-footer">                                
                    <button athlete="button" className="btn btn-primary flex-fill" onClick={() => removeCredit()}>{translate.t("Remove")}</button>
                    <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )

}

export default withApollo(RemoveCreditFromCustomer);