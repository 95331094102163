class DeliveryArea {

    constructor(props){
        this.props = props;
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData, name){
      
        if(e.target){

            const t = e.target;
            const v = t.type === 'checkbox' ? t.checked : t.value;
            const n = t.name;
            
            setFormData({...formData,[n]: v});

        }else if(name){
            setFormData({...formData,[name]: e});
        }
              
    }

}

export default DeliveryArea;