import translate from '../../Translations/index';
import Notification from '../../../Library/notification';

class MealSideDishPrice {

    constructor(props){
        this.props = props;
    }

    addMealSideDishPrice(addMealSideDishPrice,formData){
        if(formData.sideDishPrice !== ""){
            
            formData.sideDishPrice          = parseFloat(formData.sideDishPrice);
            addMealSideDishPrice({
                variables:{
                    mealSideDishPriceInputs:formData
                }
            }); 

        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidPrice"),false,true,this.props.client);
        }                     

    }

    getData(data,mealSideDishPriceID,formData,setFormData){
        console.log(data);
        if(mealSideDishPriceID != 0){            
            setFormData({...formData,
                mealSideDishPriceID:    data.mealSideDishPrice.mealSideDishPriceID,
                countryID:              data.mealSideDishPrice.countryID,
                sideDishPrice:          data.mealSideDishPrice.sideDishPrice,
                vatID:                  data.mealSideDishPrice.vatID,
            });
        }
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default MealSideDishPrice;