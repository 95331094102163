/**
 * soubor se seznamem veškerých graphql dotazů pro obrázky
 */
import gql from 'graphql-tag';


/**
 *  Seznam veškerých obrázku jídla
 */
export const GET_MEAL_IMAGES = gql`
    query MealImages($mealID: ID!){
        mealImages(mealID:$mealID){
            mealImageID
            name
        }

    }
`;

/**
 * vymazání obrázku jídla
 */
export const DELETE_MEAL_IMAGES = gql`
    mutation deleteMealImages($mealImagesIDs:[ID]!,$mealID:ID!) {
        deleteMealImages(mealImagesIDs:$mealImagesIDs,mealID:$mealID)
    }
`;


/**
 * Upload obrázku pro jídlo
 */
export const UPLOAD_MEAL_IMAGES = gql`
  mutation uploadMealImages($files: Upload!,$mealID:ID!) {
    uploadMealImages(files: $files,mealID:$mealID){
        mealImageID
        name
    } 
  }
`; 


