import React,{ Component, Fragment, useRef, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import translate from '../../Translations/index';
import {BRANCHES_FOR_STATISTICS, DATA_FOR_STATISTIC_MAP_ORDERS, } from '../Queries/statistics';
import Loading from '../../../GlobalComponents/Loading';
import StatisticLib from '../Library/statistics';
import DatePicker,{ registerLocale } from 'react-datepicker';
import { ADMIN_USER } from '../../../GlobalQueries/globalQueries';
import { GOOGLE_API_KEY } from '../../../Config';
import useSupercluster from "use-supercluster";
import GoogleMapReact from "google-map-react";

const moment             = require("moment");

const Marker = ({ children }) => children;

const StatisticMapOrders = (props) => {

    const statisticLib = new StatisticLib(props)

	const adminUserData = props.client.readQuery({ query:ADMIN_USER });

    const [dateFrom,setDateFrom]                    = useState(moment().toDate());
    const [dateTo,setDateTo]                        = useState(moment().toDate());


    const mapRef                            = useRef();
    const [bounds, setBounds]               = useState(null);
    const [zoom, setZoom]                   = useState(10);
    const [points, setPoints]               = useState([]);
    const [totalDistance, setTotalDistance] = useState(0);
    const { clusters, supercluster }        = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: 75, maxZoom: 20 }
    });

    const [filters,setFilters] = useState({
        countryID:adminUserData.adminUser.branchCountryID ? adminUserData.adminUser.branchCountryID : 60,
        dateFrom:moment().format("YYYY-MM-DD"),
        dateTo:moment().format("YYYY-MM-DD"),
        driverID:0,
        branchID:adminUserData.adminUser.roleID == 5 ? adminUserData.adminUser.branchID : 0
    });
    const [salesData,setSalesData] = useState([]);

    const {loading, error, data, refetch}   = useQuery(DATA_FOR_STATISTIC_MAP_ORDERS,{
        fetchPolicy:"network-only",
        variables:{
            filterData:filters
        },
        onCompleted(data){
            if(data.ordersForStatistics){
                let totalDistance   = 0;
                let pointArray      = [];

                for(const item of data.ordersForStatistics){
                    if(item.shippingTypeID == "4"){
                        pointArray.push({
                            type: "Feature",
                            properties: { cluster: false,orderID:item.orderID },
                            geometry: {
                            type: "Point",
                            coordinates: [
                                parseFloat(item.lng),
                                parseFloat(item.lat)
                            ]
                            }
                        });
                        totalDistance += item.distance;
                    }
                }
                setPoints([...pointArray]);
                setTotalDistance(totalDistance);
                
            }
        },
    });
    const {loading:branchesLoading, error:branchesError, data:branchesData}   = useQuery(BRANCHES_FOR_STATISTICS,{
        fetchPolicy:"network-only",
        variables:{
            countryID:filters.countryID
        }
    });

    const setDateFromFilter = (date) => {
        setFilters({...filters,dateFrom:moment(date).format("YYYY-MM-DD")});
        setDateFrom(date);
    }
    const setDateToFilter = (date) => {
        setFilters({...filters,dateTo:moment(date).format("YYYY-MM-DD")});
        setDateTo(date);
    }

    const countryChanged = (e) => {
        setFilters({...filters,branchID:0,countryID:e.target.value});
    }


    return (
        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main form-group">
                        
                        <div className="card-header d-flex align-items-center flex-row">
                            <div className="fs20 bold">{translate.t("Statistics")} / <span className="fs14">{translate.t("Map")}</span></div>
                            {[1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) !== -1 &&
                                <div className="ml-auto d-flex align-items-center mr-5">
                                    {translate.t("Country")} 
                                    <select className="form-control ml-2" name="countryID" value={filters.countryID} onChange={(e) => countryChanged(e)}>
                                        {false && <option value={0}>-- {translate.t("ChooseCountry")} --</option>}
                                        {data && data.allActiveCountries && data.allActiveCountries.map((item,index) => (
                                            <option key={index} value={item.countryID}> {item.name} </option>
                                        ))}
                                    </select>
                                </div>
                            }
                            {[1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) !== -1 &&
                                <div className="ml-auto d-flex align-items-center mr-5">
                                    {translate.t("Branch")} 
                                    <select className="form-control ml-2" name="branchID" value={filters.branchID} onChange={(e) => setFilters({...filters,branchID:e.target.value})}>
                                        <option value={0}>-- {translate.t("SelectBranch")} --</option>
                                        {branchesData && branchesData.allBranches && branchesData.allBranches.map((item,index) => (
                                            <option key={index} value={item.branchID}> {item.name} </option>
                                        ))}
                                    </select>
                                </div>
                            }
                            {[1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) !== -1 &&
                                <div className="d-flex align-items-center mr-5">
                                    {translate.t("Driver")} 
                                    <select className="form-control ml-2" name="driverID" onChange={(e) => setFilters({...filters,driverID:e.target.value})}>
                                        <option value={0}> --{translate.t("SelectDriver")}-- </option>
                                        {data && data.allDrivers && data.allDrivers.map((item,index) => (
                                            <option key={index} value={item.driverID}> {item.user && item.user.name + " " + item.user.surname} </option>
                                        ))}
                                    </select>
                                </div>
                            }
                            <div className={"d-flex align-items-center mr-5 " + ([1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) === -1 ? "ml-auto" : "" )}>
                                {translate.t("DateFrom")}
                                <DatePicker className="form-control ml-2" dateFormat="dd.MM.yyyy" locale="cs" selected={dateFrom} onChange={date => setDateFromFilter(date)} />
                            </div>
                            <div className="d-flex align-items-center">
                                {translate.t("DateTo")}
                                <DatePicker className="form-control ml-2" dateFormat="dd.MM.yyyy" locale="cs" selected={dateTo} onChange={date => setDateToFilter(date)} />
                            </div>
                        </div>
                        
                    </div>
                    <div>
                                
                    {!loading ? 
                        <div className="row" style={{flexWrap:'wrap'}}>

                            <div className="col-12">
                                <div className="card statistic-map main form-group">
                            
                                    <div className="card-header d-flex align-items-center flex-row bold">
                                        {translate.t("Orders")}
                                    </div>
                                    <div className="card-body">
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
                                            defaultCenter={{ lat: 50.0791, lng: 14.4142 }}
                                            defaultZoom={9}
                                            yesIWantToUseGoogleMapApiInternals
                                            onGoogleApiLoaded={({ map }) => {
                                                mapRef.current = map;
                                            }}
                                            onChange={({ zoom, bounds }) => {
                                                setZoom(zoom);
                                                setBounds([
                                                    bounds.nw.lng,
                                                    bounds.se.lat,
                                                    bounds.se.lng,
                                                    bounds.nw.lat
                                                ]);
                                            }}
                                        >
                                            {clusters.map(cluster => {
                                                const [longitude, latitude] = cluster.geometry.coordinates;
                                                const {
                                                    cluster: isCluster,
                                                    point_count: pointCount
                                                } = cluster.properties;

                                                if (isCluster) {
                                                    return (
                                                    <Marker
                                                        key={`cluster-${cluster.id}`}
                                                        lat={latitude}
                                                        lng={longitude}
                                                    >
                                                        <div
                                                        className="cluster-marker"
                                                        onClick={() => {
                                                            const expansionZoom = Math.min(
                                                                supercluster.getClusterExpansionZoom(cluster.id),
                                                                20
                                                            );
                                                            mapRef.current.setZoom(expansionZoom);
                                                            mapRef.current.panTo({ lat: latitude, lng: longitude });
                                                        }}
                                                        >
                                                        {pointCount}
                                                        </div>
                                                    </Marker>
                                                    );
                                                }

                                                return (
                                                    <Marker
                                                    key={`order-${cluster.properties.orderID}`}
                                                    lat={latitude}
                                                    lng={longitude}
                                                    >
                                                        <div className="cluster-marker">1</div>
                                                    </Marker>
                                                );
                                            })}
                                        </GoogleMapReact>
                                    </div>
                                </div>

                                <div className="card main form-group">
                            
                                    <div className="card-header d-flex align-items-center flex-row bold">
                                        {translate.t("Distance")}
                                    </div>
                                    <div className="card-body">  
                                        <div>{translate.t("TotalDistance")}: {totalDistance / 1000} km</div>
                                        <div>{translate.t("AverageDistance")}: {points.length > 0 ? (Math.round(totalDistance / points.length) / 1000) : 0} km</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        :                                    
                        <Loading />                                    
                    }
                        
                    </div>
                </div>
            </div> 	
	
        </div>
    )

}

export default withApollo(StatisticMapOrders);