/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_BRANCH_BLOCKED_DAY = gql`
    mutation AddEditBranchBlockedDay($branchBlockedDayID:ID,$branchID:ID,$dateBlocked:String){
        addEditBranchBlockedDay(branchBlockedDayID:$branchBlockedDayID,branchID:$branchID,dateBlocked:$dateBlocked){
            branchBlockedDayID          
            branchID
            dateBlocked
        }
    }
`;


/**
 *  Seznam veškerých athleteů
 */
export const GET_BRANCH_BLOCKED_DAYS = gql`
    query AllBranchBlockedDays($offset:Int,$limit:Int,$branchID:ID){
        allBranchBlockedDays(offset:$offset,limit:$limit,branchID:$branchID){ 
            branchBlockedDayID          
            branchID
            dateBlocked
        }
        branchBlockedDayListCount
    }
`;

/**
 *  Seznam veškerých athleteů
 */
export const GET_BRANCH_BLOCKED_DAY = gql`
    query BranchBlockedDay($branchBlockedDayID:ID){
        branchBlockedDay(branchBlockedDayID:$branchBlockedDayID){            
            branchBlockedDayID          
            branchID
            dateBlocked
        }
       
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_BRANCH_BLOCKED_DAYS = gql`
    mutation deleteBranchBlockedDays($branchBlockedDayIDs:ID!) {
        deleteBranchBlockedDays(branchBlockedDayIDs:$branchBlockedDayIDs)
    }
`;