import React,{ Component, Fragment, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import {GET_HOMEPAGEDATA,DELETE_HOMEPAGEBANNERS,UPDATE_HOMEPAGEBANNER_PRIORITY,EDIT_HOMEPAGEDATA} from '../Queries/homepage';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import edit from '../../../Public/Images/edit.svg';
import move from '../../../Public/Images/move.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import AddEditHomepageBanner from './AddEditHomepageBanner';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {HOMEPAGEBANNER_IMAGE_URL,SERVER_URL} from '../../../Config/index';
import HomepageLib from '../Library/homepage';

const Homepage = (props) => {

    const helper = new Helper();
    helper.checkAdminRights(props,["1","2"]);
    
    const homepageLib = new HomepageLib(props);
    const [formData,setFormData] = useState({
        selectedLang:"cz",
        langs:[],
    });

    const listVariables = {
        lang:"cz"
    }

    const [allLanguageMutations, setAllLanguageMutations] = useState([]);
    const [selectedHomepageBanners,setSelectedHomepageBanners]          = useState([]);
	const [showDeleteNotifi,setShowDeleteNotifi] 	= useState(false);
	const [showAdd,setShowAdd] 			            = useState(false);
    const [selectedHomepageBannerID,setSelectedHomepageBannerID] 	    = useState(0);
    const {loading, error:getError, data:homepageBannersData, refetch}   = useQuery(GET_HOMEPAGEDATA,{
        variables:listVariables,
        fetchPolicy: 'cache-and-network',
        onCompleted(data){
            
            setAllLanguageMutations(data.allLanguageMutations);   
                   
            let langs = homepageLib.completeHomepageLangsArray(data.homepageWithLangs.langs,data.allLanguageMutations); 
            console.log(langs);  
            setFormData({...formData,
                langs:langs
            }); 
        },
    });

    const [addEditHomepage,{data:addHomepageData,loading:addHomepageLoading,error:addHomepageError}] = useMutation(EDIT_HOMEPAGEDATA,{
        onCompleted(data){
            
		},update(cache,response) {            
            
            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    })

    const [deleteHomepageBanner, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_HOMEPAGEBANNERS,{	
		update(cache,response) {            
            
            refetch();

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    const [updateHomepageBannerPriority,{data:updateData,loading:updateLoading,error:updateError}] = useMutation(UPDATE_HOMEPAGEBANNER_PRIORITY,{
        update(){
            refetch();
        }
    })

    const [editHomepageData,{data:addHomepageBannerData,loading:addLoading,error:addError}] = useMutation(EDIT_HOMEPAGEDATA,{
        onCompleted(data){

           props.openCloseModal(false);
            
		},update(cache,response) {            
            
            let notify = new Notification();
            if(formData.homepageBannerID && formData.homepageBannerID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        },
        refetchQueries(data){
            return [{
                query:GET_HOMEPAGEDATA,
                variables: { lang:"cz" }
            }]
        }
    })

    const openCloseModal = (open,homepageBannerID) => {
        setSelectedHomepageBannerID(homepageBannerID);
        setShowAdd(open);
    }

    const showDelNotifi = () => {
        if(selectedHomepageBanners.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const delHomepageBanner = (action) => {

        if(action){

            let homepageBannerIDs = selectedHomepageBanners.join(",");
            deleteHomepageBanner({variables:{homepageBannerIDs}});
        }

        setShowDeleteNotifi(false);
    }

    const initiateDeleteHomepageBanner = (e,homepageBannerID) => {

        var checked = e.target.checked;
        var arr = [...selectedHomepageBanners];

        if(!checked){
            for(let i in selectedHomepageBanners){
                if(selectedHomepageBanners[i] == homepageBannerID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [homepageBannerID,...arr];
        }

        setSelectedHomepageBanners(arr);
  
    }

    const onDragEnd = (result,updateHomepageBannerPriority) => {

        const IDs = result.draggableId.split("-");

        updateHomepageBannerPriority({
            variables:{
                homepageBannerID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    if(getError || deleteError || updateError){
        var error = getError ? getError : (deleteError ? deleteError : updateError);
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }

    return (
        <>
            <div id="settings" className="whole-container" >
                
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <div className="card-header d-flex align-items-center">
                                <h4>{translate.t("Homepage")} - {translate.t("SEO")}</h4>
                                {allLanguageMutations && allLanguageMutations.length > 1 &&
                                    <div className="ml-auto">
                                        <select className="form-control" name="selectedLang" value={formData.selectedLang} onChange={(e) => homepageLib.formHandle(e,formData,setFormData)}>
                                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.suffix}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                }
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <label className="input-label">{translate.t("MetaTitle")} ({formData.selectedLang})</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="metaTitle" value={homepageLib.getLangValue(formData,"metaTitle")} onChange={(e) => homepageLib.formLangHandle(e,formData,setFormData)} />
                                        </div>
                                    </div>  
                                    <div className="col-12 col-sm-6">
                                        <label className="input-label">{translate.t("MetaKeywords")} ({formData.selectedLang})</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="metaKeywords" value={homepageLib.getLangValue(formData,"metaKeywords")} onChange={(e) => homepageLib.formLangHandle(e,formData,setFormData)} />
                                        </div>
                                    </div> 
                                    <div className="col-12">
                                        <label className="input-label">{translate.t("MetaDescription")} ({formData.selectedLang})</label>
                                        <div className="input-group">
                                            <input className="form-control" type="text" name="metaDescription" value={homepageLib.getLangValue(formData,"metaDescription")} onChange={(e) => homepageLib.formLangHandle(e,formData,setFormData)} />
                                            
                                            <div className="input-group-append">
                                                <span className={"input-group-text " + (homepageLib.getCharCount(formData,"metaDescription") > 160 ? 'red' : '')}>{homepageLib.getCharCount(formData,"metaDescription")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">                                
                                <button type="button" className="btn btn-primary " onClick={() => homepageLib.saveHomepageData(addEditHomepage,formData)}>{(formData.homepageBannerID && formData.homepageBannerID != 0 ? translate.t("Edit") : translate.t("Edit"))}</button>
                            </div>
                        </div>
                    </div>
                </div> 	
            </div>
            <div id="settings" className="whole-container" >
                
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <div className="card-header d-flex align-items-center">
                                {translate.t("Homepage")} - {translate.t("Banners")}
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                                <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                            </div>
                            <div className="card-body">

                                {!error ?
                                    <div>
                                        <div className="data-list">
                                            
                                            <div className="data-list-item header">
                                                <div className="data-list-item-content">
                                                    <div>{translate.t("Photo")}</div>
                                                    <div>{translate.t("Text")}</div>
                                                    <div className="text-center static small">{translate.t("Active")}</div>
                                                    <div className="text-right static small">{translate.t("Options")}</div>
                                                </div>
                                            </div>
                                            
                                            {homepageBannersData && homepageBannersData.allHomepageBanners && homepageBannersData.allHomepageBanners.length > 0 &&

                                                <DragDropContext onDragEnd={(result) => onDragEnd(result,updateHomepageBannerPriority)}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided, snapshot) => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                {homepageBannersData && homepageBannersData.allHomepageBanners && homepageBannersData.allHomepageBanners.map((item,index) => {
                                                                    
                                                                    var checked = false;
                                                                    for(let i in selectedHomepageBanners){
                                                                        if(selectedHomepageBanners[i] == item.homepageBannerID)checked = true;
                                                                    }

                                                                    return (
                                                                        <Draggable key={index} draggableId={item.homepageBannerID} index={index}>
                                                                            {(provided, snapshot) => (
                                                                            <div className="data-list-item" 
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <div className="data-list-item-content categories">
                                                                                    <div className="cross" style={{paddingLeft:10}}>
                                                                                        <img className="move-icon" src={move} />
                                                                                        <img src={SERVER_URL + "/" + HOMEPAGEBANNER_IMAGE_URL + "/hb-" + item.homepageBannerID + "/mala_" + item.photo} />
                                                                                    </div>
                                                                                    
                                                                                    <div dangerouslySetInnerHTML={{__html: item.text}}></div>
                                                                                    <div className="text-center static small">{(item.active == 1 ? "Ano" : "Ne" )}</div>
                                                                                    <div className="text-right static small">
                                                                                        <img onClick={() => openCloseModal(true,item.homepageBannerID)} className="edit-icon" src={edit} />
                                                                                        <input className="delete-checked" type="checkbox" name="deleteAthlete[]" checked={checked} onChange = {(e) => initiateDeleteHomepageBanner(e,item.homepageBannerID)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                        </Draggable>
                                                                    )
                                                                    
                                                                })}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            }
                                        </div>
                                        {!loading ? 
                                            <Fragment>
                                                {homepageBannersData && homepageBannersData.allHomepageBanners && homepageBannersData.allHomepageBanners.length == 0 &&
                                                    <NoItems text={translate.t("NoItemsAddedYet")} />
                                                }
                                            </Fragment>
                                            :                                    
                                            <Loading />                                    
                                        }
                                        
                                    </div>
                                    :
                                    <Fragment>
                                        {error &&  <Error text={error} />}
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div> 	
                {showAdd &&
                    <AddEditHomepageBanner openCloseModal={openCloseModal} selectedHomepageBannerID={selectedHomepageBannerID} listVariables={listVariables} />
                }	
                {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delHomepageBanner} /> }	
            </div>
        </>
    )

}

export default withApollo(Homepage);