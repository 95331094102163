import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {ADD_EDIT_BRANCH_BLOCKED_DAY, GET_BRANCH_BLOCKED_DAY,} from '../Queries/branchBlockedDays';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import BranchBlockedDaysLib from '../Library/branchBlockedDays';
import DatePicker,{ registerLocale } from 'react-datepicker';

const AddEditBranchBlockedDay = (props) => {

    const branchBlockedDays = new BranchBlockedDaysLib(props);

    const [formData,setFormData] = useState({
        
        branchBlockedDayID:props.selectedBranchBlockedDayID,
        branchID: props.branchID,
        dateBlocked:"",
        
    });

    const {data:branchData,loading:getLoading,error:getError} = useQuery(GET_BRANCH_BLOCKED_DAY,{
        variables:{branchBlockedDayID: formData.branchBlockedDayID},
        fetchPolicy: 'network-only',
        onCompleted(data){

            branchBlockedDays.getData(data,formData.branchBlockedDayID,formData,setFormData);
            
        },
    })

    const [addBranchBlockedDay,{data:addBranchData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_BRANCH_BLOCKED_DAY,{
        onCompleted(data){

			props.openCloseModal(false);
            
		},update(cache,response) {    
            
            branchBlockedDays.updateList(cache,response,props.listVariables,formData.branchBlockedDayID);
            
            let notify = new Notification();
            if(formData.branchBlockedDayID && formData.branchBlockedDayID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    })

    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError)
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.branchBlockedDayID && formData.branchBlockedDayID != 0 ? translate.t("EditBranch") : translate.t("AddBranch"))}</h4>
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                <Fragment>  
 
                                    <div className="row">    
                                                                            
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("BlockedDay")}</label>
                                                <div className="form-group">
                                                    <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={formData.dateBlocked} onChange={date => setFormData({...formData,dateBlocked: date})} />
                                                </div>
                                            </div>                                                
                                        </div> 
                       
                                    </div> 
                                        
                                </Fragment> 
                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button athlete="button" className="btn btn-primary flex-fill" onClick={() => branchBlockedDays.addBranchBlockedDay(addBranchBlockedDay,formData)}>{(formData.branchBlockedDayID && formData.branchBlockedDayID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )

}

export default withApollo(AddEditBranchBlockedDay);