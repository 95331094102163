import React,{ Component, Fragment, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import AppRatingLib from '../Library/appRating';
import { GET_APP_RATING_DATA,ADDEDIT_APP_RATING_DATA } from '../Queries/appRating';

const AppRating = (props) => {

    const helper = new Helper();
    helper.checkAdminRights(props,["1","2"]);
    
    const appRatingLib = new AppRatingLib(props);
    const [formData,setFormData] = useState({
        appRatingModalID:1,
        selectedLang:"cz",
        langs:[],
        active:1,
    });

    
    const [allLanguageMutations, setAllLanguageMutations] = useState([]);
    const {loading, error:getError, data:getAppRatingData, refetch}   = useQuery(GET_APP_RATING_DATA,{
        variables:{
            appRatingModalID:1
        },
        fetchPolicy: 'cache-and-network',
        onCompleted(data){
            console.log(data);
            setAllLanguageMutations(data.allLanguageMutations);   
            appRatingLib.getData(data,formData,setFormData);
              
        },
    });
    
    const [editAppRatingData,{data:addRatingData,loading:addRatingLoading,error:addRatingError}] = useMutation(ADDEDIT_APP_RATING_DATA,{
        onCompleted(data){
            
		},update(cache,response) {            
            
            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    })
    
    return (
        <>
            <div id="settings" className="whole-container" >
                
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <div className="card-header d-flex align-items-center">
                                <h4>{translate.t("AppRatingModal")}</h4>
                                {allLanguageMutations && allLanguageMutations.length > 1 &&
                                    <div className="ml-auto">
                                        <select className="form-control" name="selectedLang" value={formData.selectedLang} onChange={(e) => appRatingLib.formHandle(e,formData,setFormData)}>
                                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.suffix}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                }
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <label className="input-label">{translate.t("Title")} ({formData.selectedLang})</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="title" value={appRatingLib.getLangValue(formData,"title")} onChange={(e) => appRatingLib.formLangHandle(e,formData,setFormData)} />
                                        </div>
                                        
                                        <label className="input-label">{translate.t("Text")} ({formData.selectedLang})</label>
                                        <div className="form-group">
                                            <textarea className="form-control" type="text" name="text" value={appRatingLib.getLangValue(formData,"text")} onChange={(e) => appRatingLib.formLangHandle(e,formData,setFormData)} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <label className="input-label">{translate.t("Active")}</label>
                                        <div className="form-group">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(formData.active == 1 ?  true : false)} onChange={(e) => appRatingLib.formHandle(e,formData,setFormData)} />
                                                <label className="form-check-label" htmlFor="active1">
                                                {translate.t("Yes")}
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(formData.active == 0 ?  true : false)} onChange={(e) => appRatingLib.formHandle(e,formData,setFormData)} />
                                                <label className="form-check-label" htmlFor="active2">
                                                {translate.t("No")}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                            <div className="modal-footer">                                
                                <button type="button" className="btn btn-primary " onClick={() => appRatingLib.saveAppRatingDataData(editAppRatingData,formData)}>{translate.t("Edit")}</button>
                            </div>
                        </div>
                    </div>
                </div> 	
            </div>
        </>
    )

}

export default withApollo(AppRating);