/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_APPBANNER = gql`
    mutation AddEditAppBanner($active:Int,$appBannerID:ID,$photo:Upload, $langs:[AppBannerLangsInput]){
        addEditAppBanner(active:$active, appBannerID:$appBannerID,photo:$photo,langs:$langs){
            appBannerID
            active
            photo
        }
    }
`;


/**
 *  Data kategorie
 */

export const GET_APPBANNER = gql`
    query AppBanner($appBannerID: ID!){
        appBannerWithLangs(appBannerID:$appBannerID){
            appBannerID
            active  
            photo
            langs{
                lang
                text
            }   
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_APPBANNERS = gql`
    query AllAppBanners($lang:String){
        allAppBanners(lang:$lang,onlyActive:false){            
            appBannerID
            active  
            photo
            text
            lang  
        }
    }
`;

/**
 * Smazání kategorie
 */

export const DELETE_APPBANNERS = gql`
    mutation deleteAppBanners($appBannerIDs:ID!) {
        deleteAppBanners(appBannerIDs:$appBannerIDs)
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_APPBANNER_PRIORITY = gql`
    mutation updateAppBannerPriority($appBannerID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateAppBannerPriority(appBannerID:$appBannerID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;