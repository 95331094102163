/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_SIDEDISH = gql`
    mutation AddEditSideDish($active:Int,$isAlcohol:Int,$sideDishID:ID,$photo:Upload, $langs:[SideDishLangsInput]){
        addEditSideDish(active:$active, isAlcohol:$isAlcohol, sideDishID:$sideDishID,photo:$photo,langs:$langs)
    }
`;


/**
 *  Data kategorie
 */

export const GET_SIDEDISH = gql`
    query SideDish($sideDishID: ID!){
        sideDishWithLangs(sideDishID:$sideDishID){
            sideDishID
            active  
            isAlcohol
            photo
            langs{
                lang
                name
            }      
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_SIDEDISHES = gql`
    query AllSideDishes($lang:String){
        allSideDishes(lang:$lang,onlyActive:false){            
            sideDishID
            isAlcohol
            name
            prices{
                price
                countryID
                country{
                    currencyID
                    currency{
                        code
                    }
                }
            }
            active    
        }
    }
`;

/**
 * Smazání kategorie
 */

export const DELETE_SIDEDISHES = gql`
    mutation deleteSideDishes($sideDishIDs:ID!) {
        deleteSideDishes(sideDishIDs:$sideDishIDs)
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_SIDEDISH_PRIORITY = gql`
    mutation updateSideDishPriority($sideDishID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateSideDishPriority(sideDishID:$sideDishID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;