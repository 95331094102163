/**
 * soubor se seznamem veškerých graphql dotazů pro odkazy
 */
import gql from 'graphql-tag';

/**
 * vložení odkazu
 */
export const ADD_EDIT_APPLINK = gql`
    mutation AddEditAppLink(
        $appLinkID:ID,
        $parentID: String!, 
        $active:Int, 
        $countries:[ID],
        $isTermsAndConditions:Int, 
        $isPrivatePolicy:Int,
        $inHeader:Int, 
        $inFooter:Int, 
        $photo:Upload,
        $icon:Upload,
        $langs:[AppLinkLangsInput]
    ){
        addEditAppLink(
            appLinkID:$appLinkID,
            parentID: $parentID, 
            active:$active, 
            countries:$countries,
            isTermsAndConditions:$isTermsAndConditions, 
            isPrivatePolicy:$isPrivatePolicy,
            inHeader:$inHeader,
            inFooter:$inFooter,
            photo:$photo,
            icon:$icon,
            langs:$langs
        )
    }
`;

/**
 *  Úprava priority odkazu, když se přesouvají položky
 */
export const UPDATE_APPLINK_PRIORITY = gql`
    mutation updateAppLinkPriority($parentID:String!,$appLinkID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateAppLinkPriority(parentID:$parentID,appLinkID:$appLinkID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;


/**
 *  Data pro jednu kategorii
 */

export const GET_APPLINK = gql`
    query AppLinkWithLangs($appLinkID: ID!){
        appLinkWithLangs(appLinkID:$appLinkID){
            appLinkID
            active
            isTermsAndConditions
            isPrivatePolicy
            parentID
            inHeader
            inFooter 
            photo
            icon
            langs{
                lang
                name
                ownUrl
                description
                niceName
                metaTitle
                metaKeywords
                metaDescription
            } 
            countries{
                countryID
                name
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        allActiveCountries(fromAdmin:1){
            countryID
            name
        }
    }
`;

/**
 *  Seznam veškerých kategorií - maximlání zanoření je 7
 */
export const GET_APPLINKS = gql`
    query AllAppLinks($lang: String){
        allAppLinks(lang:$lang){
            appLinkID
            name
            active
            isTermsAndConditions
            isPrivatePolicy
            parentID
            inHeader
            inFooter 
            countries{
                name
            }
            subAppLink{
                appLinkID
                name
                active
                parentID
                inHeader
                inFooter 
                subAppLink{
                    appLinkID
                    name
                    active
                    parentID
                    inHeader
                    inFooter 
                    subAppLink{
                        appLinkID
                        name
                        active
                        parentID
                        inHeader
                        inFooter 
                        subAppLink{
                            appLinkID
                            name
                            active
                            parentID
                            inHeader
                            inFooter 
                            subAppLink{
                                appLinkID
                                name
                                active
                                parentID
                                inHeader
                                inFooter 
                                subAppLink{
                                    appLinkID
                                    name
                                    active
                                    parentID
                                    inHeader
                                    inFooter 
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_APPLINKS = gql`
    mutation deleteAppLink($appLinkID:ID!,$parentID:String) {
        deleteAppLink(appLinkID:$appLinkID)
        reindexAppLinkPriority(parentID:$parentID)
    }
`;
