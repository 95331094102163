/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_ARTICLE = gql`
    mutation AddEditArticle($articleID:ID!,$photo:Upload,$linkIDs:[String],$langs:[ArticleLangsInput]){
        addEditArticle(articleID:$articleID,photo:$photo,linkIDs:$linkIDs,langs:$langs){
            articleID
            title
            photo
            lang
        }
    }
`;

/**
 * publikování článku
 */
export const PUBLISH_ARTICLE = gql`
    mutation PublishArticle($articleID:ID!,$lang:String){
        publishArticle(articleID:$articleID,lang:$lang)
    }
`;


/**
 *  Data pro jednu kategorii
 */

export const GET_ARTICLE = gql`
    query ArticleWithLangs($articleID: ID!){
        articleWithLangs(articleID:$articleID){
            articleID
            photo
            linkIDs
            langs{
                lang
                title
                niceTitle
                perex
                text
                metaTitle
                metaKeywords
                metaDescription
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

/**
 *  Seznam veškerých articleů
 */
export const GET_ARTICLES = gql`
    query AllArticles($lang: String!){
        allArticles(lang: $lang){
            articleID
            title
            photo
            lang
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_ARTICLES = gql`
    mutation deleteArticles($articleIDs:ID!) {
        deleteArticles(articleIDs:$articleIDs)
    }
`;


/**
 * vyhledání článku
 */
export const SEACRH_ARTICLES = gql`

    query SearchArticles($text: String!,$lang:String){
        searchArticles(text:$text,lang:$lang){
            title
            articleID
            photo 
        }

    }

`;


