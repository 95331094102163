/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_DRIVER = gql`
    mutation AddEditDriver($driverID:ID,$driverTypeID:ID,$adminUserID:ID,$deliveryAreaID:ID,$description:String,$phone:String,$realTimeMealsCount:Int,$lang:String){
        addEditDriver(driverID:$driverID,driverTypeID:$driverTypeID,adminUserID:$adminUserID,deliveryAreaID:$deliveryAreaID,description:$description,phone:$phone,realTimeMealsCount:$realTimeMealsCount,lang:$lang){
            driverID
            driverTypeID
            deliveryAreaID
            adminUserID
            realTimeMealsCount
            phone
            description
            user{
                name
                surname
            } 
            deliveryArea{
                name
            }  
            driverType{
                driverTypeID
                title
            } 
        }
    }
`;


/**
 *  Seznam veškerých athleteů
 */
export const GET_DRIVERS = gql`
    query AllDrivers($offset:Int,$limit:Int){
        allDrivers(offset:$offset,limit:$limit){            
            driverID
            deliveryAreaID
            adminUserID
            driverTypeID
            realTimeMealsCount
            description
            phone
            driverType{
                driverTypeID
                title
            }
            user{
                name
                surname
            } 
            deliveryArea{
                name
            }     
        }
        driverListCount
    }
`;

/**
 *  Seznam veškerých athleteů
 */
export const GET_DRIVER = gql`
    query Driver($driverID:ID){
        driver(driverID:$driverID){            
            driverID
            driverTypeID
            deliveryAreaID
            adminUserID
            realTimeMealsCount
            description    
            phone
            driverType{
                driverTypeID
                title
            }
        }
        allUnusedAdminUserDrivers(lang:"cz",driverID: $driverID){
            adminUserID
            name
            surname
        }
        allDriverTypes{
            driverTypeID
            title
        }
        allBranchDeliveryAreas{
            deliveryAreaID
            name
        }
        allNoBranchDeliveryAreas{
            deliveryAreaID
            name
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_DRIVERS = gql`
    mutation deleteDrivers($driverIDs:ID!) {
        deleteDrivers(driverIDs:$driverIDs)
    }
`;



