import React,{ Fragment, useState } from 'react';
import Orders from './Orders';

const AllOrders = (props) => {


    return(
        <div id="settings" className="whole-container" >
            <Orders showInvoice={true} />
        </div>
    )

}

export default AllOrders;