import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {GET_MEAL_IMAGES,DELETE_MEAL_IMAGES,UPLOAD_MEAL_IMAGES} from '../Queries/mealImages';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import {SERVER_URL,MEAL_IMAGE_URL,MAX_UPLOAD_FILE_LIMIT} from '../../../Config/index';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import MealImageLib from '../Library/mealImage';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';


const MealPhoto = (props) => {

    const mealPhotoLib = new MealImageLib(props);

    const [showDeleteInfo,setDeleteInfo] = useState(false);
    const [mealImagesID,setMealImages] = useState([]);

    var {data,loading,error:getError} = useQuery(GET_MEAL_IMAGES,{
        variables:{
            mealID:props.mealID
        }
    })

    const [deleteMealImages,{loading:deleteLoading}] = useMutation(DELETE_MEAL_IMAGES,{
        variables:{
            mealID: props.mealID
        },
        update(cache,response) {
            mealPhotoLib.updateMealImagesAfterDelete(cache,response,props.mealID);
        }
    })
    
    const [uploadMealImages,{error:uploadError,loading:uploadLoading}] = useMutation(UPLOAD_MEAL_IMAGES,{
        update(cache,response) {
            mealPhotoLib.updateMealImages(cache,response,props.mealID);
        }
    });

    const showDelNotifi = () => {
        if(mealImagesID.length > 0){
            setDeleteInfo(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const delMealImage = (action) => {

        if(action){

            deleteMealImages({variables:{mealImagesIDs:mealImagesID}});
        }

        setDeleteInfo(false);
    }
    const uploadFiles = (files) => {
        let fileSizeExceeded = false;
        for(let f of files){
            console.log(f.size);
            if((f.size / 1000000) > MAX_UPLOAD_FILE_LIMIT){
                fileSizeExceeded = true;
            }
        }
        
        if(!fileSizeExceeded){
            uploadMealImages({ variables: { files:files, mealID: props.mealID } });
        }else{
            let notify      = new Notification();
            notify.setNotification(null,translate.t("MaxFileSizeExceededInOneOfFile"),false,true,props.client);
        }
        
    }

    var error = "";
    if(getError || uploadError){

        var err = getError || uploadError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(err);
    }

    if(deleteLoading)loading = deleteLoading;
    if(uploadLoading)loading = uploadLoading;

    return(

        <Fragment>

            {error ?
                <Error text={error} />
                 :
                <Fragment>

                    <div className="form-group input-group">
                        <div className="custom-file">                            
                            <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                                required
                                multiple
                                accept=".jpg, .jpeg"
                                onChange={
                                    ({ target: { validity, files: files } }) => uploadFiles(files)
                                }
                            />
                            <label className="custom-file-label" htmlFor="customFile">{translate.t("ChoosePhotos")}<small className=" ml-2">(JPG/JPEG, {translate.t("Max")} {MAX_UPLOAD_FILE_LIMIT}Mb {translate.t("PerFile")})</small></label>
                        </div>
                        <div className="input-group-append">
                            <button className="btn btn-danger btn-delete-files" type="button" onClick={() => showDelNotifi()}>{translate.t("DeleteSelectedPhotos")}</button>
                        </div>
                    </div>

                    {loading ? 

                        <Loading />
                
                        :
                
                        <div className="row">
                    
                            {data.mealImages && data.mealImages.map((item,index) => {
                        
                                return(
                                    <div key={index} className="col-2 form-group">
                                        <div className="image-item">
                                            <div className="menu-item">
                                                <input type="checkbox" name="delete" onChange={(e) => mealPhotoLib.setDeleteImage(e,item.mealImageID,mealImagesID,setMealImages)} />
                                            </div>
                                            
                                            <img src={SERVER_URL + "/" + MEAL_IMAGE_URL + "/m-" + props.mealID + "/mala_" + item.name} />
                                            
                                        </div>
                                    </div>
                                )
                            })}         
                    
                        </div>

                    }
                
                    {showDeleteInfo && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané obrázky ?'} callback={delMealImage} />}
                    
                </Fragment>
            }
        
        </Fragment>
    )

}

export default withApollo(MealPhoto);









