import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {ADD_EDIT_VOUCHER, GET_BRANCHES_FOR_VOUCHER, GET_VOUCHER} from '../Queries/voucher';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import VoucherLib from '../Library/voucher';
import refresh from '../../../Public/Images/refresh.svg';

import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const AddEditVoucher = (props) => {

    const voucherLib = new VoucherLib(props);

    const [selectedTab, setTab] = useState(1);
    const [formData,setFormData] = useState({
        
        voucherID:props.selectedVoucherID,      
        voucherTypeID:1,  
        countryID:"0",
        active:"1",     
        forAllBranches:"1",
        code:"",
        description:"",
        validFrom:"",
        validTo:"",
        timeFrom:"",
        timeTo:"",
        value:""	,
        valueInPercentage:"0",
        limitedUsage:"0",
        limitedPerUser:"0",
        count:"0",
        countPerUser:"0",
        branchIDs:[]

    });

    const {data:voucherData,loading:getLoading,error:getError,refetch:refetchVoucher} = useQuery(GET_VOUCHER,{
        variables:{voucherID: formData.voucherID},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted(data){

            voucherLib.getData(data,formData.voucherID,formData,setFormData);
            
        },
    })

    const {data:branchesData,loading:branchesLoading,error:getBranchesError} = useQuery(GET_BRANCHES_FOR_VOUCHER,{
        variables:{countryID:formData.countryID},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted(data){
            
        },
    })

    const [addEditVoucher,{data:addVoucherData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_VOUCHER,{
        onCompleted(data){
            props.openCloseModal(false);            
		},update(cache,response) {  
            voucherLib.updateList(cache,response,props.listVariables,formData.voucherID);

            refetchVoucher();
            let notify = new Notification();
            if(formData.voucherID && formData.voucherID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            console.log(err);
            
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
            
        }
    })
    
    const getCurrency = () => {
        if(voucherData && voucherData.allActiveCountries){
            const selectedCountry = voucherData.allActiveCountries.filter(item => item.countryID == formData.countryID);
            console.log(selectedCountry);
            if(selectedCountry[0] && selectedCountry[0].currency && selectedCountry[0].currency.title){
                return selectedCountry[0].currency.title
            }
        }
        return "";
    }
    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.voucherID && formData.voucherID != 0 ? translate.t("EditVoucher") : translate.t("AddVoucher"))}</h4>
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div>                            
                        {!getLoading && !addLoading ?
                            <Fragment> 
                                <div className="tab-content" id="myTabContent">
                                    
                                    {selectedTab == 1 &&

                                        <div className="row">                       
                                                
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label className="input-label">*{translate.t("Code")}</label>
                                                    <div className="input-group mb-3">
                                                        <input className="form-control" type="text" name="code" value={formData.code} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                        <span className="input-group-text generate-code" title={translate.t("GenerateCode")} onClick={() => voucherLib.generateCode(formData,setFormData)}><img src={refresh} /></span>
                                                    </div>  
                                                </div>                                                
                                            </div> 
                                            <div className="col-4">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="input-label">*{translate.t("Country")}</label>
                                                            <select className="form-control" name="countryID" value={formData.countryID} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)}>
                                                                    <option value={0}>-- {translate.t("ChooseCountry")} --</option>
                                                                {voucherData && voucherData.allActiveCountries && voucherData.allActiveCountries.map((item,index) => (
                                                                    <option key={index} value={item.countryID}> {item.name} </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <label className="input-label">{translate.t("Active")}</label>
                                                        <div className="form-group">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(formData.active == 1 ?  true : false)} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                                <label className="form-check-label" htmlFor="active1">
                                                                    {translate.t("Yes")}
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(formData.active == 0 ?  true : false)} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                                <label className="form-check-label" htmlFor="active2">
                                                                    {translate.t("No")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div> 
                                            </div>
                                            <div className="col-4">
                                                <label className="input-label">{translate.t("Type")}</label>
                                                <div className="ml-auto">
                                                    <select className="form-control" name="voucherTypeID" value={formData.voucherTypeID} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)}>
                                                        {voucherData && voucherData.allVoucherTypes && voucherData.allVoucherTypes.map((item,index) => (
                                                            <option key={index} value={item.voucherTypeID}> {item.name} </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>  
                                            <div className="col-4">                                                
                                                <div className="form-group">
                                                    <label className="input-label">{translate.t("Description")}</label>
                                                    <div className="form-group">
                                                        <textarea style={{height:"300px"}} className="form-control" type="text" name="description" value={formData.description} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                    </div>
                                                </div>                                                
                                            </div> 
                                            <div className="col-8">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className="form-group">
                                                            <label className="input-label">{translate.t("ValidFrom")}</label>
                                                            <div className="form-group datepicker-w-100">
                                                                <DatePicker className="form-control w-100" dateFormat="yyyy-MM-dd" locale={translate.locale == "cz" ? "cs" : "en"} selected={formData.validFrom} onChange={date => setFormData({...formData,validFrom:date})} />
                                                            </div>
                                                        </div>                                                
                                                    </div> 
                                                    <div className="col-3">
                                                        <div className="form-group">
                                                            <label className="input-label">{translate.t("ValidTo")}</label>
                                                            <div className="form-group datepicker-w-100">
                                                                <DatePicker className="form-control w-100" dateFormat="yyyy-MM-dd" locale={translate.locale == "cz" ? "cs" : "en"} selected={formData.validTo} onChange={date => setFormData({...formData,validTo:date})} />
                                                            </div>
                                                        </div>                                                
                                                    </div> 
                                                    <div className="col-3">
                                                        <div className="form-group">
                                                            <label className="input-label">{translate.t("TimeFrom")}</label>
                                                            <div className="form-group datepicker-w-100">
                                                                <input className="form-control" type="text" name="timeFrom" placeholder="hh:mm" value={formData.timeFrom} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                            </div>
                                                        </div>                                                
                                                    </div> 
                                                    <div className="col-3">
                                                        <div className="form-group">
                                                            <label className="input-label">{translate.t("TimeTo")}</label>
                                                            <div className="form-group datepicker-w-100">
                                                                <input className="form-control" type="text" name="timeTo" placeholder="hh:mm" value={formData.timeTo} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                            </div>
                                                        </div>                                                
                                                    </div> 
                                                    {formData.voucherTypeID == 1 &&
                                                        <>
                                                            <div className="col-6">
                                                                <label className="input-label">{translate.t("ValueInPercentage")}</label>
                                                                <div className="form-group">
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="valueInPercentage" id="valueInPercentage1" value="1" checked={(formData.valueInPercentage == 1 ?  true : false)} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                                        <label className="form-check-label" htmlFor="valueInPercentage1">
                                                                            {translate.t("Yes")}
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="valueInPercentage" id="valueInPercentage2" value="0" checked={(formData.valueInPercentage == 0 ?  true : false)} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                                        <label className="form-check-label" htmlFor="valueInPercentage2">
                                                                            {translate.t("No")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>  
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label className="input-label">*{translate.t("Value")}</label>                                                                
                                                                    <div className="input-group mb-3">
                                                                        <input className="form-control" type="number" step="1" name="value" value={formData.value} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                                        <span className="input-group-text">{formData.valueInPercentage == "1" ? "%" : getCurrency()}</span>
                                                                    </div>    
                                                                </div>  
                                                                                                            
                                                            </div> 
                                                        </>
                                                    }
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="input-label">{translate.t("Quantity")}</label>
                                                            
                                                            <div className="form-group">
                                                                
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="limitedUsage" id="limitedUsage2" value="0" checked={(formData.limitedUsage == "0" ?  true : false)} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                                    <label className="form-check-label" htmlFor="limitedUsage2">
                                                                        {translate.t("Unlimited")} 
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="limitedUsage" id="limitedUsage1" value="1" checked={(formData.limitedUsage == "1" ?  true : false)} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                                    <label className="form-check-label" htmlFor="limitedUsage1">
                                                                        {translate.t("LimitedByCount")}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>                                                
                                                    </div>
                                                    {formData.limitedUsage == "1" ?
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("VoucherCount")}</label>                                                                
                                                                <div className="form-group">
                                                                    <input className="form-control" type="number" step="1" name="count" value={formData.count} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                                </div>  
                                                            </div>                                                                                                              
                                                        </div>    
                                                        :
                                                        <div className="col-6" style={{minHeight:"93.2px"}}></div>                             
                                                    } 
                                                    
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="input-label">{translate.t("LimitPerUser")}</label>
                                                            
                                                            <div className="form-group">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="limitedPerUser" id="limitedPerUser1" value="1" checked={(formData.limitedPerUser == "1" ?  true : false)} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                                    <label className="form-check-label" htmlFor="limitedPerUser1">
                                                                        {translate.t("Yes")}
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="limitedPerUser" id="limitedPerUser2" value="0" checked={(formData.limitedPerUser == "0" ?  true : false)} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                                    <label className="form-check-label" htmlFor="limitedPerUser2">
                                                                        {translate.t("No")} 
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>                                                
                                                    </div>
                                                    {formData.limitedPerUser == "1" ?
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("MaxUsesPerUser")}</label>                                                                
                                                                <div className="form-group">
                                                                    <input className="form-control" type="number" step="1" name="countPerUser" value={formData.countPerUser} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                                </div>  
                                                            </div>  
                                                                                                        
                                                        </div>      
                                                        :
                                                        <div className="col-6" style={{minHeight:"93.2px"}}></div>                             
                                                    } 
                                                </div>  
                                            </div>  
                                            <div className="col-12">
                                                <div className="card main">
                                                    <div className="card-header d-flex align-items-center">
                                                        {translate.t("BranchSettings")}
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-6 ">                                                                
                                                                <div className="form-group">
                                                                    <label className="input-label">{translate.t("IsForAllBranches")}</label>
                                                                    <div className="form-group">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="forAllBranches" id="forAllBranches1" value="1" checked={(formData.forAllBranches == 1 ?  true : false)} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                                            <label className="form-check-label" htmlFor="forAllBranches1">
                                                                                {translate.t("Yes")}
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="forAllBranches" id="forAllBranches2" value="0" checked={(formData.forAllBranches == 0 ?  true : false)} onChange={(e) => voucherLib.formHandle(e,formData,setFormData)} />
                                                                            <label className="form-check-label" htmlFor="forAllBranches2">
                                                                                {translate.t("No")}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {formData.forAllBranches == 0 &&
                                                                <div className="col-6 ">                                                      
                                                                    <div className="form-group">
                                                                        <label className="input-label">{translate.t("SelectBranches")}</label>
                                                                        <div className="form-check">
                                                                            {formData.countryID != 0 ?                                                                                
                                                                                <>
                                                                                    {branchesData && branchesData.allBranches && branchesData.allBranches.map((item,index) => (                                                            
                                                                                        <div className="form-check" key={index}>
                                                                                            <input className="form-check-input" type="checkbox" id={"branchCheck" + item.branchID} value={item.branchID} checked={formData.branchIDs.indexOf(item.branchID) !== -1} onChange={(e) => voucherLib.selectBranchInList(e,formData,setFormData)} />
                                                                                            <label className="form-check-label" htmlFor={"branchCheck" + item.branchID}>
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    ))}
                                                                                </>
                                                                                :
                                                                                <div>{translate.t("ChooseCountryToSelectBranches")}</div>
                                                                            }
                                                                        </div>
                                                                    </div>  
                                                                </div>  
                                                            }                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                                      
                                        </div>

                                        
                                        
                                    }


                                </div>

                            </Fragment> 
                        :
                        <Loading />  
                        }  
                    </div>
                </div>
                <div className="modal-footer">                                
                    <button athlete="button" className="btn btn-primary flex-fill" onClick={() => voucherLib.addVoucher(addEditVoucher,formData,branchesData.allBranches)}>{(formData.voucherID && formData.voucherID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )

}

export default withApollo(AddEditVoucher);