import {GET_VOUCHERS} from '../Queries/voucher';
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';
import Helper from '../../../Library/helper';
const moment             = require("moment");

class Voucher {

    constructor(props){
        this.props = props;
    }

    addVoucher(addEditVoucher,formData,allBranches){
        formData.code = formData.code.replaceAll(" ","");
        
        if(formData.code != ""){
            if(formData.countryID != 0){

                if(formData.value != "" || formData.voucherTypeID == 2){
                    
                    let sendData = {...formData};
                    sendData.active             = parseInt(sendData.active);
                    sendData.forAllBranches     = parseInt(sendData.forAllBranches);
                    sendData.valueInPercentage  = sendData.voucherTypeID == 1  ? parseInt(sendData.valueInPercentage) : 0;
                    sendData.limitedUsage       = parseInt(sendData.limitedUsage);
                    sendData.limitedPerUser     = parseInt(sendData.limitedPerUser);
                    sendData.count              = parseInt(sendData.count);
                    sendData.countPerUser       = parseInt(sendData.countPerUser);
                    sendData.value              = sendData.voucherTypeID == 1  ? parseFloat(sendData.value) : 0;
                    sendData.validFrom          =  sendData.validFrom           ? moment(sendData.validFrom).format("YYYY-MM-DD") + " 00:00:00" : null;
                    sendData.validTo            =  sendData.validTo             ? moment(sendData.validTo).format("YYYY-MM-DD") + " 23:59:59" : null;
                    sendData.timeFrom           =  sendData.timeFrom            ? sendData.timeFrom : null;
                    sendData.timeTo             =  sendData.timeTo              ? sendData.timeTo : null;
                    sendData.count              =  sendData.count != "" && sendData.limitedUsage ? parseInt(sendData.count) : 0;
                    sendData.countPerUser       =  sendData.countPerUser != "" && sendData.limitedPerUser  ? parseInt(sendData.countPerUser) : 0;
                    sendData.branchIDs          =  this.setBranchIDsForSend(sendData,allBranches);
                    
                    addEditVoucher({
                        variables:{
                            voucherData:sendData
                        }
                    }); 

                }else{
                    let notify = new Notification();
                    notify.setNotification(null,translate.t("IvalidVoucherValue"),false,true,this.props.client);
                }

            }else{
                let notify = new Notification();
                notify.setNotification(null,translate.t("InvalidCountry"),false,true,this.props.client);
            }
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidCode"),false,true,this.props.client);
        }

    }

    setBranchIDsForSend = (sendData,allBranches) => {
        const allBranchIDs = sendData.forAllBranches == 0  ? sendData.branchIDs : [];
        let allBranchIDsToSend = [];

        for(const item of allBranchIDs){
            for(const branch of allBranches){
                if(branch.branchID == item && branch.countryID == sendData.countryID){
                    allBranchIDsToSend.push(item);
                }
            }
        }
        return allBranchIDsToSend;

    }
    getData(data,voucherID,formData,setFormData){
        
        if(voucherID != 0){
            let branchIDs = [];
            for(const branchData of data.voucher.allowedBranches){
                branchIDs.push(branchData.branchID);
            }
            setFormData({...formData,                           
                active:data.voucher.active,    
                countryID:  data.voucher.countryID,           
                forAllBranches:data.voucher.forAllBranches,
                code:data.voucher.code,
                description:data.voucher.description,
                validFrom:data.voucher.validFrom    ? moment(data.voucher.validFrom).toDate() : null,
                validTo:data.voucher.validTo        ? moment(data.voucher.validTo).toDate() : null,
                timeFrom:data.voucher.timeFrom     ? moment(data.voucher.timeFrom,"HH:mm").format("HH:mm") : "",
                timeTo:data.voucher.timeTo         ? moment(data.voucher.timeTo,"HH:mm").format("HH:mm") : "",
                value:data.voucher.value,
                valueInPercentage:data.voucher.valueInPercentage,
                limitedUsage:data.voucher.limitedUsage,
                limitedPerUser:data.voucher.limitedPerUser,
                count:data.voucher.count,
                countPerUser:data.voucher.countPerUser,
                voucherTypeID:data.voucher.voucherTypeID,
                branchIDs
            });
        }
    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateList(cache, response,variables,voucherID){

        const { allVouchers,voucherListCount } = cache.readQuery({ query: GET_VOUCHERS ,variables});       
        
        if(voucherID && voucherID != 0){

            var arr = [...allVouchers];

            arr.forEach((item,index) => {
                
                if(item.voucherID == response.data.addEditVoucher.voucherID){
                    arr[index] = response.data.addEditVoucher;
                }

            });

            cache.writeQuery({ 
                query:GET_VOUCHERS,
                variables,
                data:{
                    allVouchers: arr,
                    voucherListCount :voucherListCount
                } 
            });
/*
            cache.writeQuery({ 
                query:GET_VOUCHER_MEALS_OPENING_TIMES,
                variables:{voucherID},
                data:{
                    allVoucherMealsForAdmin: response.data.addEditVoucher.voucherMeals,
                    allVoucherOpeningTimes: response.data.addEditVoucher.voucherOpenTimes,
                } 
            });
  */      

        }else{
            var arr = [response.data.addEditVoucher,...allVouchers];

            cache.writeQuery({ 
                query:GET_VOUCHERS,
                variables,
                data:{
                    allVouchers: arr,
                    voucherListCount :voucherListCount
                } 
            });
        }

    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteVouchers.split(",");

        const { allVouchers,voucherListCount } = cache.readQuery({ query: GET_VOUCHERS ,variables});
        var arr = [...allVouchers];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.voucherID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_VOUCHERS,
            variables,
            data:{
                allVouchers: [...arr],
                voucherListCount :voucherListCount - resp.length
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }
    generateCode(formData,setFormData){
	    const helper = new Helper();
        const code = helper.makeid(6).toUpperCase();
        setFormData({...formData,code});
              
    }

    selectBranchInList(e,formData,setFormData){
        const t = e.target;
        const v = t.value;

        let arr = [...formData.branchIDs];
        const itemIndex = formData.branchIDs.indexOf(t.value);
        if(itemIndex === -1){
            arr.push(t.value);
        }else{
            arr.splice(itemIndex, 1);
        }
        setFormData({...formData,branchIDs:arr});
    }
}

export default Voucher;