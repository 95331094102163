let sandboxEnabled                = false;
let graphqlServerUrl              = "https://server.bombayexpress.app/graphql";
let serverUrl                     = "https://server.bombayexpress.app";

/*************************/
/*** TESTOVACÍ SERVER ****/
/*************************/
if(sandboxEnabled){
    graphqlServerUrl             = "https://bombayexpress-server.znackarna.dev/graphql";
    serverUrl                     = "https://bombayexpress-server.znackarna.dev";
}

if (process && process.env && process.env.NODE_ENV === "development") {
    graphqlServerUrl              = "http://localhost:8919/graphql";
    serverUrl                      = "http://localhost:8919";
}

export const GRAPHQL_SERVER_URL              = graphqlServerUrl;
export const SERVER_URL                      = serverUrl;
export const SANDBOX_ENABLED                 = sandboxEnabled;
export const BANNER_IMAGE_URL                = "Public/Images/Banner";
export const TINYMCE_IMAGE_URL               = "Public/Images/TinyMCE";
export const TINYMCE_FILE_URL                = "Public/Files/TinyMCE";
export const MARKETING_IMAGE_URL             = "Public/Images/Marketing";
export const ARTICLE_IMAGE_URL               = "Public/Images/Article";
export const COMPETITION_IMAGE_URL           = "Public/Images/Competition";
export const COMPETITION_FILE_URL            = "Public/Files/Competition";
export const ATHLETE_IMAGE_URL               = "Public/Images/Athlete";
export const MEAL_IMAGE_URL                  = "Public/Images/Meal";
export const SIDEDISH_IMAGE_URL              = "Public/Images/SideDish";
export const OPTION_IMAGE_URL                = "Public/Images/Options";
export const DRINK_IMAGE_URL                 = "Public/Images/Drink";
export const BRANCH_IMAGE_URL                = "Public/Images/Branch";
export const CONTENT_MANAGER_IMAGE_URL       = "Public/Images/ContentManager";
export const APPLINK_IMAGE_URL               = "Public/Images/AppLink";
export const LINK_IMAGE_URL                 = "Public/Images/Link";
export const APPBANNER_IMAGE_URL             = "Public/Images/AppBanner";
export const HOMEPAGEBANNER_IMAGE_URL        = "Public/Images/HomepageBanner";
export const MAX_UPLOAD_FILE_LIMIT           = 2; //Mb
export const GOOGLE_API_KEY                  = 'AIzaSyCaUulvkVAiUm6YIUj4PJhStarx0uLbKdw';
export const CATEGORY_IMAGE_URL              = "Public/Images/Category";

