import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import translate from '../../Translations/index';
import NoItems from '../../../GlobalComponents/NoItems';
import remove from '../../../Public/Images/remove.svg';
import plus from '../../../Public/Images/plus_green.svg';
import BranchOpenTime from '../Library/brancheOpenTime';

var error = "";
var loading = false;

const BranchOpeningTimes = (props) => {

    var openTimes     = props.formData.openTimes;
    var setOpenTimes  = props.setFormData;

    var branchOpenTime = new BranchOpenTime(props);

    return(

        <Fragment>

            {error ?
                <Error text={error} />
                 :
                <Fragment>

                    {loading ? 

                        <Loading />
                
                        :
                
                        <div className="card main">
                        
                            <div className="card-header d-flex align-items-center">
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => branchOpenTime.addOpenTime()}>{translate.t("Add")}</button>
                            </div>
                            <div className="card-body">

                                <p>{translate.t("IfDontFillOpenTimes")}</p>

                                <div className="data-list">
                                    
                                    <div className="data-list-item header">
                                        <div className="data-list-item-content">
                                            <div>{translate.t("TimeFromTo")}</div>
                                            <div className="text-center static small">{translate.t("Mon")}</div>
                                            <div className="text-center static small">{translate.t("Tue")}</div>
                                            <div className="text-center static small">{translate.t("Wed")}</div>
                                            <div className="text-center static small">{translate.t("Thu")}</div>
                                            <div className="text-center static small">{translate.t("Fri")}</div>
                                            <div className="text-center static small">{translate.t("Sat")}</div>
                                            <div className="text-center static small">{translate.t("Sun")}</div>
                                            <div className="text-right static small">{translate.t("Options")}</div>
                                        </div>
                                    </div>
                                    <Fragment>
                                        {openTimes && openTimes.map((item,index) => {
                                                                                                            
                                            return (
                                            
                                                <div key={index} className="data-list-item-content">
                                                    <div className="d-flex">
                                                        {item.times.map((it,ind) => (
                                                            <div key={ind} className="d-flex align-items-center">
                                                                <input type="text" value={it.timeFrom} onChange={(e) => branchOpenTime.setTime(e,index,ind)} name="timeFrom" className="form-control mr-1" placeholder={translate.t("From")} /> 
                                                                - 
                                                                <input type="text" value={it.timeTo} onChange={(e) => branchOpenTime.setTime(e,index,ind)} name="timeTo" className="form-control ml-1 mr-3" placeholder={translate.t("To")} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="text-center static small"><input type="checkbox" checked={item.mon == 1 ? true : false} onChange={(e) => branchOpenTime.setDay(e,index)} name="mon" /></div>
                                                    <div className="text-center static small"><input type="checkbox" checked={item.tue == 1 ? true : false} onChange={(e) => branchOpenTime.setDay(e,index)} name="tue" /></div>
                                                    <div className="text-center static small"><input type="checkbox" checked={item.wed == 1 ? true : false} onChange={(e) => branchOpenTime.setDay(e,index)} name="wed" /></div>
                                                    <div className="text-center static small"><input type="checkbox" checked={item.thu == 1 ? true : false} onChange={(e) => branchOpenTime.setDay(e,index)} name="thu" /></div>
                                                    <div className="text-center static small"><input type="checkbox" checked={item.fri == 1 ? true : false} onChange={(e) => branchOpenTime.setDay(e,index)} name="fri" /></div>
                                                    <div className="text-center static small"><input type="checkbox" checked={item.sat == 1 ? true : false} onChange={(e) => branchOpenTime.setDay(e,index)} name="sat" /></div>
                                                    <div className="text-center static small"><input type="checkbox" checked={item.sun == 1 ? true : false} onChange={(e) => branchOpenTime.setDay(e,index)} name="sun" /></div>
                                                
                                                    <div className="text-right static small">
                                                        <img className="plus-icon mr-1" src={plus} onClick={() => branchOpenTime.addTime(index)} />
                                                        <img className="remove-icon" src={remove} onClick={() => branchOpenTime.deleteOpenTime(index)} />
                                                    </div>
                                                </div>
                                                        
                                            )
                                            
                                        })} 
                                    </Fragment>
                                </div>
                                
                                
                                {openTimes && openTimes.length == 0 &&
                                    <NoItems text={translate.t("NoItemsAddedYet")} /> 
                                }
 
                            </div>
                        </div>

                    }
                
                </Fragment>
            }
        
        </Fragment>
    )

}

export default withApollo(BranchOpeningTimes);









