import React,{ Component } from 'react';
import ReactDOM from 'react-dom';

const INITIAL_STATE = {
    value:""
}

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class SearchInput extends Component {

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;

        this.wrapperRef         = React.createRef();
        this.changeText         = this.changeText.bind(this);
        this.handleKeyDown      = this.handleKeyDown.bind(this);
        this.triggerChange      = this.triggerChange.bind(this);
        this.handleKeyDown      = this.handleKeyDown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.timer = null;
        if(this.props.value){
            this.setState({value:this.props.value});
        }
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        if(this.props.value != prevProps.value)
        {
            this.setState({value:this.props.value});
        }
    }     

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    changeText(e){

        clearTimeout(this.timer);

        this.setState({value:e.target.value})
        this.timer = setTimeout(this.triggerChange,WAIT_INTERVAL);

        if(this.props.textChanged){
            this.props.textChanged(e.target.value);
        }
    }

    triggerChange(){

        this.props.onChange(this.state.value);
    }

    handleKeyDown(e) {
        if (e.keyCode === ENTER_KEY) {
            clearTimeout(this.timer);
            this.triggerChange();
        }
    }
	
    /**
     * Clicked outside search input
     */
    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
    
        if (!domNode || !domNode.contains(event.target)) {
            if(this.props.onClickedOutside){
                this.props.onClickedOutside();
            }
        }
    }

    render() {

        const {className,placeholder} = this.props;

        return (
            <input 
                type        = "text" 
                className   = {className} 
                placeholder = {placeholder} 
                value       = {this.state.value} 
                onChange    = {(e) => this.changeText(e)}
                onKeyDown   = {(e) => this.handleKeyDown(e)}
                autoComplete="new-password"
            />
        );
    }
  
}


export default SearchInput;
