import gql from 'graphql-tag';

export const GET_MEAL_SIDE_DISH_PRICE = gql`
    query MealSideDishPrice($mealSideDishPriceID: ID!){
        mealSideDishPrice(mealSideDishPriceID:$mealSideDishPriceID){            
            mealSideDishPriceID
            countryID
            sideDishPrice
            vatID
        }
        allActiveCountries(fromAdmin:1){
            countryID
            currencyID
            name
            currency{
                title
            }
        }        
        allVats{
            vatID
            value
        } 
    }
`;

export const ADD_EDIT_MEAL_SIDE_DISH_PRICE = gql`
    mutation AddEditMealSideDishPrice($mealSideDishPriceInputs:MealSideDishPriceInputs) {
        addEditMealSideDishPrice(mealSideDishPriceInputs:$mealSideDishPriceInputs){
            mealSideDishPriceID
            mealSideDishID
            countryID
            sideDishPrice
            vatID
        }
    }
`;

export const DELETE_MEAL_SIDE_DISH_PRICE = gql`
    mutation DeleteMealSideDishPrice($mealSideDishPriceID:[ID]!) {
        deleteMealSideDishPrice(mealSideDishPriceID:$mealSideDishPriceID)
    }
`;




