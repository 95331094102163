import React,{ Component, Fragment, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import {GET_SETTINGS,UPDATE_SETTINGS} from '../Queries/settings';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import SettingsLib from '../Library/setting';
import Notification from '../../../Library/notification';
import info from '../../../Public/Images/info.svg';
import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';

registerLocale('cs', cs);

const Settings = (props) => {

    const helper = new Helper();
    helper.checkAdminRights(props,["1","2"]);
    
    const settingsLib = new SettingsLib(props)

    const [formData,setFormData] = useState({
        company:"",
        street:"",
        city:"",
        zip:"",
        country:"",
        ic:"",
        dic:"",
        bankAccount:"",
        hideEatInOption:"0",
        hideTakeAwayOption:"0",
        deliveryTypeID:"1",
        slotDuration:"30",
        timeHandleDelivery:"5",
        enableCreditUsage:"1",
        minOrderPriceToAddCredit:[],
        inRestaurantVat:[],
        takeAwayVat:[],
        alcoholVat:[],
        deliveryPrice:[],
        deliveryPriceVat:[],
        enableVoucherUsage:[],
        firstMealFree:[],
        restaurantDeliveryTimeToAlert:[],
        restaurantDeliveryTime:[],
        restaurantCookingTime:[],
        creditPercentage:[],
        addCreditOnCashPayment:[],
        minimumOrderAmount:[],
        cashPayEnabled:[],
        termsAndConditionsChangeDate:null
    });
  
    const {loading, error:getError, data:settingsData, refetch}   = useQuery(GET_SETTINGS,{
        fetchPolicy:"network-only",
        onCompleted(data){
            settingsLib.getData(data,formData,setFormData);
        },
    });

    const [updateSettings,{data:updateData,loading:updateLoading,error:updateError}] = useMutation(UPDATE_SETTINGS,{
        update(cache,response){
            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
        }
    })

    const getCountryName = (countryID) => {
        if(settingsData && settingsData.allActiveCountries && settingsData.allActiveCountries.length){
            for(const item of settingsData.allActiveCountries){
                if(item.countryID == countryID){
                    return item.name;
                }
            }
        }
        return "";
    }

    const getCountryCurrency = (countryID) => {
        if(settingsData && settingsData.allActiveCountries && settingsData.allActiveCountries.length){
            for(const item of settingsData.allActiveCountries){
                if(item.countryID == countryID && item.currency){
                    return item.currency.title;
                }
            }
        }
        return "";
    }

    if(getError || updateError){
        var error = (getError ? getError : updateError);
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }

    return (
        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main form-group">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t("Settings")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => settingsLib.updateSettings(updateSettings,formData)} >{translate.t("Save")}</button>
                        </div>
                        
                    </div>
                    <div>

                        {!error ?
                            <div>
                                
                                {!loading ? 
                                    <div className="row" style={{flexWrap:'wrap'}}>

                                        <div className="col-12 col-sm-6">
                                        
                                            <div className="card main form-group">
                                                <div className="card-header d-flex align-items-center">
                                                    {translate.t("BillingInformation")}
                                                </div>
                                                <div className="card-body">

                                                    <div className="row">    
                                                                                
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("Company")}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="company" value={formData.company} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div>    
                
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("Street")}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="street" value={formData.street} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div>  

                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("City")}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="city" value={formData.city} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div>    
                
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("Zip")}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="zip" value={formData.zip} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div> 

                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("Country")}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="country" value={formData.country} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div> 

                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("IC")}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="ic" value={formData.ic} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div>    
                
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("DIC")}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="dic" value={formData.dic} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div> 

                                                    </div> 
                                                    
                                                </div>
                                            </div>
                                            <div className="card main form-group">
                                                <div className="card-header d-flex align-items-center">
                                                    {translate.t("Transport")}
                                                </div>
                                                <div className="card-body">

                                                    <div className="row">    

                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("DeliveryTimeFrom")}</label>
                                                                <div className="form-group">
                                                                    <select className="form-control" name="deliveryTimeFrom" value={formData.deliveryTimeFrom} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)}>
                                                                        <option value="01:00">01:00</option>
                                                                        <option value="02:00">02:00</option>
                                                                        <option value="03:00">03:00</option>
                                                                        <option value="04:00">04:00</option>
                                                                        <option value="05:00">05:00</option>
                                                                        <option value="06:00">06:00</option>
                                                                        <option value="07:00">07:00</option>
                                                                        <option value="08:00">08:00</option>
                                                                        <option value="09:00">09:00</option>
                                                                        <option value="10:00">10:00</option>
                                                                        <option value="11:00">11:00</option>
                                                                        <option value="12:00">12:00</option>
                                                                        <option value="13:00">13:00</option>
                                                                        <option value="14:00">14:00</option>
                                                                        <option value="15:00">15:00</option>
                                                                        <option value="16:00">16:00</option>
                                                                        <option value="17:00">17:00</option>
                                                                        <option value="18:00">18:00</option>
                                                                        <option value="19:00">19:00</option>
                                                                        <option value="20:00">20:00</option>
                                                                        <option value="21:00">21:00</option>
                                                                        <option value="22:00">22:00</option>
                                                                        <option value="23:00">23:00</option>
                                                                        <option value="24:00">24:00</option>
                                                                    </select>
                                                                    
                                                                </div>
                                                            </div>                                                
                                                        </div>  

                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("DeliveryTimeTo")}</label>
                                                                <div className="form-group">
                                                                    <select className="form-control" name="deliveryTimeTo" value={formData.deliveryTimeTo} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)}>
                                                                        <option value="01:00">01:00</option>
                                                                        <option value="02:00">02:00</option>
                                                                        <option value="03:00">03:00</option>
                                                                        <option value="04:00">04:00</option>
                                                                        <option value="05:00">05:00</option>
                                                                        <option value="06:00">06:00</option>
                                                                        <option value="07:00">07:00</option>
                                                                        <option value="08:00">08:00</option>
                                                                        <option value="09:00">09:00</option>
                                                                        <option value="10:00">10:00</option>
                                                                        <option value="11:00">11:00</option>
                                                                        <option value="12:00">12:00</option>
                                                                        <option value="13:00">13:00</option>
                                                                        <option value="14:00">14:00</option>
                                                                        <option value="15:00">15:00</option>
                                                                        <option value="16:00">16:00</option>
                                                                        <option value="17:00">17:00</option>
                                                                        <option value="18:00">18:00</option>
                                                                        <option value="19:00">19:00</option>
                                                                        <option value="20:00">20:00</option>
                                                                        <option value="21:00">21:00</option>
                                                                        <option value="22:00">22:00</option>
                                                                        <option value="23:00">23:00</option>
                                                                        <option value="24:00">24:00</option>
                                                                    </select>
                                                                    
                                                                </div>
                                                            </div>                                                
                                                        </div>     
                
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("SlotDuration")}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="slotDuration" value={formData.slotDuration} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div> 

                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("TimeHandleDelivery")}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="timeHandleDelivery" value={formData.timeHandleDelivery} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div> 
                                                        
                                                        <div className="col-12">
                                                            <div className="card main form-group mt-2">
                                        
                                                                <div className="card-header d-flex align-items-center font-weight-bold" style={{fontSize:"0.875rem"}}>
                                                                    {translate.t("DeliveryPrice")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        {formData.deliveryPrice && formData.deliveryPrice.length && formData.deliveryPrice.map((item,index) => (
                                                                            <div className="form-group col-6">
                                                                                <label className="input-label font-weight-normal">{getCountryName(item.countryID)}</label>
                                                                                <div className="input-group mb-3">
                                                                                    <input className="form-control" type="number" value={item.value} onChange={(e) => settingsLib.countryFormHandle(e.target.value,item.countryID,"deliveryPrice",formData,setFormData)} />
                                                                                    <span className="input-group-text">{getCountryCurrency(item.countryID)}</span>
                                                                                </div>
                                                                            </div>     
                                                                        ))}
                                                                    </div>
                                                                </div>      
                                                            </div>                                           
                                                        </div>
                                                        
                                                        <div className="col-12">
                                                            <div className="card main form-group mt-2">
                                        
                                                                <div className="card-header d-flex align-items-center font-weight-bold" style={{fontSize:"0.875rem"}}>
                                                                    {translate.t("DeliveryPriceVat")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        {formData.deliveryPriceVat && formData.deliveryPriceVat.length && formData.deliveryPriceVat.map((item,index) => (
                                                                            <div className="form-group col-6">
                                                                                <label className="input-label font-weight-normal">{getCountryName(item.countryID)}</label>
                                                                                <select className="form-control" value={item.value} onChange={(e) => settingsLib.countryFormHandle(e.target.value,item.countryID,"deliveryPriceVat",formData,setFormData)}>
                                                                                    {settingsData && settingsData.allVats && settingsData.allVats.map((item,index) => (
                                                                                        <option key={index} value={item.value}>{item.value}%</option>
                                                                                    ))}
                                                                                </select>         
                                                                            </div>     
                                                                        ))}
                                                                    </div>
                                                                </div>      
                                                            </div>                                           
                                                        </div>

                                                    </div> 
                                                    
                                                </div>
                                            </div>
                                            
                                            <div className="card main form-group">
                                                <div className="card-header d-flex align-items-center">
                                                    {translate.t("GenneralSettings")}
                                                </div>
                                                <div className="card-body">

                                                    <div className="row">    
                                                        {false &&
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label className="input-label">{translate.t("HideEatInOption")}</label>
                                                                    <div className="form-group">
                                                                        <select className="form-control" name="hideEatInOption" value={formData.hideEatInOption} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)}>
                                                                            <option value="1">{translate.t("Yes")}</option>
                                                                            <option value="0">{translate.t("No")}</option>
                                                                        </select>
                                                                        
                                                                    </div>
                                                                </div>                                                
                                                            </div>  
                                                        }  
                                                        {false &&
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label className="input-label">{translate.t("HideTakeAwayOption")}</label>
                                                                    <div className="form-group">
                                                                        <select className="form-control" name="hideTakeAwayOption" value={formData.hideTakeAwayOption} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)}>
                                                                            <option value="1">{translate.t("Yes")}</option>
                                                                            <option value="0">{translate.t("No")}</option>
                                                                        </select>
                                                                        
                                                                    </div>
                                                                </div>                                                
                                                            </div>  
                                                        }  

                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("DeliveryType")}</label>
                                                                <div className="form-group">
                                                                    <select className="form-control" name="deliveryTypeID" value={formData.deliveryTypeID} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)}>
                                                                        <option value="1">{translate.t("Realtime")}</option>
                                                                        <option value="2">{translate.t("FromRestaurant")}</option>
                                                                        <option value="3">{translate.t("RealtimeAndFromRestaurant")}</option>
                                                                    </select>
                                                                    
                                                                </div>
                                                            </div>                                                
                                                        </div> 

                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("LastTerstAndConditionsEditedDate")}</label>
                                                                <div className="form-group">
                                                                    <DatePicker className="form-control w-100" dateFormat="yyyy-MM-dd" locale={translate.locale == "cz" ? "cs" : "en"} selected={formData.termsAndConditionsChangeDate} onChange={date => setFormData({...formData,termsAndConditionsChangeDate:date})} />
                                                                </div>
                                                            </div>                                                
                                                        </div> 

                                                    </div> 
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6">

                                            <div className="card main form-group">
                                        
                                                <div className="card-header d-flex align-items-center">
                                                    {translate.t("Credit")}
                                                </div>
                                                <div className="card-body">

                                                    <div className="row">     
                                                        
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("EnableCreditUsage")}</label>
                                                                <div className="form-group">
                                                                    <select className="form-control" name="enableCreditUsage" value={formData.enableCreditUsage} onChange={(e) => settingsLib.formHandle(e,formData,setFormData)}>
                                                                        <option value="1">{translate.t("Yes")}</option>
                                                                        <option value="0">{translate.t("No")}</option>
                                                                    </select>
                                                                    
                                                                </div>
                                                            </div>                                                
                                                        </div>   
                                                        
                                                        <div className="col-12">
                                                            <div className="card main form-group mt-2">
                                        
                                                                <div className="card-header d-flex align-items-center font-weight-bold" style={{fontSize:"0.875rem"}}>
                                                                    {translate.t("AddCreditOnCashPayment")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        {formData.addCreditOnCashPayment && formData.addCreditOnCashPayment.length && formData.addCreditOnCashPayment.map((item,index) => (
                                                                            <div className="form-group col-6">
                                                                                <label className="input-label font-weight-normal">{getCountryName(item.countryID)}</label>                                                                                
                                                                                <select className="form-control" value={item.value} onChange={(e) => settingsLib.countryFormHandle(e.target.value,item.countryID,"addCreditOnCashPayment",formData,setFormData)}>
                                                                                    <option value="1">{translate.t("Yes")}</option>
                                                                                    <option value="0">{translate.t("No")}</option>
                                                                                </select>  
                                                                            </div>     
                                                                        ))}
                                                                    </div>
                                                                </div>      
                                                            </div>                                           
                                                        </div>                  
                                                        <div className="col-12">
                                                            <div className="card main form-group mt-2">
                                                                <div className="card-header d-flex align-items-center font-weight-bold" style={{fontSize:"0.875rem"}}>
                                                                    {translate.t("CreditPercentage")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        {formData.creditPercentage && formData.creditPercentage.length && formData.creditPercentage.map((item,index) => (
                                                                            <div className="form-group col-6">
                                                                                <label className="input-label font-weight-normal">{getCountryName(item.countryID)}</label>
                                                                                <div className="input-group mb-3">
                                                                                <input className="form-control" type="number" value={item.value} onChange={(e) => settingsLib.countryFormHandle(e.target.value,item.countryID,"creditPercentage",formData,setFormData)} />
                                                                                    <span className="input-group-text">%</span>
                                                                                </div>
                                                                            </div>     
                                                                        ))}
                                                                    </div>
                                                                </div>      
                                                            </div>                                           
                                                        </div> 
                                                        
                                                        <div className="col-12">
                                                            <div className="card main form-group mt-2">

                                                                <div className="card-header d-flex align-items-center font-weight-bold" style={{fontSize:"0.875rem"}}>
                                                                    {translate.t("MinOrderPriceToAddCredit")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        {formData.minOrderPriceToAddCredit && formData.minOrderPriceToAddCredit.length && formData.minOrderPriceToAddCredit.map((item,index) => (
                                                                            <div className="form-group col-6">
                                                                                <label className="input-label font-weight-normal">{getCountryName(item.countryID)}</label>
                                                                                <div className="input-group mb-3">
                                                                                <input className="form-control" type="number" value={item.value} onChange={(e) => settingsLib.countryFormHandle(e.target.value,item.countryID,"minOrderPriceToAddCredit",formData,setFormData)} />
                                                                                    <span className="input-group-text">{getCountryCurrency(item.countryID)}</span>
                                                                                </div>
                                                                            </div>     
                                                                        ))}
                                                                    </div>
                                                                </div>      
                                                            </div>                                           
                                                        </div> 
                                                    </div> 
                                                    
                                                </div>
                                            </div>

                                            <div className="card main form-group">
                                        
                                                <div className="card-header d-flex align-items-center">
                                                    {translate.t("Orders")}
                                                </div>
                                                <div className="card-body">

                                                    <div className="row">      
                                                        
                                                        <div className="col-12">
                                                            <div className="card main form-group mt-2">
                                        
                                                                <div className="card-header d-flex align-items-center font-weight-bold" style={{fontSize:"0.875rem"}}>
                                                                    {translate.t("RestaurantCookingTime")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        {formData.restaurantCookingTime && formData.restaurantCookingTime.length && formData.restaurantCookingTime.map((item,index) => (
                                                                            <div className="form-group col-6">
                                                                                <label className="input-label font-weight-normal">{getCountryName(item.countryID)}</label>
                                                                                <input className="form-control" type="number" value={item.value} onChange={(e) => settingsLib.countryFormHandle(e.target.value,item.countryID,"restaurantCookingTime",formData,setFormData)} />
                                                                                   
                                                                            </div>     
                                                                        ))}
                                                                    </div>
                                                                </div>      
                                                            </div>                                           
                                                        </div>     
                                                        
                                                        <div className="col-12">
                                                            <div className="card main form-group mt-2">
                                        
                                                                <div className="card-header d-flex align-items-center font-weight-bold" style={{fontSize:"0.875rem"}}>
                                                                    {translate.t("RestaurantDeliveryTime")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        {formData.restaurantDeliveryTime && formData.restaurantDeliveryTime.length && formData.restaurantDeliveryTime.map((item,index) => (
                                                                            <div className="form-group col-6">
                                                                                <label className="input-label font-weight-normal">{getCountryName(item.countryID)}</label>
                                                                                <input className="form-control" type="number" value={item.value} onChange={(e) => settingsLib.countryFormHandle(e.target.value,item.countryID,"restaurantDeliveryTime",formData,setFormData)} />
                                                                                   
                                                                            </div>     
                                                                        ))}
                                                                    </div>
                                                                </div>      
                                                            </div>                                           
                                                        </div>     
                                                        
                                                        <div className="col-12">
                                                            <div className="card main form-group mt-2">
                                        
                                                                <div className="card-header d-flex align-items-center font-weight-bold" style={{fontSize:"0.875rem"}}>
                                                                    {translate.t("RestaurantDeliveryTimeToAlert")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="d-flex flex-row align-items-center mb-3">
                                                                        <img className="settings-info-icon mr-2" src={info} />
                                                                        <div className="fs12 font-italic">{translate.t("RestaurantDeliveryTimeToAlertDesc")}</div>
                                                                    </div>
                                                                    <div className="row">
                                                                        {formData.restaurantDeliveryTimeToAlert && formData.restaurantDeliveryTimeToAlert.length && formData.restaurantDeliveryTimeToAlert.map((item,index) => (
                                                                            <div className="form-group col-6">
                                                                                <label className="input-label font-weight-normal">{getCountryName(item.countryID)}</label>
                                                                                <input className="form-control" type="number" value={item.value} onChange={(e) => settingsLib.countryFormHandle(e.target.value,item.countryID,"restaurantDeliveryTimeToAlert",formData,setFormData)} />
                                                                                   
                                                                            </div>     
                                                                        ))}
                                                                    </div>
                                                                </div>      
                                                            </div>                                           
                                                        </div> 
                                                        <div className="col-12">
                                                            <div className="card main form-group mt-2">
                                        
                                                                <div className="card-header d-flex align-items-center font-weight-bold" style={{fontSize:"0.875rem"}}>
                                                                    {translate.t("IsFirstMealFree")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        {formData.firstMealFree && formData.firstMealFree.length && formData.firstMealFree.map((item,index) => (
                                                                            <div className="form-group col-6">
                                                                                <label className="input-label font-weight-normal">{getCountryName(item.countryID)}</label>
                                                                                
                                                                                <select className="form-control" value={item.value} onChange={(e) => settingsLib.countryFormHandle(e.target.value,item.countryID,"firstMealFree",formData,setFormData)}>
                                                                                    <option value="1">{translate.t("Yes")}</option>
                                                                                    <option value="0">{translate.t("No")}</option>
                                                                                </select>  
                                                                            </div>     
                                                                        ))}
                                                                    </div>
                                                                </div>      
                                                            </div>                                           
                                                        </div> 
                                                        <div className="col-12">
                                                            <div className="card main form-group mt-2">
                                        
                                                                <div className="card-header d-flex align-items-center font-weight-bold" style={{fontSize:"0.875rem"}}>
                                                                    {translate.t("EnableVoucherUsage")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        {formData.enableVoucherUsage && formData.enableVoucherUsage.length && formData.enableVoucherUsage.map((item,index) => (
                                                                            <div className="form-group col-6">
                                                                                <label className="input-label font-weight-normal">{getCountryName(item.countryID)}</label>
                                                                                
                                                                                <select className="form-control" value={item.value} onChange={(e) => settingsLib.countryFormHandle(e.target.value,item.countryID,"enableVoucherUsage",formData,setFormData)}>
                                                                                    <option value="1">{translate.t("Yes")}</option>
                                                                                    <option value="0">{translate.t("No")}</option>
                                                                                </select>  
                                                                            </div>     
                                                                        ))}
                                                                    </div>
                                                                </div>      
                                                            </div>                                           
                                                        </div> 
                                                        <div className="col-12">
                                                            <div className="card main form-group mt-2">
                                        
                                                                <div className="card-header d-flex align-items-center font-weight-bold" style={{fontSize:"0.875rem"}}>
                                                                    {translate.t("InRestaurantVat")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="d-flex flex-row align-items-center mb-3">
                                                                        <img className="settings-info-icon mr-2" src={info} />
                                                                        <div className="fs12 font-italic">{translate.t("LeaveZeroToHaveVatFromMeal")}</div>
                                                                    </div>
                                                                    <div className="row">
                                                                        {formData.inRestaurantVat && formData.inRestaurantVat.length && formData.inRestaurantVat.map((item,index) => (
                                                                            <div className="form-group col-6">
                                                                                <label className="input-label font-weight-normal">{getCountryName(item.countryID)}</label>
                                                                                <div className="input-group mb-3">
                                                                                <input className="form-control" type="number" value={item.value} onChange={(e) => settingsLib.countryFormHandle(e.target.value,item.countryID,"inRestaurantVat",formData,setFormData)} />
                                                                                    <span className="input-group-text">%</span>
                                                                                </div>
                                                                            </div>     
                                                                        ))}
                                                                    </div>
                                                                </div>      
                                                            </div>                                           
                                                        </div>  
                                                        <div className="col-12">
                                                            <div className="card main form-group mt-2">
                                        
                                                                <div className="card-header d-flex align-items-center font-weight-bold" style={{fontSize:"0.875rem"}}>
                                                                    {translate.t("TakeAwayVat")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="d-flex flex-row align-items-center mb-3">
                                                                        <img className="settings-info-icon mr-2" src={info} />
                                                                        <div className="fs12 font-italic">{translate.t("LeaveZeroToHaveVatFromMeal")}</div>
                                                                    </div>
                                                                    <div className="row">
                                                                        {formData.takeAwayVat && formData.takeAwayVat.length && formData.takeAwayVat.map((item,index) => (
                                                                            <div className="form-group col-6">
                                                                                <label className="input-label font-weight-normal">{getCountryName(item.countryID)}</label>
                                                                                <div className="input-group mb-3">
                                                                                <input className="form-control" type="number" value={item.value} onChange={(e) => settingsLib.countryFormHandle(e.target.value,item.countryID,"takeAwayVat",formData,setFormData)} />
                                                                                    <span className="input-group-text">%</span>
                                                                                </div>
                                                                            </div>     
                                                                        ))}
                                                                    </div>
                                                                </div>      
                                                            </div>                                           
                                                        </div> 
                                                        <div className="col-12">
                                                            <div className="card main form-group mt-2">
                                        
                                                                <div className="card-header d-flex align-items-center font-weight-bold" style={{fontSize:"0.875rem"}}>
                                                                    {translate.t("AlcoholVat")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="d-flex flex-row align-items-center mb-3">
                                                                        <img className="settings-info-icon mr-2" src={info} />
                                                                        <div className="fs12 font-italic">{translate.t("LeaveZeroToHaveVatFromMeal")}</div>
                                                                    </div>
                                                                    <div className="row">
                                                                        {formData.alcoholVat && formData.alcoholVat.length && formData.alcoholVat.map((item,index) => (
                                                                            <div className="form-group col-6">
                                                                                <label className="input-label font-weight-normal">{getCountryName(item.countryID)}</label>
                                                                                <div className="input-group mb-3">
                                                                                <input className="form-control" type="number" value={item.value} onChange={(e) => settingsLib.countryFormHandle(e.target.value,item.countryID,"alcoholVat",formData,setFormData)} />
                                                                                    <span className="input-group-text">%</span>
                                                                                </div>
                                                                            </div>     
                                                                        ))}
                                                                    </div>
                                                                </div>      
                                                            </div>                                           
                                                        </div>                                                         
                                                     </div> 
                                       
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                    :                                    
                                    <Loading />                                    
                                }
                                
                            </div>
                            :
                            <Fragment>
                                {error &&  <Error text={error} />}
                            </Fragment>
                        }
                    </div>
                </div>
            </div> 	
	
        </div>
    )

}

export default withApollo(Settings);