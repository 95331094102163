/*
    Úvodní layout administrace
*/
import React,{ Component } from 'react';
import Header from './Header';
import Notification from '../GlobalComponents/Notification';
import {Switch, Route } from 'react-router-dom';
import withAdminAuthentication from '../Modules/Auth/Components/WithAuthentication';

import AllArticles from '../Modules/Article/Components/AllArticles.js';
import ChangePassword from '../Modules/Auth/Components/ChangePassword';
import Homepage from '../Modules/Content/Components/Homepage';
import Link from '../Modules/Content/Components/Link';
import AppLink from '../Modules/Content/Components/AppLink';
import AppBanners from '../Modules/Content/Components/AppBanners';
import GreenInfoBanners from '../Modules/Content/Components/GreenInfoBanners';
import Athletes from '../Modules/Athlete/Components/Athletes';
import AdminUsers from '../Modules/AdminUser/Components/AdminUsers';

import Meals from '../Modules/Meals/Components/Meals'; 
import MealCategories from '../Modules/Meals/Components/Categories';
import SideDishes from '../Modules/Meals/Components/SideDishes';
import Drinks from '../Modules/Meals/Components/Drinks';
import Options from '../Modules/Meals/Components/Options';
import Customers from '../Modules/Customer/Components/Customers';
import DeliveryAreas from '../Modules/Driver/Components/DeliveryAreas';
import Drivers from '../Modules/Driver/Components/Drivers';

import AllOrders from '../Modules/Order/Components/AllOrders';
import Settings from '../Modules/Settings/Components/Settings';

import AllBranches from '../Modules/Branch/Components/Branches';
import StatisticSales from '../Modules/Statistics/Components/StatisticSales';
import Vouchers from '../Modules/Voucher/Components/Vouchers';
import StatisticMeals from '../Modules/Statistics/Components/StatisticMeals';
import StatisticMapOrders from '../Modules/Statistics/Components/StatisticMapOrders';
import StatisticCreditAndVouchers from '../Modules/Statistics/Components/StatisticCreditAndVouchers';
import StatisticCustomers from '../Modules/Statistics/Components/StatisticCustomers';
import AppRating from '../Modules/Content/Components/AppRating';
import { SANDBOX_ENABLED } from '../Config';
import translate from '../Modules/Translations/index';


class Layout extends Component {
	
  render() {
	  	
    return (
	   
    	<div className={`wholeContent ${SANDBOX_ENABLED ? "sandbox-enabled" : ""}`}>			       
 	      
			{SANDBOX_ENABLED &&
				<div className='sandbox-info'>!!! {translate.t("YouAreInTestMode")} !!!</div>
			}
 	        <div id="layout">	
			
				<Header />  
				<Switch>
        
					<Route exact path='/content/homepage' component={Homepage}></Route>
					<Route exact path='/content/link' component={Link}></Route>
					<Route exact path='/content/app-link' component={AppLink}></Route>
					<Route exact path='/content/app-banner' component={AppBanners}></Route>
					<Route exact path='/content/green-info-banners' component={GreenInfoBanners}></Route>
					<Route exact path='/content/app-rating-modal' component={AppRating}></Route>
					<Route exact path='/athletes' component={Athletes}></Route>
					<Route exact path='/meals' component={Meals}></Route>
					<Route exact path='/users' component={AdminUsers}></Route>
					<Route exact path='/users/customers' component={Customers}></Route>
					<Route exact path='/meals/categories' component={MealCategories}></Route>
					<Route exact path='/meals/side-dishes' component={SideDishes}></Route>
					<Route exact path='/meals/drinks' component={Drinks}></Route>
					<Route exact path='/meals/options' component={Options}></Route>

					<Route exact path='/drivers' component={Drivers}></Route>
					<Route exact path='/drivers/delivery-areas' component={DeliveryAreas}></Route>

					<Route exact path='/orders' component={AllOrders}></Route>

					<Route exact path='/branches' component={AllBranches}></Route>

					<Route exact path='/content/article' component={AllArticles}></Route>
					<Route exact path='/settings' component={Settings}></Route>
					<Route exact path='/settings/change-password' component={ChangePassword}></Route>
					<Route exact path='/statistics/sales' component={StatisticSales}></Route>
					<Route exact path='/statistics/meals' component={StatisticMeals}></Route>
					<Route exact path='/statistics/map-orders' component={StatisticMapOrders}></Route>
					<Route exact path='/statistics/credit-vouchers' component={StatisticCreditAndVouchers}></Route>
					<Route exact path='/statistics/customers' component={StatisticCustomers}></Route>
					<Route exact path='/vouchers' component={Vouchers}></Route>
					
				</Switch>
				<Notification />
			</div>
		     	       
		</div>
   
    );
  }
}

export default withAdminAuthentication(Layout);
