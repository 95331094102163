/*
    Přidání štítku
*/
import React,{ Component, Fragment, useState } from 'react';
import {Mutation,Query, useMutation, useQuery} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_ATHLETE, GET_ATHLETE} from '../Queries/athlete';
import { withApollo } from 'react-apollo'; 
import AthleteLib from '../Library/athlete';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import LinkSelector from '../../Content/Components/LinkSelector';
import translate from '../../Translations/index';
import refreshIcon from '../../../Public/Images/refresh.svg';
import moment from "moment";
import { Editor } from '@tinymce/tinymce-react';

import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { SERVER_URL, ATHLETE_IMAGE_URL } from '../../../Config';
import remove from '../../../Public/Images/remove.svg';



const AddEditAthlete = (props) => {
    const athleteLib              = new AthleteLib(props);  
	const [formData,setFormData] 	= useState({
        selectedLang:"cz",
        athleteID:props.selectedAthleteID,
        name:"",
        surname:"",
        email:"",
        birthDate: null,
        phone:"",
        password:"",
        bibliography:"",
        photo:"",
        photoURL:"",
        roleID:0,
        lang:translate.locale
    });
    const [allLanguageMutations,setAllLanguageMutations]    = useState([]);
    
    const {loading:getLoading, error:getError, data:athleteData} = useQuery(GET_ATHLETE,{
        variables:{athleteID:formData.athleteID,lang:translate.locale},
        fetchPolicy: 'cache-and-network',
        onCompleted(data){
            setAllLanguageMutations(data.allLanguageMutations);            
            if(formData.athleteID != 0){
                setFormData({...formData,
                    name:data.athlete.name,
                    surname:data.athlete.surname,
                    email:data.athlete.email,
                    birthDate:moment(data.athlete.birthDate).toDate(),
                    phone:data.athlete.phone,
                    bibliography:data.athlete.bibliography,
                    photoURL:data.athlete.photo ? SERVER_URL + "/" + ATHLETE_IMAGE_URL + "/stredni_" + data.athlete.photo : "",
                    roleID:data.athlete.roleID,
                    isCurrencyExchange:data.athlete.isCurrencyExchange
                });        
            }else{
                generateNewPwd();
            }    
		}
    });
    
	const [addAthlete, { data,loading:addLoading, error: addError }] = useMutation(ADD_EDIT_ATHLETE,{	
		onCompleted(data){
			if(formData.athleteID && formData.athleteID != 0){
                props.openCloseModal(false);
            }else{
                setFormData({...formData,athleteID:data.addEditAthlete.athleteID});

                props.openCloseModal(false);
            }
		},update(cache,response) {            
            athleteLib.updateList(cache,response,formData.athleteID,props.listVariables);
            
            let notify = new Notification();

            if(formData.athleteID && formData.athleteID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);
		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    });
    

    const generateNewPwd = () => {   
        const helper = new Helper();     
        setFormData({...formData,
            password:helper.makeid(6)
        });  
    }

    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError)
    }
    
    return(
                
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.athleteID && formData.athleteID != 0 ? translate.t("EditAthlete") : translate.t("AddAthlete"))}</h4>
                    {allLanguageMutations && allLanguageMutations.length > 1 &&
                        <div className="ml-auto">
                            <select className="form-control" name="selectedLang" onChange={(e) => athleteLib.formHandle(e)}>
                                {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                    <option key={index} value={item.suffix}> {item.suffix} </option>
                                ))}
                            </select>
                        </div>
                    }
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                <Fragment>  
                                    <div className="row">                                            
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("Name")}</label>
                                                <div className="form-group">
                                                    <input className="form-control" athlete="text" name="name" value={formData.name} onChange={(e) => athleteLib.formHandle(e,formData,setFormData)} />
                                                </div>
                                            </div>                                                
                                        </div>                                      
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("Surname")}</label>
                                                <div className="form-group">
                                                    <input className="form-control" athlete="text" name="surname" value={formData.surname} onChange={(e) => athleteLib.formHandle(e,formData,setFormData)} />
                                                </div>
                                            </div>                                                
                                        </div>                                          
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("Email")}</label>
                                                <div className="form-group">
                                                    <input className="form-control" athlete="text" name="email" value={formData.email} onChange={(e) => athleteLib.formHandle(e,formData,setFormData)} />
                                                </div>
                                            </div>                                                
                                        </div>                                    
                                        <div className="col-4">
                                            <div className="form-group">
                                                {formData.athleteID && formData.athleteID == 0 ? 
                                                    <label className="input-label">*{translate.t("Password")}</label> 
                                                    :
                                                    <label className="input-label">{translate.t("NewPassword")}</label>  
                                                }     
                                                <div className="input-group mb-2">
                                                    <input className="form-control" athlete="text" name="password" value={formData.password} onChange={(e) => athleteLib.formHandle(e,formData,setFormData)} />
                                                    <div className="input-group-append">
                                                        <div className="input-group-text"><img className="hp-30 cursor-pointer" onClick={() => generateNewPwd()} src={refreshIcon} /></div>
                                                    </div>
                                                </div>
                                            </div>                                                
                                        </div>                                              
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="input-label">{translate.t("Phone")}</label>
                                                <div className="form-group">
                                                    <input className="form-control" athlete="text" name="phone" value={formData.phone} onChange={(e) => athleteLib.formHandle(e,formData,setFormData)} />
                                                </div>
                                            </div>                                                
                                        </div> 
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("BirthDate")}</label>
                                                <div className="form-group date-w-100">
                                                    <DatePicker className="form-control w-100" dateFormat="yyyy-MM-dd" locale={translate.locale == "cz" ? "cs" : "en"} selected={formData.birthDate} onChange={date => setFormData({...formData,birthDate:date})} />
                                                </div> 
                                            </div>  
                                        </div>   
                                        <div className="col-4">
                                            <div className="form-group input-group">
                                                <div className="form-group">                                                
                                                    <label className="input-label">{translate.t("AthletePhoto")}</label>
                                                    {!formData.photoURL && !formData.photo ?
                                                        <div className="custom-file">                                                            
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customPhoto"
                                                                name="photo"
                                                                accept=".jpg,.jpeg,.png"
                                                                onChange={(e) => athleteLib.openImage(e,setFormData,formData)}
                                                            />
                                                            <label className="custom-file-label" htmlFor="customPhoto">{translate.t("ChoosePhoto")}</label>
                                                        </div>
                                                    :            
                                                    <div>  
                                                        <div className="d-flex flex-column align-items-start">
                                                            <img className = "maxwidth-100 athlete-img" src={formData.photoURL} />
                                                            <img onClick={() => setFormData({...formData,photo:"",photoURL:""})} className="remove-icon mt-2" src={remove} title={translate.t("Remove")} />
                                                        </div>                               
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>  
                                        <div className="col-8">                                                    
                                            <div className="form-group">
                                                <label className="input-label">{translate.t("Bibliography")}</label>
                                                <div className="form-group">
                                                    <Editor
                                                        name="description"
                                                        apiKey = '9z3phhnyq7jkeeqztql1euovfotvpd2gdduygtdpk14wu4ij'
                                                        value={formData.bibliography}
                                                        init={{
                                                            plugins: 'link image code',
                                                            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                                        }}
                                                        onEditorChange={(content) => athleteLib.formHandleEditor(content,"bibliography",formData,setFormData)}
                                                    />
                                                </div>
                                            </div>
                                        </div>                           
                                    </div> 
                                </Fragment> 
                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button athlete="button" className="btn btn-primary flex-fill" onClick={() => athleteLib.addAthlete(addAthlete,formData)}>{(formData.athleteID && formData.athleteID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>    
    );


}

export default withApollo(AddEditAthlete);