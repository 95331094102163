import gql from 'graphql-tag';


export const GET_MEAL_SIDE_DISHES = gql`
    query AllMealSideDishes($mealID: ID!,$lang:String){
        allMealSideDishes(mealID:$mealID,lang:$lang){   
            mealSideDishID
            sideDishID
            sideDishPrice
            lang
            sideDish{
                name
            }
            prices{
                mealSideDishPriceID
                sideDishPrice
                countryID
                vatID
                country{
                    currencyID
                    currency{
                        code
                    }
                }
                vat{
                    value
                }
            }
        }
        allSideDishes(lang:$lang,onlyActive:false){
            sideDishID
            name
            price
        }
    }
`;
export const DELETE_MEAL_SIDE_DISHES = gql`
    mutation DeleteMealSideDishes($mealSideDishIDs:[ID]!,$mealID:ID) {
        deleteMealSideDishes(mealSideDishIDs:$mealSideDishIDs)
        reindexMealSideDishPriority(mealID:$mealID)
    }
`;
export const UPDATE_MEAL_SIDE_DISH_PRIORITY = gql`
    mutation updateMealSideDishPriority($mealID:ID,!$mealSideDishID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateMealSideDishPriority(mealID:$mealID,mealSideDishID:$mealSideDishID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

export const ADD_MEAL_SIDE_DISH = gql`
    mutation AddMealSideDish($mealID:ID,$sideDishID:ID) {
        addMealSideDish(mealID:$mealID,sideDishID:$sideDishID)
        reindexMealSideDishPriority(mealID:$mealID)
    }
`;





