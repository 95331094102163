import React,{ Component, Fragment, useEffect, useRef, useState } from 'react';
import SearchInput from './SearchInput';
import BranchLib from '../Modules/Branch/Library/branch';


const AddressSearch = (props) => {

    const branchLib = new BranchLib(props);
    
    const [address, setAddress]                 = useState("");
    const [addressList, setAddressList]         = useState([]);
    const [searchlistOpen,setSearchlistOpen]    = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const getAddresses = async (address) => {
        try {
            const addressList =  await branchLib.getAddresses(address);
            console.log(addressList);
            setAddressList(addressList);
        } catch (error) {
            
        }
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            closeSearchList();
        }
    };

    const closeSearchList = () => {
        setAddress("");
        setSearchlistOpen(false);
        setAddressList([]);
    }

    const addressSelected = (addressItem) => {
        props.onSelected(addressItem);
        closeSearchList();
    }

    return(
        <div ref={ref} className="w-100">
            <div className="w-100" onClick={() => setSearchlistOpen(true)}>
                <SearchInput className="form-control" value={address} onChange={(text) => getAddresses(text)} textChanged={(text) => setAddress(text)} />
            </div>
            {addressList && addressList.length > 0 &&
                <div className="search-list w-100">
                    {addressList.map((item,index) => (
                        <div key={index} className="search-list-item" onClick={() => addressSelected(item)}>{item.street} {item.number}, {item.zip} {item.city}</div>
                    ))}
                </div>
            }
        </div>
    )
  
}


export default AddressSearch;
