import React,{ Component,Fragment, useState } from 'react';
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import move from '../../../Public/Images/move.svg';
import Banners from './Banners';


const BannersElement = (props) => {


    const [showBanners, setShowBanners] = useState(false);

    const {element,showHelpers,cm,lastIndexes} = props;
    var parentIndexes = "";
    
    var indexes = lastIndexes.split("-");
    if(indexes.length > 2){
        indexes.pop();
        parentIndexes = indexes.join("-");
    }

    return (
        <>

            <div className="cm-headline">

                {showHelpers && element.showMenu &&
                    <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">
                            
                            {parentIndexes != "" &&
                                <div className="form-group mr-1">
                                    <div className="item-container" onClick={(e) => cm.selectElement(e,"column",parentIndexes,false)}>
                                        <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                    </div>
                                </div>
                            }
                            <div className="form-group">
                                <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                    <img className="remove-icon"  src={remove} alt="Odstranit banery" title="Odstranit banery" />
                                </div>
                            </div>
                        </div>  
                    </div>
                }

                <div className="cm-headline-content cm-banner-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"banners",lastIndexes,false)}}>
                    <div className="cm-content-padding text-center">
                        Zde se bude nacházet seznam bannerů
                        <div><button onClick={() => setShowBanners(true)}>Zobrazit bannery</button></div>
                    </div>
                </div>
                {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
                {showHelpers && 
                    <div className="cm-dragable-item" {...props.handle}>
                        <div className="item-container">
                            <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                        </div>
                    </div>
                }

            </div>

            {showBanners &&
            
                <Banners openCloseModal = {setShowBanners} />

            }

        </>

    );

    
}

export default BannersElement;