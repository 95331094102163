import gql from 'graphql-tag';

export const GET_APP_RATING_DATA = gql`
    query AppRatingData($appRatingModalID: ID!){
        appRatingDataWithLangs(appRatingModalID:$appRatingModalID){
            appRatingModalID
            active  
            langs{
                lang
                title
                text
            }   
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
    }
`;


export const ADDEDIT_APP_RATING_DATA = gql`
    mutation EditAppRatingData($appRatingModalID:ID,$active:Int,$langs:[AppRatingLangsInput]){
        editAppRatingData(appRatingModalID:$appRatingModalID,active:$active,langs:$langs)
    }
`;