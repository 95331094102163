/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_CUSTOMER = gql`
    mutation AddEditCustomer($customerID:ID,$email:String,$phone:String,$name:String,$surname:String,$allCredits:[CustomerCreditInput],$lang:String){
        addEditCustomer(customerID:$customerID,email:$email,phone:$phone,name:$name,surname:$surname,allCredits:$allCredits,lang:$lang){
            customerID
            name
            surname
            email
            phone  
        }
    }
`;

export const ADD_CREDIT_TO_CUSTOMER = gql`
    mutation AddCreditToCustomer($customerID:ID,$countryID:ID,$credit:Float){
        addCreditToCustomer(customerID:$customerID,countryID:$countryID,credit:$credit)
    }
`;
export const REMOVE_CREDIT_FROM_CUSTOMER = gql`
    mutation RemoveCreditFromCustomer($customerID:ID,$countryID:ID,$credit:Float){
        removeCreditFromCustomer(customerID:$customerID,countryID:$countryID,credit:$credit)
    }
`;
/**
 *  Seznam veškerých athleteů
 */
export const GET_CUSTOMERS = gql`
    query AllCustomers($offset:Int,$limit:Int,$searchQuery:String){
        allCustomers(offset:$offset,limit:$limit,searchQuery:$searchQuery){            
            customerID
            name
            surname
            credit
            email
            phone   
            deliveryAddress{
                street
                city
                zip
            }  
            allCredits{
                customerID
                countryID
                credit
                unprocessedOrdersCredit
                country{
                    currencyID
                    name
                    currency{
                        code
                    }
                }
            }    
        }
        customerListCount
    }
`;
/**
 *  Seznam veškerých athleteů
 */
export const GET_CUSTOMER = gql`
    query Customer($customerID:ID){
        customer(customerID:$customerID){            
            customerID
            countryID
            name
            surname
            credit
            email
            phone   
            deliveryAddress{
                street
                city
                zip
            }      
            country{
                currencyID
                countryID
                currency{
                    code
                }
            }
            allCredits{
                customerCreditID
                customerID
                countryID
                credit
                unprocessedOrdersCredit
                country{
                    name
                }
            }
        }
        specificCustomerStatistic(customerID:$customerID){
            orderCount
            allOrdersPrice
            code
        }
        allActiveCountries(fromAdmin:1){
            countryID
            name
            currencyID
            currency{
                code
            }
        }
        
    }
`;

/**
 *  Seznam veškerých athleteů
 */
export const SEARCH_CUSTOMERS = gql`
    query SearchCustomers($searchText:String,$limit:Int){
        searchCustomers(searchText:$searchText,limit:$limit){            
            customerID
            name
            surname
            credit
            email
            phone
            deliveryAddress{
                street
                city
                zip
            }
        }
    }
`;

/**
 * Smazání kategorie.
 */
 export const DELETE_CUSTOMERS = gql`
 mutation deleteCustomers($customerIDs:ID!) {
     deleteCustomers(customerIDs:$customerIDs)
 }
`;
/**
 * Export CSV souboru zakazniku
 */
export const EXPORT_CUSTOMERS_CSV = gql`
    mutation ExportCustomersCSV($from:String, $to:String) {
        exportCustomersCSV(from:$from,to:$to)
    }
`;





