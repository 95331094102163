import {GET_ORDERS} from '../Queries/order';
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';
import {GOOGLE_API_KEY} from '../../../Config/index';

class Order {

    constructor(props){
        this.props = props;
    }

    async addOrder(addEditOrder,formData){

        if(formData.name != ""){
            if(formData.surname != ""){
                if(formData.street != ""){     
                    if(formData.city != ""){                  
                        if(formData.zip != ""){
                            console.log(formData.street + ',' + formData.zip + "," + formData.city + ",Česká republika");
                            let adresses = await fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURI(formData.street + ', ' + formData.zip + ", " + formData.city + ", Česká republika") + '&key=' + GOOGLE_API_KEY);
                            
                            adresses  = await adresses.json();  
                            console.log(adresses);
                            if(adresses.results.length > 0){
                                let selectedAddress = null;
                                for(const address of adresses.results){
                                    if(!address.partial_match){
                                        selectedAddress = address;
                                        break;
                                    }
                                }
                                if(selectedAddress){
                                    formData.lat = selectedAddress.geometry.location.lat;
                                    formData.lng = selectedAddress.geometry.location.lng;

                                    addEditOrder({
                                        variables:formData
                                    });
                                }else{                                
                                    let notify = new Notification();
                                    notify.setNotification(null,translate.t("GoogleDidNotFoundAddress"),false,true,this.props.client);
                                }
                            }else{                                
                                let notify = new Notification();
                                notify.setNotification(null,translate.t("GoogleDidNotFoundAddress"),false,true,this.props.client);
                            }
                        }else{
                            let notify = new Notification();
                            notify.setNotification(null,translate.t("InvalidZip"),false,true,this.props.client);
                        }
                    }else{
                        let notify = new Notification();
                        notify.setNotification(null,translate.t("InvalidCity"),false,true,this.props.client);
                    }
                }else{
                    let notify = new Notification();
                    notify.setNotification(null,translate.t("InvalidStreet"),false,true,this.props.client);
                }

            }else{
                let notify = new Notification();
                notify.setNotification(null,translate.t("InvalidSurname"),false,true,this.props.client);
            }

        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidName"),false,true,this.props.client);
        }

    }

    getData(data,orderID,formData,setFormData){

        if(orderID != 0){
            
            setFormData({...formData,
                name: data.order.name,
                surname: data.order.surname,
                street: data.order.street,
                city: data.order.city, 
                zip: data.order.zip, 
            });
        }
    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateList(cache, response,variables,orderID){

        const { allOrders,orderListCount } = cache.readQuery({ query: GET_ORDERS ,variables});

        
        if(orderID && orderID != 0){

            var arr = [...allOrders];

            arr.forEach((item,index) => {
                
                if(item.orderID == response.data.addEditOrder.orderID){
                    arr[index] = response.data.addEditOrder;
                }

            });

            cache.writeQuery({ 
                query:GET_ORDERS,
                variables,
                data:{
                    allOrders: arr,
                    orderListCount :orderListCount
                } 
            });

        }else{
            var arr = [response.data.addEditOrder,...allOrders];

            cache.writeQuery({ 
                query:GET_ORDERS,
                variables,
                data:{
                    allOrders: arr,
                    orderListCount :orderListCount
                } 
            });
        }

    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteOrders.split(",");

        const { allOrders,orderListCount } = cache.readQuery({ query: GET_ORDERS ,variables});
        var arr = [...allOrders];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.orderID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_ORDERS,
            variables,
            data:{
                allOrders: [...arr],
                orderListCount :orderListCount - resp.length
            } 
        });

    }

    updateAfterStorno(cache, response,variables){

        var orderID = response.data.stornoOrder;

        const { allOrders,orderListCount,settings } = cache.readQuery({ query: GET_ORDERS ,variables});
        var arr = [...allOrders];

        
        arr.forEach((item,index) => {
            if(item.orderID == orderID){
                arr[index].orderStatusID = 7;
                arr[index].orderStatus = {...arr[index].orderStatus,name:"Stornovaná",orderStatusID:7};
            }
        });
        
        console.log(arr);
    
        cache.writeQuery({ 
            query:GET_ORDERS,
            variables,
            data:{
                allOrders: [...arr],
                orderListCount,
                settings
            } 
        });

    }

    updateAfterRefund(cache, response,variables){

        var orderID = response.data.refundOrder;

        const { allOrders,orderListCount,settings } = cache.readQuery({ query: GET_ORDERS ,variables});
        var arr = [...allOrders];

        
        arr.forEach((item,index) => {
            if(item.orderID == orderID){
                arr[index].refunded = 1;
            }
        });
        
        console.log(arr);
    
        cache.writeQuery({ 
            query:GET_ORDERS,
            variables,
            data:{
                allOrders: [...arr],
                orderListCount,
                settings
            } 
        });

    }
    
    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default Order;