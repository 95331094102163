import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Orders from '../../Order/Components/Orders';

var error = "";
var loading = false;

const OrdersHistory = (props) => {

    const listVariables = {
        offset:0,
        limit:50,
        driverID:parseInt(props.driverID),
        toDelivery:true
    }

    return(

        <Fragment>

            {error ?
                <Error text={error} />
                 :
                <Fragment>

                    {loading ? 

                        <Loading />
                
                        :
                
                        <Orders listVariables={listVariables} />

                    }
                
                    
                </Fragment>
            }
        
        </Fragment>
    )

}

export default withApollo(OrdersHistory);









