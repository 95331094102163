/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_VOUCHER = gql`
    mutation AddEditVoucher($voucherData:VoucherIsertData){
        addEditVoucher(voucherData:$voucherData){
            voucherID
            countryID
            active
            code	
            description	
            validFrom	
            validTo	
            timeFrom
            timeTo
            value	
            valueInPercentage	
            limitedUsage
            limitedPerUser
            count
            countPerUser
            forAllBranches   
            voucherTypeID 
            dateAdd
            voucherType{
                name
            }        
            allowedBranches{
                branchID
                name
            }
            country{
                name
                currencyID
                currency{
                    code
                }
            }
        }
    }
`;


/**
 *  Seznam veškerých athleteů
 */
export const GET_VOUCHERS = gql`
    query AllVouchers($offset:Int,$limit:Int){
        allVouchers(offset:$offset,limit:$limit){            
            voucherID
            countryID
            voucherTypeID
            voucherType{
                name
            }
            active
            code	
            description	
            validFrom	
            validTo	
            timeFrom
            timeTo
            value	
            valueInPercentage	
            limitedUsage
            limitedPerUser
            count
            countPerUser
            dateAdd
            forAllBranches
            allowedBranches{
                branchID
                name
            }
            country{
                name
                currencyID
                currency{
                    code
                }
            }
        }
        voucherListCount
    }
`;

/**
 *  Seznam veškerých athleteů
 */
export const GET_VOUCHER = gql`
    query Voucher($voucherID:ID){
        voucher(voucherID:$voucherID){            
            voucherID
            countryID
            voucherTypeID
            active
            code	
            description	
            validFrom	
            validTo	
            timeFrom
            timeTo
            value	
            valueInPercentage	
            limitedUsage
            limitedPerUser
            count
            countPerUser
            forAllBranches
            allowedBranches{
                branchID
                name
            }
            country{
                name
                currencyID
                currency{
                    title
                }
            }
        }
        
        allVoucherTypes{            
            voucherTypeID
            name
        }
        allActiveCountries(fromAdmin:1){
            countryID
            name
            currencyID
            currency{
                title
            }
        }
    }
`;
export const GET_BRANCHES_FOR_VOUCHER = gql`
    query AllBranches($countryID:ID){        
        allBranches(offset:0,limit:10000,countryID:$countryID){            
            branchID
            name
            street
            city
            zip    
            countryID
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_VOUCHERS = gql`
    mutation deleteVouchers($voucherIDs:ID!) {
        deleteVouchers(voucherIDs:$voucherIDs)
    }
`;


