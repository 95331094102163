/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_CATEGORY = gql`
    mutation AddEditCategory($active:Int,$categoryID:ID,$photo:Upload, $langs:[CategoryLangsInput]){
        addEditCategory(active:$active, categoryID:$categoryID,photo:$photo,langs:$langs)
    }
`;


/**
 *  Data kategorie
 */

export const GET_CATEGORY = gql`
    query Category($categoryID: ID!){
        categoryWithLangs(categoryID:$categoryID){
            categoryID
            active  
            photo
            langs{
                lang
                name
            }      
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_CATEGORIES = gql`
    query AllCategories($lang:String){
        allCategories(lang:$lang,onlyActive:false){            
            categoryID
            name
            active      
            photo
        }
    }
`;

/**
 * Smazání kategorie
 */

export const DELETE_CATEGORIES = gql`
    mutation deleteCategories($categoryIDs:ID!) {
        deleteCategories(categoryIDs:$categoryIDs)
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_CATEGORY_PRIORITY = gql`
    mutation updateCategoryPriority($categoryID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateCategoryPriority(categoryID:$categoryID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;