import React,{ Component, Fragment, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import {GET_BANNERS,DELETE_BANNERS,UPDATE_BANNER_PRIORITY} from '../Queries/banner';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import edit from '../../../Public/Images/edit.svg';
import move from '../../../Public/Images/move.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import AddEditBanner from './AddEditBanner';
import Modal from 'react-modal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {BANNER_IMAGE_URL,SERVER_URL} from '../../../Config/index';

const Banners = (props) => {

    const listVariables = {
        lang:"cz"
    }

    const [selectedBanners,setSelectedBanners]          = useState([]);
	const [showDeleteNotifi,setShowDeleteNotifi] 	= useState(false);
	const [showAdd,setShowAdd] 			            = useState(false);
    const [selectedBannerID,setSelectedBannerID] 	    = useState(0);
    
    const {loading, error:getError, data:bannersData, refetch}   = useQuery(GET_BANNERS,{
        variables:listVariables,
        fetchPolicy: 'cache-and-network'
    });

    const [deleteBanner, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_BANNERS,{	
		update(cache,response) {            
            
            refetch();

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    const [updateBannerPriority,{data:updateData,loading:updateLoading,error:updateError}] = useMutation(UPDATE_BANNER_PRIORITY,{
        update(){
            refetch();
        }
    })

    const openCloseModal = (open,bannerID) => {
        setSelectedBannerID(bannerID);
        setShowAdd(open);
    }

    const showDelNotifi = () => {
        if(selectedBanners.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const delBanner = (action) => {

        if(action){

            let bannerIDs = selectedBanners.join(",");
            deleteBanner({variables:{bannerIDs}});
        }

        setShowDeleteNotifi(false);
    }

    const initiateDeleteBanner = (e,bannerID) => {

        var checked = e.target.checked;
        var arr = [...selectedBanners];

        if(!checked){
            for(let i in selectedBanners){
                if(selectedBanners[i] == bannerID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [bannerID,...arr];
        }

        setSelectedBanners(arr);
  
    }

    const onDragEnd = (result,updateBannerPriority) => {

        const IDs = result.draggableId.split("-");

        updateBannerPriority({
            variables:{
                bannerID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    if(getError || deleteError || updateError){
        var error = getError ? getError : (deleteError ? deleteError : updateError);
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">

                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">Bannery</h4>
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>

                <div className="modal-body">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t("Banners")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                        </div>
                        <div className="card-body">

                            {!error ?
                                <div>
                                    <div className="data-list">
                                        
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>Banner</div>
                                                <div>Název</div>
                                                <div className="text-center">Aktivní</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        
                                        {bannersData && bannersData.allBanners && bannersData.allBanners.length > 0 &&

                                            <DragDropContext onDragEnd={(result) => onDragEnd(result,updateBannerPriority)}>
                                                <Droppable droppableId="droppable">
                                                    {(provided, snapshot) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {bannersData && bannersData.allBanners && bannersData.allBanners.map((item,index) => {
                                                                
                                                                var checked = false;
                                                                for(let i in selectedBanners){
                                                                    if(selectedBanners[i] == item.bannerID)checked = true;
                                                                }

                                                                return (
                                                                    <Draggable key={index} draggableId={item.bannerID} index={index}>
                                                                        {(provided, snapshot) => (
                                                                        <div className="data-list-item" 
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <div className="data-list-item-content categories">
                                                                                <div className="cross" style={{paddingLeft:10}}>
                                                                                    <img className="move-icon" src={move} />
                                                                                    <img src={SERVER_URL + "/" + BANNER_IMAGE_URL + "/mala_" + item.photo} />
                                                                                </div>
                                                                                <div className="text-center">
                                                                                    {item.title}
                                                                                </div>
                                                                                <div className="text-center">
                                                                                    {(item.active == 1 ? "Ano" : "Ne")}
                                                                                </div>
                                                                                <div className="text-right">
                                                                                    <img onClick={() => openCloseModal(true,item.bannerID)} className="edit-icon" src={edit} />
                                                                                    <input className="delete-checked" type="checkbox" name="deleteAthlete[]" checked={checked} onChange = {(e) => initiateDeleteBanner(e,item.bannerID)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                                
                                                            })}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        }
                                    </div>
                                    {!loading ? 
                                        <Fragment>
                                            {bannersData && bannersData.allBanners && bannersData.allBanners.length == 0 &&
                                                <NoItems text={translate.t("NoItemsAddedYet")} />
                                            }
                                        </Fragment>
                                        :                                    
                                        <Loading />                                    
                                    }
                                    
                                </div>
                                :
                                <Fragment>
                                    {error &&  <Error text={error} />}
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
            
                {showAdd &&
                    <AddEditBanner openCloseModal={openCloseModal} selectedBannerID={selectedBannerID} listVariables={listVariables} />
                }	
                {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delBanner} /> }	

            </div>
        </Modal>
       
    )

}

export default withApollo(Banners);