/**
 *  komponenta pro štítky pro produkty
 */

import React,{ Fragment, useState } from 'react';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import {GET_VOUCHERS,DELETE_VOUCHERS} from '../Queries/voucher';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import VoucherLib from '../Library/voucher';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import AddEditVoucher from './AddEditVoucher';
import edit from '../../../Public/Images/edit.svg';
const moment             = require("moment");

const Voucher = (props) => {
        
    const helper = new Helper();
    helper.checkAdminRights(props,["1","2"]);
    
    const listVariables = {
        offset:0,
        limit:50
    }

	const [selectedVoucher,setSelectedVoucher]      = useState([]);
    const [showDeleteNotifi,setShowDeleteNotifi]    = useState(false);
    const [showAdd,setShowAdd] 			            = useState(false);
    const [selectedVoucherID,setSelectedVoucherID]    = useState(0);
    
    const {loading, error:getError, data:voucherListData, fetchMore}   = useQuery(GET_VOUCHERS,{
        variables:listVariables
    });
 
	const [deleteVoucher, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_VOUCHERS,{	
		update(cache,response) {            
            let voucher = new VoucherLib(this);
            voucher.updateAfterDelete(cache, response,listVariables);

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    /**
     * smazání štítků
     * @param {*} deleteVoucher funkce, která posílá data na server
     * @param {*} voucherID ID voucheru
     */
    
    const initiateDeleteVoucher = (e,voucherID) => {

        var checked = e.target.checked;
        var arr = [...selectedVoucher];

        if(!checked){
            for(let i in selectedVoucher){
                if(selectedVoucher[i] == voucherID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [voucherID,...arr];
        }

        setSelectedVoucher(arr);
  
    }

    const delVoucher = (action) => {

        if(action){

            let voucherIDs = selectedVoucher.join(",");

            deleteVoucher({
                variables:{
                    voucherIDs: voucherIDs
                }
            })
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    }

    const showDelNotifi = () => {

        if(selectedVoucher.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const openCloseModal = (open,voucherID) => {
        setSelectedVoucherID(voucherID);
        setShowAdd(open);
    }

    
    if(getError || deleteError){
        var error = getError ? getError : deleteError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }

    return(

        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t("VoucherList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                        </div>
                        <div className="card-body">

                            {!error ?
                                <div>
                                    <div className="data-list">
                                        
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div className="text-center">{translate.t("Code")}</div>
                                                <div className="text-center">{translate.t("Type")}</div>
                                                <div className="text-center">{translate.t("Country")}</div>
                                                <div className="text-center">{translate.t("ForBranch")}</div>
                                                <div className="text-center">{translate.t("Description")}</div>
                                                <div className="text-center">{translate.t("ValidFrom")}</div>
                                                <div className="text-center">{translate.t("ValidTo")}</div>
                                                <div className="text-center">{translate.t("TimeFrom")}</div>
                                                <div className="text-center">{translate.t("TimeTo")}</div>
                                                <div className="text-center">{translate.t("Value")}</div>
                                                <div className="text-center">{translate.t("VoucherCount")}</div>
                                                <div className="text-center">{translate.t("PerUser")}</div>
                                                <div className="text-center">{translate.t("DateAdd")}</div>
                                                <div className="text-right static small">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {voucherListData && voucherListData.allVouchers && voucherListData.allVouchers.map((item,index) => {

                                                var checked = false;
                                                for(let i in selectedVoucher){
                                                    if(selectedVoucher[i] == item.voucherID)checked = true;
                                                }
                                                                                                                
                                                return (
                                                
                                                    <div key={index} className="data-list-item-content">
                                                        <div className="text-center wrap-word">{item.code}</div>
                                                        <div className="text-center">{item.voucherType && translate.t(item.voucherType.name)}</div>
                                                        <div className="text-center">{item.country && item.country.name}</div>
                                                        <div className="text-center">
                                                            {item.forAllBranches ? 
                                                                translate.t("AllBranches") 
                                                                : 
                                                                <>
                                                                    {item.allowedBranches && item.allowedBranches.map((item,index) => (                                                            
                                                                        <div key={index}>{item.name}</div>
                                                                    ))}
                                                                </>
                                                            }
                                                        </div>
                                                        <div className="text-center">{item.description}</div>
                                                        <div className="text-center">{item.validFrom ? moment(item.validFrom).format("DD.MM.YYYY") : ""}</div>
                                                        <div className="text-center">{item.validTo ? moment(item.validTo).format("DD.MM.YYYY") : ""}</div>
                                                        <div className="text-center">{item.timeFrom ? moment(item.timeFrom,"HH:mm").format("HH:mm") : ""}</div>
                                                        <div className="text-center">{item.timeTo ? moment(item.timeTo,"HH:mm").format("HH:mm") : ""}</div>
                                                        <div className="text-center">{item.value} {item.valueInPercentage ? "%" : item.country.currency.code}</div>
                                                        <div className="text-center">
                                                            {item.limitedUsage ? item.count : translate.t("Unlimited")} 
                                                        </div>
                                                        <div className="text-center">
                                                            {item.limitedPerUser != "0" && 
                                                                <div>{item.countPerUser} {translate.t("PerUser")}</div>
                                                            }
                                                        </div>
                                                        <div className="text-center">{moment(item.dateAdd).format("DD.MM.YYYY")}</div>
                                                        <div className="text-right static small">
                                                            <img onClick={() => openCloseModal(true,item.voucherID)} className="edit-icon" src={edit} />
                                                            <input className="delete-checked" type="checkbox" name="deleteVoucher[]" checked={checked} onChange = {(e) => initiateDeleteVoucher(e,item.voucherID)} />
                                                        </div>
                                                    </div>
                                                            
                                                )
                                                
                                            })} 
                                        </Fragment>
                                    </div>
                                    {!loading ? 
                                        <Fragment>
                                            {voucherListData && voucherListData.allVouchers && voucherListData.allVouchers.length == 0 ?
                                                <NoItems text={translate.t("NoItemsAddedYet")} />
                                                :  
                                                <Fragment>     
                                                    {!loading && voucherListData && voucherListData.allVouchers.length < voucherListData.voucherListCount &&                                 
                                                        <div className="text-center p-5">
                                                            <button className="btn btn-primary" onClick={() => fetchMore({
                                                                variables: {...listVariables,
                                                                    offset: voucherListData.allVouchers.length
                                                                },
                                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                                    if (!fetchMoreResult) return prev;
                                                                    return Object.assign({}, prev, {
                                                                        allVouchers: [...prev.allVouchers, ...fetchMoreResult.allVouchers]
                                                                    });
                                                                }
                                                            })}> {translate.t("LoadMore")} </button>
                                                        </div> 
                                                    }
                                                </Fragment>
                                            }
                                        </Fragment>
                                        :                                    
                                        <Loading />                                    
                                    }
                                    
                                </div>
                                :
                                <Fragment>
                                    {error &&  <Error text={error} />}
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div> 	
            {showAdd &&
                <AddEditVoucher openCloseModal={openCloseModal} selectedVoucherID={selectedVoucherID} listVariables={listVariables} />
            }
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delVoucher} /> }	
        </div>
    );

}

export default withApollo(Voucher);