import React,{ Component,Fragment } from 'react';
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import move from '../../../Public/Images/move.svg';


class ImageElement extends Component{

    constructor(props){
        super(props);
    }

    render(){

        const {element,showHelpers,cm,lastIndexes} = this.props;
        var parentIndexes = "";
        
        var indexes = lastIndexes.split("-");
        if(indexes.length > 2){
            indexes.pop();
            parentIndexes = indexes.join("-");
        }

        return (
            <div className="cm-headline">

                {showHelpers && element.showMenu &&
                    <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">

                            {element.url && 
                                <div className="form-group mr-1">
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="customFile" onChange={(e) => cm.uploadImageElement(e,lastIndexes,element.url)}  />
                                        <label className="custom-file-label" htmlFor="customFile">Vyměnit fotku</label>
                                    </div>
                                </div>
                            }
                            
                            <div className="form-group mr-1">
                                
                                <div className="item-container" onClick={() => cm.showSettings("image",lastIndexes)}>
                                    <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                                </div>
                                {element.showSettings &&
                                    <Fragment>
                                        <div className="settings">
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Zarovnání:</label>
                                                    </div>
                                                    <select value={element.imageAlign} className="form-control" name="imageAlign" onChange={(e) => cm.setElementData(e,lastIndexes,"image")}>
                                                        <option value="start"> vlevo </option>
                                                        <option value="center"> centrovaně </option>
                                                        <option value="end"> vpravo </option>
                                                    </select>
                                                </div>
                                            </div>
                                           
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Šířka:</label>
                                                    </div>
                                                    <input type="number" value={element.width} className="form-control" name="width" onChange={(e) => cm.setElementData(e,lastIndexes,"image")} />
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Výška:</label>
                                                    </div>
                                                    <input type="number" value={element.height} className="form-control" name="height" onChange={(e) => cm.setElementData(e,lastIndexes,"image")}  />
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Zaoblení:</label>
                                                    </div>
                                                    <input type="number" value={element.borderRadius} className="form-control" name="borderRadius" onChange={(e) => cm.setElementData(e,lastIndexes,"image")}  />
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Třída:</label>
                                                    </div>
                                                    <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"image")}  />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cm-closing-overlay" onClick={() => cm.showSettings("image",lastIndexes)}></div>
                                    </Fragment>
                                }
                            </div>
                            {parentIndexes != "" &&
                                <div className="form-group mr-1">
                                    <div className="item-container" onClick={(e) => cm.selectElement(e,"column",parentIndexes)}>
                                        <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                    </div>
                                </div>
                            }
                            <div className="form-group">
                                <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                    <img className="remove-icon"  src={remove} alt="Odstranit obrázek" title="Odstranit obrázek" />
                                </div>
                            </div>
                        </div>  
                    </div>
                }

                <div className="cm-headline-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"image",lastIndexes)}}>
                    <div className={(element.url ? "d-flex justify-content-" + element.imageAlign : "d-flex")}>
                        {element.url ?
                            <div className="cm-image-content" style={{width:element.width + "px",height:element.height + "px"}}>
                                <img className={element.className} src={element.url} alt="" title="" style={{borderRadius:element.borderRadius + "px"}} />
                            </div>
                            :
                            <div className="cm-image-choose-container">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="customFile" onChange={(e) => cm.uploadImageElement(e,lastIndexes)}  />
                                    <label className="custom-file-label" htmlFor="customFile">Vybrat obrázek</label>
                                </div>
                            </div>
                    
                        }
                    </div>
                </div>
                {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
                {showHelpers && 
                    <div className="cm-dragable-item" {...this.props.handle}>
                        <div className="item-container">
                            <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                        </div>
                    </div>
                }

            </div>
        );

    }
}

export default ImageElement;