import gql from 'graphql-tag';

export const GET_SIDE_DISH_PRICES = gql`
    query AllSideDishPrices($sideDishID: ID!){
        allSideDishPrices(sideDishID:$sideDishID){
            sideDishPriceID
            sideDishID
            countryID
            price
            vatID
            country{
                name
                currencyID
                currency{
                    code
                }
            }
            vat{
                value
            }
        }

    }
`;
export const GET_SIDE_DISH_PRICE = gql`
    query SideDishPrice($sideDishPriceID: ID!){
        sideDishPrice(sideDishPriceID:$sideDishPriceID){
            sideDishPriceID
            countryID
            price
            vatID
        }
        allActiveCountries(fromAdmin:1){
            countryID
            currencyID
            name
            currency{
                title
            }
        }        
        allVats{
            vatID
            value
        }
    }
`;

export const ADD_EDIT_SIDE_DISH_PRICE = gql`
    mutation AddEditSideDishPrice($sideDishPriceInputs:SideDishPriceInputs) {
        addEditSideDishPrice(sideDishPriceInputs:$sideDishPriceInputs){
            sideDishPriceID
            sideDishID
            countryID
            price
            vatID
        }
    }
`;

export const DELETE_SIDE_DISH_PRICES = gql`
    mutation DeleteSideDishPrices($sideDishPriceIDs:[ID]!) {
        deleteSideDishPrices(sideDishPriceIDs:$sideDishPriceIDs)
    }
`;




