import Notification from '../../../Library/notification';
import translate from '../../Translations/index';
import moment from 'moment';

class Settings {

    constructor(props){
        this.props = props;
    }

    updateSettings(updateSettings,formData){

        var er = /^[0-9]+$/;

        //if(er.test(formData.creditPercentage)){
                    
            //if(er.test(formData.minOrderPriceToAddCredit)){
                    
                //if(er.test(formData.inRestaurantVat)){
                    
                    if(er.test(formData.timeHandleDelivery)){
                    
                        if(er.test(formData.slotDuration)){
                    
                            //if(er.test(formData.deliveryPrice)){
                                
                                updateSettings({
                                    variables:{
                                        settings:{...formData,
                                            termsAndConditionsChangeDate : !!formData.termsAndConditionsChangeDate ? moment(formData.termsAndConditionsChangeDate).format("YYYY-MM-DD") : ""
                                        }
                                    }
                                }); 
                            /*
                            }else{
                                let notify = new Notification();
                                notify.setNotification(null,translate.t("InvalidDeliveryPrice"),false,true,this.props.client);
                            }
                            */
                        
                        }else{
                            let notify = new Notification();
                            notify.setNotification(null,translate.t("InvalidSlotDuration"),false,true,this.props.client);
                        }
                    
                    }else{
                        let notify = new Notification();
                        notify.setNotification(null,translate.t("InvalidTimeHandleDelivery"),false,true,this.props.client);
                    }
                
            /*
                }else{
                    let notify = new Notification();
                    notify.setNotification(null,translate.t("InvalidRestaurantVat"),false,true,this.props.client);
                }
            }else{
                let notify = new Notification();
                notify.setNotification(null,translate.t("InvalidOrderPriceToAddCredit"),false,true,this.props.client);
            }
        
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidCreditPercentage"),false,true,this.props.client);
        }
            */

    }

    getData(data,formData,setFormData){
   
        let newFormData = { 
            company:                        data.settingsWithCountryValues.company,
            street:                         data.settingsWithCountryValues.street,
            city:                           data.settingsWithCountryValues.city,
            zip:                            data.settingsWithCountryValues.zip,
            country:                        data.settingsWithCountryValues.country,
            ic:                             data.settingsWithCountryValues.IC,
            dic:                            data.settingsWithCountryValues.DIC,
            bankAccount:                    data.settingsWithCountryValues.bankAccount,
            deliveryTimeFrom:               data.settingsWithCountryValues.deliveryTimeFrom,
            deliveryTimeTo:                 data.settingsWithCountryValues.deliveryTimeTo,
            slotDuration:                   data.settingsWithCountryValues.slotDuration,
            timeHandleDelivery:             data.settingsWithCountryValues.timeHandleDelivery,
            hideEatInOption:                data.settingsWithCountryValues.hideEatInOption,
            hideTakeAwayOption:             data.settingsWithCountryValues.hideTakeAwayOption,
            deliveryTypeID:                 data.settingsWithCountryValues.deliveryTypeID,
            enableCreditUsage:              data.settingsWithCountryValues.enableCreditUsage,
            termsAndConditionsChangeDate:   !!data.settingsWithCountryValues.termsAndConditionsChangeDate ? new Date(data.settingsWithCountryValues.termsAndConditionsChangeDate) : null
        }

        let minOrderPriceToAddCredit        = [];
        let inRestaurantVat                 = [];
        let takeAwayVat                     = [];
        let alcoholVat                      = [];
        let minimumOrderAmount              = [];
        let deliveryPrice                   = [];
        let deliveryPriceVat                = [];
        let enableVoucherUsage              = [];
        let firstMealFree                   = [];
        let cashPayEnabled                  = [];
        let restaurantDeliveryTimeToAlert   = [];
        let restaurantDeliveryTime          = [];
        let restaurantCookingTime           = [];
        let creditPercentage                = [];
        let addCreditOnCashPayment          = [];
        
        
        for(let item of data.allActiveCountries){
            let newItem = {label:'minOrderPriceToAddCredit',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.minOrderPriceToAddCredit){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            minOrderPriceToAddCredit.push(newItem);
            
            newItem = {label:'inRestaurantVat',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.inRestaurantVat){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            inRestaurantVat.push(newItem);
            
            newItem = {label:'takeAwayVat',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.takeAwayVat){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            takeAwayVat.push(newItem);
            
            newItem = {label:'alcoholVat',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.alcoholVat){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            alcoholVat.push(newItem);
            
            newItem = {label:'minimumOrderAmount',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.minimumOrderAmount){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            minimumOrderAmount.push(newItem);
            
            newItem = {label:'deliveryPrice',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.deliveryPrice){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            deliveryPrice.push(newItem);
            
            newItem = {label:'deliveryPriceVat',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.deliveryPriceVat){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            deliveryPriceVat.push(newItem);
            
            newItem = {label:'enableVoucherUsage',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.enableVoucherUsage){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            enableVoucherUsage.push(newItem);
            
            newItem = {label:'firstMealFree',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.firstMealFree){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            firstMealFree.push(newItem);
            
            newItem = {label:'cashPayEnabled',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.cashPayEnabled){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            cashPayEnabled.push(newItem);
            
            newItem = {label:'restaurantDeliveryTimeToAlert',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.restaurantDeliveryTimeToAlert){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            restaurantDeliveryTimeToAlert.push(newItem);
            
            newItem = {label:'restaurantDeliveryTime',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.restaurantDeliveryTime){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            restaurantDeliveryTime.push(newItem);
            
            newItem = {label:'restaurantCookingTime',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.restaurantCookingTime){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            restaurantCookingTime.push(newItem);
            
            newItem = {label:'creditPercentage',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.creditPercentage){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            creditPercentage.push(newItem);
            
            newItem = {label:'addCreditOnCashPayment',countryID:item.countryID,value:"0"};
            for(let it of data.settingsWithCountryValues.addCreditOnCashPayment){
                if(it.countryID == item.countryID){
                    newItem.value = it.value;
                }
            }
            addCreditOnCashPayment.push(newItem);
            

        }
        
        newFormData.minOrderPriceToAddCredit        = minOrderPriceToAddCredit;
        newFormData.inRestaurantVat                 = inRestaurantVat;
        newFormData.takeAwayVat                     = takeAwayVat;
        newFormData.alcoholVat                      = alcoholVat;
        newFormData.minimumOrderAmount              = minimumOrderAmount;
        newFormData.deliveryPrice                   = deliveryPrice;
        newFormData.deliveryPriceVat                = deliveryPriceVat;
        newFormData.enableVoucherUsage              = enableVoucherUsage;
        newFormData.firstMealFree                   = firstMealFree;
        newFormData.cashPayEnabled                  = cashPayEnabled;
        newFormData.restaurantDeliveryTimeToAlert   = restaurantDeliveryTimeToAlert;
        newFormData.restaurantDeliveryTime          = restaurantDeliveryTime;
        newFormData.restaurantCookingTime           = restaurantCookingTime;
        newFormData.creditPercentage                = creditPercentage;
        newFormData.addCreditOnCashPayment          = addCreditOnCashPayment;
        

        setFormData({...formData,...newFormData});
        
    }
    

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
     formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

    countryFormHandle(value,countryID,objectName,formData,setFormData){
        
        let newArray = [];
        for(let item of formData[objectName]){
            let newItem = {...item};

            if(countryID == newItem.countryID){
                newItem.value = value;
            }
            newArray.push(newItem);
        }
        
        
        setFormData({...formData,[objectName]: newArray});
              
    }

}

export default Settings;