import React,{ Component } from 'react';
import AllAppLinks from './AllAppLinks.js';
import AddEditAppLink from './AddEditAppLink';
import translate from '../../Translations/index';
import Helper from '../../../Library/helper';
import { withApollo } from 'react-apollo';

const INITIAL_STATE = {
    showAddAppLink : false,
}

class AppLink extends Component{

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;
       this.openCloseModal = this.openCloseModal.bind(this);

       const helper = new Helper();
       helper.checkAdminRights(props,["1","2"]);
    }

    

    openCloseModal(type){
        this.setState({
            showAddAppLink:type,
        })
    }

    render(){

        return(

            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <div className="card-header d-flex align-items-center">
                                {translate.t("AppLinkList")}
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.openCloseModal(true)}>{translate.t("Add")}</button>
                            </div>
                            <div className="card-body">
                                <AllAppLinks />
                            </div>
                        </div>
                    </div>
                </div> 	
                {this.state.showAddAppLink &&
                    <AddEditAppLink openCloseModal={this.openCloseModal} />
                }		
            </div>
        );

    }

}

export default withApollo(AppLink);