/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_DRINK = gql`
    mutation AddEditDrink($active:Int,$isAlcohol:Int,$drinkID:ID,$photo:Upload, $langs:[DrinkLangsInput]){
        addEditDrink(active:$active, isAlcohol:$isAlcohol, drinkID:$drinkID,photo:$photo,langs:$langs)
    }
`;


/**
 *  Data kategorie
 */

export const GET_DRINK = gql`
    query Drink($drinkID: ID!){
        drinkWithLangs(drinkID:$drinkID){
            drinkID
            active  
            isAlcohol
            price
            vatID
            photo
            langs{
                lang
                name
            }      
        }
        allVats{
            vatID
            value
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_DRINKS = gql`
    query AllDrinks($lang:String){
        allDrinks(lang:$lang,onlyActive:false){            
            drinkID
            isAlcohol
            name
            prices{
                price
                countryID
                country{
                    currencyID
                    currency{
                        code
                    }
                }
            }
            active    
        }
    }
`;

/**
 * Smazání kategorie
 */

export const DELETE_DRINKS = gql`
    mutation deleteDrinks($drinkIDs:ID!) {
        deleteDrinks(drinkIDs:$drinkIDs)
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_DRINK_PRIORITY = gql`
    mutation updateDrinkPriority($drinkID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateDrinkPriority(drinkID:$drinkID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;