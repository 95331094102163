/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_OPTION = gql`
    mutation AddEditOption($active:Int,$isAlcohol:Int,$optionID:ID,$photo:Upload, $langs:[OptionLangsInput]){
        addEditOption(active:$active,isAlcohol:$isAlcohol, optionID:$optionID,photo:$photo,langs:$langs)
    }
`;


/**
 *  Data kategorie
 */

export const GET_OPTION = gql`
    query Option($optionID: ID!){
        optionWithLangs(optionID:$optionID){
            optionID
            active  
            isAlcohol
            price
            photo
            vatID
            packageVatID
            packagePrice
            langs{
                lang
                name
            }      
        }
        allVats{
            vatID
            value
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_OPTIONS = gql`
    query AllOptions($lang:String){
        allOptions(lang:$lang,onlyActive:false){            
            optionID
            isAlcohol
            name
            prices{
                price
                countryID
                country{
                    currencyID
                    currency{
                        code
                    }
                }
            }
            active    
        }
    }
`;

/**
 * Smazání kategorie
 */

export const DELETE_OPTIONS = gql`
    mutation deleteOptions($optionIDs:ID!) {
        deleteOptions(optionIDs:$optionIDs)
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_OPTION_PRIORITY = gql`
    mutation updateOptionPriority($optionID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateOptionPriority(optionID:$optionID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;