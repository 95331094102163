import React,{ Component, Fragment, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import edit from '../../../Public/Images/edit.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import AddEditMeal from './AddEditMeal';
import { DELETE_MEAL_PRICES, GET_MEAL_PRICES } from '../Queries/mealPrices';
import AddEditMealPrice from './AddEditMealPrice';

const MealPrices = (props) => {

    const helper = new Helper();
    helper.checkAdminRights(props,["1","2"]);
    
    const listVariables = {
        mealID:props.mealID
    }

    const [selectedItems,setSelectedItems]          = useState([]);
	const [showDeleteNotifi,setShowDeleteNotifi] 	= useState(false);
	const [showAdd,setShowAdd] 			            = useState(false);
    const [selectedMealPriceID,setSelectedMealPrieID] 	    = useState(0);
    
    const {loading, error:getError, data:listData, refetch}   = useQuery(GET_MEAL_PRICES,{
        variables:listVariables,
        fetchPolicy: 'cache-and-network'
    });

    const [deleteMeal, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_MEAL_PRICES,{	
		update(cache,response) {            
            
            refetch();

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });


    const openCloseModal = (open,mealPriceID) => {
        setSelectedMealPrieID(mealPriceID);
        setShowAdd(open);
    }

    const showDelNotifi = () => {
        if(selectedItems.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const delMeal = (action) => {

        if(action){

            let mealPriceIDs = selectedItems.join(",");
            deleteMeal({variables:{mealPriceIDs}});
        }

        setShowDeleteNotifi(false);
    }

    const initiateDeleteMeal = (e,mealPriceID) => {

        var checked = e.target.checked;
        var arr = [...selectedItems];

        if(!checked){
            for(let i in selectedItems){
                if(selectedItems[i] == mealPriceID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [mealPriceID,...arr];
        }

        setSelectedItems(arr);
  
    }

    return (
        <div>
            
            <div className="d-flex flex-column" >
                        
                <div className="d-flex align-items-center mb-4">
                    <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                    <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                </div>
                <div>
                    <div className="data-list">
                       
                        {!loading ? 
                            <> 
                                <div className="data-list-item header">
                                    <div className="data-list-item-content">
                                        <div>{translate.t("Country")}</div>
                                        <div className="text-center">{translate.t("Price")}</div>
                                        <div className="text-center">{translate.t("PackagePrice")}</div>
                                        <div className="text-center">{translate.t("Vat")}</div>
                                        <div className="text-center">{translate.t("PackageVat")}</div>
                                        <div className="text-right">{translate.t("Options")}</div>
                                    </div>
                                </div>
                                
                                {listData && listData.allMealPrices && listData.allMealPrices.length > 0 ?
                                    <>
                                        {listData.allMealPrices.map((item,index) => {
                                                                
                                            var checked = false;
                                            for(let i in selectedItems){
                                                if(selectedItems[i] == item.mealPriceID)checked = true;
                                            }   

                                            return(                                                 
                                                <div key={index} className="data-list-item" >
                                                    <div className="data-list-item-content">
                                                        <div className="">{item.country && item.country.name}</div>
                                                        <div className="text-center">{item.price} {item.country && item.country.currency && item.country.currency.code}</div>
                                                        <div className="text-center">{item.packagePrice} {item.country && item.country.currency && item.country.currency.code}</div>
                                                        <div className="text-center">{item.vat && item.vat.value}%</div>
                                                        <div className="text-center">{item.packageVat && item.packageVat.value}%</div>
                                                        <div className="text-right">
                                                            <img onClick={() => openCloseModal(true,item.mealPriceID)} className="edit-icon" src={edit} />
                                                            <input className="delete-checked" type="checkbox" name="deleteAthlete[]" checked={checked} onChange = {(e) => initiateDeleteMeal(e,item.mealPriceID)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                    :                                
                                    <NoItems text={translate.t("NoItemsAddedYet")} />
                                }
                            </>
                            :                                   
                            <Loading />                                    
                        }  
                    </div>                                                  
                </div>
            </div> 	
            {showAdd &&
                <AddEditMealPrice mealPriceID={selectedMealPriceID} mealID={props.mealID} openCloseModal={openCloseModal} listVariables={listVariables} />
            }	
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delMeal} /> }	
        </div>
    )

}

export default withApollo(MealPrices);