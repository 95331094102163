import gql from 'graphql-tag';

export const GET_MEAL_DRINK_PRICE = gql`
    query MealDrinkPrice($mealDrinkPriceID: ID!){
        mealDrinkPrice(mealDrinkPriceID:$mealDrinkPriceID){            
            mealDrinkPriceID
            countryID
            drinkPrice
            vatID
        }
        allActiveCountries(fromAdmin:1){
            countryID
            currencyID
            name
            currency{
                title
            }
        }         
        allVats{
            vatID
            value
        }
    }
`;

export const ADD_EDIT_MEAL_DRINK_PRICE = gql`
    mutation AddEditMealDrinkPrice($mealDrinkPriceInputs:MealDrinkPriceInputs) {
        addEditMealDrinkPrice(mealDrinkPriceInputs:$mealDrinkPriceInputs){
            mealDrinkPriceID
            mealDrinkID
            countryID
            drinkPrice
            vatID
        }
    }
`;

export const DELETE_MEAL_DRINK_PRICE = gql`
    mutation DeleteMealDrinkPrice($mealDrinkPriceID:[ID]!) {
        deleteMealDrinkPrice(mealDrinkPriceID:$mealDrinkPriceID)
    }
`;




