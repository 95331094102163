/*
    Smazání kategorie
*/
import React,{ Component } from 'react';
import {Mutation} from 'react-apollo';
import remove from '../../../Public/Images/remove.svg';
import {DELETE_APPLINKS,GET_APPLINKS} from '../Queries/appLink.js';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import translate from '../../Translations/index';

class DeleteAppLink extends Component{

    render(){

        /* 
            atribut refetchQueries opětovně provede dotaz definovaný v returnu v query
            update se provede po provedení mutace
        */

        return(

            <Mutation 
                mutation={DELETE_APPLINKS}
                refetchQueries = {(data)=>{
                    return [{
                        query:GET_APPLINKS,
                        variables:{lang:"cz"}
                    }]
                }}
                update = {async (cache, response) => {

                    let notify = new Notification();
                    notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);

                }}
            >
                {
                    (deleteAppLink,{data,loading,error}) => {

                        if(error){
                            const helper = new Helper(); 
                            return (<Error text={helper.getApolloErrorText(error)} />);
                        }else{

                            return( 
                                <img onClick={() => this.props.deleteAppLink(deleteAppLink,this.props.appLinkID,this.props.parentID,this.props.subAppLink)} className="remove-icon" src={remove} />              
                            );
                        }
                    }
                }
            </Mutation>
        
        );

    }

}

export default DeleteAppLink;