/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_DELIVERY_AREA = gql`
    mutation AddEditDeliveryArea($deliveryAreaID:ID,$color:String,$name:String, $path:String){
        addEditDeliveryArea(deliveryAreaID:$deliveryAreaID, color:$color,name:$name,path:$path){
            deliveryAreaID
            name
            color
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_DELIVERY_AREAS = gql`
    query AllDeliveryAreas{
        allDeliveryAreas{            
            deliveryAreaID
            name
            path
            color
        }
    }
`;

/**
 * Smazání doručovací oblasti
 */

export const DELETE_DELIVERY_AREA = gql`
    mutation deleteDeliveryArea($deliveryAreaID:ID!) {
        deleteDeliveryArea(deliveryAreaID:$deliveryAreaID)
    }
`;

