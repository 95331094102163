import React,{ Component, Fragment, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import edit from '../../../Public/Images/edit.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import AddEditGreenInfoBanner from './AddEditGreenInfoBanner';
import { DELETE_GREEN_INFO_BANNERS, GET_GREEN_INFO_BANNERS } from '../Queries/greenInfoBanner';

const GreenInfoBanners = (props) => {

    const helper = new Helper();
    helper.checkAdminRights(props,["1","2"]);
    
    const listVariables = {
        lang:"cz"
    }

    const [selectedGreenInfoBanners,setSelectedGreenInfoBanners]          = useState([]);
	const [showDeleteNotifi,setShowDeleteNotifi] 	          = useState(false);
	const [showAdd,setShowAdd] 			                      = useState(false);
    const [selectedGreenInfoBannerID,setSelectedGreenInfoBannerID] 	       = useState(0);
    
    const {loading, error:getError, data:greenInfoBannersData, refetch}   = useQuery(GET_GREEN_INFO_BANNERS,{
        variables:listVariables,
        fetchPolicy: 'cache-and-network'
    });

    const [deleteGreenInfoBanner, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_GREEN_INFO_BANNERS,{	
		update(cache,response) {            
            
            refetch();

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    const openCloseModal = (open,appBannerID) => {
        setSelectedGreenInfoBannerID(appBannerID);
        setShowAdd(open);
    }

    const showDelNotifi = () => {
        if(selectedGreenInfoBanners.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const delGreenInfoBanner = (action) => {

        if(action){

            let greenInfoBannerIDs = selectedGreenInfoBanners.join(",");
            deleteGreenInfoBanner({variables:{greenInfoBannerIDs}});
        }

        setShowDeleteNotifi(false);
    }

    const initiateDeleteGreenInfoBanner = (e,appBannerID) => {

        var checked = e.target.checked;
        var arr = [...selectedGreenInfoBanners];

        if(!checked){
            for(let i in selectedGreenInfoBanners){
                if(selectedGreenInfoBanners[i] == appBannerID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [appBannerID,...arr];
        }

        setSelectedGreenInfoBanners(arr);
  
    }

    return (
        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t("GreenInfoBannerList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                        </div>
                        <div className="card-body">
                            <div>
                                <div className="data-list">
                                    
                                    <div className="data-list-item header">
                                        <div className="data-list-item-content">
                                            <div>{translate.t("Text")}</div>
                                            <div className="text-center">{translate.t("Active")}</div>
                                            <div className="text-center">{translate.t("ShowInCountry")}</div>
                                            <div className="text-right">{translate.t("Options")}</div>
                                        </div>
                                    </div>
                                    
                                    <Fragment>
                                        {greenInfoBannersData && greenInfoBannersData.allGreenInfoBanners && greenInfoBannersData.allGreenInfoBanners.map((item,index) => {
                                            
                                            var checked = false;
                                            for(let i in selectedGreenInfoBanners){
                                                if(selectedGreenInfoBanners[i] == item.greenInfoBannerID)checked = true;
                                            }
                                                                                                            
                                            return (
                                                <div className="data-list-item-content" key={index}>
                                                    <div>{item.text}</div>
                                                    <div className="text-center">{!!item.active ? translate.t("Yes") : translate.t("No")}</div>
                                                    <div className="text-center">{!!item.country && item.country.name}</div>
                                                
                                                    <div className="text-right">
                                                        <img onClick={() => openCloseModal(true,item.greenInfoBannerID)} className="edit-icon" src={edit} />
                                                        <input className="delete-checked" type="checkbox" name="deleteGreenInfoBanner[]" checked={checked} onChange = {(e) => initiateDeleteGreenInfoBanner(e,item.greenInfoBannerID)} />                                                            
                                                    </div>
                                                </div>    
                                            )
                                            
                                        })} 
                                    </Fragment>
                                </div>
                                {!loading ? 
                                    <Fragment>
                                        {greenInfoBannersData && greenInfoBannersData.allGreenInfoBanners && greenInfoBannersData.allGreenInfoBanners.length == 0 &&
                                            <NoItems text={translate.t("NoItemsAddedYet")} />
                                        }
                                    </Fragment>
                                    :                                    
                                    <Loading />                                    
                                }
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div> 	
            {showAdd &&
                <AddEditGreenInfoBanner openCloseModal={openCloseModal} selectedGreenInfoBannerID={selectedGreenInfoBannerID} listVariables={listVariables} />
            }	
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delGreenInfoBanner} /> }	
        </div>
    )

}

export default withApollo(GreenInfoBanners);