import translate from '../../Translations/index';
import Notification from '../../../Library/notification';

class Option {

    constructor(props){
        this.props = props;
    }

    addOption(addOption,formData){

        if(formData.photo != "" || formData.optionID != 0){

            if(formData.price != "" || formData.price == 0){
            
                formData.active = parseInt(formData.active);
                formData.isAlcohol = parseInt(formData.isAlcohol);

                addOption({
                    variables:formData
                }); 

            }else{
                let notify = new Notification();
                notify.setNotification(null,translate.t("InvalidOptionPrice"),false,true,this.props.client);
            }

        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidPhoto"),false,true,this.props.client);
        }
                              
    }

    getData(data,optionID,formData,setFormData){

        if(optionID == 0){

            let langs = this.completeLangsArray([],data.allLanguageMutations); 
            setFormData({...formData,langs:langs});

        }else{

            console.log(data);
            
            setFormData({...formData,
                active: data.optionWithLangs.active,
                isAlcohol: data.optionWithLangs.isAlcohol,
                optionID: data.optionWithLangs.optionID,
                langs: this.completeLangsArray(data.optionWithLangs.langs,data.allLanguageMutations)
            });
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(lang.__typename)delete(lang["__typename"]);
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    name:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e,formData,setFormData){

        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        var langs = formData.langs;

        for(const lang of langs){
            if(lang.lang == formData.selectedLang){
                lang[n] = v;
            }
        }

        setFormData({...formData,langs: langs});
         
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} propertyName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (formData,propertyName) => {
        for(const lang of formData.langs){
            if(lang.lang == formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }

}

export default Option;