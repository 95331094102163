import translate from '../../Translations/index';
import Notification from '../../../Library/notification';

class MealOptionPrice {

    constructor(props){
        this.props = props;
    }

    addMealOptionPrice(addMealOptionPrice,formData){
        if(formData.optionPrice !== ""){
            
            formData.optionPrice          = parseFloat(formData.optionPrice);
            formData.packagePrice          = parseFloat(formData.packagePrice);
            addMealOptionPrice({
                variables:{
                    mealOptionPriceInputs:formData
                }
            }); 

        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidPrice"),false,true,this.props.client);
        }                     

    }

    getData(data,mealOptionPriceID,formData,setFormData){
        console.log(data);
        if(mealOptionPriceID != 0){            
            setFormData({...formData,
                mealOptionPriceID: data.mealOptionPrice.mealOptionPriceID,
                countryID: data.mealOptionPrice.countryID,
                optionPrice: data.mealOptionPrice.optionPrice,
                vatID: data.mealOptionPrice.vatID,
                packageVatID: data.mealOptionPrice.packageVatID,
                packagePrice: data.mealOptionPrice.packagePrice,
            });
        }
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default MealOptionPrice;