import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {ADD_EDIT_DRINK, GET_DRINK,GET_DRINKS} from '../Queries/drink';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import DrinkLib from '../Library/drink';
import {DRINK_IMAGE_URL,SERVER_URL,MAX_UPLOAD_FILE_LIMIT} from '../../../Config/index';
import DrinkPrices from './DrinkPrices';


const AddEditDrink = (props) => {

    const drinkLib = new DrinkLib(props);

    const [formData,setFormData] = useState({
        selectedLang:"cz",
        drinkID:props.selectedDrinkID,
        langs:[],
        active:1,
        isAlcohol:0,
        price:"",
        photo:"",
        vatID:3
    });

    const [allLanguageMutations, setAllLanguageMutations] = useState([]);
    const [allVats, setAllVats] = useState([]);
    const [mainPhoto, setMainPhoto] = useState("");
    const [selectedTab, setTab] = useState(1);

    const {data:catData,loading:getLoading,error:getError} = useQuery(GET_DRINK,{
        variables:{drinkID: formData.drinkID},
        fetchPolicy: 'network-only',
        onCompleted(data){

            drinkLib.getData(data,formData.drinkID,formData,setFormData);
            setAllLanguageMutations(data.allLanguageMutations);
            setAllVats(data.allVats);

            if(formData.drinkID){
                setMainPhoto(SERVER_URL + "/" + DRINK_IMAGE_URL + "/d-" + formData.drinkID + "/velka_" + data.drinkWithLangs.photo);
            }

        },
    })

    const [addEditDrink,{data:addDrinkData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_DRINK,{
        onCompleted(data){
			props.openCloseModal(false);
            
		},update(cache,response) {            
            
            let notify = new Notification();
            if(formData.athleteID && formData.athleteID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        },
        refetchQueries(data){
            return [{
                query:GET_DRINKS,
                variables: { lang:"cz" }
            }]
        }
    })

    const openMainImage = (e) => {

        var file = e.target.files[0];

        if(file){
            var reader = new FileReader();
            reader.onload = (onLoadE) => {
                if((onLoadE.total / 1000000) <= MAX_UPLOAD_FILE_LIMIT){
                    var dataURL = reader.result;
                    setMainPhoto(dataURL);
                    setFormData({...formData,photo:file});
                }else{
                    let notify      = new Notification();
                    notify.setNotification(null,translate.t("MaxFileSizeExceeded"),false,true,props.client);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError)
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.drinkID && formData.drinkID != 0 ? translate.t("EditDrink") : translate.t("AddDrink"))}</h4>
                    {allLanguageMutations && allLanguageMutations.length > 1 &&
                        <div className="ml-auto">
                            <select className="form-control" name="selectedLang" value={formData.selectedLang} onChange={(e) => drinkLib.formHandle(e,formData,setFormData)}>
                                {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                    <option key={index} value={item.suffix}> {item.suffix} </option>
                                ))}
                            </select>
                        </div>
                    }
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                <Fragment>  
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className={(selectedTab == 1 ? "nav-link active" : "nav-link")} href="#main" onClick={(e) => setTab(1)}>{translate.t("MainData")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 2 ? "nav-link active" : (formData.drinkID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.drinkID)setTab(2)}}>{translate.t("PricesAndVats")}</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        
                                        {selectedTab == 1 &&
                                            <div>                                                
                                                <div className="row">
                                                    <div className="col-3">
                                                        <label className="input-label">*{translate.t("Active")}</label>
                                                        <div className="form-group">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(formData.active == 1 ?  true : false)} onChange={(e) => drinkLib.formHandle(e,formData,setFormData)} />
                                                                <label className="form-check-label" htmlFor="active1">
                                                                    {translate.t("Yes")}
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(formData.active == 0 ?  true : false)} onChange={(e) => drinkLib.formHandle(e,formData,setFormData)} />
                                                                <label className="form-check-label" htmlFor="active2">
                                                                    {translate.t("No")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                    <div className="col-9">
                                                        <label className="input-label">*{translate.t("IsAlcohol")}</label>
                                                        <div className="form-group">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="isAlcohol" id="isAlcohol1" value="1" checked={(formData.isAlcohol == 1 ?  true : false)} onChange={(e) => drinkLib.formHandle(e,formData,setFormData)} />
                                                                <label className="form-check-label" htmlFor="isAlcohol1">
                                                                    {translate.t("Yes")}
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="isAlcohol" id="isAlcohol2" value="0" checked={(formData.isAlcohol == 0 ?  true : false)} onChange={(e) => drinkLib.formHandle(e,formData,setFormData)} />
                                                                <label className="form-check-label" htmlFor="isAlcohol2">
                                                                    {translate.t("No")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>   
                                                <div className="row">    
                                                    <div className="col-6">
                                                        
                                                        <div className="form-group">

                                                            <label className="input-label">*{translate.t("MainPhoto")}</label>

                                                            <div className="form-group input-group">
                                                                <div className="custom-file">
                                                                    <input type="file" accept=".jpg, .jpeg" className="custom-file-input" id="customFile" required onChange={(e) => openMainImage(e)} />
                                                                    <label className="custom-file-label" htmlFor="customFile">{translate.t("ChoosePhoto")}<small className=" ml-2">(JPG/JPEG, {translate.t("Max")} {MAX_UPLOAD_FILE_LIMIT}Mb)</small></label>
                                                                </div>
                                                            </div>
                                                            {mainPhoto != "" &&
                                                                <div className="form-group main-photo-container">
                                                                    
                                                                    <div className="one-image">            
                                                                        <img id="main-image" src={mainPhoto} />
                                                                    </div>

                                                                </div>
                                                            }

                                                        </div> 
                                                        
                                                    </div> 
                                                    <div className="col-6"> 
                                                        <div className="row">                                                                                      
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label className="input-label">*{translate.t("ItemName")} ({formData.selectedLang})</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" athlete="text" name="name" value={drinkLib.getLangValue(formData,"name")} onChange={(e) => drinkLib.formLangHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>                                                
                                                            </div>  
                                                        </div> 
                                                    </div> 
                                                </div> 
                                            </div> 
                                        }
                                        {selectedTab == 2 &&

                                            <DrinkPrices drinkID = {formData.drinkID} />

                                        }
                                    </div>

                                </Fragment> 
                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                {selectedTab == 1 &&
                    <div className="modal-footer">                                
                        <button athlete="button" className="btn btn-primary flex-fill" onClick={() => drinkLib.addDrink(addEditDrink,formData)}>{(formData.drinkID && formData.drinkID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                        <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                    </div>
                }
            </div>
        </Modal>
    )

}

export default withApollo(AddEditDrink);