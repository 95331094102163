import translate from '../../Translations/index';
import Notification from '../../../Library/notification';

class OptionPrice {

    constructor(props){
        this.props = props;
    }

    addOptionPrice(addOptionPrice,formData){

        if(formData.price !== ""){
            if(formData.packagePrice !== ""){
            
                formData.price          = parseFloat(formData.price);
                formData.packagePrice   = parseFloat(formData.packagePrice);
                addOptionPrice({
                    variables:{
                        optionPriceInputs:formData
                    }
                }); 

            }else{
                let notify = new Notification();
                notify.setNotification(null,translate.t("InvalidPackagePrice"),false,true,this.props.client);
            }
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidPrice"),false,true,this.props.client);
        }                     

    }

    getData(data,optionPriceID,formData,setFormData){
        console.log(optionPriceID);
        if(optionPriceID != 0){            
            setFormData({...formData,
                optionPriceID: data.optionPrice.optionPriceID,
                countryID: data.optionPrice.countryID,
                price: data.optionPrice.price,
                packagePrice: data.optionPrice.packagePrice,
                vatID: data.optionPrice.vatID,
                packageVatID: data.optionPrice.packageVatID,
            });
        }
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default OptionPrice;