import React,{ Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import edit from '../../../Public/Images/edit.svg';
import contentManager from '../../../Public/Images/content_manager.svg';
import move from '../../../Public/Images/move.svg';
import DeleteAppLink from './DeleteAppLink';
import translate from '../../Translations/index';

class SubAppLink extends Component{

    constructor(props){
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result,updateAppLinkPriority){

        const IDs = result.draggableId.split("-");

        updateAppLinkPriority({
            variables:{
                parentID: IDs[1],
                appLinkID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    render(){

        return(
            
            <DragDropContext onDragEnd={(result) => this.onDragEnd(result,this.props.updateAppLinkPriority)}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                            {this.props.appLinks && this.props.appLinks.map((item,index) => {
                                
                                if(item.appLinkID){
                                return (
                                    <Draggable key={index} draggableId={item.appLinkID+"-"+item.parentID} index={index}>
                                        {(provided, snapshot) => (
                                        <div className="data-list-item" 
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div className="data-list-item-content categories">
                                                <div className="cross" style={(this.props.level == 0 ? {paddingLeft:10}: {paddingLeft:this.props.level * 20 + 10})}>
                                                    <img className="move-icon" src={move} />
                                                    {item.name}
                                                </div>
                                                <div className="text-center">{(item.active == 1 ? translate.t("Yes") : translate.t("No") )}</div>
                                                <div className="text-center">
                                                    {!!item.countries && !!item.countries.length && item.countries.map((item,index) => (
                                                        <div key={index}>{item.name}</div>
                                                    ))}
                                                </div>
                                                <div className="text-right">
                                                    <img onClick={() => this.props.openCloseModal(true,item.appLinkID)} className="edit-icon" src={edit} /> 
                                                    <DeleteAppLink appLinkID={item.appLinkID} parentID={item.parentID} subAppLink={item.subAppLink} deleteAppLink={this.props.deleteAppLink} />
                                                </div>
                                            </div>
                                            {item.subAppLink && <SubAppLink openCloseModal={this.props.openCloseModal} openContentManager={this.props.openContentManager} deleteAppLink={this.props.deleteAppLink} updateAppLinkPriority={this.props.updateAppLinkPriority} appLinks={item.subAppLink} level={this.props.level + 1} />}
                                        </div>
                                        )}
                                    </Draggable>
                                )
                                }else{
                                    return "";
                                }
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        )

    }

}

export default SubAppLink;