import gql from 'graphql-tag';


export const GET_MEAL_DRINKS = gql`
    query AllMealDrinks($mealID: ID!,$lang:String){
        allMealDrinks(mealID:$mealID,lang:$lang){      
            mealDrinkID
            drinkID
            drinkPrice
            lang
            drink{
                name
            }
            prices{
                mealDrinkPriceID
                drinkPrice
                countryID
                vatID
                country{
                    currencyID
                    currency{
                        code
                    }
                }
                vat{
                    value
                }
            }
        }
        allDrinks(lang:$lang,onlyActive:false){
            drinkID
            name
            price
        }
    }
`;
export const DELETE_MEAL_DRINKS = gql`
    mutation DeleteMealDrinks($mealDrinkIDs:[ID]!,$mealID:ID) {
        deleteMealDrinks(mealDrinkIDs:$mealDrinkIDs)
        reindexMealDrinkPriority(mealID:$mealID)
    }
`;
export const UPDATE_MEAL_DRINK_PRIORITY = gql`
    mutation updateMealDrinkPriority($mealID:ID,!$mealDrinkID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateMealDrinkPriority(mealID:$mealID,mealDrinkID:$mealDrinkID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

export const ADD_MEAL_DRINK = gql`
    mutation AddMealDrink($mealID:ID,$drinkID:ID) {
        addMealDrink(mealID:$mealID,drinkID:$drinkID)
        reindexMealDrinkPriority(mealID:$mealID)
    }
`;





