import React,{ Component, Fragment, useEffect, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {ADD_EDIT_MEAL, GET_MEAL,GET_MEALS, REINDEX_PRIORITIES, UPDATE_MEAL_OPTION_PRIORITY, UPDATE_MEAL_SIDE_DISH_PRIORITY} from '../Queries/meal';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import MealLib from '../Library/meal';
import { Editor } from '@tinymce/tinymce-react';
import NoItems from '../../../GlobalComponents/NoItems';
import remove from '../../../Public/Images/remove.svg';
import MealImages from './MealImages';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {MEAL_IMAGE_URL,SERVER_URL,MAX_UPLOAD_FILE_LIMIT} from '../../../Config/index';
import move from '../../../Public/Images/move.svg';
import MealPrices from './MealPrices';
import MealSideDishes from './MealSideDishes';
import MealOptions from './MealOptions';
import MealDrinks from './MealDrinks';


const AddEditMeal = (props) => {

    const mealLib = new MealLib(props);

    const [formData,setFormData] = useState({
        selectedLang:"cz",
        mealID:props.selectedMealID,
        langs:[],
        active:1,
        isAlcohol:0,
        photo:"",
        mealTypeID:1,
        mealFoodTypeID:1,
        selectedSideDishes:[],
        selectedDrinks:[],
        selectedOptions:[],
        selectedCategories:[]
    });

    const [allLanguageMutations, setAllLanguageMutations] = useState([]);
    const [allSideDishes, setAllSideDishes] = useState([]);
    const [allDrinks, setAllDrinks] = useState([]);
    const [allOptions, setAllOptions] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [allVats, setAllVats] = useState([]);
    const [allMealTypes,setAllMealTypes] = useState([]);
    const [allMealFoodTypes,setAllMealFoodTypes] = useState([]);
    const [selectedTab, setTab] = useState(1);
    const [mainPhoto, setMainPhoto] = useState("");
    const [selectedSideDish, setSelectedSideDish] = useState({name:"",sideDishID:0,sideDishPrice:""});
    const [selectedDrink, setSelectedDrink] = useState({name:"",drinkID:0,drinkPrice:""});
    const [selectedOption, setSelectedOption] = useState({name:"",optionID:0,optionPrice:""});

    const {data:mealData,loading:getLoading,error:getError} = useQuery(GET_MEAL,{
        variables:{
            mealID: formData.mealID,
            lang:"cz"
        },
        fetchPolicy: 'network-only',
        onCompleted(data){
            mealLib.getData(data,formData.mealID,formData,setFormData);
            setAllLanguageMutations(data.allLanguageMutations);
            setAllSideDishes(data.allSideDishes);
            setAllDrinks(data.allDrinks);
            setAllOptions(data.allOptions);
            setAllCategories(data.allCategories);
            setAllMealTypes(data.allMealTypes);
            setAllMealFoodTypes(data.allMealFoodTypes);
            setAllVats(data.allVats);

            if(formData.mealID){
                setMainPhoto(SERVER_URL + "/" + MEAL_IMAGE_URL + "/m-" +formData.mealID + "/velka_" + data.mealWithLangs.photo);
            }

        },
    })

    const [addEditMeal,{data:addMealData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_MEAL,{
        onCompleted(data){

            if(!formData.mealID){
                setFormData({...formData,mealID:data.addEditMeal.mealID,photo:""})
                setTab(2);
                //setMainPhoto(SERVER_URL + "/" + MEAL_IMAGE_URL + "/m-" +data.addEditMeal.mealID + "/velka_" + data.mealWithLangs.photo);
            }else{
                props.openCloseModal(false);
            }
			
		},update(cache,response) {            
            
            let notify = new Notification();
            if(formData.mealID && formData.mealID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        },
        refetchQueries(data){
            return [{
                query:GET_MEALS,
                variables: { lang:"cz" }
            }]
        }
    })
    const [reindexPriorities] = useMutation(REINDEX_PRIORITIES)

    useEffect(() => {
        reindexPriorities({variables:{
            mealID:formData.mealID
        }});
    },[]);

    const openMainImage = (e) => {

        var file = e.target.files[0];

        if(file){
            var reader = new FileReader();
            reader.onload = (onLoadE) => {
                if((onLoadE.total / 1000000) <= MAX_UPLOAD_FILE_LIMIT){
                    var dataURL = reader.result;
                    setMainPhoto(dataURL);
                    setFormData({...formData,photo:file});
                }else{
                    let notify      = new Notification();
                    notify.setNotification(null,translate.t("MaxFileSizeExceeded"),false,true,props.client);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError)
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.mealID && formData.mealID != 0 ? translate.t("EditMeal") : translate.t("AddMeal"))}</h4>
                    {allLanguageMutations && allLanguageMutations.length > 1 &&
                        <div className="ml-auto">
                            <select className="form-control" name="selectedLang" value={formData.selectedLang} onChange={(e) => mealLib.formHandle(e,formData,setFormData)}>
                                {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                    <option key={index} value={item.suffix}> {item.suffix} </option>
                                ))}
                            </select>
                        </div>
                    }
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                <Fragment>  

                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className={(selectedTab == 1 ? "nav-link active" : "nav-link")} href="#main" onClick={(e) => setTab(1)}>{translate.t("MainData")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 2 ? "nav-link active" : (formData.mealID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.mealID)setTab(2)}}>{translate.t("PricesAndVats")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 3 ? "nav-link active" : (formData.mealID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.mealID)setTab(3)}}>{translate.t("SideDishes")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 4 ? "nav-link active" : (formData.mealID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.mealID)setTab(4)}}>{translate.t("Options")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 5 ? "nav-link active" : (formData.mealID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.mealID)setTab(5)}}>{translate.t("Drinks")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 6 ? "nav-link active" : (formData.mealID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.mealID)setTab(6)}}>{translate.t("Photos")}</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="myTabContent">
                                        
                                        {selectedTab == 1 &&
                                            <div className="row">
                                                <div className="col-12 col-sm-8">
                                                    <div className="row">    
                                                        <div className="col-4">
                                                            <label className="input-label">*{translate.t("Active")}</label>
                                                            <div className="form-group">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(formData.active == 1 ?  true : false)} onChange={(e) => mealLib.formHandle(e,formData,setFormData)} />
                                                                    <label className="form-check-label" htmlFor="active1">
                                                                        {translate.t("Yes")}
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(formData.active == 0 ?  true : false)} onChange={(e) => mealLib.formHandle(e,formData,setFormData)} />
                                                                    <label className="form-check-label" htmlFor="active2">
                                                                        {translate.t("No")}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>    
                                                        <div className="col-8">
                                                            <label className="input-label">*{translate.t("IsAlcohol")}</label>
                                                            <div className="form-group">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="isAlcohol" id="isAlcohol1" value="1" checked={(formData.isAlcohol == 1 ?  true : false)} onChange={(e) => mealLib.formHandle(e,formData,setFormData)} />
                                                                    <label className="form-check-label" htmlFor="isAlcohol1">
                                                                        {translate.t("Yes")}
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="isAlcohol" id="isAlcohol2" value="0" checked={(formData.isAlcohol == 0 ?  true : false)} onChange={(e) => mealLib.formHandle(e,formData,setFormData)} />
                                                                    <label className="form-check-label" htmlFor="isAlcohol2">
                                                                        {translate.t("No")}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>    


                                                        <div className="col-12">

                                                            <label className="input-label">*{translate.t("MainPhoto")}</label>

                                                            <div className="form-group input-group">
                                                                <div className="custom-file">
                                                                    <input type="file" accept=".jpg, .jpeg" className="custom-file-input" id="customFile" required onChange={(e) => openMainImage(e)} />
                                                                    <label className="custom-file-label" htmlFor="customFile">{translate.t("ChoosePhoto")}<small className=" ml-2">(JPG/JPEG, {translate.t("Max")} {MAX_UPLOAD_FILE_LIMIT}Mb)</small></label>
                                                                </div>
                                                            </div>
                                                            {mainPhoto != "" &&
                                                                <div className="form-group main-photo-container">
                                                                    
                                                                    <div className="one-image">            
                                                                        <img id="main-image" src={mainPhoto} />
                                                                    </div>

                                                                </div>
                                                            }

                                                        </div>

                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="input-label">*{translate.t("ItemName")} ({formData.selectedLang})</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="name" value={mealLib.getLangValue(formData,"name")} onChange={(e) => mealLib.formLangHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div> 
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="input-label">*{translate.t("LongItemName")} ({formData.selectedLang})</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="longName" value={mealLib.getLangValue(formData,"longName")} onChange={(e) => mealLib.formLangHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div> 
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">*{translate.t("Type")} </label>
                                                                <div className="form-group">
                                                                    <select className="form-control" name="mealTypeID"  value={formData.mealTypeID} onChange={(e) => mealLib.formHandle(e,formData,setFormData)}>
                                                                        {allMealTypes && allMealTypes.map((it,ind) => (
                                                                            <option key={ind} value={it.mealTypeID} >{it.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>                                                
                                                        </div> 
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">*{translate.t("FoodType")} </label>
                                                                <div className="form-group">
                                                                    <select className="form-control" name="mealFoodTypeID"  value={formData.mealFoodTypeID} onChange={(e) => mealLib.formHandle(e,formData,setFormData)}>
                                                                        {allMealFoodTypes && allMealFoodTypes.map((it,ind) => (
                                                                            <option key={ind} value={it.mealFoodTypeID} >{it.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>                                                
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("Alergen")} ({formData.selectedLang})</label>
                                                                <div className="form-group">
                                                                    <textarea className="form-control" name="alergens" value={mealLib.getLangValue(formData,"alergens")} onChange={(e) => mealLib.formLangHandle(e,formData,setFormData)}></textarea>
                                                                </div>
                                                            </div>                                                
                                                        </div> 
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("Ingredients")} ({formData.selectedLang})</label>
                                                                <div className="form-group">
                                                                    {mealLib.getLangValue(formData,"dscription")}
                                                                    <textarea className="form-control" name="description" value={mealLib.getLangValue(formData,"description")} onChange={(e) => mealLib.formLangHandle(e,formData,setFormData)}></textarea>
                                                                </div>
                                                            </div>                                                
                                                        </div> 
             
                                                    </div> 
                                                </div>
                                                <div className="col-12 col-sm-4">

                                                    <div className="data-list-item-content">
                                                        <label className="input-label item">{translate.t("Categories")}</label>
                                                    </div>
                                                    <div className="data-list">
                                                        {allCategories && allCategories.map((item,index) => {

                                                            var check = false;
                                                            for(let id of formData.selectedCategories){
                                                                if(id == item.categoryID)check = true;
                                                            }

                                                            return(
                                                                <div key={index} className="data-list-item-content"> 
                                                                    <div><input type="checkbox" checked = {check} name="category" value={item.categoryID} onChange={(e) => mealLib.selectCategory(e,setFormData,formData)} /> {item.name}</div>
                                                                </div>
                                                            )

                                                        })}
                                                        
                                                    </div>

                                                </div>
                                            </div>
                                        }

                                        {selectedTab == 2 &&

                                            <MealPrices mealID = {formData.mealID} />

                                        }

                                        {selectedTab == 3 &&

                                            <MealSideDishes mealID = {formData.mealID} />

                                        }

                                        {selectedTab == 4 &&

                                            <MealOptions mealID = {formData.mealID} />

                                        }

                                        {selectedTab == 5 &&

                                            <MealDrinks mealID = {formData.mealID} />

                                        }
                                        {selectedTab == 6 &&

                                            <MealImages mealID = {formData.mealID} />

                                        }

                                    </div>
                                </Fragment> 
                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                {selectedTab == 1 &&
                    <div className="modal-footer">                                
                        <button type="button" className="btn btn-primary flex-fill" onClick={() => mealLib.addMeal(addEditMeal,formData)}>{(formData.mealID && formData.mealID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                        <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                    </div>
                }
            </div>
        </Modal>
    )

}

export default withApollo(AddEditMeal);