import {GET_BRANCH_BLOCKED_DAYS} from '../Queries/branchBlockedDays';
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';
import moment from 'moment';

class Branch {

    constructor(props){
        this.props = props;
    }

    addBranchBlockedDay(addBranchBlockedDay,formData){

        if(formData.dateBlocked != ""){
  
            addBranchBlockedDay({
                variables:{
                    branchBlockedDayID:formData.branchBlockedDayID,
                    branchID: formData.branchID,
                    dateBlocked:moment(formData.dateBlocked).format("YYYY-MM-DD 00:00:00")
        
                }
            }); 

        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidBlockedDate"),false,true,this.props.client);
        }

    }

    getData(data,branchBlockedDayID,formData,setFormData){

        if(branchBlockedDayID != 0){
            
            setFormData({...formData,
                dateBlocked: moment(data.branchBlockedDay.dateBlocked).toDate()
            });
        }
    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateList(cache, response,variables,branchBlockedDayID){

        const { allBranchBlockedDays,branchBlockedDayListCount } = cache.readQuery({ query: GET_BRANCH_BLOCKED_DAYS ,variables});       
        
        if(branchBlockedDayID && branchBlockedDayID != 0){

            var arr = [...allBranchBlockedDays];

            arr.forEach((item,index) => {
                
                if(item.branchBlockedDayID == response.data.addEditBranchBlockedDay.branchBlockedDayID){
                    arr[index] = response.data.addEditBranchBlockedDay;
                }

            });

            cache.writeQuery({ 
                query:GET_BRANCH_BLOCKED_DAYS,
                variables,
                data:{
                    allBranchBlockedDays: arr,
                    branchBlockedDayListCount :branchBlockedDayListCount
                } 
            });
        

        }else{
            var arr = [response.data.addEditBranchBlockedDay,...allBranchBlockedDays];

            cache.writeQuery({ 
                query:GET_BRANCH_BLOCKED_DAYS,
                variables,
                data:{
                    allBranchBlockedDays: arr,
                    branchBlockedDayListCount :branchBlockedDayListCount
                } 
            });
        }

    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteBranchBlockedDays.split(",");

        const { allBranchBlockedDays,branchBlockedDayListCount } = cache.readQuery({ query: GET_BRANCH_BLOCKED_DAYS ,variables});
        var arr = [...allBranchBlockedDays];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.branchBlockedDayID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_BRANCH_BLOCKED_DAYS,
            variables,
            data:{
                allBranchBlockedDays: [...arr],
                branchBlockedDayListCount :branchBlockedDayListCount - resp.length
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default Branch;