import React,{ Component, Fragment, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import translate from '../../Translations/index';
import {BRANCHES_FOR_STATISTICS, DATA_FOR_STATISTIC_CREDIT_AND_VOUCHERS} from '../Queries/statistics';
import Loading from '../../../GlobalComponents/Loading';
import StatisticLib from '../Library/statistics';
import DatePicker,{ registerLocale } from 'react-datepicker';
import { ADMIN_USER } from '../../../GlobalQueries/globalQueries';
const moment             = require("moment");

const StatisticCreditAndVouchers = (props) => {

    const statisticLib = new StatisticLib(props)

	const adminUserData = props.client.readQuery({ query:ADMIN_USER });

    const [showAllMeals,setShowAllMeals]            = useState(false);
    const [showAllSideDishes,setShowAllSideDishes]  = useState(false);
    const [showAllDrinks,setShowAllDrinks]          = useState(false);
    const [showAllOptions,setShowAllOptions]        = useState(false);
    const [dateFrom,setDateFrom]                    = useState(moment().toDate());
    const [dateTo,setDateTo]                        = useState(moment().toDate());

    const [filters,setFilters] = useState({
        countryID:adminUserData.adminUser.branchCountryID ? adminUserData.adminUser.branchCountryID : 60,
        dateFrom:moment().format("YYYY-MM-DD"),
        dateTo:moment().format("YYYY-MM-DD"),
        driverID:0,
        branchID:adminUserData.adminUser.roleID == 5 ? adminUserData.adminUser.branchID : 0
    });
    const [salesData,setSalesData] = useState([]);
    const {loading, error, data, refetch}   = useQuery(DATA_FOR_STATISTIC_CREDIT_AND_VOUCHERS,{
        fetchPolicy:"network-only",
        variables:{
            filterData:filters
        },
        onCompleted(data){
            
        },
    });
    const {loading:branchesLoading, error:branchesError, data:branchesData}   = useQuery(BRANCHES_FOR_STATISTICS,{
        fetchPolicy:"network-only",
        variables:{
            countryID:filters.countryID
        }
    });

    const setDateFromFilter = (date) => {
        setFilters({...filters,dateFrom:moment(date).format("YYYY-MM-DD")});
        setDateFrom(date);
    }
    const setDateToFilter = (date) => {
        setFilters({...filters,dateTo:moment(date).format("YYYY-MM-DD")});
        setDateTo(date);
    }

    const countryChanged = (e) => {
        setFilters({...filters,branchID:0,countryID:e.target.value});
    }
    const getCurrency = () => {
        if(data && data.allActiveCountries){
            const selectedCountry = data.allActiveCountries.filter(item => item.countryID == filters.countryID);
            console.log(selectedCountry);
            if(selectedCountry[0] && selectedCountry[0].currency && selectedCountry[0].currency.code){
                return selectedCountry[0].currency.code
            }
        }
        return "";
    }    
    const currency = getCurrency();

    return (
        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main form-group">
                        
                        <div className="card-header d-flex align-items-center flex-row">
                            <div className="fs20 bold">{translate.t("Statistics")} / <span className="fs14">{translate.t("CreditAndVouchers")}</span></div>
                            
                            {[1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) !== -1 &&
                                <div className="ml-auto d-flex align-items-center mr-5">
                                    {translate.t("Country")} 
                                    <select className="form-control ml-2" name="countryID" value={filters.countryID} onChange={(e) => countryChanged(e)}>
                                        {false && <option value={0}>-- {translate.t("ChooseCountry")} --</option>}
                                        {data && data.allActiveCountries && data.allActiveCountries.map((item,index) => (
                                            <option key={index} value={item.countryID}> {item.name} </option>
                                        ))}
                                    </select>
                                </div>
                            }
                            {[1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) !== -1 &&
                                <div className="ml-auto d-flex align-items-center mr-5">
                                    {translate.t("Branch")} 
                                    <select className="form-control ml-2" name="branchID" value={filters.branchID} onChange={(e) => setFilters({...filters,branchID:e.target.value})}>
                                        <option value={0}>-- {translate.t("SelectBranch")} --</option>
                                        {branchesData && branchesData.allBranches && branchesData.allBranches.map((item,index) => (
                                            <option key={index} value={item.branchID}> {item.name} </option>
                                        ))}
                                    </select>
                                </div>
                            }
                            {[1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) !== -1 &&
                                <div className="d-flex align-items-center mr-5">
                                    {translate.t("Driver")} 
                                    <select className="form-control ml-2" name="driverID" onChange={(e) => setFilters({...filters,driverID:e.target.value})}>
                                        <option value={0}> --{translate.t("SelectDriver")}-- </option>
                                        {data && data.allDrivers && data.allDrivers.map((item,index) => (
                                            <option key={index} value={item.driverID}> {item.user && item.user.name + " " + item.user.surname} </option>
                                        ))}
                                    </select>
                                </div>
                            }
                            <div className={"d-flex align-items-center mr-5 " + ([1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) === -1 ? "ml-auto" : "" )}>
                                {translate.t("DateFrom")}
                                <DatePicker className="form-control ml-2" dateFormat="dd.MM.yyyy" locale="cs" selected={dateFrom} onChange={date => setDateFromFilter(date)} />
                            </div>
                            <div className="d-flex align-items-center">
                                {translate.t("DateTo")}
                                <DatePicker className="form-control ml-2" dateFormat="dd.MM.yyyy" locale="cs" selected={dateTo} onChange={date => setDateToFilter(date)} />
                            </div>
                        </div>
                        
                    </div>
                    <div>
                                
                    {!loading ? 
                        <div className="row" style={{flexWrap:'wrap'}}>

                            <div className="col-12 col-sm-6">
                                <div className="card statistic main form-group">
                            
                                    <div className="card-header d-flex align-items-center flex-row bold">
                                        {translate.t("UsedCreditTotal")}
                                    </div>
                                    <div className={"card-body "}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th className="text-right">{translate.t("Price")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>{translate.t("AddedCredit")}</th>
                                                    <td className="text-right">{data.creditStatistic.creditsAddedTotal} {currency}</td>                                                   
                                                </tr>
                                                <tr>
                                                    <th>{translate.t("RemovedCredit")}</th>
                                                    <td className="text-right">{data.creditStatistic.creditsRemovedTotal} {currency}</td>                                                   
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="card statistic main form-group">
                            
                                    <div className="card-header d-flex align-items-center flex-row bold">
                                        {translate.t("UsedCreditInterval")} ({moment(filters.dateFrom).format("DD.MM.YYYY")} - {moment(filters.dateTo).format("DD.MM.YYYY")})
                                    </div>
                                    <div className={"card-body "}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th className="text-right">{translate.t("Price")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>{translate.t("AddedCredit")}</th>
                                                    <td className="text-right">{data.creditStatistic.creditsAddedForPeirod} {currency}</td>                                                   
                                                </tr>
                                                <tr>
                                                    <th>{translate.t("RemovedCredit")}</th>
                                                    <td className="text-right">{data.creditStatistic.creditsRemovedForPeirod} {currency}</td>                                                   
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6">
                                <div className="card statistic main form-group">
                            
                                    <div className="card-header d-flex align-items-center flex-row bold">
                                        {translate.t("UsedVoucherTotal")}
                                    </div>
                                    <div className={"card-body "}>
                                        {data.voucherStatistic.voucherUsedPriceTotal} {currency}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="card statistic main form-group">
                            
                                    <div className="card-header d-flex align-items-center flex-row bold">
                                        {translate.t("UsedVoucherInterval")} ({moment(filters.dateFrom).format("DD.MM.YYYY")} - {moment(filters.dateTo).format("DD.MM.YYYY")})
                                    </div>
                                    <div className={"card-body "}>                                       
                                        {data.voucherStatistic.voucherUsedPricePeirod} {currency}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="card statistic main form-group">
                            
                                    <div className="card-header d-flex align-items-center flex-row bold">
                                        {translate.t("UsedVoucherInterval")} ({moment(filters.dateFrom).format("DD.MM.YYYY")} - {moment(filters.dateTo).format("DD.MM.YYYY")})
                                    </div>
                                    <div className={"card-body " + (showAllOptions ? "" : "statistic-short")}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>{translate.t("Code")}</th>
                                                    <th className="text-right">{translate.t("VoucherPriceSum")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.voucherStatistic && data.voucherStatistic.vouchers && data.voucherStatistic.vouchers.map((item,index) => (
                                                    <tr key={index}>
                                                        <th>{item.code}</th>
                                                        <td className="text-right">{item.price} {currency}</td>                                                   
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-center flex-row bold">
                                        <div className="show-hide-panel" onClick={() => setShowAllOptions(!showAllOptions)}>{showAllOptions ? translate.t("Hide") : translate.t("ShowAll")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :                                    
                        <Loading />                                    
                    }
                        
                    </div>
                </div>
            </div> 	
	
        </div>
    )

}

export default withApollo(StatisticCreditAndVouchers);