import translate from '../../Translations/index';
import Notification from '../../../Library/notification';

class MealPrice {

    constructor(props){
        this.props = props;
    }

    addMealPrice(addMealPrice,formData){

        if(formData.price !== ""){
            if(formData.packagePrice !== ""){
            
                formData.price          = parseFloat(formData.price);
                formData.packagePrice   = parseFloat(formData.packagePrice);
                addMealPrice({
                    variables:{
                        mealPriceInputs:formData
                    }
                }); 

            }else{
                let notify = new Notification();
                notify.setNotification(null,translate.t("InvalidPackagePrice"),false,true,this.props.client);
            }
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidPrice"),false,true,this.props.client);
        }                     

    }

    getData(data,mealPriceID,formData,setFormData){
        console.log(mealPriceID);
        if(mealPriceID != 0){            
            setFormData({...formData,
                mealPriceID: data.mealPrice.mealPriceID,
                countryID: data.mealPrice.countryID,
                price: data.mealPrice.price,
                packagePrice: data.mealPrice.packagePrice,
                vatID: data.mealPrice.vatID,
                packageVatID: data.mealPrice.packageVatID,
            });
        }
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default MealPrice;