import gql from 'graphql-tag';

export const GET_MEAL_PRICES = gql`
    query AllMealPrices($mealID: ID!){
        allMealPrices(mealID:$mealID){
            mealPriceID
            mealID
            countryID
            price
            packagePrice
            vatID
            packageVatID
            country{
                name
                currencyID
                currency{
                    code
                }
            }
            vat{
                value
            }
            packageVat{
                value
            }
        }

    }
`;
export const GET_MEAL_PRICE = gql`
    query MealPrice($mealPriceID: ID!){
        mealPrice(mealPriceID:$mealPriceID){
            mealPriceID
            countryID
            price
            packagePrice
            vatID
            packageVatID
        }
        allActiveCountries(fromAdmin:1){
            countryID
            currencyID
            name
            currency{
                title
            }
        }        
        allVats{
            vatID
            value
        }
    }
`;

export const ADD_EDIT_MEAL_PRICE = gql`
    mutation AddEditMealPrice($mealPriceInputs:MealPriceInputs) {
        addEditMealPrice(mealPriceInputs:$mealPriceInputs){
            mealPriceID
            mealID
            countryID
            price
            packagePrice
        }
    }
`;

export const DELETE_MEAL_PRICES = gql`
    mutation DeleteMealPrices($mealPriceIDs:[ID]!) {
        deleteMealPrices(mealPriceIDs:$mealPriceIDs)
    }
`;




