import translate from '../../Translations/index';
import Notification from '../../../Library/notification';

class Homepage {

    constructor(props){
        this.props = props;
    }

    /**
     * Přidání jídla
     * @param {*} addHomepageBanner funkce pro přidání
     * @param {*} formData aktuální data jídla
     */

    addHomepageBanner(addHomepageBanner,formData){

        if(formData.photo != "" || formData.homepageBannerID != 0){

            formData.active = parseInt(formData.active);
            
            addHomepageBanner({
                variables:formData
            }); 


        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidHomepageBannerPhoto"),false,true,this.props.client);
        }
        
    }
    /**
     * úprava dat homepage
     * @param {*} addEditHomepage funkce pro přidání
     * @param {*} formData aktuální data
     */

    saveHomepageData(addEditHomepage,formData){
            
        addEditHomepage({
            variables:formData
        }); 
        
    }

    /**
     * Vytáhne data pro dané jídlo
     * @param {*} data data z databáze
     * @param {*} homepageBannerID ID jídla
     * @param {*} formData aktuální data pro jídlo
     * @param {*} setFormData funkce která data uloží do aktuálních dat
     */

    getData(data,homepageBannerID,formData,setFormData){

        if(homepageBannerID == 0){

            let langs = this.completeLangsArray([],data.allLanguageMutations); 
            setFormData({...formData,langs:langs});

        }else{
           
            setFormData({...formData,
                active: data.homepageBannerWithLangs.active,
                langs: this.completeLangsArray(data.homepageBannerWithLangs.langs,data.allLanguageMutations)
            });
        }

    }

    /**
     * Vytáhne data pro dané jídlo
     * @param {*} data data z databáze
     * @param {*} homepageBannerID ID jídla
     * @param {*} formData aktuální data pro jídlo
     * @param {*} setFormData funkce která data uloží do aktuálních dat
     */

    getHomepageData(data,formData,setFormData){
          
        setFormData({...formData,
            langs: this.completeLangsArray(data.homepageWithLangs.langs,data.allLanguageMutations)
        });
        
        

    }


    /**
     *  Pro vyplnění jazykových mutací
     */

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(lang.__typename)delete(lang["__typename"]);
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    text:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    /**
     *  Pro vyplnění jazykových mutací
     */

    completeHomepageLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(lang.__typename)delete(lang["__typename"]);
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    metaTitle:"",
                    metaKeywords:"",
                    metaDescription:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */         
    formLangHandle(e,formData,setFormData){
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = formData.langs;
        for(const lang of langs){
            if(lang.lang == formData.selectedLang){
                lang[n] = v;
            }
        }

        setFormData({...formData,langs: langs});         
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} paramName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (formData,paramName) => {
        for(const lang of formData.langs){
            if(lang.lang == formData.selectedLang){
                if(lang[paramName] === null){
                    return "";
                }else{
                    return lang[paramName];
                }
            }
        }
        return "";
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     */
    
    formLangHandleEditor(content,paramName,formData,setFormData){
        
        let langs       = formData.langs;
        for(const lang of langs){
            if(lang.lang == formData.selectedLang){
                lang[paramName] = content;
            }
        }

        setFormData({...formData,langs: langs});
    }
    /**
     * Vraci počet znaků zadané proměnné v objektu jazykových mutací lang
     * @param {String} paramName - název proměnné v objektu lang
     * 
     * @returns počet znaků
     */
    getCharCount = (formData,paramName) => {  
        for(const lang of formData.langs){
            if(lang[paramName]){
                if(lang.lang == formData.selectedLang){
                    return lang[paramName].length;
                }
            }
        }
        return 0;
    }
    

}

export default Homepage;