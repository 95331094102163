import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {ADD_EDIT_HOMEPAGEBANNER, GET_HOMEPAGEBANNER,GET_HOMEPAGEDATA} from '../Queries/homepage';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import HomepageLib from '../Library/homepage';
import {HOMEPAGEBANNER_IMAGE_URL,MAX_UPLOAD_FILE_LIMIT,SERVER_URL} from '../../../Config/index';
import { Editor } from '@tinymce/tinymce-react';


const AddEditHomepageBanner = (props) => {

    const homepage = new HomepageLib(props);

    const [formData,setFormData] = useState({
        selectedLang:"cz",
        homepageBannerID:props.selectedHomepageBannerID,
        langs:[],
        active:1,
        photo:"",
    });

    const [allLanguageMutations, setAllLanguageMutations] = useState([]);
    const [mainPhoto, setMainPhoto] = useState("");

    const {data:homepageBannerData,loading:getLoading,error:getError} = useQuery(GET_HOMEPAGEBANNER,{
        variables:{
            homepageBannerID: formData.homepageBannerID,
            lang:"cz"
        },
        fetchPolicy: 'network-only',
        onCompleted(data){

            homepage.getData(data,formData.homepageBannerID,formData,setFormData);
            setAllLanguageMutations(data.allLanguageMutations);

            if(formData.homepageBannerID){
                setMainPhoto(SERVER_URL + "/" + HOMEPAGEBANNER_IMAGE_URL + "/hb-" +formData.homepageBannerID + "/velka_" + data.homepageBannerWithLangs.photo);
            }

        },
    })

    const [addEditHomepageBanner,{data:addHomepageBannerData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_HOMEPAGEBANNER,{
        onCompleted(data){

           props.openCloseModal(false);
            
		},update(cache,response) {            
            
            let notify = new Notification();
            if(formData.homepageBannerID && formData.homepageBannerID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        },
        refetchQueries(data){
            return [{
                query:GET_HOMEPAGEDATA,
                variables: { lang:"cz" }
            }]
        }
    })

    const openMainImage = (e) => {

        var file = e.target.files[0];

        if(file){
            var reader = new FileReader();
            reader.onload = (onLoadE) => {
                if((onLoadE.total / 1000000) <= MAX_UPLOAD_FILE_LIMIT){
                    var dataURL = reader.result;
                    setMainPhoto(dataURL);
                    setFormData({...formData,photo:file});
                }else{
                    let notify      = new Notification();
                    notify.setNotification(null,translate.t("MaxFileSizeExceeded"),false,true,props.client);
                }
            };
            reader.readAsDataURL(file);
        }
    }
    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError)
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.homepageBannerID && formData.homepageBannerID != 0 ? translate.t("EditHomepageBanner") : translate.t("AddHomepageBanner"))}</h4>
                    {allLanguageMutations && allLanguageMutations.length > 1 &&
                        <div className="ml-auto">
                            <select className="form-control" name="selectedLang" value={formData.selectedLang} onChange={(e) => homepage.formHandle(e,formData,setFormData)}>
                                {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                    <option key={index} value={item.suffix}> {item.suffix} </option>
                                ))}
                            </select>
                        </div>
                    }
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                  
                                <div className="row">    
                                    <div className="col-12">
                                        <label className="input-label">Aktivní</label>
                                        <div className="form-group">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(formData.active == 1 ?  true : false)} onChange={(e) => homepage.formHandle(e,formData,setFormData)} />
                                                <label className="form-check-label" htmlFor="active1">
                                                    Ano
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(formData.active == 0 ?  true : false)} onChange={(e) => homepage.formHandle(e,formData,setFormData)} />
                                                <label className="form-check-label" htmlFor="active2">
                                                    Ne
                                                </label>
                                            </div>
                                        </div>
                                    </div>    


                                    <div className="col-12">

                                        <label className="input-label">Fotka</label>

                                        <div className="form-group input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="customFile" required onChange={(e) => openMainImage(e)} />
                                                <label className="custom-file-label" htmlFor="customFile">{translate.t("ChoosePhoto")}<small className=" ml-2">(JPG/JPEG, {translate.t("Max")} {MAX_UPLOAD_FILE_LIMIT}Mb)</small></label>
                                            </div>
                                        </div>
                                        {mainPhoto != "" &&
                                            <div className="form-group main-photo-container">
                                                
                                                <div className="one-image">            
                                                    <img id="main-image" src={mainPhoto} />
                                                </div>

                                            </div>
                                        }

                                    </div>

                                    <div className="col-12">
                                        <label className="input-label">{translate.t("Text")} {allLanguageMutations && allLanguageMutations.length > 1 && <Fragment>({formData.selectedLang})</Fragment>}</label>
                                        <div className="form-group">
                                            
                                            <Editor
                                                name="description"
                                                apiKey = '9z3phhnyq7jkeeqztql1euovfotvpd2gdduygtdpk14wu4ij'
                                                value={homepage.getLangValue(formData,"text")}
                                                init={{
                                                    plugins: 'link code paste emoticons lists',
                                                    toolbar: 'undo redo | bold italic | link | numlist bullist ',
                                                    language_url : '/langs/cs.js',
                                                    language: "cs",
                                                    paste_as_text: true,
                                                    menubar:false,
                                                }}
                                                onEditorChange={(content) => homepage.formLangHandleEditor(content,"text",formData,setFormData)}
                                            />
                                                                                            
                                        </div>
                                    </div>  
                                                                                           
                                </div> 
  
                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => homepage.addHomepageBanner(addEditHomepageBanner,formData)}>{(formData.homepageBannerID && formData.homepageBannerID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )

}

export default withApollo(AddEditHomepageBanner);