/*
	Komponenta, kerá kontroluje, jestli je uživatel přihlášen.
*/

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Query,withApollo} from 'react-apollo';
import gql from 'graphql-tag';
import {ADMIN_USER} from '../../../GlobalQueries/globalQueries';

const withAuthentication = (Component) => {
	  
	const withAuthenticationComp = (props) => {

		const adminUserData = props.client.readQuery({ query:ADMIN_USER });
		if(!adminUserData || !adminUserData.adminUser){		
			props.history.push('/');
		}

 
		const {history} = props;
		const IS_LOGGED_IN = gql`
			query IsUserLoggedIn {
				isLoggedIn @client
				loginPlace @client
			}
		`;

		return (

			//načítá se isLoggedIn z cache apolla
			<Query query={IS_LOGGED_IN}>
				
				{({data,loading,errro}) => {

					if(data.isLoggedIn && data.loginPlace == "3fdc94bf1dd7dccf3d202a768df0a1eb")return(<Component {...props} />);
					else {history.push("/"); return "";}

				}}

			</Query>

		);    
	}	
	return withRouter(withApollo(withAuthenticationComp));
  
}

export default withAuthentication;