import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {ADD_EDIT_ORDER, GET_ORDER} from '../Queries/order';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import OrderLib from '../Library/order';
import NoItems from '../../../GlobalComponents/NoItems';

const AddEditOrder = (props) => {
    
    const orderLib = new OrderLib(props);
    const [formData,setFormData] = useState({
        
        orderID:props.selectedOrderID,
        name:"",
        surname:"",
        street:"",
        city:"",
        zip:"",
        countryID:60

    });

    const {data:orderData,loading:getLoading,error:getError} = useQuery(GET_ORDER,{
        variables:{orderID: formData.orderID},
        fetchPolicy: 'network-only',
        onCompleted(data){
            orderLib.getData(data,formData.orderID,formData,setFormData);      
        },
    })

    const [addEditOrder,{data:addOrderData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_ORDER,{
        onCompleted(data){

			props.openCloseModal(false);
            
		},update(cache,response) {    
            
            orderLib.updateList(cache,response,props.listVariables,formData.orderID);
            
            let notify = new Notification();
            if(formData.orderID && formData.orderID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    })

    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError)
    }
    console.log(orderData);
    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.orderID && formData.orderID != 0 ? translate.t("EditOrder") : translate.t("AddOrder"))}</h4>
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading  && !!orderData ?
                                <Fragment>  

                                    <div className="row">    
                                                                            
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("Name")}</label>
                                                <div className="form-group">
                                                    <input className="form-control" type="text" name="name" value={formData.name} onChange={(e) => orderLib.formHandle(e,formData,setFormData)} />
                                                </div>
                                            </div>                                                
                                        </div>    

                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("Surname")}</label>
                                                <div className="form-group">
                                                    <input className="form-control" type="text" name="surname" value={formData.surname} onChange={(e) => orderLib.formHandle(e,formData,setFormData)} />
                                                </div>
                                            </div>                                                
                                        </div>  
                                    </div> 
                                    <div className="row">   

                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("Street")}</label>
                                                <div className="form-group">
                                                    <input className="form-control" type="text" name="street" value={formData.street} onChange={(e) => orderLib.formHandle(e,formData,setFormData)} />
                                                </div>
                                            </div>                                                
                                        </div> 

                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("City")}</label>
                                                <div className="form-group">
                                                    <input className="form-control" type="text" name="city" value={formData.city} onChange={(e) => orderLib.formHandle(e,formData,setFormData)} />
                                                </div>
                                            </div>                                                
                                        </div>  

                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("Zip")}</label>
                                                <div className="form-group">
                                                    <input className="form-control" type="text" name="zip" value={formData.zip} onChange={(e) => orderLib.formHandle(e,formData,setFormData)} />
                                                </div>
                                            </div>                                                
                                        </div>    
                                    </div>                                    
                                    {orderData.order.deliverySubjectID != 0 &&
                                        <div className="card form-group">

                                            <div className="card-header d-flex align-items-center">
                                                <div className="badge badge-secondary">{translate.t("Delivery")}</div>
                                            </div>
                                            <div className="card-body">
                                                <div className='row'>
                                                    <div className="col-3">
                                                        <div className="form-group">
                                                            <label className="input-label">{translate.t("Carrier")}</label>
                                                            <div className="form-group">
                                                                {orderData.order?.deliverySubject?.title}
                                                            </div>
                                                        </div>                                                
                                                    </div> 
                                                    {!!orderData.order.woltOrderReferenceId &&
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("WoltOrderReferenceId")} <span className='description'>({translate.t("ForCommunicationWithWoltSupport")})</span></label>
                                                                <div className="form-group">
                                                                    {orderData.order.woltOrderReferenceId}
                                                                </div>
                                                            </div>                                                
                                                        </div>
                                                    } 
                                                </div>
                                            </div>                                            
                                        </div>
                                    }
                                    <div className='row'>
                                        <div className="col-12">

                                            <div className="card form-group">

                                                <div className="card-header d-flex align-items-center">
                                                    <div className="badge badge-primary">{translate.t("OrderItems")}</div>
                                                </div>
                                                <div className="card-body">
                                                    
                                                    <div className="data-list">
                                
                                                        <div className="data-list-item header">
                                                            <div className="data-list-item-content">
                                                                <div>{translate.t("Item")}</div>
                                                                <div className="text-center">{translate.t("Count")}</div>
                                                                <div className="text-right">{translate.t("Price")}</div>
                                                            </div>
                                                        </div>

                                                        <div className="data-list">
                                                            {orderData && orderData.order.items && orderData.order.items.map((item,index) => {

                                                                var itemPrice = item.price + item.packagePrice;
                                                                
                                                                return(
                                                                    <div key={index} className="data-list-item-content categories">
                                                                        <div>
                                                                            <strong>{item.name}</strong>
                                                                            {item.packagePrice != "0.00" &&
                                                                                <div>
                                                                                    <strong>{translate.t("Package")}: </strong>
                                                                                    <span>+{item.packagePrice} {orderData.order.country.currency.code}</span>
                                                                                </div>
                                                                            }
                                                                            {item.orderSideDishes && item.orderSideDishes.length > 0 &&
                                                                                <div>
                                                                                    <strong>{translate.t("SideDishes")}: </strong>
                                                                                    {item.orderSideDishes.map((it,ind) => {
                                                                                        itemPrice += it.price;
                                                                                        return (
                                                                                            <span key={ind}>{it.name} +{it.price} {orderData.order.country.currency.code}{(item.orderSideDishes.length - 1 > ind ? ", " : "")} </span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            }
                                                                            {item.orderOptions && item.orderOptions.length > 0 &&
                                                                                <div>
                                                                                    <strong>{translate.t("Options")}: </strong>
                                                                                    {item.orderOptions.map((ite,inde) => {
                                                                                        
                                                                                        itemPrice += ite.price + ite.packagePrice;
                                                                                        return (
                                                                                            <span key={inde}>{ite.name} +{ite.price + ite.packagePrice} {orderData.order.country.currency.code}{(item.orderOptions.length - 1 > inde ? ", " : "")} </span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            }
                                                                            {item.orderDrinks && item.orderDrinks.length > 0 &&
                                                                                <div>
                                                                                    <strong>{translate.t("Drink")}: </strong>
                                                                                    {item.orderDrinks.map((ite,inde) => {
                                                                                        
                                                                                        itemPrice += ite.price;
                                                                                        return (
                                                                                            <span key={inde}>{ite.name} +{ite.price} {orderData.order.country.currency.code}{(item.orderDrinks.length - 1 > inde ? ", " : "")} </span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            }
                                                                            {item.isFree == 1 ?
                                                                                <div><strong style={{textTransform:'uppercase',color:'#dc3545'}}>{translate.t("OneFreeMeal")}</strong></div>
                                                                            :
                                                                                null
                                                                            }
                                                                        </div>
                                                                        <div className="text-center">{item.count}</div>
                                                                        <div className="text-right">{(item.isFree == 1 ? itemPrice * item.count - itemPrice : itemPrice * item.count)} {orderData.order.country.currency.code}</div>
                                                                    </div>
                                                                )
                                                            })}

                                                            {orderData.order.deliveryPrice > 0 &&
                                                                <div className="data-list-item-content categories">
                                                                    <div><strong>{translate.t("Transport")}</strong></div>
                                                                    <div className="text-right">{orderData.order.deliveryPrice} {orderData.order.country.currency.code}</div>
                                                                </div>
                                                            }
                                                            {orderData.order.creditToUse > 0 &&
                                                                <div className="data-list-item-content categories">
                                                                    <div><strong>{translate.t("CreditUsedForOrder")}</strong></div>
                                                                    <div className="text-right">-{orderData.order.creditToUse} {orderData.order.country.currency.code}</div>
                                                                </div>
                                                            }
                                                            {orderData.order.voucherValue > 0 &&
                                                                <div className="data-list-item-content categories">
                                                                    <div><strong>{translate.t("UsedVoucher")} ({orderData.order.voucherCode})</strong></div>
                                                                    <div className="text-right">-{orderData.order.voucherValue} {orderData.order.country.currency.code}</div>
                                                                </div>
                                                            }

                                                            <div className="data-list-item-content categories total">
                                                                <div><strong>{translate.t("Total")}</strong></div>
                                                                <div className="text-right"><strong>{orderData.order.totalPrice} {orderData.order.country.currency.code}</strong></div>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>

                                                    {orderData && orderData.order.items && orderData.order.items.length == 0 &&
                                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                                    }

                                                </div>

                                            </div>   

                                        </div>                       
                                        
                                                                
                                    </div> 


                                        

                                </Fragment> 
                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button athlete="button" className="btn btn-primary flex-fill" onClick={() => orderLib.addOrder(addEditOrder,formData)}>{(formData.orderID && formData.orderID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )

}

export default withApollo(AddEditOrder);