/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component, Fragment } from 'react';
import AddEditArticle from './AddEditArticle';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import { withApollo } from 'react-apollo';
import {GET_ARTICLES,DELETE_ARTICLES} from '../Queries/article';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import edit from '../../../Public/Images/edit.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import ArticleLib from '../Library/article';
import Helper from '../../../Library/helper';
import {ARTICLE_IMAGE_URL,SERVER_URL} from '../../../Config/index';
import translate from '../../Translations/index';

const INITIAL_STATE = {
    showAdd : false,
    showDeleteNotifi:false,
    deleteArticle:"",
    selectedArticles:[],
    listVariables:{
        lang:"cz"
    }
}

class AllArticles extends Component{

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;
       this.openCloseModal  = this.openCloseModal.bind(this);
       this.deleteArticle  = this.deleteArticle.bind(this);
       this.delArticle     = this.delArticle.bind(this);
       this.showDelNotifi   = this.showDelNotifi.bind(this);

    }

    openCloseModal(type,articleID){
        this.setState({
            showAdd:type,
            selectedArticleID:articleID
        })
    }

    /**
     * smazání štítků
     * @param {*} deleteArticles funkce, která posílá data na server
     * @param {*} articleID ID articleu
     */
    
    deleteArticle(e,deleteArticles,articleID){

        var checked = e.target.checked;
        var arr = [...this.state.selectedArticles];

        if(!checked){
            for(let i in this.state.selectedArticles){
                if(this.state.selectedArticles[i] == articleID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [articleID,...arr];
        }

        
        this.setState({
            deleteArticles,
            selectedArticles:arr
        });
  
    }

    delArticle(action){

        if(action){

            let articleIDs = this.state.selectedArticles.join(",");

            this.state.deleteArticles({
                variables:{
                    articleIDs: articleIDs
                }
            })
        }
        this.setState(INITIAL_STATE);
    }

    showDelNotifi(){

        if(this.state.selectedArticles.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,this.props.client);
        }
    }

    render(){

        const {selectedArticles,listVariables} = this.state;

        return(

            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <div className="card-header d-flex align-items-center">
                                {translate.t("ArticleList")}
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.openCloseModal(true)}>{translate.t("Add")}</button>
                                <button className="btn btn-danger btn-thiner ml-2" onClick={() => this.showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                            </div>
                            <div className="card-body">
                                <Query query={GET_ARTICLES} variables={listVariables}>
                                {
                                    ({data,loading,error }) => {
                                        
                                        if(loading) return (<Loading />);
                                        if(error) return (<Error text={error} />);

                                        return(
                                            <div>
                                                <div className="data-list">
                                                    
                                                    <div className="data-list-item header">
                                                        <div className="data-list-item-content">
                                                            <div>{translate.t("Image")}</div>
                                                            <div>{translate.t("Title")}</div>
                                                            <div className="text-right">{translate.t("Options")}</div>
                                                        </div>
                                                    </div>
                                                    <Mutation
                                                        mutation = {DELETE_ARTICLES}
                                                        update = {async (cache, response) => {

                                                            let article = new ArticleLib(this);
                                                            article.updateAfterDelete(cache, response,listVariables);

                                                            let notify = new Notification();
                                                            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
                                        
                                                        }}
                                                        onError = {(error) => {
                                                            const helper = new Helper(); 
                                                            let notify = new Notification();
                                                            notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                                        }}
                                                    >
                                                        {
                                                            (deleteArticle,response) => {
                                                        
                                                                return(
                                                                    <Fragment>
                                                                        {data.allArticles && data.allArticles.map((item,index) => {

                                                                            var checked = false;
                                                                            for(let i in selectedArticles){
                                                                                if(selectedArticles[i] == item.articleID)checked = true;
                                                                            }
                                                                                                                                            
                                                                            return (
                                                                            
                                                                                <div key={index} className="data-list-item-content">
                                                                                    <div><img src={SERVER_URL + "/" + ARTICLE_IMAGE_URL + "/mala_" + item.photo} /></div>
                                                                                    <div>{item.title}</div>
                                                                                    <div className="text-right">
                                                                                        <img onClick={() => this.openCloseModal(true,item.articleID)} className="edit-icon" src={edit} /> 
                                                                                        <input className="delete-checked" type="checkbox" name="deleteArticle[]" checked={checked} onChange = {(e) => this.deleteArticle(e,deleteArticle,item.articleID)} />
                                                                                    </div>
                                                                                </div>
                                                                                        
                                                                            )
                                                                            
                                                                        })} 
                                                                    </Fragment>
                                                                )
                                                            }
                                                        }
                                                    </Mutation>
                                                </div>
                                                {data.allArticles && data.allArticles.length == 0 &&
                                                    <NoItems text={translate.t("NoItemsAddedYet")} />
                                                }
                                                
                                            </div>
                                        );
                                    }
                                }
                                </Query>
                            </div>
                        </div>
                    </div>
                </div> 	
                {this.state.showAdd &&
                    <AddEditArticle listVariables={listVariables} openCloseModal={this.openCloseModal} selectedArticleID={this.state.selectedArticleID} />
                }	
                {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={this.delArticle} /> }	
            </div>
        );

    }

}

export default withApollo(AllArticles);