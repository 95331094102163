/**
 * hlavní sekce
 */

const SECTION = {
    backgroundColorClass:"",
    backgroundImage:"",
    sectionName:{},
    fullWidth:0,
    fullHeight:0,
    paddingClass:"padding",
    showAddElements:false,
    elements:[],
    className:""
}

/**
 * Seznam veškerých elementů
 */

const ELEMENTS_LIST = ["columns","headline","paragraph","image","button","video","articles","newsletter","html","banners","list","turnaments","athlets","ladders"];

/**
 * definice elementu sloupce
 */

const COLUMNS = {
    columns:{
        showMenu:true,
        className:"",
        cols:[]
    }
}

/**
 * definice elementu sloupce
 */

const COLUMN = {
    showAddElements:false,
    showSettings:false,
    showMenu:true,
    contentAlign:"start",
    paddingTop:0,
    paddingRight:0,
    paddingBottom:0,
    paddingLeft:0,
    elements:[],
    className:""
}

/**
 * Definice elementu nadpis
 */

const HEADLINE = (langs) => { 

    var text = {};
    var subHeadlineText = {};

    for(let i in langs){
        text[langs[i].suffix] = "Nadpis sekce " + langs[i].suffix;
        subHeadlineText[langs[i].suffix] = "Podnadpis dané sekce s trošku delším textem " + langs[i].suffix;
    }

    return { 
        headline: {
            showMenu:true,
            showSettings:false,
            type:"h1",
            text:text,
            textAlign:"text-center",
            hasSubHeadline:1,
            subHeadlineText:subHeadlineText,
            className:""
        }
    }
}

const PARAGRAPH = (langs) => { 

    var text = {};

    for(let i in langs){
        text[langs[i].suffix] = "Text daného odstavce " + langs[i].suffix;
    }

    return { 
        paragraph: {
            showMenu:true,
            showSettings:false,
            showLinkSettings:false,
            text:text,
            textAlign:"text-left",
            fontSize:"small",
            className:""
        }
    }
}

const LISTITEM = (langs,empty) => { 

    var text = {};

    for(let i in langs){
        text[langs[i].suffix] = (empty == true ? "": "Text daného řádku " + langs[i].suffix);
    }

    return {
        text:text
    }
    
}

const LIST = (langs) => { 

    return { 
        list: {
            showMenu:true,
            showSettings:false,
            showLinkSettings:false,
            type:"ul",
            listItems:[LISTITEM(langs),LISTITEM(langs)],
            className:""
        }
    }
}

const IMAGE = { 
    image: {
        showMenu:true,
        showSettings:false,
        url:"",
        imageAlign:"center",
        width:300,
        height:300,
        borderRadius:0,
        className:""
    }
}

const BUTTON = (langs) => { 

    var text = {};

    for(let i in langs){
        text[langs[i].suffix] = "Název tlačítka " + langs[i].suffix;
    }

    return {
        button: {
            showMenu:true,
            showSettings:false,
            text:text,
            buttonAlign:"center",
            url:"",
            className:""
        }
    }
}

const VIDEO = (langs) => { 

    var url = {};
    var type = {};

    for(let i in langs){
        url[langs[i].suffix] = "";
        type[langs[i].suffix] = "youtube";
    }

    return {
        video: {
            showMenu:true,
            showSettings:false,
            url:url,
            type:type,
            className:""
        }
    }
}

const ARTICLES = { 
    articles: {
        showMenu:true,
        showSettings:false,
        count:4,
        className:""
    }
}

const BANNERS = { 
    banners: {
        showMenu:true,
        showSettings:false,
        className:""
    }
}

const TURNAMENTS = { 
    turnaments: {
        showMenu:true,
        showSettings:false,
        className:""
    }
}

const ATHLETS = { 
    athlets: {
        showMenu:true,
        showSettings:false,
        className:""
    }
}

const LADDERS = { 
    ladders: {
        showMenu:true,
        showSettings:false,
        className:""
    }
}

const NEWSLETTER = { 
    newsletter: {
        showMenu:true,
        showSettings:false,
        className:""
    }
}

const HTML_ELM = (langs) => { 

    var code = {};

    for(let i in langs){
        code[langs[i].suffix] = "";
    }

    return {
        html: {
            showMenu:true,
            showSettings:false,
            code:code,
            type:"html",
            className:""
        }
    }
}

export {SECTION,COLUMNS,COLUMN,HEADLINE,ELEMENTS_LIST,PARAGRAPH,IMAGE,BUTTON,VIDEO,ARTICLES,NEWSLETTER,HTML_ELM,BANNERS,LIST,LISTITEM,LADDERS,ATHLETS,TURNAMENTS};