import {GET_BRANCHES,GET_BRANCH_MEALS_OPENING_TIMES} from '../Queries/branch';
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';
import { GOOGLE_API_KEY } from '../../../Config';

class Branch {

    constructor(props){
        this.props = props;
    }

    addBranch(addEditBranch,formData){


        if(formData.photo != "" || formData.branchID != 0){

            if(formData.name != ""){

                if(formData.street != "" && formData.city != "" && formData.lat > 0 &&  formData.lng > 0){   

                        let regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

                        var checkOpenTime = "";
                        if(formData.openTimes.length > 0){
                            for(let val of formData.openTimes){
                                for(let time of val.times){
                                    
                                    if((time.timeFrom != "" && !regex.test(time.timeFrom)) || (time.timeTo != "" && !regex.test(time.timeTo))){
                                        checkOpenTime = translate.t("InvalidOpenTimes");
                                    }
                                }
                            }
                        }

                        if(checkOpenTime == ""){
                        

                            formData.active                 = parseInt(formData.active);
                            formData.deliveryAreaID         = parseInt(formData.deliveryAreaID);
                            formData.hideTakeAwayOption     = parseInt(formData.hideTakeAwayOption);
                            formData.hideEatInOption        = parseInt(formData.hideEatInOption);
                            formData.useStaticDeliveryPrice = parseInt(formData.useStaticDeliveryPrice);                            
                            formData.staticDeliveryPrice    = parseFloat(formData.staticDeliveryPrice);
                            formData.freeDeliveryPriceValue = parseFloat(formData.freeDeliveryPriceValue);
                            formData.deliveryUnitPrice      = parseFloat(formData.deliveryUnitPrice);
                            formData.unitSize               = parseInt(formData.unitSize);
                            formData.freeUnitCount          = parseInt(formData.freeUnitCount);
                            formData.maxDeliveryPrice       = parseFloat(formData.maxDeliveryPrice);
                            formData.ownDeliveryEnabled                 = parseInt(formData.ownDeliveryEnabled);
                            formData.woltDeliveryEnabled                = parseInt(formData.woltDeliveryEnabled);
                            formData.minimumOrderAmount                 = parseFloat(formData.minimumOrderAmount);
                            formData.minimumMealPreparationTimeForWolt  = parseInt(formData.minimumMealPreparationTimeForWolt)
                            formData.cashPayForDeliveryEnabled          = parseInt(formData.cashPayForDeliveryEnabled);
                            formData.cashPayForEatInAndTakeAwayEnabled  = parseInt(formData.cashPayForEatInAndTakeAwayEnabled);

                            addEditBranch({
                                variables:formData
                            }); 

                        }else{
                            let notify = new Notification();
                            notify.setNotification(null,checkOpenTime,false,true,this.props.client);
                        }

                }else{
                    let notify = new Notification();
                    notify.setNotification(null,translate.t("InvalidAddress"),false,true,this.props.client);
                }

            }else{
                let notify = new Notification();
                notify.setNotification(null,translate.t("InvalidBranchName"),false,true,this.props.client);
            }
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidPhoto"),false,true,this.props.client);
        }

    }
    getAddresses(address){

        return new Promise(async (resolve,rejected) => {

            var adresses;
            var allAddresses = [];
            try {
                if(address){
                    
                    adresses = await fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURI(address) + '&key=' + GOOGLE_API_KEY);                    
                    adresses  = await adresses.json();  
                    
                    let i = 1;
                    for(let val of adresses.results){

                        let addrObject = {addressID : i};

                        for(let ac of val.address_components){

                            if(ac.types[0] == "premise" || ac.types[0] == "street_number")addrObject.number = ac.short_name;
                            if(ac.types[0] == "route")addrObject.street = ac.short_name;
                            if(ac.types[0] == "locality" || ac.types[0] == "political")addrObject.city = ac.short_name;
                            if(ac.types[0] == "postal_code")addrObject.zip = ac.short_name;
                            if(ac.types[0] == "administrative_area_level_2")addrObject.region = ac.short_name;

                        }

                        if(!addrObject.city){
                            if(addrObject.region == "Hlavní město Praha")addrObject.city = "Praha";
                            else addrObject.city = "";
                        }
                        if(!addrObject.zip)addrObject.zip = "";
                        if(!addrObject.number)addrObject.number = "";

                        addrObject.lat = val.geometry.location.lat;
                        addrObject.lng = val.geometry.location.lng;                        

                        if(addrObject.street && addrObject.street != ""){
                            allAddresses.push(addrObject);
                            i++;
                        }

                    }


                }
                resolve(allAddresses);

            } catch (err) {
                rejected(err);
            }

            

        });

    }
    getData(data,branchID,formData,setFormData){

        if(branchID != 0){
            
            setFormData({...formData,
                name: data.branch.name,
                street: data.branch.street,
                city: data.branch.city,
                zip: data.branch.zip, 
                lat: data.branch.lat, 
                lng: data.branch.lng, 
                phone: data.branch.phone,
                invoiceCompany: data.branch.invoiceCompany,
                invoiceStreet: data.branch.invoiceStreet,
                invoiceZip: data.branch.invoiceZip,
                invoiceCity: data.branch.invoiceCity,
                invoiceCountry: data.branch.invoiceCountry,
                invoiceIC: data.branch.invoiceIC,
                invoiceDIC: data.branch.invoiceDIC,
                countryID:data.branch.countryID,
                active:data.branch.active,
                deliveryAreaID:data.branch.deliveryAreaID,
                hideTakeAwayOption:data.branch.hideTakeAwayOption,
                hideEatInOption:data.branch.hideEatInOption,
                useStaticDeliveryPrice:data.branch.useStaticDeliveryPrice,
                staticDeliveryPrice:data.branch.staticDeliveryPrice,
                freeDeliveryPriceValue:data.branch.freeDeliveryPriceValue,
                deliveryUnitPrice:data.branch.deliveryUnitPrice,
                unitSize:data.branch.unitSize,
                freeUnitCount:data.branch.freeUnitCount,
                maxDeliveryPrice:data.branch.maxDeliveryPrice,                   
                ownDeliveryEnabled:data.branch.ownDeliveryEnabled ,
                woltDeliveryEnabled:data.branch.woltDeliveryEnabled ,
                minimumOrderAmount:data.branch.minimumOrderAmount,
                minimumMealPreparationTimeForWolt:data.branch.minimumMealPreparationTimeForWolt,
                cashPayForDeliveryEnabled:data.branch.cashPayForDeliveryEnabled,
                cashPayForEatInAndTakeAwayEnabled:data.branch.cashPayForEatInAndTakeAwayEnabled          
            });
        }
    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateList(cache, response,variables,branchID){

        const { allBranches,branchListCount } = cache.readQuery({ query: GET_BRANCHES ,variables});       
        
        if(branchID && branchID != 0){

            var arr = [...allBranches];

            arr.forEach((item,index) => {
                
                if(item.branchID == response.data.addEditBranch.branchID){
                    arr[index] = response.data.addEditBranch;
                }

            });

            cache.writeQuery({ 
                query:GET_BRANCHES,
                variables,
                data:{
                    allBranches: arr,
                    branchListCount :branchListCount
                } 
            });
/*
            cache.writeQuery({ 
                query:GET_BRANCH_MEALS_OPENING_TIMES,
                variables:{branchID},
                data:{
                    allBranchMealsForAdmin: response.data.addEditBranch.branchMeals,
                    allBranchOpeningTimes: response.data.addEditBranch.branchOpenTimes,
                } 
            });
  */      

        }else{
            var arr = [response.data.addEditBranch,...allBranches];

            cache.writeQuery({ 
                query:GET_BRANCHES,
                variables,
                data:{
                    allBranches: arr,
                    branchListCount :branchListCount
                } 
            });
        }

    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteBranches.split(",");

        const { allBranches,branchListCount } = cache.readQuery({ query: GET_BRANCHES ,variables});
        var arr = [...allBranches];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.branchID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_BRANCHES,
            variables,
            data:{
                allBranches: [...arr],
                branchListCount :branchListCount - resp.length
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        setFormData({...formData,[n]: v});
              
    }

}

export default Branch;