/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Fragment, useState } from 'react';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import {GET_CUSTOMERS,DELETE_CUSTOMERS,EXPORT_CUSTOMERS_CSV} from '../Queries/customer';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import CustomerLib from '../Library/customer';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import edit from '../../../Public/Images/edit.svg';
import AddEditCustomer from './AddEditCustomer';
import SearchInput from '../../../GlobalComponents/SearchInput';
import { SERVER_URL } from '../../../Config';
import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';


const Customers = (props) => {
        
    const helper = new Helper();
    helper.checkAdminRights(props,["1","2"]);
    

	const [listVariables,setListVariables]              = useState({
        offset:0,
        limit:200,
        searchQuery:""
    });
	const [searchQuery,setSearchQuery]                  = useState("");
	const [selectedCustomers,setSelectedCustomers]      = useState([]);
    const [showDeleteNotifi,setShowDeleteNotifi] 	    = useState(false);
    const [selectedCustomerID,setSelectedCustomerID]    = useState(0);
    const [showAdd,setShowAdd] 			                = useState(false);
    const [exportFrom,setExportFrom]	                = useState(null);
    const [exportTo,setExportTo]	                    = useState(null);
    
    
    const {loading, error:getError, data:customerListData, fetchMore}   = useQuery(GET_CUSTOMERS,{
        variables:listVariables,
        fetchPolicy: 'cache-and-network'
    });
    
	const [deleteCustomer, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_CUSTOMERS,{	
		update(cache,response) {            
            let customer = new CustomerLib(this);
            customer.updateAfterDelete(cache, response,listVariables);

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });
    
	const [exportCustomersCSV, { data:exportData,loading: exportLoading, error: exportError }] = useMutation(EXPORT_CUSTOMERS_CSV,{	
        variables:{
            from:   exportFrom ? moment(exportFrom).format("YYYY-MM-DD") : null,
            to:     exportTo ? moment(exportTo).format("YYYY-MM-DD") : null
        },
		onCompleted(data){
            var win = window.open(SERVER_URL + "/" + data.exportCustomersCSV, '_blank');
        },	
		onError(err){
            console.log(err);
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    /**
     * smazání štítků
     * @param {*} deleteCustomers funkce, která posílá data na server
     * @param {*} customerID ID customeru
     */
    
    const initiateDeleteCustomer = (e,customerID) => {

        var checked = e.target.checked;
        var arr = [...selectedCustomers];

        if(!checked){
            for(let i in selectedCustomers){
                if(selectedCustomers[i] == customerID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [customerID,...arr];
        }

        setSelectedCustomers(arr);
  
    }

    const delCustomer = (action) => {

        if(action){

            let customerIDs = selectedCustomers.join(",");

            deleteCustomer({
                variables:{
                    customerIDs: customerIDs
                }
            })
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    }

    const showDelNotifi = () => {

        if(selectedCustomers.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }
    const openCloseModal = (open,customerID) => {
        setSelectedCustomerID(customerID);
        setShowAdd(open);
    }

    
    if(getError || deleteError){
        var error = getError ? getError : deleteError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }
    return(

        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex flex-column">
                            <div className="d-flex align-items-center">
                                {translate.t("CustomerList")} 
                                <div className="ml-auto d-flex align-items-center mr-5">
                                    <div className="text-nowrap mr-2">{translate.t("SearchCustomer")}:</div> 
                                    <SearchInput className="form-control header-search-input" value={searchQuery} onChange={(text) => setListVariables({...listVariables,searchQuery:text})} />
                                </div>
                                <button className="btn btn-danger btn-thiner" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                <div className="ml-auto mr-2">od</div>
                                <DatePicker className="form-control w-100" dateFormat="yyyy-MM-dd" locale={translate.locale == "cz" ? "cs" : "en"} selected={exportFrom} onChange={date => setExportFrom(date)} />
                                <div className="ml-4 mr-2">do</div>
                                <DatePicker className="form-control w-100" dateFormat="yyyy-MM-dd" locale={translate.locale == "cz" ? "cs" : "en"} selected={exportTo} onChange={date => setExportTo(date)} />
                                {!exportLoading ?
                                    <button className="btn btn-primary btn-thiner ml-5" onClick={() => exportCustomersCSV()}>{translate.t("ExportCSV")}</button>
                                    :
                                    <div className="ml-5 customer-export-loading">
                                        <Loading displayText={false} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="card-body">

                            {!error ?
                                <div>
                                    <div className="data-list">
                                        
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("Name")}</div>
                                                <div className="text-center">{translate.t("Email")}</div>
                                                <div className="text-center">{translate.t("Phone")}</div>
                                                <div className="text-center static">{translate.t("UsableCredit")}</div>
                                                <div className="text-center">{translate.t("Address")}</div>
                                                <div className="text-right static small">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {customerListData && customerListData.allCustomers && customerListData.allCustomers.map((item,index) => {

                                                var checked = false;
                                                for(let i in selectedCustomers){
                                                    if(selectedCustomers[i] == item.customerID)checked = true;
                                                }
                                                                                                                
                                                return (
                                                
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{item.name} {item.surname}</div>
                                                        <div className="text-center">{item.email}</div>
                                                        <div className="text-center">{item.phone}</div>
                                                        <div className="text-center static">
                                                            {!!item.allCredits && item.allCredits.map((creditItem,index) => (
                                                                <div key={index}>
                                                                    {creditItem.credit - creditItem.unprocessedOrdersCredit} {creditItem.country.currency.code}
                                                                                                                                    
                                                                    {creditItem.unprocessedOrdersCredit > 0 &&
                                                                        <div>({creditItem.credit} - {creditItem.unprocessedOrdersCredit} {creditItem.country.currency.code})</div>
                                                                    }
                                                                
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div>
                                                            {item.deliveryAddress && item.deliveryAddress.map((it,ind) => (
                                                                <div key={ind}>{it.street}, {it.zip} {it.city}</div>
                                                            ))}
                                                        </div>
                                                        <div className="text-right static small">
                                                            <img onClick={() => openCloseModal(true,item.customerID)} className="edit-icon" src={edit} />
                                                            <input className="delete-checked" type="checkbox" name="deleteCustomer[]" checked={checked} onChange = {(e) => initiateDeleteCustomer(e,item.customerID)} />
                                                        </div>
                                                    </div>
                                                            
                                                )
                                                
                                            })} 
                                        </Fragment>
                                    </div>
                                    {!loading ? 
                                        <Fragment>
                                            {customerListData && customerListData.allCustomers && customerListData.allCustomers.length == 0 ?
                                                <NoItems text={translate.t("NoItemsAddedYet")} />
                                                :  
                                                <Fragment>     
                                                    {!loading && customerListData && customerListData.allCustomers.length < customerListData.customerListCount &&                                 
                                                        <div className="text-center p-5">
                                                            <button className="btn btn-primary" onClick={() => fetchMore({
                                                                variables: {...listVariables,
                                                                    offset: customerListData.allCustomers.length
                                                                },
                                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                                    if (!fetchMoreResult) return prev;
                                                                    return Object.assign({}, prev, {
                                                                        allCustomers: [...prev.allCustomers, ...fetchMoreResult.allCustomers]
                                                                    });
                                                                }
                                                            })}> {translate.t("LoadMore")} </button>
                                                        </div> 
                                                    }
                                                </Fragment>
                                            }
                                        </Fragment>
                                        :                                    
                                        <Loading />                                    
                                    }
                                    
                                </div>
                                :
                                <Fragment>
                                    {error &&  <Error text={error} />}
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div> 	
            	
            {showAdd &&
                <AddEditCustomer openCloseModal={openCloseModal} selectedCustomerID={selectedCustomerID} listVariables={listVariables} />
            }
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delCustomer} /> }	
        </div>
    );

}

export default withApollo(Customers);