import React,{ Component, Fragment, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import {GET_DRINKS,DELETE_DRINKS,UPDATE_DRINK_PRIORITY} from '../Queries/drink';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import edit from '../../../Public/Images/edit.svg';
import move from '../../../Public/Images/move.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import AddEditDrink from './AddEditDrink';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const Drinks = (props) => {

    const helper = new Helper();
    helper.checkAdminRights(props,["1","2"]);
    
    const listVariables = {
        lang:translate.locale
    }

    const [selectedDrinks,setSelectedDrinks]        = useState([]);
	const [showDeleteNotifi,setShowDeleteNotifi] 	        = useState(false);
	const [showAdd,setShowAdd] 			                    = useState(false);
    const [selectedDrinkID,setSelectedDrinkID] 	    = useState(0);
    
    const {loading, error:getError, data:drinksData, refetch}   = useQuery(GET_DRINKS,{
        variables:listVariables,
        fetchPolicy: 'cache-and-network'
    });

    const [deleteDrink, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_DRINKS,{	
		update(cache,response) {            
            
            refetch();

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    const [updateDrinkPriority,{data:updateData,loading:updateLoading,error:updateError}] = useMutation(UPDATE_DRINK_PRIORITY,{
        update(){
            refetch();
        }
    })

    const openCloseModal = (open,drinkID) => {
        setSelectedDrinkID(drinkID);
        setShowAdd(open);
    }

    const showDelNotifi = () => {
        if(selectedDrinks.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const delDrink = (action) => {

        if(action){

            let drinkIDs = selectedDrinks.join(",");
            deleteDrink({variables:{drinkIDs}});
        }

        setShowDeleteNotifi(false);
    }

    const initiateDeleteDrink = (e,drinkID) => {

        var checked = e.target.checked;
        var arr = [...selectedDrinks];

        if(!checked){
            for(let i in selectedDrinks){
                if(selectedDrinks[i] == drinkID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [drinkID,...arr];
        }

        setSelectedDrinks(arr);
  
    }

    const onDragEnd = (result,updateDrinkPriority) => {

        const IDs = result.draggableId.split("-");

        updateDrinkPriority({
            variables:{
                drinkID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    if(getError || deleteError || updateError){
        var error = getError ? getError : (deleteError ? deleteError : updateError);
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }

    return (
        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t("Drinks")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                        </div>
                        <div className="card-body">

                            {!error ?
                                <div>
                                    <div className="data-list">
                                        
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("Name")}</div>
                                                <div className="text-center">{translate.t("Active")}</div>
                                                <div className="text-center">{translate.t("Price")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        
                                        {drinksData && drinksData.allDrinks && drinksData.allDrinks.length > 0 &&
                                            <DragDropContext onDragEnd={(result) => onDragEnd(result,updateDrinkPriority)}>
                                                <Droppable droppableId="droppable">
                                                    {(provided, snapshot) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {drinksData && drinksData.allDrinks && drinksData.allDrinks.map((item,index) => {
                                                                
                                                                var checked = false;
                                                                for(let i in selectedDrinks){
                                                                    if(selectedDrinks[i] == item.drinkID)checked = true;
                                                                }

                                                                return (
                                                                    <Draggable key={index} draggableId={item.drinkID} index={index}>
                                                                        {(provided, snapshot) => (
                                                                        <div className="data-list-item" 
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <div className="data-list-item-content categories">
                                                                                <div className="cross" style={{paddingLeft:10}}>
                                                                                    <img className="move-icon" src={move} />
                                                                                    {item.name}
                                                                                </div>
                                                                                <div className="text-center">{(item.active == 1 ? "ANO" : "NE")}</div>
                                                                                <div className="text-center">{item.prices && item.prices.length > 0 && item.prices.map((priceItem,index) => (<div key={index}>{priceItem.price} {priceItem.country.currency.code}</div>))}</div>
                                                                                <div className="text-right">
                                                                                    <img onClick={() => openCloseModal(true,item.drinkID)} className="edit-icon" src={edit} />
                                                                                    <input className="delete-checked" type="checkbox" name="deleteAthlete[]" checked={checked} onChange = {(e) => initiateDeleteDrink(e,item.drinkID)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                                
                                                            })}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        }
                                    </div>
                                    {!loading ? 
                                        <Fragment>
                                            {drinksData && drinksData.allDrinks && drinksData.allDrinks.length == 0 &&
                                                <NoItems text={translate.t("NoItemsAddedYet")} />
                                            }
                                        </Fragment>
                                        :                                    
                                        <Loading />                                    
                                    }
                                    
                                </div>
                                :
                                <Fragment>
                                    {error &&  <Error text={error} />}
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div> 	
            {showAdd &&
                <AddEditDrink openCloseModal={openCloseModal} selectedDrinkID={selectedDrinkID} listVariables={listVariables} />
            }	
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delDrink} /> }	
        </div>
    )

}

export default withApollo(Drinks);