import gql from 'graphql-tag';

export const GET_GREEN_INFO_BANNERS = gql`
    query AllGreenInfoBanners($lang:String){
        allGreenInfoBanners(lang:$lang){            
            greenInfoBannerID
            countryID
            active  
            text
            country{
                name
            }
            lang  
        }
    }
`;
export const GET_GREEN_INFO_BANNER_DATA = gql`
    query GreenInfoBannerData($greenInfoBannerID: ID!){
        greenInfoBannerDataWithLangs(greenInfoBannerID:$greenInfoBannerID){
            greenInfoBannerID
            active  
            countryID
            showInCart
            showInRealtimeDelivery
            showInRestaurantDelivery
            showInBranch
            langs{
                lang
                text
            }   
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }        
        allActiveCountries(fromAdmin:1){
            countryID
            name
        }
    }
`;

export const DELETE_GREEN_INFO_BANNERS = gql`
    mutation deleteGreenInfoBanners($greenInfoBannerIDs:ID!) {
        deleteGreenInfoBanners(greenInfoBannerIDs:$greenInfoBannerIDs)
    }
`;

export const ADDEDIT_GREEN_INFO_BANNER_DATA = gql`
    mutation EditGreenInfoBannerData($greenInfoBannerID:ID,$active:Int,$countryID:ID,$showInCart:Int,$showInRealtimeDelivery:Int,$showInRestaurantDelivery:Int,$showInBranch:Int,$langs:[GreenInfoLangsInput]){
        editGreenInfoBannerData(greenInfoBannerID:$greenInfoBannerID,active:$active,countryID:$countryID,showInCart:$showInCart,showInRealtimeDelivery:$showInRealtimeDelivery,showInRestaurantDelivery:$showInRestaurantDelivery,showInBranch:$showInBranch,langs:$langs)
    }
`;