import translate from '../../Translations/index';
import Notification from '../../../Library/notification';

class MealDrinkPrice {

    constructor(props){
        this.props = props;
    }

    addMealDrinkPrice(addMealDrinkPrice,formData){
        if(formData.drinkPrice !== ""){
            
            formData.drinkPrice          = parseFloat(formData.drinkPrice);
            addMealDrinkPrice({
                variables:{
                    mealDrinkPriceInputs:formData
                }
            }); 

        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidPrice"),false,true,this.props.client);
        }                     

    }

    getData(data,mealDrinkPriceID,formData,setFormData){
        console.log(data);
        if(mealDrinkPriceID != 0){            
            setFormData({...formData,
                mealDrinkPriceID: data.mealDrinkPrice.mealDrinkPriceID,
                countryID: data.mealDrinkPrice.countryID,
                drinkPrice: data.mealDrinkPrice.drinkPrice,
                vatID: data.mealDrinkPrice.vatID,
            });
        }
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default MealDrinkPrice;