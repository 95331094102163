import gql from 'graphql-tag';

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    notifications: [Notification]
    isAdminLogin: Boolean!
    adminUserData: AdminUserData
  }

  type Notification {
    error: Boolean
    success: Boolean
    text: String!
  }

  type AdminUserData {
    name:String
    surname:String
    roleID:ID
    email:String
  }

`;


export const resolvers = {};