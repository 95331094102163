import translate from '../../Translations/index';
import Notification from '../../../Library/notification';

class Meal {

    constructor(props){
        this.props = props;
    }

    /**
     * Přidání jídla
     * @param {*} addMeal funkce pro přidání
     * @param {*} formData aktuální data jídla
     */

    addMeal(addMeal,formData){

        if(formData.photo != "" || formData.mealID != 0){

            if(formData.price != ""){

                if(formData.selectedCategories.length > 0){
            
                    formData.active = parseInt(formData.active);
                    formData.isAlcohol = parseInt(formData.isAlcohol);
                    
                    addMeal({
                        variables:formData
                    }); 

                }else{
                    let notify = new Notification();
                    notify.setNotification(null,translate.t("InvalidMealCategories"),false,true,this.props.client);
                }

            }else{
                let notify = new Notification();
                notify.setNotification(null,translate.t("InvalidMealPrice"),false,true,this.props.client);
            }

        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidPhoto"),false,true,this.props.client);
        }
        
    }

    /**
     * Vytáhne data pro dané jídlo
     * @param {*} data data z databáze
     * @param {*} mealID ID jídla
     * @param {*} formData aktuální data pro jídlo
     * @param {*} setFormData funkce která data uloží do aktuálních dat
     */

    getData(data,mealID,formData,setFormData){

        if(mealID == 0){

            let langs = this.completeLangsArray([],data.allLanguageMutations); 
            setFormData({...formData,langs:langs});

        }else{

            var selectedCategories = [];
            if(data.mealWithLangs.categories && data.mealWithLangs.categories.length > 0){
                for(let val of data.mealWithLangs.categories){
                    selectedCategories.push(val.categoryID);
                }
            }

            var selectedSideDishes = [];
            if(data.mealWithLangs.sideDishes && data.mealWithLangs.sideDishes.length > 0){
                for(let val of data.mealWithLangs.sideDishes){

                    var sideDishedName = "";
                    for(let j of data.allSideDishes){
                        if(j.sideDishID == val.sideDishID)sideDishedName = j.name;
                    }

                    selectedSideDishes.push({
                        name:sideDishedName,
                        sideDishID:val.sideDishID,
                        sideDishPrice:val.sideDishPrice
                    });
                }
            }

            var selectedDrinks = [];
            if(data.mealWithLangs.drinks && data.mealWithLangs.drinks.length > 0){
                for(let val of data.mealWithLangs.drinks){
                    var drinkName = "";
                    for(let j of data.allDrinks){
                        if(j.drinkID == val.drinkID)drinkName = j.name;
                    }

                    selectedDrinks.push({
                        name:drinkName,
                        drinkID:val.drinkID,
                        drinkPrice:val.drinkPrice
                    });
                }
            }

            var selectedOptions = [];
            if(data.mealWithLangs.options && data.mealWithLangs.options.length > 0){
                for(let val of data.mealWithLangs.options){

                    var optionName = "";
                    for(let j of data.allOptions){
                        if(j.optionID == val.optionID)optionName = j.name;
                    }

                    selectedOptions.push({
                        name:optionName,
                        optionID:val.optionID,
                        optionPrice:val.optionPrice
                    });
                }
            }
           
            setFormData({...formData,
                active: data.mealWithLangs.active,   
                isAlcohol: data.mealWithLangs.isAlcohol,                
                mealTypeID: data.mealWithLangs.mealTypeID,
                mealFoodTypeID: data.mealWithLangs.mealFoodTypeID,
                mealID: data.mealWithLangs.mealID,
                selectedCategories,
                selectedSideDishes,
                selectedDrinks,
                selectedOptions,
                langs: this.completeLangsArray(data.mealWithLangs.langs,data.allLanguageMutations)
            });
        }

    }

    /**
     * Přidá přílohu do seznamu vybraných příloh
     * @param {*} e 
     * @param {*} allSideDishes seznam příloh
     * @param {*} setSelectedSideDish funkce pro přidání vybrané přílohy
     */

    setSelectedSideDish(e,allSideDishes,setSelectedSideDish){

        var sideDishID = e.target.value;
        for(let val of allSideDishes){
            if(val.sideDishID == sideDishID){
                var item = {
                    sideDishID: val.sideDishID,
                    name: val.name,
                    sideDishPrice:parseFloat(val.price)
                }
                setSelectedSideDish(item);
            }
        }

    }

    /**
     * Přidání přílohy mezi vybrané
     * @param {*} selectedSideDish vybraná příloha
     * @param {*} setFormData funkce pro přidání
     * @param {*} formData aktuální data jídla
     */

    addSelectedSideDish(selectedSideDish,setFormData,formData){

        if(selectedSideDish.sideDishID != 0){
            setFormData({...formData,selectedSideDishes:[...formData.selectedSideDishes,selectedSideDish]});
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidSidDish"),false,true,this.props.client);
        }
            
    }

    /**
     * Úprava ceny u vybrané přílohy
     * @param {*} e ukazatel na element
     * @param {*} index index v seznamu vybraných příloh
     * @param {*} formData aktuální data jídla
     * @param {*} setFormData funkce pro uložení dat
     */

    setSelectedSideDishPrice(e,index,formData,setFormData){
       
        var value = parseFloat(e.target.value);
        var arr = [...formData.selectedSideDishes];
        
        arr[index] = {...arr[index],sideDishPrice:value}
        setFormData({...formData,selectedSideDishes:arr});    

    }

    removeSelectedSideDish(index,formData,setFormData){
        
        var arr = [...formData.selectedSideDishes];
        
        for(let i in arr){
            if(i == index)arr.splice(i,1);
        }

        setFormData({...formData,selectedSideDishes:arr});
    }


    /**
     * Přidá nápoj do seznamu vybraných nápojů
     * @param {*} e 
     * @param {*} allDrinks seznam nápojů
     * @param {*} setSelectedDrink funkce pro přidání vybraného nápouje
     */

    setSelectedDrink(e,allDrinks,setSelectedDrink){

        var drinkID = e.target.value;
        for(let val of allDrinks){
            if(val.drinkID == drinkID){
                var item = {
                    drinkID: val.drinkID,
                    name: val.name,
                   drinkPrice:parseFloat(val.price)
                }
                setSelectedDrink(item);
            }
        }

    }

    /**
     * Přidání nápoje mezi vybrané
     * @param {*} selectedDrink vybraný nápoj
     * @param {*} setFormData funkce pro přidání
     * @param {*} formData aktuální data jídla
     */

    addSelectedDrink(selectedDrink,setFormData,formData){

        if(selectedDrink.drinkID != 0){
            setFormData({...formData,selectedDrinks:[...formData.selectedDrinks,selectedDrink]});
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidDrink"),false,true,this.props.client);
        }
            
    }

    /**
     * Úprava ceny u vybraného nápoje
     * @param {*} e ukazatel na element
     * @param {*} index index v seznamu vybraných nápojů
     * @param {*} formData aktuální data jídla
     * @param {*} setFormData funkce pro uložení dat
     */

    setSelectedDrinkPrice(e,index,formData,setFormData){
       
        var value = parseFloat(e.target.value);
        var arr = [...formData.selectedDrinks];
        
        arr[index] = {...arr[index],drinkPrice:value}
        setFormData({...formData,selectedDrinks:arr});    

    }

    removeSelectedDrink(index,formData,setFormData){
        
        var arr = [...formData.selectedDrinks];
        
        for(let i in arr){
            if(i == index)arr.splice(i,1);
        }

        setFormData({...formData,selectedDrinks:arr});
    }
    /**
     * Zakliknutí kategori
     * @param {*} e ukazatel na kategorii
     * @param {*} setFormData funkce pro uložení dat
     * @param {*} formData aktuální data jídla
     */

    selectCategory(e,setFormData,formData){

        var value = e.target.value;
        var arr = [...formData.selectedCategories];

        var check = false;

        for(let i in arr){
            if(arr[i] == value){
                 arr.splice(i,1);
                 check = true;
            }
        }

        if(!check){
            arr.push(value);
        }

        setFormData({...formData,selectedCategories:arr});

    }

    /**
     * Přidá přílohu do seznamu vybraných možností
     * @param {*} e 
     * @param {*} allOptions seznam možností
     * @param {*} setSelectedSideDish funkce pro přidání vybrané možnosti
     */

    setSelectedOption(e,allOptions,setSelectedOption){

        var optionID = e.target.value;
        for(let val of allOptions){
            if(val.optionID == optionID){
                var item = {
                    optionID: val.optionID,
                    name: val.name,
                    optionPrice:parseFloat(val.price)
                }
                setSelectedOption(item);
            }
        }

    }

    /**
     * Přidání možnosti mezi vybrané
     * @param {*} selectedOption vybraná možnost
     * @param {*} setFormData funkce pro přidání
     * @param {*} formData aktuální data jídla
     */

    addSelectedOption(selectedOption,setFormData,formData){

        if(selectedOption.optionID != 0){
            setFormData({...formData,selectedOptions:[...formData.selectedOptions,selectedOption]});
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidOption"),false,true,this.props.client);
        }
            
    }

    /**
     * Úprava ceny u vybrané přílohy
     * @param {*} e ukazatel na element
     * @param {*} index index v seznamu vybraných příloh
     * @param {*} formData aktuální data jídla
     * @param {*} setFormData funkce pro uložení dat
     */

    setSelectedOptionPrice(e,index,formData,setFormData){
       
        var value = parseFloat(e.target.value);
        var arr = [...formData.selectedOptions];
        
        arr[index] = {...arr[index],optionPrice:value}
        setFormData({...formData,selectedOptions:arr});    

    }

    removeSelectedOption(index,formData,setFormData){
        
        var arr = [...formData.selectedOptions];
        
        for(let i in arr){
            if(i == index)arr.splice(i,1);
        }

        setFormData({...formData,selectedOptions:arr});
    }


    /**
     *  Pro vyplnění jazykových mutací
     */

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(lang.__typename)delete(lang["__typename"]);
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    name:"",
                    longName:"",
                    description:"",
                    alergens:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e,formData,setFormData){

        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        var langs = formData.langs;

        for(const lang of langs){
            if(lang.lang == formData.selectedLang){
                lang[n] = v;
            }
        }

        setFormData({...formData,langs: langs});
         
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} propertyName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (formData,propertyName) => {
        for(const lang of formData.langs){
            if(lang.lang == formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     */
    
    formLangHandleEditor(content,paramName,formData,setFormData){
        
        let langs       = formData.langs;
        for(const lang of langs){
            if(lang.lang == formData.selectedLang){
                lang[paramName] = content;
            }
        }

        setFormData({...formData,langs: langs});
    }

}

export default Meal;