/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 *  Data kategorie
 */

            
export const GET_SETTINGS = gql`
    query Settings{
        settingsWithCountryValues{
            company
            street
            city
            zip
            country
            IC
            DIC
            bankAccount  
            deliveryTimeFrom
            deliveryTimeTo
            slotDuration
            timeHandleDelivery        
            hideEatInOption
            hideTakeAwayOption
            deliveryTypeID   
            enableCreditUsage
            termsAndConditionsChangeDate
            minOrderPriceToAddCredit{                
                countryID	
                label
                value
            } 
            inRestaurantVat{                
                countryID	
                label
                value
            } 
            takeAwayVat{                
                countryID	
                label
                value
            } 
            alcoholVat{                
                countryID	
                label
                value
            } 
            minimumOrderAmount{                
                countryID	
                label
                value
            } 
            deliveryPrice{                
                countryID	
                label
                value
            } 
            deliveryPriceVat{                
                countryID	
                label
                value
            }             
            restaurantCookingTime{                
                countryID	
                label
                value
            } 
            restaurantDeliveryTime{                
                countryID	
                label
                value
            } 
            restaurantDeliveryTimeToAlert{                
                countryID	
                label
                value
            } 
            cashPayEnabled{                
                countryID	
                label
                value
            } 
            firstMealFree{                
                countryID	
                label
                value
            } 
            enableVoucherUsage{                
                countryID	
                label
                value
            } 
            creditPercentage{               
                countryID	
                label
                value
            }
            addCreditOnCashPayment{               
                countryID	
                label
                value
            }
        }
        allVats{
            vatID
            value
        }
                   
        allActiveCountries(fromAdmin:1){
            countryID
            name
            currencyID
            currency{
                code
                title
            }
        }
        
    }
`;

export const UPDATE_SETTINGS = gql`
    mutation saveSettings($settings:SettingsInput){
        saveSettings(settings:$settings)       
    }
`;
