import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import {GET_MEALS} from '../../Meals/Queries/meal';
import translate from '../../Translations/index';
import NoItems from '../../../GlobalComponents/NoItems';

var error = "";

const BranchMeals = (props) => {

    var loading = false;

    var selectedMeals    = props.formData.selectedMeals;
    var setSelectedMeals = props.setFormData;

    const [allMeals,setAllMeals] = useState({
        filteredMeals:[],
        allMeals:[]
    });
    const [searchText,setSearchText] = useState("");

    var {loading:mealLoading, error:getMealError, data:mealListData}   = useQuery(GET_MEALS,{
        variables:{
            lang:"cz"
        },
        onCompleted(data){

            setAllMeals({
                filteredMeals:data.allMeals.filter(item => item.prices.filter(it => it.countryID == props.branchData.branch.country.countryID).length > 0),
                allMeals:data.allMeals.filter(item => item.prices.filter(it => it.countryID == props.branchData.branch.country.countryID).length > 0)
            });

        }
    });

    const selectMeal = (e,mealID) => {

        var checked = e.target.checked;
        var arr = [...selectedMeals];

        if(!checked){
            for(let i in selectedMeals){
                if(selectedMeals[i] == mealID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [mealID,...arr];
        }

        setSelectedMeals({...props.formData,selectedMeals:arr});
  
    }

    const searchMeal = (text) => {

        var filteredMeals = allMeals.allMeals.filter(function(el) {
            return el.name.toLowerCase().indexOf(text.toLowerCase()) !== -1
        }).filter(item => item.prices.filter(it => it.countryID == props.branchData.branch.country.countryID).length > 0);

        setAllMeals({...allMeals,filteredMeals:filteredMeals});
        setSearchText(text);

    }

    if(mealLoading)loading = mealLoading;
    if(getMealError)error = getMealError;


    return(

        <Fragment>

            {error ?
                <Error text={error} />
                 :
                <Fragment>

                    {loading ? 

                        <Loading />
                
                        :

                        <div>

                            <div className="form-group">
                                <input placeholder={translate.t("SearchMeal")} type="text" className="form-control" value={searchText} onChange={(e) => searchMeal(e.target.value)} />
                            </div>
                
                            <div className="data-list">
                                            
                                <div className="data-list-item header">
                                    <div className="data-list-item-content">
                                        <div className="static small"></div>
                                        <div>{translate.t("Name")}</div>
                                        <div className="text-center">{translate.t("Categories")}</div>
                                        <div className="text-center">{translate.t("Type")}</div>
                                        <div className="text-center">{translate.t("FoodType")}</div>
                                        <div className="text-center">{translate.t("Active")}</div>
                                        <div className="text-right">{translate.t("Price")}</div>
                                    </div>
                                </div>

                                <Fragment>

                                    {allMeals && allMeals.filteredMeals && allMeals.filteredMeals.map((item,index) => {

                                        var checked = false;
                                        for(let i in selectedMeals){
                                            if(selectedMeals[i] == item.mealID)checked = true;
                                        }
                                                                                                        
                                        return (
                                        
                                            <div key={index} className="data-list-item-content categories">
                                                <div className="static small">
                                                    <input className="delete-checked" type="checkbox" name="selectMeal[]" checked={checked} onChange = {(e) => selectMeal(e,item.mealID)} />
                                                </div>
                                                <div>
                                                    {item.name}
                                                </div>
                                                <div className="text-center">
                                                    {item.categories.map((it,ind) => (
                                                        <div key={ind}>{(it.category ? it.category.name : "")}</div>
                                                    ))}
                                                </div>
                                                <div className="text-center">{item.mealType.name}</div>
                                                <div className="text-center">{item.mealFoodType.name}</div>
                                                <div className="text-center">{(item.active == 1 ? "ANO" : "NE")}</div>
                                                <div className="text-right">{item.prices.filter(it => it.countryID == props.branchData.branch.country.countryID)[0].price} {props.branchData.branch.country.currency.code}</div>
                                                
                                            </div>
                                                    
                                        )
                                        
                                    })} 

                                    {allMeals && allMeals.filteredMeals && allMeals.filteredMeals.length == 0 &&

                                        <NoItems text={translate.t("NoItemsAddedYet")} />

                                    }

                                </Fragment>

                            </div>

                        </div>

                    }
                
                    
                </Fragment>
            }
        
        </Fragment>
    )

}

export default withApollo(BranchMeals);









