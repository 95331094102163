class BranchOpenTime {

    constructor(props){
        this.props = props;
    }

    addOpenTime = () => {

        var openTime = {
            times:[{
                timeFrom:"",
                timeTo:""
            }],
            branchID:0,
            mon:0,
            tue:0,
            wed:0,
            thu:0,
            fri:0,
            sat:0,
            sun:0,
        }

        this.props.setFormData({...this.props.formData,openTimes:[...this.props.formData.openTimes,openTime]});

    }

    addTime = (index) => {

        var time = {
            timeFrom:"",
            timeTo:""
        }

        var arr = [...this.props.formData.openTimes];
        var arr2 = [...this.props.formData.openTimes[index].times,time];

        arr[index] = {...arr[index],times:arr2}

        this.props.setFormData({...this.props.formData,openTimes:arr});

    }

    deleteOpenTime = (index) => {

        var arr = [...this.props.formData.openTimes];
        arr = [...arr.slice(0,index),...arr.slice(index + 1)];

        this.props.setFormData({...this.props.formData,openTimes:arr});

    }

    setTime = (e,index,ind) => {

        var t = e.target;
        var name = t.name;
        var value = t.value;

        var arr = [...this.props.formData.openTimes];
        var arr2 = [...this.props.formData.openTimes[index].times];

        arr2[ind] = {...arr2[ind],[name]:value};
        arr[index] = {...arr[index],times:arr2}

        this.props.setFormData({...this.props.formData,openTimes:arr});

    }

    setDay = (e,index) => {

        var t = e.target;
        var name = t.name;
        var value = t.checked;

        if(value)value = 1;
        else value = 0;

        var arr = [...this.props.formData.openTimes];
        arr[index] = {...arr[index],[name]:value}

        this.props.setFormData({...this.props.formData,openTimes:arr});

    }

}

export default BranchOpenTime;