/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_MEAL = gql`
    mutation AddEditMeal($active:Int,$isAlcohol:Int,$mealID:ID,$photo:Upload,$mealTypeID:ID,$mealFoodTypeID:ID, $langs:[MealLangsInput], $selectedSideDishes:[MealSideDishesInput], $selectedOptions:[MealOptionsInput], $selectedDrinks:[MealDrinksInput], $selectedCategories:[ID]){
        addEditMeal(active:$active,isAlcohol:$isAlcohol, mealID:$mealID,photo:$photo,mealTypeID:$mealTypeID,mealFoodTypeID:$mealFoodTypeID,langs:$langs,selectedSideDishes:$selectedSideDishes,selectedOptions:$selectedOptions,selectedDrinks:$selectedDrinks,selectedCategories:$selectedCategories){
            mealID
        }
    }
`;


/**
 *  Data kategorie
 */

export const GET_MEAL = gql`
    query Meal($mealID: ID!,$lang:String){
        mealWithLangs(mealID:$mealID){
            mealID
            isAlcohol
            active              
            photo
            mealTypeID
            mealFoodTypeID
            langs{
                lang
                name
                longName
                description
                alergens
            }   
            categories{
                categoryID
            } 
            sideDishes{
                sideDishID
                sideDishPrice
            } 
            drinks{
                drinkID
                drinkPrice
            }  
            options{
                optionID
                optionPrice
            }  
        }
        allSideDishes(lang:$lang,onlyActive:false){
            sideDishID
            name
            price
        }
        allDrinks(lang:$lang,onlyActive:false){
            drinkID
            name
            price
        }
        allOptions(lang:$lang,onlyActive:false){
            optionID
            name
            price
        }
        allCategories(lang:$lang,onlyActive:false){
            categoryID
            name
        }
        allMealTypes{
            mealTypeID
            name
        }
        allMealFoodTypes{
            mealFoodTypeID
            name
        }
        allVats{
            vatID
            value
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
        allActiveCountries(fromAdmin:1){
            countryID
            name
        }
    }
`;

export const REINDEX_PRIORITIES = gql`
    mutation ReindexPriorities($mealID:ID) {
        reindexMealSideDishPriority(mealID:$mealID)
        reindexMealOptionPriority(mealID:$mealID)
        reindexMealDrinkPriority(mealID:$mealID)
    }
`;


/**
 *  Seznam veškerých kategorií
 */
export const GET_MEALS = gql`
    query AllMeals($lang:String){
        allMeals(lang:$lang,onlyActive:false){            
            mealID
            isAlcohol
            name
            prices{
                price
                countryID
                country{
                    currencyID
                    currency{
                        code
                    }
                }
            }
            active
            mealTypeID
            mealFoodTypeID
            lang
            mealType{
                name
            }
            mealFoodType{
                name
            }
            categories{
                categoryID
                lang
                category{
                    name
                }
            }   
        }
    }
`;

/**
 * Smazání kategorie
 */

export const DELETE_MEALS = gql`
    mutation deleteMeals($mealIDs:ID!) {
        deleteMeals(mealIDs:$mealIDs)
        reindexMealPriority
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_MEAL_PRIORITY = gql`
    mutation updateMealPriority($mealID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateMealPriority(mealID:$mealID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;