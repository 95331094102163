import gql from 'graphql-tag';

export const GET_DRINK_PRICES = gql`
    query AllDrinkPrices($drinkID: ID!){
        allDrinkPrices(drinkID:$drinkID){
            drinkPriceID
            drinkID
            countryID
            price
            vatID
            country{
                name
                currencyID
                currency{
                    code
                }
            }
            vat{
                value
            }
        }

    }
`;
export const GET_DRINK_PRICE = gql`
    query DrinkPrice($drinkPriceID: ID!){
        drinkPrice(drinkPriceID:$drinkPriceID){
            drinkPriceID
            countryID
            price
            vatID
        }
        allActiveCountries(fromAdmin:1){
            countryID
            currencyID
            name
            currency{
                title
            }
        }        
        allVats{
            vatID
            value
        }
    }
`;

export const ADD_EDIT_DRINK_PRICE = gql`
    mutation AddEditDrinkPrice($drinkPriceInputs:DrinkPriceInputs) {
        addEditDrinkPrice(drinkPriceInputs:$drinkPriceInputs){
            drinkPriceID
            drinkID
            countryID
            price
            vatID
        }
    }
`;

export const DELETE_DRINK_PRICES = gql`
    mutation DeleteDrinkPrices($drinkPriceIDs:[ID]!) {
        deleteDrinkPrices(drinkPriceIDs:$drinkPriceIDs)
    }
`;




