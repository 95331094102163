/**
 *  komponenta pro štítky pro produkty
 */

import React,{ Fragment, useState } from 'react';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import {GET_DRIVERS,DELETE_DRIVERS} from '../Queries/driver';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import DriverLib from '../Library/driver';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import AddEditDriver from './AddEditDriver';
import edit from '../../../Public/Images/edit.svg';

const Drivers = (props) => {
        
    const helper = new Helper();
    helper.checkAdminRights(props,["1","2"]);
    
    const listVariables = {
        offset:0,
        limit:50
    }

	const [selectedDrivers,setSelectedDrivers]      = useState([]);
    const [showDeleteNotifi,setShowDeleteNotifi]    = useState(false);
    const [showAdd,setShowAdd] 			            = useState(false);
    const [selectedDriverID,setSelectedDriverID]    = useState(0);
    
    const {loading, error:getError, data:driverListData, fetchMore}   = useQuery(GET_DRIVERS,{
        variables:listVariables
    });
 
	const [deleteDriver, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_DRIVERS,{	
		update(cache,response) {            
            let driver = new DriverLib(this);
            driver.updateAfterDelete(cache, response,listVariables);

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    /**
     * smazání štítků
     * @param {*} deleteDrivers funkce, která posílá data na server
     * @param {*} driverID ID driveru
     */
    
    const initiateDeleteDriver = (e,driverID) => {

        var checked = e.target.checked;
        var arr = [...selectedDrivers];

        if(!checked){
            for(let i in selectedDrivers){
                if(selectedDrivers[i] == driverID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [driverID,...arr];
        }

        setSelectedDrivers(arr);
  
    }

    const delDriver = (action) => {

        if(action){

            let driverIDs = selectedDrivers.join(",");

            deleteDriver({
                variables:{
                    driverIDs: driverIDs
                }
            })
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    }

    const showDelNotifi = () => {

        if(selectedDrivers.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const openCloseModal = (open,driveID) => {
        setSelectedDriverID(driveID);
        setShowAdd(open);
    }

    
    if(getError || deleteError){
        var error = getError ? getError : deleteError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }
    return(

        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t("DriverList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                        </div>
                        <div className="card-body">

                            {!error ?
                                <div>
                                    <div className="data-list">
                                        
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("Name")}</div>
                                                <div className="text-center">{translate.t("DriverType")}</div>
                                                <div className="text-center">{translate.t("DeliveryArea")}</div>
                                                <div className="text-center">{translate.t("NumberOfRealTimeMeal")}</div>
                                                <div className="text-center">{translate.t("Phone")}</div>
                                                <div className="text-center">{translate.t("CarDescription")}</div>
                                                <div className="text-right static small">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {driverListData && driverListData.allDrivers && driverListData.allDrivers.map((item,index) => {

                                                var checked = false;
                                                for(let i in selectedDrivers){
                                                    if(selectedDrivers[i] == item.driverID)checked = true;
                                                }
                                                                                                                
                                                return (
                                                
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{item.user && item.user.name} {item.user && item.user.surname}</div>
                                                        <div className="text-center">{item.driverType ? translate.t(item.driverType.title) : ""}</div>
                                                        <div className="text-center">{item.deliveryArea && item.deliveryArea.name}</div>
                                                        <div className="text-center">{item.realTimeMealsCount}</div>
                                                        <div className="text-center">{item.phone}</div>
                                                        <div className="text-center">{item.description}</div>
                                                       
                                                        <div className="text-right static small">
                                                            <img onClick={() => openCloseModal(true,item.driverID)} className="edit-icon" src={edit} />
                                                            <input className="delete-checked" type="checkbox" name="deleteDriver[]" checked={checked} onChange = {(e) => initiateDeleteDriver(e,item.driverID)} />
                                                        </div>
                                                    </div>
                                                            
                                                )
                                                
                                            })} 
                                        </Fragment>
                                    </div>
                                    {!loading ? 
                                        <Fragment>
                                            {driverListData && driverListData.allDrivers && driverListData.allDrivers.length == 0 ?
                                                <NoItems text={translate.t("NoItemsAddedYet")} />
                                                :  
                                                <Fragment>     
                                                    {!loading && driverListData && driverListData.allDrivers.length < driverListData.driverListCount &&                                 
                                                        <div className="text-center p-5">
                                                            <button className="btn btn-primary" onClick={() => fetchMore({
                                                                variables: {...listVariables,
                                                                    offset: driverListData.allDrivers.length
                                                                },
                                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                                    if (!fetchMoreResult) return prev;
                                                                    return Object.assign({}, prev, {
                                                                        allDrivers: [...prev.allDrivers, ...fetchMoreResult.allDrivers]
                                                                    });
                                                                }
                                                            })}> {translate.t("LoadMore")} </button>
                                                        </div> 
                                                    }
                                                </Fragment>
                                            }
                                        </Fragment>
                                        :                                    
                                        <Loading />                                    
                                    }
                                    
                                </div>
                                :
                                <Fragment>
                                    {error &&  <Error text={error} />}
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div> 	
            {showAdd &&
                <AddEditDriver openCloseModal={openCloseModal} selectedDriverID={selectedDriverID} listVariables={listVariables} />
            }
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delDriver} /> }	
        </div>
    );

}

export default withApollo(Drivers);