import React,{ Component, Fragment, useState,useEffect } from 'react';
import {withApollo,useMutation,useQuery} from 'react-apollo';
import translate from '../../Translations/index';
import DeliveryAreaLib from '../Library/deliveryArea';
import { SketchPicker  } from 'react-color';
import {ADD_EDIT_DELIVERY_AREA,GET_DELIVERY_AREAS,DELETE_DELIVERY_AREA} from '../Queries/deliveryArea';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import remove from '../../../Public/Images/remove.svg';
import moment from 'moment';
import { GOOGLE_API_KEY } from '../../../Config/index';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import { uuid } from 'uuidv4';

var wHeight = window.innerHeight - 15;
var gogleMap = null;
var polygon = null;
var polygonList = [];
var listener;
var ApiKey = "";

const INITIAL_FORM_DATA = {
    color:"#FF0000",
    name:""
}

const DeliveryAreas = (props) => {

    const helper = new Helper();
    helper.checkAdminRights(props,["1","2"]);
    
    const deliveryAreaLib = new DeliveryAreaLib(props);
    
    const [googleMapSet,setGoogleMapSet]         = useState(false);
    const [mapIsReaddy,setMapIsReady]            = useState(false);
    const [addArea,setAddArea]                   = useState(false);
    const [showColorPicker,setShowColorPicker]   = useState(false);
    const [allDeliveryAreas,setAllDeliveryAreas] = useState(null);
    const [showDeleteNotifi,setShowDeleteNotifi] = useState(false);

    const [formData,setFormData] = useState(INITIAL_FORM_DATA);

    var [addEditDeliveryArea,{loading}] = useMutation(ADD_EDIT_DELIVERY_AREA,{
        onCompleted(data){

            var isUpdating = !!polygon.polyData;

            polygon.polyData = {
                deliveryAreaID : data.addEditDeliveryArea.deliveryAreaID,
                name           : data.addEditDeliveryArea.name,
                color          : data.addEditDeliveryArea.color,
            }
            polygon.setOptions({editable:false});
            polygon.addListener('click', polygonClick);
            if(!isUpdating)polygonList.push(polygon);

            polygon = null;  
            
            setAddArea(false);
            setFormData(INITIAL_FORM_DATA);
            
            window.google.maps.event.removeListener(listener);

            let notify = new Notification();
            notify.setNotification(null,translate.t("SuccessfullySaved"),true,false,props.client);

            setClicablePolygons(true);

        },onError(err){

            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    });

    var [deleteDeliveryArea,{loading:deleteLoading}] = useMutation(DELETE_DELIVERY_AREA,{
        onCompleted(data){

            for(let i in polygonList){
                if(polygonList[i].id == polygon.id){
                    polygonList.splice(i,1);
                }
            }

            polygon.setMap(null);
            polygon = null;  

            setAddArea(false);
            setFormData(INITIAL_FORM_DATA);
            setClicablePolygons(true);
            window.google.maps.event.removeListener(listener);

            let notify = new Notification();
            notify.setNotification(null,translate.t("SuccessFullyDeleted"),true,false,props.client);

        },onError(err){

            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    });

    const {data,error,loading:getLoading} = useQuery(GET_DELIVERY_AREAS,{
        fetchPolicy: 'network-only',
        onCompleted(data){
            setAllDeliveryAreas(data.allDeliveryAreas);
        }
    });

    const addLatLng = (event,color) => {

        if(!polygon){

            polygon = new window.google.maps.Polygon({
                strokeColor: color,
                strokeOpacity: 1.0,
                clickable:false,
                strokeWeight: 3,
                fillColor: color,
                fillOpacity: 0.35,
                id: polygonList.length,
                editable:true
            });

            polygon.setMap(gogleMap);

        }

        var path = polygon.getPath();
        path.push(event.latLng);
    
    }

    const savePolygon = () => {
        
        if(polygon){
            if(formData.name != ""){

                var path = polygon.getPath();
                var pathString = "";

                // Iterate over the vertices.
                for (var i =0; i < path.getLength(); i++) {
                    var xy = path.getAt(i);
                    if(pathString)pathString += ";";
                    pathString += xy.lat() + ',' + xy.lng();
                }

                addEditDeliveryArea({
                    variables:{
                        name:  formData.name,
                        color: formData.color,
                        path: pathString,
                        deliveryAreaID: (polygon.polyData ? polygon.polyData.deliveryAreaID : 0)
                    }
                });

            }else{
                let notify = new Notification();
                notify.setNotification(null,translate.t("InvalidAreaName"),false,true,props.client);
            }
         
        }else{

            polygon = null;  
            setAddArea(false);
            setClicablePolygons(true);
            window.google.maps.event.removeListener(listener);

        }  
 
    }

    const cancelSelection = () => {
        polygon = null;  
        setAddArea(false);
        setClicablePolygons(true);        
        
        if(polygonList.length > 0){
            for(let val of polygonList){
                val.setOptions({
                    editable:false
                })
            }
        }
        window.google.maps.event.removeListener(listener);
    }

    const setClicablePolygons = (clickable) => {

        if(polygonList.length > 0){
            for(let val of polygonList){
                val.setOptions({
                    clickable:clickable
                })
            }
        }
    }

    const setEditablePolygons = (editable) => {

        if(polygonList.length > 0){
            for(let val of polygonList){
                val.setOptions({
                    editable:editable
                })
            }
        }
    }

    const addNewPolygon = () => {

        setAddArea(true);
        
        listener = gogleMap.addListener('click', (event) => addLatLng(event, formData.color));
        setClicablePolygons(false); 
    }

    const changeColor = (color) => {

        color = (color.hex ? color.hex : color);

        deliveryAreaLib.formHandle(color,formData,setFormData,"color");

        if(polygon){
            polygon.setOptions({
                strokeColor: color,
                fillColor: color,
            });
        }else{
            window.google.maps.event.removeListener(listener);
            listener = gogleMap.addListener('click', (event) => addLatLng(event, color));
        }

        
    }

    const removeDeliveryArea = (status) => {
        if(status){
            if(polygon){
                deleteDeliveryArea({
                    variables:{
                        deliveryAreaID: polygon.polyData.deliveryAreaID
                    }
                })
            }else{
                setClicablePolygons(true);
                setAddArea(false);
                window.google.maps.event.removeListener(listener);
            }
        }
        setShowDeleteNotifi(false);
    }

    function polygonClick(){
        
        setAddArea(true);
        setFormData({
            color:this.polyData.color,
            name:this.polyData.name
        });

        setClicablePolygons(false);
        setEditablePolygons(false);

        for(let val of polygonList){
            if(val.id == this.id){
                console.log(this.id);
                console.log(val.id);
                console.log(val);
                polygon = val;
            }
        }

        polygon.setOptions({editable:true});
        console.log(this.id);
        
    }

    useEffect(() => {

        if(ApiKey == ""){

            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}`;
            script.async = true;
            script.defer = true;
            script.addEventListener('load', () => {
                setMapIsReady(true);
            });

            document.body.appendChild(script);
        }else{
            setMapIsReady(true);
        }

    },[]);

    if(mapIsReaddy && !googleMapSet){

        if(allDeliveryAreas){
            gogleMap = new window.google.maps.Map(document.getElementById('map'), {
                center: {lat: 50.083433, lng: 14.421338},
                zoom: 12,
                mapTypeId: 'roadmap',
            });
            
            if(allDeliveryAreas && allDeliveryAreas.length > 0){
                    
                var poly;
                var polyCoords;

                for(let i in allDeliveryAreas){

                    polyCoords = allDeliveryAreas[i].path.split(";");
                    
                    for(let p in polyCoords){
                        polyCoords[p] = polyCoords[p].split(",");
                        polyCoords[p] = {
                            lat: parseFloat(polyCoords[p][0]),
                            lng: parseFloat(polyCoords[p][1]),
                        }
                    }
                    const polyID = uuid();
                    console.log(polyID);
                    poly = new window.google.maps.Polygon({
                        paths: polyCoords,
                        strokeColor: allDeliveryAreas[i].color,
                        strokeOpacity: 1.0,
                        clickable:true,
                        strokeWeight: 3,
                        fillColor: allDeliveryAreas[i].color,
                        fillOpacity: 0.35,
                        id: polyID,
                        polyData:{
                            deliveryAreaID: allDeliveryAreas[i].deliveryAreaID,
                            name: allDeliveryAreas[i].name,
                            color: allDeliveryAreas[i].color
                        }
                    });
        
                    poly.setMap(gogleMap);
                    poly.addListener('click', polygonClick);

                    polygonList.push(poly);

                }
            }
            setGoogleMapSet(true)
        }
    }

    if(getLoading)loading = getLoading;

    return(
        <div id="settings" style={{height:wHeight+"px"}} className="whole-container" >
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t("DeliveryAreas")}

                            {addArea ?
                                <>
                                    <button className="btn btn-primary btn-thiner ml-auto mr-2" onClick={() => cancelSelection()}>{translate.t("Cancel")}</button>
                                    <button className="btn btn-blue btn-thiner" onClick={() => savePolygon()}>{translate.t("SaveArea")}</button>
                                </>
                            :
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => addNewPolygon()}>{translate.t("AddArea")}</button>
                            }  
                            
                        </div>
                        <div className="card-body" style={{height:wHeight-127+"px"}}>
                            {error ?
                                <Error text={error} />
                                :

                                <Fragment>
                                    {addArea &&
                                        <div id="map-menu">
                                            <div className="d-flex">
                                                <div className="d-flex item">
                                                    <label>{translate.t("AreaColor")}:</label>

                                                    <div className="input-group">
                                                        <div className="input-group-prepend" style={{backgroundColor:formData.color,width:"50px",border:"1px solid #ddd",cursor:"pointer"}} onClick={() => setShowColorPicker(true)} >
                                                            
                                                        </div>
                                                        <input type="text" name="color" className="form-control" value={formData.color} onChange={(e) => changeColor(e.target.value)} />
                                                    </div>

                                                    {showColorPicker &&
                                                        <div>
                                                            <div style={{position:"absolute",top:80,left:15,zIndex:2}}>
                                                                <SketchPicker color={formData.color} onChange={ (color) => changeColor(color) } />
                                                            </div>
                                                            <div style={ {position: 'fixed',top: '0px',right: '0px',bottom: '0px',left: '0px',zIndex:1} } onClick={ () => setShowColorPicker(false) }></div>
                                                        </div>
                                                    }
                                            
                                                </div>
                                                <div className="d-flex item">
                                                    <label>{translate.t("InternalAreaName")}:</label>
                                                    <input type="text" className="form-control" name="name" value={formData.name} onChange={(e) => deliveryAreaLib.formHandle(e,formData,setFormData)} />
                                                </div>
                                                <div className="d-flex item remove">
                                                    <button className="btn btn-danger btn-thiner ml-auto" onClick={() => setShowDeleteNotifi(true)}>{translate.t("DeleteArea")}</button>

                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div id="map"></div>
                                    {loading &&
                                        <div className="card-body-loading">
                                            <Loading />
                                        </div>
                                    }
                                </Fragment>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveDeliveryArea?")} callback={removeDeliveryArea} /> }
        </div>
    )
}

export default withApollo(DeliveryAreas);