/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_APPLINK,GET_APPLINKS,GET_APPLINK} from '../Queries/appLink.js';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import AppLinkLib from '../Library/appLink';
import translate from '../../Translations/index';
import { Editor } from '@tinymce/tinymce-react';

const INITIAL_STATE = {
    err:"",
    allLanguageMutations:[],
    allActiveCountries:[],
    icon:"",
    mainPhoto:"",
    formData:{
        selectedLang:"cz",
        active:1,
        isTermsAndConditions:0,
        isPrivatePolicy:0,
        parentID:"0",
        inHeader:0,
        inFooter:0,
        appLinkID:0,
        icon:"",
        photo:"",
        langs:[],
        countries:[]
    }
}

class AddEditAppLink extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.appLinkLib = new AppLinkLib(this); 
    }

    componentDidMount = () => {
        
        this.appLinkLib.getData();
    }

    openMainImage = (e) => {

        var file = e.target.files[0];

        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;

            this.setState({
                mainPhoto:dataURL,
                formData:{...this.state.formData,photo:file}
            })
        };
        reader.readAsDataURL(file);
    }

    openIcon = (e) => {

        var file = e.target.files[0];

        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;

            this.setState({
                icon:dataURL,
                formData:{...this.state.formData,icon:file}
            })
        };
        reader.readAsDataURL(file);
    }

    selectCountry = (e,country) => {
        let countries = [...this.state.formData.countries];
        
        if(countries.filter((it) => it.countryID == country.countryID).length == 0){
            countries.push(country);
        }else{
            for(const key in countries){
                if(countries[key].countryID == country.countryID){
                    countries.splice(key, 1);
                }
            }
        }
        this.setState({formData:{...this.state.formData,countries}});
    }


    render(){

        var {err,allLanguageMutations,icon,mainPhoto} = this.state;

        return(

            <Mutation 
                mutation={ADD_EDIT_APPLINK}
                errorPolicy = "all"
                refetchQueries = {(data)=>{
                    return [{
                        query:GET_APPLINKS,
                        variables:{lang:"cz"}
                    }]
                }}
                update = {async (cache, response) => {

                    let notify = new Notification();

                    if(this.props.selectedAppLinkID && this.props.selectedAppLinkID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
                    else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

                }}

                onCompleted = {() => {
                    this.props.openCloseModal(false);
                }}
                
            >
            {
                (addEditAppLink,{loading, error}) => {

                    const {selectedLang,parentID,active,inHeader,inFooter,isTermsAndConditions,isPrivatePolicy,countries} = this.state.formData;
                    const {selectedAppLinkID} = this.props;

                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{(selectedAppLinkID && selectedAppLinkID != 0 ? translate.t("LinkEdit") : translate.t("LinkAdd"))}</h4>
                                    {allLanguageMutations && allLanguageMutations.length > 1 &&
                                        <div className="ml-auto">
                                            <select className="form-control" name="selectedLang" onChange={(e) => this.appLinkLib.formHandle(e)}>
                                                {allLanguageMutations.map((item,index) => (
                                                    <option key={index} value={item.suffix}> {item.suffix} </option>
                                                ))}
                                            </select>
                                        </div>
                                    }
                                    <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                        <span aria-hidden="true">x</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {err ?
                                        <Error text={err} />
                                    :
                                        <Fragment>
                                            {!loading ?
                                            <div className="row">

                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <label className="input-label">{translate.t("Active")}</label>
                                                            <div className="form-group">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(active == 1 ?  true : false)} onChange={(e) => this.appLinkLib.formHandle(e)} />
                                                                    <label className="form-check-label" htmlFor="active1">
                                                                    {translate.t("Yes")}
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(active == 0 ?  true : false)} onChange={(e) => this.appLinkLib.formHandle(e)} />
                                                                    <label className="form-check-label" htmlFor="active2">
                                                                    {translate.t("No")}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <label className="input-label">{translate.t("IsTermsAndConditions")}</label>
                                                            <div className="form-group">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="isTermsAndConditions" id="isTermsAndConditions1" value="1" checked={(isTermsAndConditions == 1 ?  true : false)} onChange={(e) => this.appLinkLib.formHandle(e)} />
                                                                    <label className="form-check-label" htmlFor="isTermsAndConditions1">
                                                                    {translate.t("Yes")}
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="isTermsAndConditions" id="isTermsAndConditions2" value="0" checked={(isTermsAndConditions == 0 ?  true : false)} onChange={(e) => this.appLinkLib.formHandle(e)} />
                                                                    <label className="form-check-label" htmlFor="isTermsAndConditions2">
                                                                    {translate.t("No")}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>   
                                                        <div className="col-3">
                                                            <label className="input-label">{translate.t("IsPrivatePolicy")}</label>
                                                            <div className="form-group">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="isPrivatePolicy" id="isPrivatePolicy1" value="1" checked={(isPrivatePolicy == 1 ?  true : false)} onChange={(e) => this.appLinkLib.formHandle(e)} />
                                                                    <label className="form-check-label" htmlFor="isPrivatePolicy1">
                                                                    {translate.t("Yes")}
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="isPrivatePolicy" id="isPrivatePolicy2" value="0" checked={(isPrivatePolicy == 0 ?  true : false)} onChange={(e) => this.appLinkLib.formHandle(e)} />
                                                                    <label className="form-check-label" htmlFor="isPrivatePolicy2">
                                                                    {translate.t("No")}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>                                                         
                                                        <div className="col-3">
                                                            <label className="input-label">{translate.t("ShowInCountry")}</label>
                                                            <div className="form-group"> 
                                                                {this.state.allActiveCountries && this.state.allActiveCountries.map((item,index) => (                                                            
                                                                    <div className="form-check" key={index}>
                                                                        <input className="form-check-input"  type="checkbox" checked={countries.filter((it) => it.countryID == item.countryID).length > 0} onChange={(e) => this.selectCountry(e,item)} id={"country" + item.countryID} />
                                                                        <label className="form-check-label" htmlFor={"country" + item.countryID}>
                                                                            {item.name}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div>

                                                <div className="col-12">

                                                    <label className="input-label">{translate.t("Icon")}</label>

                                                    <div className="d-flex align-items-center justify-content-center">

                                                        {icon != "" &&
                                                            <div className="form-group main-photo-container mr-5">
                                                                
                                                                <div className="one-image" style={{backgroundColor:'rgb(250, 250, 250)',border:"1px solid #ddd"}}>            
                                                                    <img style={{maxHeight:"150px"}} id="main-image" src={icon} />
                                                                </div>

                                                            </div>
                                                        }

                                                        <div className="form-group input-group">
                                                            <div className="custom-file">
                                                                <input type="file" className="custom-file-input" id="customIcon" required onChange={(e) => this.openIcon(e)} />
                                                                <label className="custom-file-label" htmlFor="customIcon">{translate.t("ChooseIcon")}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    

                                                </div>

                                                <div className="col-12">

                                                    <div className="row">

                                                        <div className="col-12 col-sm-6">
                                                        
                                                            <div className="form-group">

                                                                <label className="input-label">*{translate.t("MainPhoto")}</label>
                                                                <div className="form-group input-group">
                                                                    <div className="custom-file">
                                                                        <input type="file" className="custom-file-input" id="customFile" required onChange={(e) => this.openMainImage(e)} />
                                                                        <label className="custom-file-label" htmlFor="customFile">{translate.t("ChoosePhoto")}</label>
                                                                    </div>
                                                                </div>
                                                                {mainPhoto != "" &&
                                                                    <div className="form-group main-photo-container">
                                                                        
                                                                        <div className="one-image">            
                                                                            <img id="main-image" src={mainPhoto} />
                                                                        </div>

                                                                    </div>
                                                                }
                                                            </div>
                                                        
                                                        </div>

                                                        <div className="col-12 col-sm-6">
                                                                                                                                                                        
                                                            <div className="col-12">
                                                                <label className="input-label">{translate.t("AppLinkTitle")} {allLanguageMutations && allLanguageMutations.length > 1 && <Fragment>({selectedLang})</Fragment>}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="name" value={this.appLinkLib.getLangValue("name")} onChange={(e) => this.appLinkLib.formLangHandle(e)} />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-12">
                                                                <label className="input-label">{translate.t("OwnUrlAddress")} {allLanguageMutations && allLanguageMutations.length > 1 && <Fragment>({selectedLang})</Fragment>}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="ownUrl" value={this.appLinkLib.getLangValue("ownUrl")} onChange={(e) => this.appLinkLib.formLangHandle(e)} />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-12">
                                                                <label className="input-label">{translate.t("LinkContent")} {allLanguageMutations && allLanguageMutations.length > 1 && <Fragment>({selectedLang})</Fragment>}</label>
                                                                <div className="form-group">
                                                                    
                                                                    <Editor
                                                                        name="description"
                                                                        apiKey = '9z3phhnyq7jkeeqztql1euovfotvpd2gdduygtdpk14wu4ij'
                                                                        value={this.appLinkLib.getLangValue("description")}
                                                                        init={{
                                                                            plugins: 'link code paste emoticons lists media',
                                                                            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | link | numlist bullist | media',
                                                                            language_url : '/langs/cs.js',
                                                                            language: "cs",
                                                                            paste_as_text: true,
                                                                            menubar:false,
                                                                        }}
                                                                        onEditorChange={(content) => this.appLinkLib.formLangHandleEditor(content,"description")}
                                                                    />
                                                                    
                                                                                                                    
                                                                </div>
                                                            </div>  

                                                        </div>

                                                    </div>
                                                </div>
                                                

                                            </div>

                                            :

                                            <Loading />  

                                            }  
                                        </Fragment>
                                    }
                                            
                                </div>
                                <div className="modal-footer"> 
                                    {!loading && <button type="button" className="btn btn-primary flex-fill" onClick={() => this.appLinkLib.addAppLink(addEditAppLink)}>{(this.props.selectedAppLinkID && this.props.selectedAppLinkID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>}
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>{translate.t("Close")}</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditAppLink);