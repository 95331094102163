import React, { Component } from 'react';
import translate from './index';
import Loading from '../../GlobalComponents/Loading';
import {GET_ADMIN_LANGUAGES} from '../../GlobalQueries/globalQueries';

const withTranslation = (Component,client) => {
	class withTranslation extends Component {
		
			constructor(props){
				super(props);	
				
				this.state = {
					error:'',
					loading:true		  
				}
			}
		
			componentDidMount() {				
				this.setLang();	
			}

			setLang = async () => {
				try {

					
					let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : "";

					if(lang == ""){
						var languageMutations = await client.query({
							query:GET_ADMIN_LANGUAGES
						});
						
						if(languageMutations && languageMutations.data.allAdminLanguageMutations.length > 0){
							lang = languageMutations.data.allAdminLanguageMutations[0].suffix;
						}else{
							lang = 'cz';
						}
						localStorage.setItem('lang',lang);
					}

					translate.locale = lang;

					this.setState({loading:false});
					
				} catch(error) {
					// error reading value
					//this.setState({loading:false});
					alert('Error: ' + error);
				}
			}
			
			render() {
				if(this.state.loading){
					return (
						<Loading />
					);
				}else{
					return (	
						<Component {...this.props} />
					);
				}
					
			}
	}


	return withTranslation;
  
}

export default withTranslation;