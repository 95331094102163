import moment from 'moment';
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';

class Settings {

    constructor(props){
        this.props = props;
    }
   
    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }


    
	calculateOrderItemTotalPrice(mealItem){
		let mealTotalPrice = parseFloat(mealItem.price) + parseFloat(mealItem.packagePrice);

		for(let oio of mealItem.orderOptions){
			mealTotalPrice += oio.price + oio.packagePrice;			
		}
		for(let oisd of mealItem.orderSideDishes){
			mealTotalPrice += oisd.price;			
		}
		for(let oid of mealItem.orderDrinks){
			mealTotalPrice += oid.price;			
		}
		return mealTotalPrice;
    }
    

	reducePricesByCredit(order){
            
        const totalPriceWithoutCreditSubstracted    = order.totalPrice + (order.creditToUse + order.voucherValue);

        if(order.deliveryPrice > 0){				
            const deliveryRatio	    = order.deliveryPrice / totalPriceWithoutCreditSubstracted;
            order.deliveryPrice	-= (order.creditToUse + order.voucherValue) * deliveryRatio;
        }		


        for(let val of order.items){

            val.mealTotalPrice = this.calculateOrderItemTotalPrice({...val});

            let mealRatio 			= val.mealTotalPrice / totalPriceWithoutCreditSubstracted;
            val.creditsToSubstract	= (order.creditToUse + order.voucherValue) * mealRatio;
            
            const mealPriceRatio		= val.price / val.mealTotalPrice;
            val.price					-= val.creditsToSubstract * mealPriceRatio;
            
            for(let oio of val.orderOptions){
                const optionRatio 			= oio.price / val.mealTotalPrice;
                oio.price					-= val.creditsToSubstract * optionRatio;
                const optionPackageRatio 	= oio.packagePrice / val.mealTotalPrice;
                oio.packagePrice			-= val.creditsToSubstract * optionPackageRatio;   
            }

            for(let oisd of val.orderSideDishes){
                const sideDischRatio 	= oisd.price / val.mealTotalPrice;
                oisd.price			-= val.creditsToSubstract * sideDischRatio;  
            }

            for(let oid of val.orderDrinks){
                const drinkRatio 	= oid.price / val.mealTotalPrice;
                oid.price			-= val.creditsToSubstract * drinkRatio;
            }

            if(val.packagePrice != "0.00"){
                const packageRatio 		= val.packagePrice / val.mealTotalPrice;
                val.packagePrice		-= val.creditsToSubstract * packageRatio;
            }

        }

        return order;
		
	}
    calculateSales = (orders) => {
        
        let newSalesData = {
            sales:{
                delivery:{
                    byCardNoVat:0,
                    byCardVat:0,
                    byCardTaxBaseVatValues:{},
                    byCardTaxBaseNoVatValues:{},
                    byCardVatValues:{},
                    cashNoVat:0,
                    cashVat:0,
                    cashTaxBaseVatValues:{},
                    cashTaxBaseNoVatValues:{},
                    cashVatValues:{},
                    totalNoVat:0,
                    totalVat:0,
                    totalTaxBaseVatValues:{},
                    totalTaxBaseNoVatValues:{},
                    totalVatValues:{},
                },            
                eatInTakeAway:{
                    byCardNoVat:0,
                    byCardVat:0,
                    byCardTaxBaseVatValues:{},
                    byCardTaxBaseNoVatValues:{},
                    byCardVatValues:{},
                    cashNoVat:0,
                    cashVat:0,
                    cashTaxBaseVatValues:{},
                    cashTaxBaseNoVatValues:{},
                    cashVatValues:{},
                    totalNoVat:0,
                    totalVat:0,
                    totalTaxBaseVatValues:{},
                    totalTaxBaseNoVatValues:{},
                    totalVatValues:{},
                }
            },
            canceled:{
                delivery:{
                    byCardNoVat:0,
                    byCardVat:0,
                    byCardTaxBaseVatValues:{},
                    byCardTaxBaseNoVatValues:{},
                    byCardVatValues:{},
                    cashNoVat:0,
                    cashVat:0,
                    cashTaxBaseVatValues:{},
                    cashTaxBaseNoVatValues:{},
                    cashVatValues:{},
                    totalNoVat:0,
                    totalVat:0,
                    totalTaxBaseVatValues:{},
                    totalTaxBaseNoVatValues:{},
                    totalVatValues:{},
                },            
                eatInTakeAway:{
                    byCardNoVat:0,
                    byCardVat:0,
                    byCardTaxBaseVatValues:{},
                    byCardTaxBaseNoVatValues:{},
                    byCardVatValues:{},
                    cashNoVat:0,
                    cashVat:0,
                    cashTaxBaseVatValues:{},
                    cashTaxBaseNoVatValues:{},
                    cashVatValues:{},
                    totalNoVat:0,
                    totalVat:0,
                    totalTaxBaseVatValues:{},
                    totalTaxBaseNoVatValues:{},
                    totalVatValues:{},
                }
            },
            orderDistributedOverTime:{
                "7" :{price:0,count:0},
                "8" :{price:0,count:0},
                "9" :{price:0,count:0},
                "10":{price:0,count:0},
                "11":{price:0,count:0},
                "12":{price:0,count:0},
                "13":{price:0,count:0},
                "14":{price:0,count:0},
                "15":{price:0,count:0},
                "16":{price:0,count:0},
                "17":{price:0,count:0},
                "18":{price:0,count:0},
                "19":{price:0,count:0},
                "20":{price:0,count:0},
                "21":{price:0,count:0},
                "22":{price:0,count:0},
                "23":{price:0,count:0}
            },
            orderCount: 0,
            orderPriceSum:0,
            averageOrderPrice:0,
            notFinishedOrderCount: 0,
            notFinishedOrderPriceSum:0,
            notFinishedAverageOrderPrice:0
        }

        if(orders){
            for(let orderItem of orders){ 
                
                if(orderItem.orderStatusID != 7){
                    newSalesData.orderCount ++;
                    newSalesData.orderPriceSum += orderItem.totalPrice;
                    newSalesData.averageOrderPrice  = Math.round(newSalesData.orderPriceSum / newSalesData.orderCount);
                }else{ 
                    newSalesData.notFinishedOrderCount ++;
                    newSalesData.notFinishedOrderPriceSum += orderItem.totalPrice;
                    newSalesData.notFinishedAverageOrderPrice  = Math.round(newSalesData.notFinishedOrderPriceSum / newSalesData.notFinishedOrderCount);
                }

                if((orderItem.creditToUse + orderItem.voucherValue) > 0){
                    orderItem = this.reducePricesByCredit(orderItem);
                }
                if(newSalesData.orderDistributedOverTime[moment(orderItem.dateAdd).format("H")]){
                newSalesData.orderDistributedOverTime[moment(orderItem.dateAdd).format("H")].count += 1;
                newSalesData.orderDistributedOverTime[moment(orderItem.dateAdd).format("H")].price += orderItem.totalPrice;
            }else{
                console.log(orderItem.dateAdd);
            }
                if(orderItem.orderStatusID != 7){
                    if(orderItem.deliveryPrice){

                        const deliveryPriceNoVat  = ((orderItem.deliveryPrice)/(1 + (orderItem.deliveryPriceVat/100)));
                        const deliveryPrice       = orderItem.deliveryPrice;   
                        

                        if(orderItem.shippingTypeID == 1 || orderItem.shippingTypeID == 2){
                            if(orderItem.paymentTypeID == 1){
                                newSalesData.sales.eatInTakeAway.byCardNoVat   += deliveryPriceNoVat;
                                newSalesData.sales.eatInTakeAway.byCardVat     += deliveryPrice;  
                                newSalesData.sales.eatInTakeAway.byCardTaxBaseVatValues[orderItem.deliveryPriceVat] ?   newSalesData.sales.eatInTakeAway.byCardTaxBaseVatValues[orderItem.deliveryPriceVat]     += deliveryPrice : newSalesData.sales.eatInTakeAway.byCardTaxBaseVatValues[orderItem.deliveryPriceVat]                  = deliveryPrice;       
                                newSalesData.sales.eatInTakeAway.byCardTaxBaseNoVatValues[orderItem.deliveryPriceVat] ? newSalesData.sales.eatInTakeAway.byCardTaxBaseNoVatValues[orderItem.deliveryPriceVat]   += deliveryPriceNoVat : newSalesData.sales.eatInTakeAway.byCardTaxBaseNoVatValues[orderItem.deliveryPriceVat]           = deliveryPriceNoVat;       
                                newSalesData.sales.eatInTakeAway.byCardVatValues[orderItem.deliveryPriceVat] ?          newSalesData.sales.eatInTakeAway.byCardVatValues[orderItem.deliveryPriceVat]            += (deliveryPrice - deliveryPriceNoVat) : newSalesData.sales.eatInTakeAway.byCardVatValues[orderItem.deliveryPriceVat]  = (deliveryPrice - deliveryPriceNoVat);       
                            }else if(orderItem.paymentTypeID == 2){
                                newSalesData.sales.eatInTakeAway.cashNoVat     += deliveryPriceNoVat;
                                newSalesData.sales.eatInTakeAway.cashVat       += deliveryPrice;  
                                newSalesData.sales.eatInTakeAway.cashTaxBaseVatValues[orderItem.deliveryPriceVat] ?     newSalesData.sales.eatInTakeAway.cashTaxBaseVatValues[orderItem.deliveryPriceVat]   += deliveryPrice : newSalesData.sales.eatInTakeAway.cashTaxBaseVatValues[orderItem.deliveryPriceVat]                    = deliveryPrice;       
                                newSalesData.sales.eatInTakeAway.cashTaxBaseNoVatValues[orderItem.deliveryPriceVat] ?   newSalesData.sales.eatInTakeAway.cashTaxBaseNoVatValues[orderItem.deliveryPriceVat] += deliveryPriceNoVat : newSalesData.sales.eatInTakeAway.cashTaxBaseNoVatValues[orderItem.deliveryPriceVat]             = deliveryPriceNoVat;       
                                newSalesData.sales.eatInTakeAway.cashVatValues[orderItem.deliveryPriceVat] ?            newSalesData.sales.eatInTakeAway.cashVatValues[orderItem.deliveryPriceVat]          += (deliveryPrice - deliveryPriceNoVat) : newSalesData.sales.eatInTakeAway.cashVatValues[orderItem.deliveryPriceVat]    = (deliveryPrice - deliveryPriceNoVat);  
                            }
                            newSalesData.sales.eatInTakeAway.totalNoVat        += deliveryPriceNoVat;
                            newSalesData.sales.eatInTakeAway.totalVat          += deliveryPrice;   
                            newSalesData.sales.eatInTakeAway.totalTaxBaseVatValues[orderItem.deliveryPriceVat] ?     newSalesData.sales.eatInTakeAway.totalTaxBaseVatValues[orderItem.deliveryPriceVat]     += deliveryPrice : newSalesData.sales.eatInTakeAway.totalTaxBaseVatValues[orderItem.deliveryPriceVat]                       = deliveryPrice;       
                            newSalesData.sales.eatInTakeAway.totalTaxBaseNoVatValues[orderItem.deliveryPriceVat] ?   newSalesData.sales.eatInTakeAway.totalTaxBaseNoVatValues[orderItem.deliveryPriceVat]   += deliveryPriceNoVat : newSalesData.sales.eatInTakeAway.totalTaxBaseNoVatValues[orderItem.deliveryPriceVat]                = deliveryPriceNoVat;       
                            newSalesData.sales.eatInTakeAway.totalVatValues[orderItem.deliveryPriceVat] ?            newSalesData.sales.eatInTakeAway.totalVatValues[orderItem.deliveryPriceVat]            += (deliveryPrice - deliveryPriceNoVat) : newSalesData.sales.eatInTakeAway.totalVatValues[orderItem.deliveryPriceVat]       = (deliveryPrice - deliveryPriceNoVat);  
                        }else if(orderItem.shippingTypeID == 3 || orderItem.shippingTypeID == 4){
                            if(orderItem.paymentTypeID == 1){
                                newSalesData.sales.delivery.byCardNoVat        += deliveryPriceNoVat;
                                newSalesData.sales.delivery.byCardVat          += deliveryPrice;  
                                newSalesData.sales.delivery.byCardTaxBaseVatValues[orderItem.deliveryPriceVat] ?   newSalesData.sales.delivery.byCardTaxBaseVatValues[orderItem.deliveryPriceVat]       += deliveryPrice : newSalesData.sales.delivery.byCardTaxBaseVatValues[orderItem.deliveryPriceVat]                   = deliveryPrice;       
                                newSalesData.sales.delivery.byCardTaxBaseNoVatValues[orderItem.deliveryPriceVat] ? newSalesData.sales.delivery.byCardTaxBaseNoVatValues[orderItem.deliveryPriceVat]     += deliveryPriceNoVat : newSalesData.sales.delivery.byCardTaxBaseNoVatValues[orderItem.deliveryPriceVat]            = deliveryPriceNoVat;       
                                newSalesData.sales.delivery.byCardVatValues[orderItem.deliveryPriceVat] ?          newSalesData.sales.delivery.byCardVatValues[orderItem.deliveryPriceVat]              += (deliveryPrice - deliveryPriceNoVat) : newSalesData.sales.delivery.byCardVatValues[orderItem.deliveryPriceVat]   = (deliveryPrice - deliveryPriceNoVat);        
                            }else if(orderItem.paymentTypeID == 2){
                                newSalesData.sales.delivery.cashNoVat          += deliveryPriceNoVat;
                                newSalesData.sales.delivery.cashVat            += deliveryPrice;   
                                newSalesData.sales.delivery.cashTaxBaseVatValues[orderItem.deliveryPriceVat] ?     newSalesData.sales.delivery.cashTaxBaseVatValues[orderItem.deliveryPriceVat]     += deliveryPrice : newSalesData.sales.delivery.cashTaxBaseVatValues[orderItem.deliveryPriceVat]                     = deliveryPrice;       
                                newSalesData.sales.delivery.cashTaxBaseNoVatValues[orderItem.deliveryPriceVat] ?   newSalesData.sales.delivery.cashTaxBaseNoVatValues[orderItem.deliveryPriceVat]   += deliveryPriceNoVat : newSalesData.sales.delivery.cashTaxBaseNoVatValues[orderItem.deliveryPriceVat]              = deliveryPriceNoVat;       
                                newSalesData.sales.delivery.cashVatValues[orderItem.deliveryPriceVat] ?            newSalesData.sales.delivery.cashVatValues[orderItem.deliveryPriceVat]            += (deliveryPrice - deliveryPriceNoVat) : newSalesData.sales.delivery.cashVatValues[orderItem.deliveryPriceVat]     = (deliveryPrice - deliveryPriceNoVat);  
                            }
                            newSalesData.sales.delivery.totalNoVat             += deliveryPriceNoVat;
                            newSalesData.sales.delivery.totalVat               += deliveryPrice;   
                            newSalesData.sales.delivery.totalTaxBaseVatValues[orderItem.deliveryPriceVat] ?     newSalesData.sales.delivery.totalTaxBaseVatValues[orderItem.deliveryPriceVat]   += deliveryPrice : newSalesData.sales.delivery.totalTaxBaseVatValues[orderItem.deliveryPriceVat]                    = deliveryPrice;       
                            newSalesData.sales.delivery.totalTaxBaseNoVatValues[orderItem.deliveryPriceVat] ?   newSalesData.sales.delivery.totalTaxBaseNoVatValues[orderItem.deliveryPriceVat] += deliveryPriceNoVat : newSalesData.sales.delivery.totalTaxBaseNoVatValues[orderItem.deliveryPriceVat]             = deliveryPriceNoVat;       
                            newSalesData.sales.delivery.totalVatValues[orderItem.deliveryPriceVat] ?            newSalesData.sales.delivery.totalVatValues[orderItem.deliveryPriceVat]          += (deliveryPrice - deliveryPriceNoVat) : newSalesData.sales.delivery.totalVatValues[orderItem.deliveryPriceVat]    = (deliveryPrice - deliveryPriceNoVat);  
                        }
                    }

                    for(let item of orderItem.items){
                        const [noVatPrice,vatPrice,vatValues,taxBaseVatValues,taxBaseNoVatValues]                  = this.getItemPrices(item);
                        if(orderItem.shippingTypeID == 1 || orderItem.shippingTypeID == 2){
                            if(orderItem.paymentTypeID == 1){
                                newSalesData.sales.eatInTakeAway.byCardNoVat   += noVatPrice;
                                newSalesData.sales.eatInTakeAway.byCardVat     += vatPrice;    
                                for(const vatIndex in taxBaseVatValues){
                                    newSalesData.sales.eatInTakeAway.byCardTaxBaseVatValues[vatIndex] ? newSalesData.sales.eatInTakeAway.byCardTaxBaseVatValues[vatIndex] += taxBaseVatValues[vatIndex] : newSalesData.sales.eatInTakeAway.byCardTaxBaseVatValues[vatIndex] = taxBaseVatValues[vatIndex];       
                                }  
                                for(const vatIndex in taxBaseNoVatValues){
                                    newSalesData.sales.eatInTakeAway.byCardTaxBaseNoVatValues[vatIndex] ? newSalesData.sales.eatInTakeAway.byCardTaxBaseNoVatValues[vatIndex] += taxBaseNoVatValues[vatIndex] : newSalesData.sales.eatInTakeAway.byCardTaxBaseNoVatValues[vatIndex] = taxBaseNoVatValues[vatIndex];       
                                }
                                for(const vatIndex in vatValues){
                                    newSalesData.sales.eatInTakeAway.byCardVatValues[vatIndex] ? newSalesData.sales.eatInTakeAway.byCardVatValues[vatIndex] += vatValues[vatIndex] : newSalesData.sales.eatInTakeAway.byCardVatValues[vatIndex] = vatValues[vatIndex];       
                                }
                            }else if(orderItem.paymentTypeID == 2){
                                newSalesData.sales.eatInTakeAway.cashNoVat     += noVatPrice;
                                newSalesData.sales.eatInTakeAway.cashVat       += vatPrice;    
                                for(const vatIndex in taxBaseVatValues){
                                    newSalesData.sales.eatInTakeAway.cashTaxBaseVatValues[vatIndex] ? newSalesData.sales.eatInTakeAway.cashTaxBaseVatValues[vatIndex] += taxBaseVatValues[vatIndex] : newSalesData.sales.eatInTakeAway.cashTaxBaseVatValues[vatIndex] = taxBaseVatValues[vatIndex];       
                                }  
                                for(const vatIndex in taxBaseNoVatValues){
                                    newSalesData.sales.eatInTakeAway.cashTaxBaseNoVatValues[vatIndex] ? newSalesData.sales.eatInTakeAway.cashTaxBaseNoVatValues[vatIndex] += taxBaseNoVatValues[vatIndex] : newSalesData.sales.eatInTakeAway.cashTaxBaseNoVatValues[vatIndex] = taxBaseNoVatValues[vatIndex];       
                                }   
                                for(const vatIndex in vatValues){
                                    newSalesData.sales.eatInTakeAway.cashVatValues[vatIndex] ? newSalesData.sales.eatInTakeAway.cashVatValues[vatIndex] += vatValues[vatIndex] : newSalesData.sales.eatInTakeAway.cashVatValues[vatIndex] = vatValues[vatIndex];       
                                }
                            }
                            newSalesData.sales.eatInTakeAway.totalNoVat        += noVatPrice;
                            newSalesData.sales.eatInTakeAway.totalVat          += vatPrice;    
                            for(const vatIndex in taxBaseVatValues){
                                newSalesData.sales.eatInTakeAway.totalTaxBaseVatValues[vatIndex] ? newSalesData.sales.eatInTakeAway.totalTaxBaseVatValues[vatIndex] += taxBaseVatValues[vatIndex] : newSalesData.sales.eatInTakeAway.totalTaxBaseVatValues[vatIndex] = taxBaseVatValues[vatIndex];       
                            }  
                            for(const vatIndex in taxBaseNoVatValues){
                                newSalesData.sales.eatInTakeAway.totalTaxBaseNoVatValues[vatIndex] ? newSalesData.sales.eatInTakeAway.totalTaxBaseNoVatValues[vatIndex] += taxBaseNoVatValues[vatIndex] : newSalesData.sales.eatInTakeAway.totalTaxBaseNoVatValues[vatIndex] = taxBaseNoVatValues[vatIndex];       
                            }  
                            for(const vatIndex in vatValues){
                                newSalesData.sales.eatInTakeAway.totalVatValues[vatIndex] ? newSalesData.sales.eatInTakeAway.totalVatValues[vatIndex] += vatValues[vatIndex] : newSalesData.sales.eatInTakeAway.totalVatValues[vatIndex] = vatValues[vatIndex];       
                            } 
                        }else if(orderItem.shippingTypeID == 3 || orderItem.shippingTypeID == 4){
                            if(orderItem.paymentTypeID == 1){
                                newSalesData.sales.delivery.byCardNoVat        += noVatPrice;
                                newSalesData.sales.delivery.byCardVat          += vatPrice;      
                                for(const vatIndex in taxBaseVatValues){
                                    newSalesData.sales.delivery.byCardTaxBaseVatValues[vatIndex] ? newSalesData.sales.delivery.byCardTaxBaseVatValues[vatIndex] += taxBaseVatValues[vatIndex] : newSalesData.sales.delivery.byCardTaxBaseVatValues[vatIndex] = taxBaseVatValues[vatIndex];       
                                }  
                                for(const vatIndex in taxBaseNoVatValues){
                                    newSalesData.sales.delivery.byCardTaxBaseNoVatValues[vatIndex] ? newSalesData.sales.delivery.byCardTaxBaseNoVatValues[vatIndex] += taxBaseNoVatValues[vatIndex] : newSalesData.sales.delivery.byCardTaxBaseNoVatValues[vatIndex] = taxBaseNoVatValues[vatIndex];       
                                }     
                                for(const vatIndex in vatValues){
                                    newSalesData.sales.delivery.byCardVatValues[vatIndex] ? newSalesData.sales.delivery.byCardVatValues[vatIndex] += vatValues[vatIndex] : newSalesData.sales.delivery.byCardVatValues[vatIndex] = vatValues[vatIndex];       
                                }
                            }else if(orderItem.paymentTypeID == 2){
                                newSalesData.sales.delivery.cashNoVat          += noVatPrice;
                                newSalesData.sales.delivery.cashVat            += vatPrice;   
                                for(const vatIndex in taxBaseVatValues){
                                    newSalesData.sales.delivery.cashTaxBaseVatValues[vatIndex] ? newSalesData.sales.delivery.cashTaxBaseVatValues[vatIndex] += taxBaseVatValues[vatIndex] : newSalesData.sales.delivery.cashTaxBaseVatValues[vatIndex] = taxBaseVatValues[vatIndex];       
                                }  
                                for(const vatIndex in taxBaseNoVatValues){
                                    newSalesData.sales.delivery.cashTaxBaseNoVatValues[vatIndex] ? newSalesData.sales.delivery.cashTaxBaseNoVatValues[vatIndex] += taxBaseNoVatValues[vatIndex] : newSalesData.sales.delivery.cashTaxBaseNoVatValues[vatIndex] = taxBaseNoVatValues[vatIndex];       
                                }    
                                for(const vatIndex in vatValues){
                                    newSalesData.sales.delivery.cashVatValues[vatIndex] ? newSalesData.sales.delivery.cashVatValues[vatIndex] += vatValues[vatIndex] : newSalesData.sales.delivery.cashVatValues[vatIndex] = vatValues[vatIndex];       
                                }
                            }
                            newSalesData.sales.delivery.totalNoVat             += noVatPrice;
                            newSalesData.sales.delivery.totalVat               += vatPrice;     
                            for(const vatIndex in taxBaseVatValues){
                                newSalesData.sales.delivery.totalTaxBaseVatValues[vatIndex] ? newSalesData.sales.delivery.totalTaxBaseVatValues[vatIndex] += taxBaseVatValues[vatIndex] : newSalesData.sales.delivery.totalTaxBaseVatValues[vatIndex] = taxBaseVatValues[vatIndex];       
                            }  
                            for(const vatIndex in taxBaseNoVatValues){
                                newSalesData.sales.delivery.totalTaxBaseNoVatValues[vatIndex] ? newSalesData.sales.delivery.totalTaxBaseNoVatValues[vatIndex] += taxBaseNoVatValues[vatIndex] : newSalesData.sales.delivery.totalTaxBaseNoVatValues[vatIndex] = taxBaseNoVatValues[vatIndex];       
                            }  
                            for(const vatIndex in vatValues){
                                newSalesData.sales.delivery.totalVatValues[vatIndex] ? newSalesData.sales.delivery.totalVatValues[vatIndex] += vatValues[vatIndex] : newSalesData.sales.delivery.totalVatValues[vatIndex] = vatValues[vatIndex];       
                            } 
                        }
                    }
                }else{

                    if(orderItem.deliveryPrice){

                        const deliveryPriceNoVat  = ((orderItem.deliveryPrice)/(1 + (orderItem.deliveryPriceVat/100)));
                        const deliveryPrice       = orderItem.deliveryPrice;   

                        if(orderItem.shippingTypeID == 1 || orderItem.shippingTypeID == 2){
                            if(orderItem.paymentTypeID == 1){
                                newSalesData.canceled.eatInTakeAway.byCardNoVat   += deliveryPriceNoVat;
                                newSalesData.canceled.eatInTakeAway.byCardVat     += deliveryPrice;  
                                newSalesData.canceled.eatInTakeAway.byCardTaxBaseVatValues[orderItem.deliveryPriceVat]      ? newSalesData.canceled.eatInTakeAway.byCardTaxBaseVatValues[orderItem.deliveryPriceVat]    += deliveryPrice : newSalesData.canceled.eatInTakeAway.byCardTaxBaseVatValues[orderItem.deliveryPriceVat]                   = deliveryPrice;       
                                newSalesData.canceled.eatInTakeAway.byCardTaxBaseNoVatValues[orderItem.deliveryPriceVat]    ? newSalesData.canceled.eatInTakeAway.byCardTaxBaseNoVatValues[orderItem.deliveryPriceVat]  += deliveryPriceNoVat : newSalesData.canceled.eatInTakeAway.byCardTaxBaseNoVatValues[orderItem.deliveryPriceVat]            = deliveryPriceNoVat;       
                                newSalesData.canceled.eatInTakeAway.byCardVatValues[orderItem.deliveryPriceVat]             ? newSalesData.canceled.eatInTakeAway.byCardVatValues[orderItem.deliveryPriceVat]           += (deliveryPrice - deliveryPriceNoVat) : newSalesData.canceled.eatInTakeAway.byCardVatValues[orderItem.deliveryPriceVat]   = (deliveryPrice - deliveryPriceNoVat);            
                            }else if(orderItem.paymentTypeID == 2){
                                newSalesData.canceled.eatInTakeAway.cashNoVat     += deliveryPriceNoVat;
                                newSalesData.canceled.eatInTakeAway.cashVat       += deliveryPrice;  
                                newSalesData.canceled.eatInTakeAway.cashTaxBaseVatValues[orderItem.deliveryPriceVat]    ? newSalesData.canceled.eatInTakeAway.cashTaxBaseVatValues[orderItem.deliveryPriceVat]      += deliveryPrice : newSalesData.canceled.eatInTakeAway.cashTaxBaseVatValues[orderItem.deliveryPriceVat]                     = deliveryPrice;       
                                newSalesData.canceled.eatInTakeAway.cashTaxBaseNoVatValues[orderItem.deliveryPriceVat]  ? newSalesData.canceled.eatInTakeAway.cashTaxBaseNoVatValues[orderItem.deliveryPriceVat]    += deliveryPriceNoVat : newSalesData.canceled.eatInTakeAway.cashTaxBaseNoVatValues[orderItem.deliveryPriceVat]              = deliveryPriceNoVat;       
                                newSalesData.canceled.eatInTakeAway.cashVatValues[orderItem.deliveryPriceVat]           ? newSalesData.canceled.eatInTakeAway.cashVatValues[orderItem.deliveryPriceVat]             += (deliveryPrice - deliveryPriceNoVat) : newSalesData.canceled.eatInTakeAway.cashVatValues[orderItem.deliveryPriceVat]     = (deliveryPrice - deliveryPriceNoVat);  
                            }
                            newSalesData.canceled.eatInTakeAway.totalNoVat        += deliveryPriceNoVat;
                            newSalesData.canceled.eatInTakeAway.totalVat          += deliveryPrice;  
                            newSalesData.canceled.eatInTakeAway.totalTaxBaseVatValues[orderItem.deliveryPriceVat]   ? newSalesData.canceled.eatInTakeAway.totalTaxBaseVatValues[orderItem.deliveryPriceVat]     += deliveryPrice : newSalesData.canceled.eatInTakeAway.totalTaxBaseVatValues[orderItem.deliveryPriceVat]                    = deliveryPrice;       
                            newSalesData.canceled.eatInTakeAway.totalTaxBaseNoVatValues[orderItem.deliveryPriceVat] ? newSalesData.canceled.eatInTakeAway.totalTaxBaseNoVatValues[orderItem.deliveryPriceVat]   += deliveryPriceNoVat : newSalesData.canceled.eatInTakeAway.totalTaxBaseNoVatValues[orderItem.deliveryPriceVat]             = deliveryPriceNoVat;       
                            newSalesData.canceled.eatInTakeAway.totalVatValues[orderItem.deliveryPriceVat]          ? newSalesData.canceled.eatInTakeAway.totalVatValues[orderItem.deliveryPriceVat]            += (deliveryPrice - deliveryPriceNoVat) : newSalesData.canceled.eatInTakeAway.totalVatValues[orderItem.deliveryPriceVat]    = (deliveryPrice - deliveryPriceNoVat);   
                        }else if(orderItem.shippingTypeID == 3 || orderItem.shippingTypeID == 4){
                            if(orderItem.paymentTypeID == 1){
                                newSalesData.canceled.delivery.byCardNoVat        += deliveryPriceNoVat;
                                newSalesData.canceled.delivery.byCardVat          += deliveryPrice;   
                                newSalesData.canceled.delivery.byCardTaxBaseVatValues[orderItem.deliveryPriceVat]   ? newSalesData.canceled.delivery.byCardTaxBaseVatValues[orderItem.deliveryPriceVat]     += deliveryPrice : newSalesData.canceled.delivery.byCardTaxBaseVatValues[orderItem.deliveryPriceVat]                    = deliveryPrice;       
                                newSalesData.canceled.delivery.byCardTaxBaseNoVatValues[orderItem.deliveryPriceVat] ? newSalesData.canceled.delivery.byCardTaxBaseNoVatValues[orderItem.deliveryPriceVat]   += deliveryPriceNoVat : newSalesData.canceled.delivery.byCardTaxBaseNoVatValues[orderItem.deliveryPriceVat]             = deliveryPriceNoVat;       
                                newSalesData.canceled.delivery.byCardVatValues[orderItem.deliveryPriceVat]          ? newSalesData.canceled.delivery.byCardVatValues[orderItem.deliveryPriceVat]            += (deliveryPrice - deliveryPriceNoVat) : newSalesData.canceled.delivery.byCardVatValues[orderItem.deliveryPriceVat]    = (deliveryPrice - deliveryPriceNoVat);         
                            }else if(orderItem.paymentTypeID == 2){
                                newSalesData.canceled.delivery.cashNoVat          += deliveryPriceNoVat;
                                newSalesData.canceled.delivery.cashVat            += deliveryPrice; 
                                newSalesData.canceled.delivery.cashTaxBaseVatValues[orderItem.deliveryPriceVat]     ? newSalesData.canceled.delivery.cashTaxBaseVatValues[orderItem.deliveryPriceVat]     += deliveryPrice : newSalesData.canceled.delivery.cashTaxBaseVatValues[orderItem.deliveryPriceVat]                    = deliveryPrice;       
                                newSalesData.canceled.delivery.cashTaxBaseNoVatValues[orderItem.deliveryPriceVat]   ? newSalesData.canceled.delivery.cashTaxBaseNoVatValues[orderItem.deliveryPriceVat]   += deliveryPriceNoVat : newSalesData.canceled.delivery.cashTaxBaseNoVatValues[orderItem.deliveryPriceVat]             = deliveryPriceNoVat;       
                                newSalesData.canceled.delivery.cashVatValues[orderItem.deliveryPriceVat]            ? newSalesData.canceled.delivery.cashVatValues[orderItem.deliveryPriceVat]            += (deliveryPrice - deliveryPriceNoVat) : newSalesData.canceled.delivery.cashVatValues[orderItem.deliveryPriceVat]    = (deliveryPrice - deliveryPriceNoVat);    
                            }
                            newSalesData.canceled.delivery.totalNoVat             += deliveryPriceNoVat;
                            newSalesData.canceled.delivery.totalVat               += deliveryPrice; 
                            newSalesData.canceled.delivery.totalTaxBaseVatValues[orderItem.deliveryPriceVat]    ? newSalesData.canceled.delivery.totalTaxBaseVatValues[orderItem.deliveryPriceVat]      += deliveryPrice : newSalesData.canceled.delivery.totalTaxBaseVatValues[orderItem.deliveryPriceVat]                     = deliveryPrice;       
                            newSalesData.canceled.delivery.totalTaxBaseNoVatValues[orderItem.deliveryPriceVat]  ? newSalesData.canceled.delivery.totalTaxBaseNoVatValues[orderItem.deliveryPriceVat]    += deliveryPriceNoVat : newSalesData.canceled.delivery.totalTaxBaseNoVatValues[orderItem.deliveryPriceVat]              = deliveryPriceNoVat;       
                            newSalesData.canceled.delivery.totalVatValues[orderItem.deliveryPriceVat]           ? newSalesData.canceled.delivery.totalVatValues[orderItem.deliveryPriceVat]             += (deliveryPrice - deliveryPriceNoVat) : newSalesData.canceled.delivery.totalVatValues[orderItem.deliveryPriceVat]     = (deliveryPrice - deliveryPriceNoVat);    
                        }
                    }

                    for(let item of orderItem.items){
                        const [noVatPrice,vatPrice,vatValues,taxBaseVatValues,taxBaseNoVatValues]                     = this.getItemPrices(item);
                        if(orderItem.shippingTypeID == 1 || orderItem.shippingTypeID == 2){
                            if(orderItem.paymentTypeID == 1){
                                newSalesData.canceled.eatInTakeAway.byCardNoVat   += noVatPrice;
                                newSalesData.canceled.eatInTakeAway.byCardVat     += vatPrice;                                      
                                for(const vatIndex in taxBaseVatValues){
                                    newSalesData.canceled.eatInTakeAway.byCardTaxBaseVatValues[vatIndex] ? newSalesData.canceled.eatInTakeAway.byCardTaxBaseVatValues[vatIndex] += taxBaseVatValues[vatIndex] : newSalesData.canceled.eatInTakeAway.byCardTaxBaseVatValues[vatIndex] = taxBaseVatValues[vatIndex];       
                                }  
                                for(const vatIndex in taxBaseNoVatValues){
                                    newSalesData.canceled.eatInTakeAway.byCardTaxBaseNoVatValues[vatIndex] ? newSalesData.canceled.eatInTakeAway.byCardTaxBaseNoVatValues[vatIndex] += taxBaseNoVatValues[vatIndex] : newSalesData.canceled.eatInTakeAway.byCardTaxBaseNoVatValues[vatIndex] = taxBaseNoVatValues[vatIndex];       
                                }
                                for(const vatIndex in vatValues){
                                    newSalesData.canceled.eatInTakeAway.byCardVatValues[vatIndex] ? newSalesData.canceled.eatInTakeAway.byCardVatValues[vatIndex] += vatValues[vatIndex] : newSalesData.canceled.eatInTakeAway.byCardVatValues[vatIndex] = vatValues[vatIndex];       
                                }  
                            }else if(orderItem.paymentTypeID == 2){
                                newSalesData.canceled.eatInTakeAway.cashNoVat     += noVatPrice;
                                newSalesData.canceled.eatInTakeAway.cashVat       += vatPrice;    
                                for(const vatIndex in taxBaseVatValues){
                                    newSalesData.canceled.eatInTakeAway.cashTaxBaseVatValues[vatIndex] ? newSalesData.canceled.eatInTakeAway.cashTaxBaseVatValues[vatIndex] += taxBaseVatValues[vatIndex] : newSalesData.canceled.eatInTakeAway.cashTaxBaseVatValues[vatIndex] = taxBaseVatValues[vatIndex];       
                                }  
                                for(const vatIndex in taxBaseNoVatValues){
                                    newSalesData.canceled.eatInTakeAway.cashTaxBaseNoVatValues[vatIndex] ? newSalesData.canceled.eatInTakeAway.cashTaxBaseNoVatValues[vatIndex] += taxBaseNoVatValues[vatIndex] : newSalesData.canceled.eatInTakeAway.cashTaxBaseNoVatValues[vatIndex] = taxBaseNoVatValues[vatIndex];       
                                }                                   
                                for(const vatIndex in vatValues){
                                    newSalesData.canceled.eatInTakeAway.cashVatValues[vatIndex] ? newSalesData.canceled.eatInTakeAway.cashVatValues[vatIndex] += vatValues[vatIndex] : newSalesData.canceled.eatInTakeAway.cashVatValues[vatIndex] = vatValues[vatIndex];       
                                }
                            }
                            newSalesData.canceled.eatInTakeAway.totalNoVat        += noVatPrice;
                            newSalesData.canceled.eatInTakeAway.totalVat          += vatPrice;    
                            for(const vatIndex in taxBaseVatValues){
                                newSalesData.canceled.eatInTakeAway.totalTaxBaseVatValues[vatIndex] ? newSalesData.canceled.eatInTakeAway.totalTaxBaseVatValues[vatIndex] += taxBaseVatValues[vatIndex] : newSalesData.canceled.eatInTakeAway.totalTaxBaseVatValues[vatIndex] = taxBaseVatValues[vatIndex];       
                            }  
                            for(const vatIndex in taxBaseNoVatValues){
                                newSalesData.canceled.eatInTakeAway.totalTaxBaseNoVatValues[vatIndex] ? newSalesData.canceled.eatInTakeAway.totalTaxBaseNoVatValues[vatIndex] += taxBaseNoVatValues[vatIndex] : newSalesData.canceled.eatInTakeAway.totalTaxBaseNoVatValues[vatIndex] = taxBaseNoVatValues[vatIndex];       
                            }      
                            for(const vatIndex in vatValues){
                                newSalesData.canceled.eatInTakeAway.totalVatValues[vatIndex] ? newSalesData.canceled.eatInTakeAway.totalVatValues[vatIndex] += vatValues[vatIndex] : newSalesData.canceled.eatInTakeAway.totalVatValues[vatIndex] = vatValues[vatIndex];       
                            } 
                        }else if(orderItem.shippingTypeID == 3 || orderItem.shippingTypeID == 4){
                            if(orderItem.paymentTypeID == 1){
                                newSalesData.canceled.delivery.byCardNoVat        += noVatPrice;
                                newSalesData.canceled.delivery.byCardVat          += vatPrice;         
                                for(const vatIndex in taxBaseVatValues){
                                    newSalesData.canceled.delivery.byCardTaxBaseVatValues[vatIndex] ? newSalesData.canceled.delivery.byCardTaxBaseVatValues[vatIndex] += taxBaseVatValues[vatIndex] : newSalesData.canceled.delivery.byCardTaxBaseVatValues[vatIndex] = taxBaseVatValues[vatIndex];       
                                }  
                                for(const vatIndex in taxBaseNoVatValues){
                                    newSalesData.canceled.delivery.byCardTaxBaseNoVatValues[vatIndex] ? newSalesData.canceled.delivery.byCardTaxBaseNoVatValues[vatIndex] += taxBaseNoVatValues[vatIndex] : newSalesData.canceled.delivery.byCardTaxBaseNoVatValues[vatIndex] = taxBaseNoVatValues[vatIndex];       
                                }     
                                for(const vatIndex in vatValues){
                                    newSalesData.canceled.delivery.byCardVatValues[vatIndex] ? newSalesData.canceled.delivery.byCardVatValues[vatIndex] += vatValues[vatIndex] : newSalesData.canceled.delivery.byCardVatValues[vatIndex] = vatValues[vatIndex];       
                                }
                            }else if(orderItem.paymentTypeID == 2){
                                newSalesData.canceled.delivery.cashNoVat          += noVatPrice;
                                newSalesData.canceled.delivery.cashVat            += vatPrice;     
                                for(const vatIndex in taxBaseVatValues){
                                    newSalesData.canceled.delivery.cashTaxBaseVatValues[vatIndex] ? newSalesData.canceled.delivery.cashTaxBaseVatValues[vatIndex] += taxBaseVatValues[vatIndex] : newSalesData.canceled.delivery.cashTaxBaseVatValues[vatIndex] = taxBaseVatValues[vatIndex];       
                                }  
                                for(const vatIndex in taxBaseNoVatValues){
                                    newSalesData.canceled.delivery.cashTaxBaseNoVatValues[vatIndex] ? newSalesData.canceled.delivery.cashTaxBaseNoVatValues[vatIndex] += taxBaseNoVatValues[vatIndex] : newSalesData.canceled.delivery.cashTaxBaseNoVatValues[vatIndex] = taxBaseNoVatValues[vatIndex];       
                                }  
                                for(const vatIndex in vatValues){
                                    newSalesData.canceled.delivery.cashVatValues[vatIndex] ? newSalesData.canceled.delivery.cashVatValues[vatIndex] += vatValues[vatIndex] : newSalesData.canceled.delivery.cashVatValues[vatIndex] = vatValues[vatIndex];       
                                }
                            }
                            newSalesData.canceled.delivery.totalNoVat             += noVatPrice;
                            newSalesData.canceled.delivery.totalVat               += vatPrice;    
                            for(const vatIndex in taxBaseVatValues){
                                newSalesData.canceled.delivery.totalTaxBaseVatValues[vatIndex] ? newSalesData.canceled.delivery.totalTaxBaseVatValues[vatIndex] += taxBaseVatValues[vatIndex] : newSalesData.canceled.delivery.totalTaxBaseVatValues[vatIndex] = taxBaseVatValues[vatIndex];       
                            }  
                            for(const vatIndex in taxBaseNoVatValues){
                                newSalesData.canceled.delivery.totalTaxBaseNoVatValues[vatIndex] ? newSalesData.canceled.delivery.totalTaxBaseNoVatValues[vatIndex] += taxBaseNoVatValues[vatIndex] : newSalesData.canceled.delivery.totalTaxBaseNoVatValues[vatIndex] = taxBaseNoVatValues[vatIndex];       
                            }  
                            for(const vatIndex in vatValues){
                                newSalesData.canceled.delivery.totalVatValues[vatIndex] ? newSalesData.canceled.delivery.totalVatValues[vatIndex] += vatValues[vatIndex] : newSalesData.canceled.delivery.totalVatValues[vatIndex] = vatValues[vatIndex];       
                            } 
                        }
                    }
                }
            }
        }
        return newSalesData;
    }
    getItemPrices = (item) => {

        let vatValues           = {};
        let taxBaseVatValues    = {};
        let taxBaseNoVatValues  = {};
        let vatPrice            = 0;
        let noVatPrice          = 0;
        let itemCount           = item.isFree ? item.count - 1 : item.count;

        //cena polozky
        const itemVat       = (item.price * itemCount - (item.price * itemCount)/(1 + (item.vat/100)));
        const itemPrice     = item.price * itemCount;
        vatPrice    += parseFloat(itemPrice);
        noVatPrice  += itemPrice - itemVat;
        taxBaseVatValues[item.vat]      ? taxBaseVatValues[item.vat]    += parseFloat(itemPrice)    : taxBaseVatValues[item.vat]     = parseFloat(itemPrice);
        taxBaseNoVatValues[item.vat]    ? taxBaseNoVatValues[item.vat]  += (itemPrice - itemVat)    : taxBaseNoVatValues[item.vat]   = (itemPrice - itemVat);
        vatValues[item.vat]             ? vatValues[item.vat]           += itemVat                  : vatValues[item.vat]            = itemVat;

        //cena baleni polozky
        const packageVat 	= (item.packagePrice * itemCount - (item.packagePrice * itemCount)/(1 + (item.packagePriceVat/100)));
        const packagePrice  = item.packagePrice * itemCount

        vatPrice    += packagePrice;
        noVatPrice  += packagePrice - packageVat;
        taxBaseVatValues[item.packagePriceVat]      ? taxBaseVatValues[item.packagePriceVat]    += packagePrice                 : taxBaseVatValues[item.packagePriceVat]    = packagePrice;
        taxBaseNoVatValues[item.packagePriceVat]    ? taxBaseNoVatValues[item.packagePriceVat]  += (packagePrice - packageVat)  : taxBaseNoVatValues[item.packagePriceVat]  = (packagePrice - packageVat);
        vatValues[item.packagePriceVat]             ? vatValues[item.packagePriceVat]           += packageVat                   : vatValues[item.packagePriceVat]           = packageVat;

        //cena za pridavky
        if(item.orderOptions && item.orderOptions.length > 0){
            for(const option of item.orderOptions){
                
                const optionVat       = (option.price * itemCount - (option.price * itemCount)/(1 + (option.vat/100)));
                const optionPrice     = option.price * itemCount;

                vatPrice    += optionPrice;
                noVatPrice  += optionPrice - optionVat;
                taxBaseVatValues[option.vat]    ? taxBaseVatValues[option.vat]      += optionPrice                 : taxBaseVatValues[option.vat]      = optionPrice;
                taxBaseNoVatValues[option.vat]  ? taxBaseNoVatValues[option.vat]    += (optionPrice - optionVat)    : taxBaseNoVatValues[option.vat]   = (optionPrice - optionVat);
                vatValues[option.vat]           ? vatValues[option.vat]             += optionVat                    : vatValues[option.vat]            = optionVat;

                const optionPackageVat 	    = (option.packagePrice * itemCount - (option.packagePrice * itemCount)/(1 + (option.packageVat/100)));
                const optionPackagePrice    = option.packagePrice * itemCount

                vatPrice    += optionPackagePrice;
                noVatPrice  += optionPackagePrice - optionPackageVat;
                taxBaseVatValues[option.packageVat]     ? taxBaseVatValues[option.packageVat]   += optionPackagePrice                       : taxBaseVatValues[option.packageVat]   = optionPackagePrice;
                taxBaseNoVatValues[option.packageVat]   ? taxBaseNoVatValues[option.packageVat] += (optionPackagePrice - optionPackageVat)  : taxBaseNoVatValues[option.packageVat] = (optionPackagePrice - optionPackageVat);
                vatValues[option.packageVat]            ? vatValues[option.packageVat]          += optionPackageVat                         : vatValues[option.packageVat]          = optionPackageVat;
            }
        }
        //cena za prilohy
        if(item.orderSideDishes && item.orderSideDishes.length > 0){
            for(const sideDish of item.orderSideDishes){
                
                const sideDishVat       = (sideDish.price * itemCount - (sideDish.price * itemCount)/(1 + (sideDish.vat/100)));
                const sideDishPrice     = sideDish.price * itemCount;

                vatPrice    += sideDishPrice;
                noVatPrice  += sideDishPrice - sideDishVat;
                taxBaseVatValues[sideDish.vat]      ? taxBaseVatValues[sideDish.vat]    += sideDishPrice                : taxBaseVatValues[sideDish.vat]    = sideDishPrice;
                taxBaseNoVatValues[sideDish.vat]    ? taxBaseNoVatValues[sideDish.vat]  += (sideDishPrice - sideDishVat): taxBaseNoVatValues[sideDish.vat]  = (sideDishPrice - sideDishVat);
                vatValues[sideDish.vat]             ? vatValues[sideDish.vat]           += sideDishVat                  : vatValues[sideDish.vat]           = sideDishVat;
            }
        }
        //cena za napoje
        if(item.orderDrinks && item.orderDrinks.length > 0){
            for(const drink of item.orderDrinks){
                
                const drinVat       = (drink.price * itemCount - (drink.price * itemCount)/(1 + (drink.vat/100)));
                const drinPrice     = drink.price * itemCount;

                vatPrice    += drinPrice;
                noVatPrice  += drinPrice - drinVat;
                taxBaseVatValues[drink.vat]     ? taxBaseVatValues[drink.vat]   += drinPrice            : taxBaseVatValues[drink.vat]   = drinPrice;
                taxBaseNoVatValues[drink.vat]   ? taxBaseNoVatValues[drink.vat] += (drinPrice - drinVat): taxBaseNoVatValues[drink.vat] = (drinPrice - drinVat);
                vatValues[drink.vat]            ? vatValues[drink.vat]          += drinVat              : vatValues[drink.vat]          = drinVat;
            }
        }
        noVatPrice  = noVatPrice;
        return [noVatPrice,vatPrice,vatValues,taxBaseVatValues,taxBaseNoVatValues];
    }

}

export default Settings;