import {GET_DRIVERS} from '../Queries/driver';
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';

class Driver {

    constructor(props){
        this.props = props;
    }

    addDriver(addEditDriver,formData){
        formData.lang = translate.locale;
        formData.driverTypeID = parseInt(formData.driverTypeID);
        
        if(formData.driverTypeID != ""){
            if(formData.adminUserID != 0){

                if(formData.deliveryAreaID != 0){
            
                    formData.realTimeMealsCount = parseInt(formData.realTimeMealsCount);

                    if(formData.realTimeMealsCount >= 0){
                        
                        if(formData.phone != ""){
                        
                            addEditDriver({
                                variables:formData
                            }); 
        
                        }else{
                            let notify = new Notification();
                            notify.setNotification(null,translate.t("InvalidDriverPhone"),false,true,this.props.client);
                        }

                    }else{
                        let notify = new Notification();
                        notify.setNotification(null,translate.t("InvalidRealTimeMealsCount"),false,true,this.props.client);
                    }

                }else{
                    let notify = new Notification();
                    notify.setNotification(null,translate.t("InvalidDeliveryArea"),false,true,this.props.client);
                }

            }else{
                let notify = new Notification();
                notify.setNotification(null,translate.t("InvalidDriver"),false,true,this.props.client);
            }

        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidDriverType"),false,true,this.props.client);
        }

    }

    getData(data,driverID,formData,setFormData){

        if(driverID != 0){
            
            setFormData({...formData,
                adminUserID: data.driver.adminUserID,
                deliveryAreaID: data.driver.deliveryAreaID,
                realTimeMealsCount: data.driver.realTimeMealsCount,
                description: data.driver.description, 
                phone:  data.driver.phone,  
                driverTypeID:  data.driver.driverType ? data.driver.driverType.driverTypeID : "", 
            });
        }
    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateList(cache, response,variables,driverID){

        const { allDrivers,driverListCount } = cache.readQuery({ query: GET_DRIVERS ,variables});

        
        if(driverID && driverID != 0){

            var arr = [...allDrivers];

            arr.forEach((item,index) => {
                
                if(item.driverID == response.data.addEditDriver.driverID){
                    arr[index] = response.data.addEditDriver;
                }

            });

            cache.writeQuery({ 
                query:GET_DRIVERS,
                variables,
                data:{
                    allDrivers: arr,
                    driverListCount :driverListCount
                } 
            });

        }else{
            var arr = [response.data.addEditDriver,...allDrivers];

            cache.writeQuery({ 
                query:GET_DRIVERS,
                variables,
                data:{
                    allDrivers: arr,
                    driverListCount :driverListCount
                } 
            });
        }

    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteDrivers.split(",");

        const { allDrivers,driverListCount } = cache.readQuery({ query: GET_DRIVERS ,variables});
        var arr = [...allDrivers];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.driverID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_DRIVERS,
            variables,
            data:{
                allDrivers: [...arr],
                driverListCount :driverListCount - resp.length
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default Driver;