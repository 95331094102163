/**
 *  komponenta pro štítky pro produkty
 */

import React,{ Fragment, useState } from 'react';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import {GET_BRANCHES,DELETE_BRANCHES} from '../Queries/branch';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import BranchLib from '../Library/branch';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import AddEditBranch from './AddEditBranch';
import edit from '../../../Public/Images/edit.svg';
import { ADMIN_USER } from '../../../GlobalQueries/globalQueries';

const Branches = (props) => {
        
	const adminUserData = props.client.readQuery({ query:ADMIN_USER });

    const listVariables = {
        offset:0,
        limit:50
    }

	const [selectedBranches,setSelectedBranches]      = useState([]);
    const [showDeleteNotifi,setShowDeleteNotifi]    = useState(false);
    const [showAdd,setShowAdd] 			            = useState(false);
    const [selectedBranchID,setSelectedBranchID]    = useState(0);
    
    const {loading, error:getError, data:branchListData, fetchMore}   = useQuery(GET_BRANCHES,{
        variables:listVariables
    });
 
	const [deleteBranch, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_BRANCHES,{	
		update(cache,response) {            
            let branch = new BranchLib(this);
            branch.updateAfterDelete(cache, response,listVariables);

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    /**
     * smazání štítků
     * @param {*} deleteBranches funkce, která posílá data na server
     * @param {*} branchID ID branchu
     */
    
    const initiateDeleteBranch = (e,branchID) => {

        var checked = e.target.checked;
        var arr = [...selectedBranches];

        if(!checked){
            for(let i in selectedBranches){
                if(selectedBranches[i] == branchID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [branchID,...arr];
        }

        setSelectedBranches(arr);
  
    }

    const delBranch = (action) => {

        if(action){

            let branchIDs = selectedBranches.join(",");

            deleteBranch({
                variables:{
                    branchIDs: branchIDs
                }
            })
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    }

    const showDelNotifi = () => {

        if(selectedBranches.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const openCloseModal = (open,branchID) => {
        setSelectedBranchID(branchID);
        setShowAdd(open);
    }

    
    if(getError || deleteError){
        var error = getError ? getError : deleteError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }

    return(

        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t("BranchList")}
                            
                            {[1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) != -1 &&
                                <>
                                    <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                                    <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                                </>
                            }
                        </div>
                        <div className="card-body">

                            {!error ?
                                <div>
                                    <div className="data-list">
                                        
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("Name")}</div>
                                                <div className="text-center">{translate.t("Country")}</div>
                                                <div className="text-center">{translate.t("Street")}</div>
                                                <div className="text-center">{translate.t("City")}</div>
                                                <div className="text-center">{translate.t("Zip")}</div>
                                                <div className="text-right static small">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {branchListData && branchListData.allBranches && branchListData.allBranches.map((item,index) => {
                                                
                                                var checked = false;
                                                for(let i in selectedBranches){
                                                    if(selectedBranches[i] == item.branchID)checked = true;
                                                }
                                                                                                                
                                                return (
                                                    <Fragment key={index}>
                                                        {([1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) != -1 || adminUserData.adminUser.branchID == item.branchID) &&
                                                            <div className="data-list-item-content">
                                                                <div>{item.name}</div>
                                                                <div className="text-center">{!!item.country && item.country.name}</div>
                                                                <div className="text-center">{item.street}</div>
                                                                <div className="text-center">{item.city}</div>
                                                                <div className="text-center">{item.zip}</div>
                                                            
                                                                <div className="text-right static small">
                                                                    <img onClick={() => openCloseModal(true,item.branchID)} className="edit-icon" src={edit} />
                                                                    {[1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) != -1 &&
                                                                        <input className="delete-checked" type="checkbox" name="deleteBranch[]" checked={checked} onChange = {(e) => initiateDeleteBranch(e,item.branchID)} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        } 
                                                    </Fragment>      
                                                )
                                                
                                            })} 
                                        </Fragment>
                                    </div>
                                    {!loading ? 
                                        <Fragment>
                                            {branchListData && branchListData.allBranches && branchListData.allBranches.length == 0 ?
                                                <NoItems text={translate.t("NoItemsAddedYet")} />
                                                :  
                                                <Fragment>     
                                                    {!loading && branchListData && branchListData.allBranches.length < branchListData.branchListCount &&                                 
                                                        <div className="text-center p-5">
                                                            <button className="btn btn-primary" onClick={() => fetchMore({
                                                                variables: {...listVariables,
                                                                    offset: branchListData.allBranches.length
                                                                },
                                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                                    if (!fetchMoreResult) return prev;
                                                                    return Object.assign({}, prev, {
                                                                        allBranches: [...prev.allBranches, ...fetchMoreResult.allBranches]
                                                                    });
                                                                }
                                                            })}> {translate.t("LoadMore")} </button>
                                                        </div> 
                                                    }
                                                </Fragment>
                                            }
                                        </Fragment>
                                        :                                    
                                        <Loading />                                    
                                    }
                                    
                                </div>
                                :
                                <Fragment>
                                    {error &&  <Error text={error} />}
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div> 	
            {showAdd &&
                <AddEditBranch openCloseModal={openCloseModal} selectedBranchID={selectedBranchID} listVariables={listVariables} />
            }
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delBranch} /> }	
        </div>
    );

}

export default withApollo(Branches);