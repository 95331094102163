/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_BRANCH = gql`    
    
    mutation AddEditBranch(
        $branchID:ID,
        $photo:Upload,
        $name:String,
        $street:String,
        $city:String,
        $zip:String,
        $phone:String,
        $countryID:ID,
        $selectedMeals:[ID],
        $openTimes:[OpenTimes],
        $active:Int,$deliveryAreaID:ID,
        $hideEatInOption:Int,
        $hideTakeAwayOption:Int,
        $lat:Float,
        $lng:Float,
        $useStaticDeliveryPrice:Int,
        $staticDeliveryPrice:Float,
        $freeDeliveryPriceValue:Float,
        $deliveryUnitPrice:Float,
        $unitSize:Int,
        $maxDeliveryPrice:Float,
        $freeUnitCount:Int,
        $invoiceCompany:String,
        $invoiceStreet:String,
        $invoiceCity:String,
        $invoiceZip:String,
        $invoiceCountry:String,
        $invoiceIC:String,
        $invoiceDIC:String,
        $ownDeliveryEnabled:Int,
        $woltDeliveryEnabled:Int,
        $minimumOrderAmount:Float,
        $minimumMealPreparationTimeForWolt:Int,
        $cashPayForDeliveryEnabled:Int,
        $cashPayForEatInAndTakeAwayEnabled:Int){

            addEditBranch(
                branchID:$branchID,
                photo:$photo,
                name:$name,
                street:$street,
                city:$city,
                zip:$zip,
                phone:$phone,
                countryID:$countryID,
                selectedMeals:$selectedMeals,
                openTimes:$openTimes,
                active:$active,
                deliveryAreaID:$deliveryAreaID,
                hideEatInOption:$hideEatInOption,
                hideTakeAwayOption:$hideTakeAwayOption,
                lat:$lat,
                lng:$lng,
                useStaticDeliveryPrice:$useStaticDeliveryPrice,
                staticDeliveryPrice:$staticDeliveryPrice,
                freeDeliveryPriceValue:$freeDeliveryPriceValue,
                deliveryUnitPrice:$deliveryUnitPrice,
                unitSize:$unitSize,
                maxDeliveryPrice:$maxDeliveryPrice,
                freeUnitCount:$freeUnitCount,
                invoiceCompany:$invoiceCompany,
                invoiceStreet:$invoiceStreet,
                invoiceCity:$invoiceCity,
                invoiceZip:$invoiceZip,
                invoiceCountry:$invoiceCountry,
                invoiceIC:$invoiceIC,
                invoiceDIC:$invoiceDIC,
                ownDeliveryEnabled:$ownDeliveryEnabled,
                woltDeliveryEnabled:$woltDeliveryEnabled,
                minimumOrderAmount:$minimumOrderAmount,
                minimumMealPreparationTimeForWolt:$minimumMealPreparationTimeForWolt,
                cashPayForDeliveryEnabled:$cashPayForDeliveryEnabled,
                cashPayForEatInAndTakeAwayEnabled:$cashPayForEatInAndTakeAwayEnabled){

                    branchID
                    name
                    street
                    city
                    zip 
                    phone 
                    lat
                    lng
                    countryID
                    active
                    branchMeals{
                        branchMealID
                        branchID
                        mealID
                    }
                    branchOpenTimes{
                        branchOpenTimesID         
                        branchID
                        mon
                        tue
                        wed
                        thu
                        fri
                        sat
                        sun
                        times{
                            timeFrom
                            timeTo
                        }
                    }
            }
    }
`;


/**
 *  Seznam veškerých athleteů
 */
export const GET_BRANCHES = gql`
    query AllBranches($offset:Int,$limit:Int){
        allBranches(offset:$offset,limit:$limit,onlyActive:false){            
            branchID
            name
            street
            city
            zip   
            lat
            lng
            phone    
            countryID
            country{
                name
            }
        }
        branchListCount
    }
`;

/**
 *  Seznam veškerých athleteů
 */
export const GET_BRANCH = gql`
    query Branch($branchID:ID){
        branch(branchID:$branchID){            
            branchID
            name
            street
            photo
            city
            zip  
            lat
            lng
            phone  
            invoiceCompany
            invoiceStreet
            invoiceZip
            invoiceCity
            invoiceCountry
            invoiceIC
            invoiceDIC
            countryID
            active
            deliveryAreaID
            hideEatInOption	
            hideTakeAwayOption            
            useStaticDeliveryPrice
            staticDeliveryPrice
            freeDeliveryPriceValue
            deliveryUnitPrice
            ownDeliveryEnabled
            woltDeliveryEnabled
            minimumOrderAmount
            minimumMealPreparationTimeForWolt
            cashPayForDeliveryEnabled
            cashPayForEatInAndTakeAwayEnabled
            unitSize
            freeUnitCount
            maxDeliveryPrice
            country{
                countryID
                currencyID
                currency{
                    code
                }
            }
        }
        allDeliveryAreasForBranchAdmin(branchID:$branchID){                    
            deliveryAreaID
            name
            path
            color
        }
        allActiveCountries(fromAdmin:1){
            countryID
            name
            currencyID
            currency{
                code
            }
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_BRANCHES = gql`
    mutation deleteBranches($branchIDs:ID!) {
        deleteBranches(branchIDs:$branchIDs)
    }
`;


export const GET_BRANCH_MEALS_OPENING_TIMES = gql`
    query AllBranchMealsOpeningTimes($branchID:ID){
        allBranchMealsForAdmin(branchID:$branchID){            
            branchMealID
            branchID
            mealID
        }
        allBranchOpeningTimes(branchID:$branchID){   
            branchOpenTimesID         
            branchID
            mon
            tue
            wed
            thu
            fri
            sat
            sun
            times{
                timeFrom
                timeTo
            }
        }
    }
`;


