import React,{ Component,Fragment } from 'react';
import {Mutation, withApollo } from 'react-apollo';
import { createPortal } from 'react-dom'
import move from '../../../Public/Images/move.svg';
import Modal from 'react-modal';
import HeadlineElement from './HeadlineElement';
import ColumnsElement from './ColumnsElement';
import ParagraphElement from './ParagraphElement';
import ListElement from './ListElement';
import ImageElement from './ImageElement';
import ButtonElement from './ButtonElement';
import VideoElement from './VideoElement';
import ArticlesElement from './ArticlesElement';
import BannersElement from './BannersElement';
import TurnamentsElement from './TurnamentsElement';
import AthletsElement from './AthletsElement';
import LaddersElement from './LaddersElement';
import NewsletterElement from './NewsletterElement'; 
import AddElement from './AddElement';
import HtmlElement from './HtmlElement';
import plusGreen from '../../../Public/Images/plus_green.svg';
import remove from '../../../Public/Images/remove.svg';
import ContentManagerLib from '../Library/contentManager';
import {SECTION} from '../Library/elements';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const INITIAL_STATE = {
    showHelpers:true,
    lang:"cz",
    allLanguages:[],
    content:{
        sections:[SECTION]    
    }
}

class ContentManager extends Component{

    constructor(props){
        super(props);

        this.state = INITIAL_STATE;
        
    }

    componentDidMount(){

        const {client,linkID} = this.props;
        this.cm = new ContentManagerLib(this,client);

        this.cm.getData(linkID);

    }

    setRef = node => {
        if(node)this.iframeNode = node.node;
    }



    render(){

        const {content,showHelpers,lang,allLanguages} = this.state;
        const {linkID} = this.props;
        
        return(
            <Modal
                className="Modal__Bootstrap modal-dialog modal-max"
                closeTimeoutMS={150}
                isOpen={true}
                onRequestClose={() => this.props.openContentManager(0)}
            >

                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Content manager</h4>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={showHelpers} id="defaultCheck1" onChange={() => this.cm.showHideHelpers()} />
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                ovládací prvky
                            </label>
                        </div>
                        <div className="mr-2">
                            <select style={{padding: '5px', height: '30px'}} value={lang} className="form-control" name="lang" onChange={(e) => this.cm.setLanguage(e)}>
                                {allLanguages && allLanguages.map((item,index) => (
                                    <option key={index} value={item.suffix}>{item.suffix}</option>
                                ))}
                            </select>
                        </div>
                        <button className="btn btn-primary btn-thiner mr-3" onClick={() => this.cm.saveContent(linkID)}>Uložit obsah</button>
                        <button type="button" className="close" onClick={() => this.props.openContentManager(0)}>
                            <span aria-hidden="true">x</span>
                            <span className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        
                        <Frame ref={this.setRef}>
                            <DragDropContext onDragEnd={(result) => this.cm.onDragSectionEnd(result)}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {content.sections && content.sections.map((item,index) => {

                                            return(

                                                <Draggable key={index} draggableId={index+1} index={index}>
                                                    {(provided, snapshot) => (
                                                    <div  
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        
                                                    >
                                                
                                                        <section key={index} className={"cm-section " + item.backgroundColorClass + " " + item.paddingClass + " " + item.className + " " + (content.sections.length == 1 && item.elements.length == 0 ? "cm-min-height" : "")+ " " + (item.fullHeight == 1 ? "full-height" : "")} style={(item.backgroundImage ? {backgroundImage:"url('"+item.backgroundImage+"')"} : {})} >
                                                            {content.sections.length == 1 && item.elements.length == 0 &&
                                                                <div className="cm-start-info">Pro vložení prvního obsahu klikněte v pravo dole na tlačítko "přidat obsah"</div>
                                                            }
                                                            {showHelpers &&
                                                                <div className="cm-menu">
                                                                    <div className="form-inline">
                                                                        <div className="form-group mr-1">
                                                                            <AddElement lastIndexes = {""+index+""} element={item} cm = {new ContentManagerLib(this)}  />
                                                                        </div>
                                                                        <div className="form-group mr-1">
                                                                            <div className="input-group">
                                                                                <div className="input-group-prepend">
                                                                                    <label className="input-group-text" htmlFor="inputGroupSelect02">Odsazení:</label>
                                                                                </div>
                                                                                <select value={item.paddingClass} className="form-control" name="section-padding" onChange={(e) => this.cm.setSectionPaddingClass(e,index)}>
                                                                                    <option value=""> Ne </option>
                                                                                    <option value="padding"> Ano </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="input-group mr-1">
                                                                            <div className="custom-file">
                                                                                <input type="file" className="custom-file-input" id="customFile" onChange={(e) => this.cm.uploadSectionBackgroundImage(e,index,item.backgroundImage)}  />
                                                                                <label className="custom-file-label" htmlFor="customFile">Obrázek pozadí</label>
                                                                            </div>
                                                                            {item.backgroundImage != "" &&
                                                                                <div className="input-group-append">
                                                                                    <div className="item-container" onClick={() => this.cm.removeSectionBackgroundImage(index)}>
                                                                                        <img className="remove-icon" src={remove} alt="Odstranit obrázek na pozadí" title="Odstranit obrázek na pozadí" />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="form-group mr-1">
                                                                            <select value={item.backgroundColorClass} className="form-control" name="section-bg-color" onChange={(e) => this.cm.setSectionBackgroundColorClass(e,index)}>
                                                                                <option value=""> --- barva pozadí ---</option>
                                                                                <option value="grey"> šedá </option>
                                                                                <option value="blue"> modrá </option>
                                                                            </select>
                                                                        </div>
                                                                        
                                                                        <div className="form-group mr-1">
                                                                            <select value={item.fullWidth} className="form-control" name="section-full-width" onChange={(e) => this.cm.setSectionFullWidth(e,index)}>
                                                                                <option value="0"> pevná šířka </option>
                                                                                <option value="1"> na celou šířku </option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group mr-1">
                                                                            <select value={item.fullHeight} className="form-control" name="section-full-height" onChange={(e) => this.cm.setSectionFullHeight(e,index)}>
                                                                                <option value="0"> auto výška </option>
                                                                                <option value="1"> na celou výšku </option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group mr-1">
                                                                            <input type="text" className="form-control" placeholder="název sekce" value={(item.sectionName[lang] ? item.sectionName[lang] : "")} onChange={(e) => this.cm.setSectionName(e,index)} />
                                                                        </div>
                                                                        <div className="form-group mr-1">
                                                                            <input type="text" className="form-control" placeholder="třída" value={item.className} onChange={(e) => this.cm.setSectionClassName(e,index)} />
                                                                        </div>
                                                                        <div className="form-group mr-1">
                                                                            <div className="item-container" {...provided.dragHandleProps}>
                                                                                <img className="remove-icon" src={move} alt="Přesunout sekci" title="Přesunout sekci" />
                                                                            </div>
                                                                        </div>
                                                                        {index != 0 &&
                                                                            <div className="form-group">
                                                                                <div className="item-container" onClick={() => this.cm.removeSection(index)}>
                                                                                    <img className="remove-icon" src={remove} alt="Odstranit sekci" title="Odstranit sekci" />
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className={(item.fullWidth == 1 ? "container-fluid"  : "container")}>

                                                                <DragDropContext onDragEnd={(result) => this.cm.onDragEnd(result,""+index+"")}>
                                                                    <Droppable droppableId="droppable">
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                {...provided.droppableProps}
                                                                                ref={provided.innerRef}
                                                                                
                                                                            >
                                                                    
                                                                                {item.elements && item.elements.map((elmItem,elmIndex) => {

                                                                                    return(
                                                                                        <Draggable key={elmIndex} draggableId={elmIndex+1} index={elmIndex}>
                                                                                            {(provided, snapshot) => (
                                                                                            <div  
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                
                                                                                            >

                                                                                                {elmItem.headline &&
                                                                                                    <HeadlineElement handle = {provided.dragHandleProps}  lang={lang} iframeNode = {this.iframeNode} element = {elmItem.headline} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client)} showHelpers = {showHelpers} />
                                                                                                }
                                                                                                {elmItem.columns &&
                                                                                                    <ColumnsElement handle = {provided.dragHandleProps} lang={lang} allLanguages={allLanguages} fullWidth={item.fullWidth} iframeNode = {this.iframeNode} element = {elmItem.columns} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client)} showHelpers = {showHelpers} />
                                                                                                }
                                                                                                {elmItem.paragraph &&
                                                                                                    <ParagraphElement handle = {provided.dragHandleProps} lang={lang} iframeNode = {this.iframeNode} element = {elmItem.paragraph} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client)} showHelpers = {showHelpers} />
                                                                                                }
                                                                                                {elmItem.list &&
                                                                                                    <ListElement handle = {provided.dragHandleProps} lang={lang} allLanguages={allLanguages}  iframeNode = {this.iframeNode} element = {elmItem.list} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client)} showHelpers = {showHelpers} />
                                                                                                }
                                                                                                {elmItem.image &&
                                                                                                    <ImageElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.image} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client)} showHelpers = {showHelpers} />
                                                                                                }
                                                                                                {elmItem.button &&
                                                                                                    <ButtonElement handle = {provided.dragHandleProps} lang={lang} iframeNode = {this.iframeNode} element = {elmItem.button} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client)} showHelpers = {showHelpers} />
                                                                                                }
                                                                                                {elmItem.video &&
                                                                                                    <VideoElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.video} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client)} showHelpers = {showHelpers} />
                                                                                                }
                                                                                                {elmItem.articles &&
                                                                                                    <ArticlesElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.articles} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client)} showHelpers = {showHelpers} />
                                                                                                }
                                                                                                {elmItem.newsletter &&
                                                                                                    <NewsletterElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.newsletter} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client)} showHelpers = {showHelpers} />
                                                                                                }
                                                                                                {elmItem.html &&
                                                                                                    <HtmlElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.html} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client)} showHelpers = {showHelpers} />                                       
                                                                                                }
                                                                                                {elmItem.banners &&
                                                                                                    <BannersElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.banners} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client)} showHelpers = {showHelpers} />                                       
                                                                                                }
                                                                                                {elmItem.turnaments &&
                                                                                                    <TurnamentsElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.turnaments} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client)} showHelpers = {showHelpers} />                                       
                                                                                                }
                                                                                                {elmItem.athlets &&
                                                                                                    <AthletsElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.athlets} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client)} showHelpers = {showHelpers} />                                       
                                                                                                }
                                                                                                {elmItem.ladders &&
                                                                                                    <LaddersElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.ladders} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client)} showHelpers = {showHelpers} />                                       
                                                                                                }

                                                                                            </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    )
                                                                                })} 
                                                                                
                                                                                {provided.placeholder}
                                                                                
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </DragDropContext>

                                                            </div>

                                                            {showHelpers && <img src={plusGreen} className="cm-add-section" onClick={() => this.cm.addSection(index)} alt="Přidat sekci" title="Přidat sekci" />}
                                                            {showHelpers && <hr className="cm-hr" />}

                                                        </section>
                                                    </div>
                                                    )}
                                                </Draggable>
                                        )})}

                                        {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                               
                        </Frame>
                        

                    </div>
                </div>

            </Modal>
        )

    }
}

class Head extends Component {

    render(){
        return(
            <Fragment>
                <link href="https://fonts.googleapis.com/css?family=Poppins:300,400,700&display=swap&subset=latin-ext" rel="stylesheet" />
                
                <link rel="stylesheet" href="/Css/Bootstrap/bootstrap.min.css"></link>
                <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/Font/fonts.css"} />
                <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/contentManager.css?2"}></link>
                <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/web.css"}></link> 
                
            </Fragment>
        )
    }
}

class Frame extends Component {
    constructor(props) {
      super(props)

      this.state = {
        contentRef:"",
        headRef:""
      }
    }

    componentDidMount() {
        this.node.addEventListener("load", this.handleLoad);
    }

    componentWillUnmout() {
        this.node.removeEventListener("load", this.handleLoad);
    }

    handleLoad = () => {
        this.iframeBody = this.node.contentDocument.body;
        this.iframeHead = this.node.contentDocument.head;
        this.forceUpdate();
    };

  
    render() {
      const { children, ...props } = this.props // eslint-disable-line
      
      return (

        <iframe srcDoc={`<!DOCTYPE html>`} ref={node => (this.node = node)}>
            {this.iframeBody &&
            createPortal(
              children,
              this.iframeBody
            )}
            {this.iframeHead &&
            createPortal(
              <Head />,
              this.iframeHead
            )}
        </iframe>
      )
    }
  }

export default withApollo(ContentManager);