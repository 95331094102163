/*
    Hlavní horní lišta, ktaré má v sobě i levé mennu
*/
import React,{ Component, Fragment } from 'react';
import { NavLink } from "react-router-dom";
import onClickOutside from "react-onclickoutside";
import { withRouter } from 'react-router-dom';
import logo from '../Public/Images/logo.png';
import menu from '../Public/Images/menu.svg';
import door from '../Public/Images/door.svg';
import food from '../Public/Images/food.svg';
import cars from '../Public/Images/cars.svg';
import notes from '../Public/Images/notes_w.svg';
import branch from '../Public/Images/branch.svg';
import voucher from '../Public/Images/voucher.svg';
import userGroup from '../Public/Images/user_group.svg';
import content from '../Public/Images/content.svg';
import doorLighter from '../Public/Images/door_lighter.svg';
import settings from '../Public/Images/cogwheel.svg';
import statistics from '../Public/Images/statistics.svg';
import { ApolloConsumer} from 'react-apollo';
import translate from '../Modules/Translations/index';
import { withApollo } from 'react-apollo'; 
import {ADMIN_USER} from '../GlobalQueries/globalQueries';

const INITIAL_MENU = [
	{menuID:1,show:false},
	{menuID:2,show:false},
	{menuID:3,show:false},
	{menuID:4,show:false},
	{menuID:5,show:false},
	{menuID:6,show:false},
	{menuID:7,show:false},
	{menuID:8,show:false}
]

const INITIAL_STATE = {
	cl:"",
	roleID:0,
	showSubmenu:INITIAL_MENU
}

class Header extends Component {
	
	  constructor(props){
		  super(props);
		  this.showSideBar   = this.showSideBar.bind(this);
		  this.hideSideBar   = this.hideSideBar.bind(this);
		  this.toogleSubmenu = this.toogleSubmenu.bind(this);
		  
		  this.logout      = this.logout.bind(this);
		  this.state = {...INITIAL_STATE};
		  
	  }
	  componentDidMount(){
		  
		const adminUserData = this.props.client.readQuery({ query:ADMIN_USER });
		this.setState({roleID:parseInt(adminUserData.adminUser.roleID)});
	  }
	  showSideBar(e){
		  e.preventDefault();
		  if(this.state.cl){
			  this.setState({cl:""});
		  }else{
			  this.setState({cl:"open"});
		  }
	  }
	  hideSideBar(e){
		  this.setState({cl:""});
	  }
	  
	  logout(e,client){	  

		  e.preventDefault();
		  client.resetStore();

		  localStorage.removeItem("token");
		  localStorage.removeItem("loggedUser");

		  window.location.reload();

	  }
	  
	  toogleSubmenu(e,menu,prevent){
		  		  
		  if(prevent)e.preventDefault();
			  
		  var arr = this.state.showSubmenu.map(function(item,index){
			  if(index !== menu)return {...item,show:false};
			  else return {...item,show:!item.show};
		  });
		  
		  if(prevent)this.setState({...this.state,showSubmenu:arr});
		  else this.setState({...this.state,showSubmenu:arr,cl:''});
	  }
	  
	  handleClickOutside = evt => {
		 	this.setState({...this.state,showSubmenu:INITIAL_MENU});
	  };
	
	  render() {

		const {cl,roleID} = this.state;	
		const authUser = localStorage.getItem('loggedUser');		  
	    return (
			<div id="header" className="mb-1">
				<ul className="d-flex m-0 p-0 main">
					<li><button tabIndex="0" onClick={this.showSideBar} ><img src={menu} /></button></li>
					<li className="logo"><img alt={translate.t("UrbanBiatlon")} src={logo} /> {translate.t("UrbanBiatlonCL")}</li>
					<li className="user ml-auto">
						<NavLink to="/settings/change-password">
							{authUser}
						</NavLink></li>
					<li className="">
						<ApolloConsumer>
							{client => (
								<button onClick={(e) => this.logout(e,client)}><img src={door} /></button>
							)}
								
						</ApolloConsumer>
					</li>

				</ul>
				<div className={"sideBar " + cl}>
					<ul>
						{[1,2].indexOf(roleID) != -1 &&						
							<li>
								<NavLink onClick={(e) => this.toogleSubmenu(e,1,true)} activeClassName="active" to="/meals"><img src={food} /> <span>{translate.t("Meals")}</span></NavLink>
								<ul className={(this.state.showSubmenu[1].show) ? '' : 'hidden' }>
									<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/meals"> {translate.t("MealList")}</NavLink></li>
									<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/meals/categories"> {translate.t("Categories")}</NavLink></li>
									<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/meals/side-dishes"> {translate.t("SideDishes")}</NavLink></li>
									<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/meals/options"> {translate.t("Options")}</NavLink></li>
									<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/meals/drinks"> {translate.t("Drinks")}</NavLink></li>
								</ul>
							</li>
						}
						<li>
							<NavLink activeClassName="active" to="/orders"><img src={notes} /> <span>{translate.t("Orders")}</span></NavLink>
							
						</li>
	
						<li>
							<NavLink activeClassName="active" to="/branches"><img src={branch} /> <span>{translate.t("Branches")}</span></NavLink>
							
						</li>
	  					
						{[1,2].indexOf(roleID) != -1 &&	
							<li>
								
								<NavLink onClick={(e) => this.toogleSubmenu(e,6,true)} activeClassName="active" to="/drivers"><img src={cars} /> <span>{translate.t("Drivers")}</span></NavLink>
								<ul className={(this.state.showSubmenu[6].show) ? '' : 'hidden' }>
									<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/drivers"> {translate.t("DriverList")}</NavLink></li>
									<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/drivers/delivery-areas"> {translate.t("DeliveryAreas")}</NavLink></li>
								</ul>
							</li>
	  					}

						{[1,2].indexOf(roleID) != -1 &&	
							<li>
								<NavLink onClick={(e) => this.toogleSubmenu(e,5,true)} activeClassName="active" to="/users"><img src={userGroup} /> <span>{translate.t("Users")}</span></NavLink>
								<ul className={(this.state.showSubmenu[5].show) ? '' : 'hidden' }>
									<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/users"> {translate.t("CompanyUsers")}</NavLink></li>
									<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/users/customers"> {translate.t("Customers")}</NavLink></li>
								</ul>
							</li>
	  					}
						
						{[1,2].indexOf(roleID) != -1 &&	
							<li>
								<NavLink activeClassName="active" to="/vouchers"><img src={voucher} /> <span>{translate.t("Vouchers")}</span></NavLink>
								
							</li>
	  					}
					

						<li>
							<NavLink onClick={(e) => this.toogleSubmenu(e,7,true)} activeClassName="active" to="/statistics"><img src={statistics} /> <span>{translate.t("Statistic")}</span></NavLink>
							<ul className={(this.state.showSubmenu[7].show) ? '' : 'hidden' }>
								<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/statistics/sales"> {translate.t("Sales")}</NavLink></li> 
								<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/statistics/meals"> {translate.t("Meals")}</NavLink></li> 
								<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/statistics/map-orders"> {translate.t("Map")}</NavLink></li> 								
								<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/statistics/credit-vouchers"> {translate.t("CreditAndVouchers")}</NavLink></li> 		
								<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/statistics/customers"> {translate.t("Customers")}</NavLink></li> 								
							</ul>
						</li>

						{[1,2].indexOf(roleID) != -1 &&	
							<li>
								<NavLink onClick={(e) => this.toogleSubmenu(e,3,true)} strict to="/content"><img src={content} /> <span>{translate.t("Content")}</span></NavLink>
								<ul className={(this.state.showSubmenu[3].show) ? '' : 'hidden' }>
									<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/homepage"> {translate.t("Homepage")}</NavLink></li> 
									<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/link"> {translate.t("LinkList")}</NavLink></li> 
									<li className=""><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/app-link"> {translate.t("AppLinkList")}</NavLink></li>
									<li className=""><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/app-banner"> {translate.t("AppBannerList")}</NavLink></li>
									<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/green-info-banners"> {translate.t("GreenInfoBanners")}</NavLink></li>
									<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/app-rating-modal"> {translate.t("AppRatingModal")}</NavLink></li>
									{false && <li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/article"> {translate.t("ArticleList")}</NavLink></li>}
									
								</ul>
							</li>
	  					}
						
						<li>
							<NavLink onClick={(e) => this.toogleSubmenu(e,4,true)} activeClassName="active" to="/settings"><img src={settings} /> <span>{translate.t("Settings")}</span></NavLink>
							<ul className={(this.state.showSubmenu[4].show) ? '' : 'hidden' }>
								{[1,2].indexOf(roleID) != -1 &&
									<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/settings"> {translate.t("System")}</NavLink></li>
								}
								<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/settings/change-password"> {translate.t("ChangePwd")}</NavLink></li>
							</ul>
						</li>
						<li>
							<ApolloConsumer>
								{client => (
									<button onClick={(e) => this.logout(e,client)}><img src={doorLighter} /> <span>{translate.t("LogOut")}</span></button>
								)}
								
							</ApolloConsumer>
						</li>

					</ul>
				</div>
			</div>
	    );
	  }
  
}


export default withApollo(withRouter(onClickOutside(Header)));
