import gql from 'graphql-tag';


export const GET_MEAL_OPTIONS = gql`
    query AllMealOptions($mealID: ID!,$lang:String){
        allMealOptions(mealID:$mealID,lang:$lang){   
            mealOptionID
            optionID
            optionPrice
            packagePrice
            lang
            option{
                name
            }
            prices{
                mealOptionPriceID
                optionPrice
                packagePrice
                countryID
                vatID
                packageVatID
                country{
                    currencyID
                    currency{
                        code
                    }
                }
                vat{
                    value
                }
                packageVat{
                    value
                }
            }
        }
        allOptions(lang:$lang,onlyActive:false){
            optionID
            name
            price
        }
    }
`;
export const DELETE_MEAL_OPTIONS = gql`
    mutation DeleteMealOptions($mealOptionIDs:[ID]!,$mealID:ID) {
        deleteMealOptions(mealOptionIDs:$mealOptionIDs)
        reindexMealOptionPriority(mealID:$mealID)
    }
`;
export const UPDATE_MEAL_OPTION_PRIORITY = gql`
    mutation updateMealOptionPriority($mealID:ID,!$mealOptionID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateMealOptionPriority(mealID:$mealID,mealOptionID:$mealOptionID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

export const ADD_MEAL_OPTION= gql`
    mutation AddMealOption($mealID:ID,$optionID:ID) {
        addMealOption(mealID:$mealID,optionID:$optionID)
        reindexMealOptionPriority(mealID:$mealID)
    }
`;





