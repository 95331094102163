/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_HOMEPAGEBANNER = gql`
    mutation AddEditHomepageBanner($active:Int,$homepageBannerID:ID,$photo:Upload, $langs:[HomepageBannerLangsInput]){
        addEditHomepageBanner(active:$active, homepageBannerID:$homepageBannerID,photo:$photo,langs:$langs){
            homepageBannerID
            active
            photo
        }
    }
`;


/**
 *  Data kategorie
 */

export const GET_HOMEPAGEBANNER = gql`
    query HomepageBanner($homepageBannerID: ID!){
        homepageBannerWithLangs(homepageBannerID:$homepageBannerID){
            homepageBannerID
            active  
            photo
            langs{
                lang
                text
            }   
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_HOMEPAGEDATA = gql`
    query AllHomepageBanners($lang:String){
        allHomepageBanners(lang:$lang,onlyActive:false){            
            homepageBannerID
            active  
            photo
            text
            lang  
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
        homepageWithLangs(homepageID:1){
            homepageID
            langs{
                lang                
                metaTitle
                metaKeywords
                metaDescription
            }    
        }
    }
`;




/**
 * Smazání kategorie
 */

export const DELETE_HOMEPAGEBANNERS = gql`
    mutation deleteHomepageBanners($homepageBannerIDs:ID!) {
        deleteHomepageBanners(homepageBannerIDs:$homepageBannerIDs)
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_HOMEPAGEBANNER_PRIORITY = gql`
    mutation updateHomepageBannerPriority($homepageBannerID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateHomepageBannerPriority(homepageBannerID:$homepageBannerID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

/**
 *  Úprava dat homepage
 */
export const EDIT_HOMEPAGEDATA = gql`
    mutation addEditHomepage($langs:[HomepageLangsInput]){
        addEditHomepage(homepageID:1,langs:$langs)
    }
`;