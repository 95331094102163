import translate from '../../Translations/index';
import Notification from '../../../Library/notification';

class SideDishPrice {

    constructor(props){
        this.props = props;
    }

    addSideDishPrice(addSideDishPrice,formData){

        if(formData.price !== ""){
            
            formData.price          = parseFloat(formData.price);
            addSideDishPrice({
                variables:{
                    sideDishPriceInputs:formData
                }
            }); 

        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidPrice"),false,true,this.props.client);
        }                     

    }

    getData(data,sideDishPriceID,formData,setFormData){
        console.log(sideDishPriceID);
        if(sideDishPriceID != 0){            
            setFormData({...formData,
                sideDishPriceID: data.sideDishPrice.sideDishPriceID,
                countryID: data.sideDishPrice.countryID,
                price: data.sideDishPrice.price,
                vatID: data.sideDishPrice.vatID,
            });
        }
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default SideDishPrice;