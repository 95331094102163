import translate from '../../Translations/index';
import Notification from '../../../Library/notification';

class SideDish {

    constructor(props){
        this.props = props;
    }

    addSideDish(addSideDish,formData){

        if(formData.photo != "" || formData.sideDishID != 0){
            
            formData.active = parseInt(formData.active);
            formData.isAlcohol = parseInt(formData.isAlcohol);
                
                addSideDish({
                    variables:formData
                }); 

        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidPhoto"),false,true,this.props.client);
        }

        
        
                                  

    }

    getData(data,sideDishID,formData,setFormData){

        if(sideDishID == 0){

            let langs = this.completeLangsArray([],data.allLanguageMutations); 
            setFormData({...formData,langs:langs});

        }else{
            
            setFormData({...formData,
                active: data.sideDishWithLangs.active,
                isAlcohol: data.sideDishWithLangs.isAlcohol,
                sideDishID: data.sideDishWithLangs.sideDishID,
                langs: this.completeLangsArray(data.sideDishWithLangs.langs,data.allLanguageMutations)
            });
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(lang.__typename)delete(lang["__typename"]);
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    name:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e,formData,setFormData){

        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        var langs = formData.langs;

        for(const lang of langs){
            if(lang.lang == formData.selectedLang){
                lang[n] = v;
            }
        }

        setFormData({...formData,langs: langs});
         
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} propertyName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (formData,propertyName) => {
        for(const lang of formData.langs){
            if(lang.lang == formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }

}

export default SideDish;