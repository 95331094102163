/**
 * soubor se seznamem veškerých graphql dotazů pro odkazy
 */
import gql from 'graphql-tag';

/**
 * vložení odkazu
 */
export const ADD_EDIT_LINK = gql`
    mutation AddEditLink(
        $linkID:ID,
        $parentID: String!, 
        $active:Int, 
        $countries:[ID],
        $isTermsAndConditions:Int, 
        $isPrivatePolicy:Int,
        $inHeader:Int, 
        $inFooter:Int, 
        $inFooterColumn:Int, 
        $inBanners:Int, 
        $photo:Upload,
        $langs:[LinkLangsInput]
    ){
        addEditLink(
            linkID:$linkID,
            parentID: $parentID, 
            active:$active, 
            countries:$countries,
            isTermsAndConditions:$isTermsAndConditions, 
            isPrivatePolicy:$isPrivatePolicy,
            inHeader:$inHeader,
            inFooter:$inFooter,
            inFooterColumn:$inFooterColumn,
            inBanners:$inBanners,
            photo:$photo,
            langs:$langs
        )
    }
`;

/**
 *  Úprava priority odkazu, když se přesouvají položky
 */
export const UPDATE_LINK_PRIORITY = gql`
    mutation updateLinkPriority($parentID:String!,$linkID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateLinkPriority(parentID:$parentID,linkID:$linkID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;


/**
 *  Data pro jednu kategorii
 */

export const GET_LINK = gql`
    query LinkWithLangs($linkID: ID!){
        linkWithLangs(linkID:$linkID){
            linkID
            active
            isTermsAndConditions
            isPrivatePolicy
            parentID
            inHeader
            inBanners
            inFooter 
            inFooterColumn
            photo
            langs{
                lang
                name
                ownUrl
                description
                niceName
                metaTitle
                metaKeywords
                metaDescription
            }
            countries{
                countryID
                name
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }    
        allActiveCountries(fromAdmin:1){
            countryID
            name
        }
    }
`;

/**
 *  Seznam veškerých kategorií - maximlání zanoření je 7
 */
export const GET_LINKS = gql`
    query AllLinks($lang: String){
        allLinks(lang:$lang){
            linkID
            name
            active
            isTermsAndConditions
            isPrivatePolicy
            countries{
                name
            }
            parentID
            inHeader
            inBanners
            inFooter 
            inFooterColumn 
            subLink{
                linkID
                name
                active
                parentID
                inHeader
                inBanners
                inFooter 
                inFooterColumn
                subLink{
                    linkID
                    name
                    active
                    parentID
                    inHeader
                    inBanners
                    inFooter 
                    inFooterColumn
                    subLink{
                        linkID
                        name
                        active
                        parentID
                        inHeader
                        inBanners
                        inFooter 
                        inFooterColumn
                        subLink{
                            linkID
                            name
                            active
                            parentID
                            inHeader
                            inBanners
                            inFooter 
                            inFooterColumn
                            subLink{
                                linkID
                                name
                                active
                                parentID
                                inHeader
                                inBanners
                                inFooter 
                                inFooterColumn
                                subLink{
                                    linkID
                                    name
                                    active
                                    parentID
                                    inHeader
                                    inBanners
                                    inFooter 
                                    inFooterColumn
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_LINKS = gql`
    mutation deleteLink($linkID:ID!,$parentID:String) {
        deleteLink(linkID:$linkID)
        reindexLinkPriority(parentID:$parentID)
    }
`;
