/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_BANNER = gql`
    mutation AddEditBanner($active:Int,$bannerID:ID,$photo:Upload, $langs:[BannerLangsInput]){
        addEditBanner(active:$active, bannerID:$bannerID,photo:$photo,langs:$langs){
            bannerID
            title
            photo
            active
        }
    }
`;


/**
 *  Data kategorie
 */

export const GET_BANNER = gql`
    query Banner($bannerID: ID!){
        bannerWithLangs(bannerID:$bannerID){
            bannerID
            active  
            photo
            langs{
                lang
                title
                text
                url
            }   
              
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_BANNERS = gql`
    query AllBanners($lang:String){
        allBanners(lang:$lang,onlyActive:false){            
            bannerID
            active
            title
            photo
        }
    }
`;

/**
 * Smazání kategorie
 */

export const DELETE_BANNERS = gql`
    mutation deleteBanners($bannerIDs:ID!) {
        deleteBanners(bannerIDs:$bannerIDs)
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_BANNER_PRIORITY = gql`
    mutation updateBannerPriority($bannerID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateBannerPriority(bannerID:$bannerID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;