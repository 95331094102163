const {GET_MEAL_IMAGES} = require("../Queries/mealImages");

class MealPhoto {

    constructor(props){
        this.props = props;
    }

    updateMealImages(cache,response,mealID){

        const { mealImages } = cache.readQuery({ query: GET_MEAL_IMAGES, variables:{mealID} });
        var arr = [...response.data.uploadMealImages,...mealImages];

        cache.writeQuery({ 
            query:GET_MEAL_IMAGES,
            variables:{mealID},
            data:{
                mealImages: arr
            } 
        });

    }

    updateMealImagesAfterDelete(cache,response,mealID){

        const { mealImages } = cache.readQuery({ query: GET_MEAL_IMAGES, variables:{mealID} });
        var arr = [...mealImages];

        for(let i in response.data.deleteMealImages){
            for(let j in arr){
                if(response.data.deleteMealImages[i] == arr[j].mealImageID){
                    arr.splice(j,1);
                }
            }
        }

        cache.writeQuery({ 
            query:GET_MEAL_IMAGES,
            variables:{mealID},
            data:{
                mealImages: arr
            } 
        });
    }

    setDeleteImage(e,mealImageID,mealImagesID,setMealImages){

        if(e.target.checked){
            setMealImages([mealImageID,...mealImagesID]);
        }else{
            
            var arr = [...mealImagesID];
            for(let i in arr){
                if(arr[i] == mealImageID)arr.splice(i,1);
            }

            setMealImages(arr);
        }

    }

}

export default MealPhoto;