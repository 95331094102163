/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_ORDER = gql`
    mutation AddEditOrder($orderID:ID,$name:String,$surname:String,$street:String,$city:String,$zip:String,$countryID:ID,$lat:Float,$lng:Float){
        addEditOrder(orderID:$orderID,name:$name,surname:$surname,street:$street,city:$city,zip:$zip,countryID:$countryID,lat:$lat,lng:$lng){
            orderID
            name
            surname
            street
            city
            zip
            countryID
            lat
            lng
        }
    }
`;


/**
 *  Seznam veškerých athleteů
 */
export const GET_ORDERS = gql`
    query AllOrders($offset:Int,$limit:Int,$driverID:Int,$toDelivery:Boolean,$branchID:ID){
        allOrders(offset:$offset,limit:$limit,driverID:$driverID,toDelivery:$toDelivery,branchID:$branchID){            
            orderID
            driverID
            orderStatusID
            payuOrderID
            printNumber
            paymentTypeID
            shippingTypeID
            deliverySubjectID
            hash
            orderNumber
            name
            surname
            street
            city
            zip
            countryID
            deliveryDate
            inPreparationDate
            deliveredDate
            dateAdd
            deliveryPrice
            totalPrice
            creditToUse
            branchID
            refunded
            payuStatusID
            voucherValue
            voucherCode
            rating
            branch{
                name
            }
            driver{
                driverID
                adminUserID
                user{
                    name
                    surname
                } 
            }  
            orderStatus{
                name
            }
            payment{
                name
            }  
            shipping{
                name
            }   
            deliverySubject{
                title
            }
            country{
                countryID
                currencyID
                name
                currency{
                    code
                }
            }
        }
        settings{
            deliveryTypeID
        }  
        orderListCount
    }
`;

/**
 *  Seznam veškerých athleteů
 */
export const GET_ORDER = gql`
    query Order($orderID:ID){
        order(orderID:$orderID){   
            orderID  
            hash       
            name
            surname
            street
            city
            zip
            countryID  
            deliveryPrice
            totalPrice
            creditToUse
            voucherValue
            voucherCode
            deliveryDate
            inPreparationDate
            deliveredDate
            deliverySubjectID
            woltDeliveryOrder
            woltOrderReferenceId
            refunded
            items{
                orderItemID
                name
                price
                vat
                packagePrice
                packagePriceVat
                count
                isFree
                orderSideDishes{
                    name
                    price
                    vat
                }
                orderDrinks{
                    name
                    price
                    vat
                }
                orderOptions{
                    name
                    price
                    vat
                    packagePrice
                    packageVat
                }
            }  
            country{
                countryID
                currencyID
                name
                currency{
                    code
                }
            } 
            deliverySubject{
                title
            }
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_ORDERS = gql`
    mutation deleteOrders($orderIDs:ID!) {
        deleteOrders(orderIDs:$orderIDs)
    }
`;

export const STORNO_ORDER = gql`
mutation stornoOrder($orderID:ID!) {
    stornoOrder(orderID:$orderID)
}
`;

export const REFUND_ORDER = gql`
mutation refundOrder($orderID:ID!) {
    refundOrder(orderID:$orderID)
}
`;





