/**
 *  komponenta pro štítky pro produkty
 */

import React,{ Fragment, useState } from 'react';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import {GET_ORDERS,DELETE_ORDERS,STORNO_ORDER, REFUND_ORDER} from '../Queries/order';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import OrderLib from '../Library/order';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import AddEditOrder from './AddEditOrder';
import edit from '../../../Public/Images/edit.svg';
import invoice from '../../../Public/Images/notes.svg';
import storno from '../../../Public/Images/storno.svg';
import refund from '../../../Public/Images/refund.svg';
import moment from "moment";
import {SERVER_URL} from '../../../Config/index';
import { ADMIN_USER } from '../../../GlobalQueries/globalQueries';
import starIcon from '../../../Public/Images/star.svg';

const Orders = (props) => {
        
	const adminUserData = props.client.readQuery({ query:ADMIN_USER });

    const [selectedOrders,setSelectedOrders]        = useState([]);
    const [stornoOrderID,setStornoOrderID]          = useState(null);
    const [showDeleteNotifi,setShowDeleteNotifi]    = useState(false);
    const [showStornoNotifi,setShowStornoNotifi]    = useState(false);
    const [refundOrderID,setRefundOrderID]          = useState(null);
    const [showRefundNotifi,setShowRefundNotifi]    = useState(false);
    const [showAdd,setShowAdd] 			            = useState(false);
    const [selectedOrderID,setSelectedOrderID]      = useState(0);
    
    
    const listVariables = { 
        offset:0,
        limit:50,
        ...(adminUserData.adminUser.roleID == 5 	&& { branchID: adminUserData.adminUser.branchID }),
    };
    const {loading, error:getError, data:orderListData, fetchMore}   = useQuery(GET_ORDERS,{
        fetchPolicy:"network-only",
        variables:listVariables
    });
    
	const [deleteOrder, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_ORDERS,{	
		update(cache,response) {            
            let order = new OrderLib(this);
            order.updateAfterDelete(cache, response,listVariables);

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    const [stornoOrder, { data:stornoData,loading: stornoLoading, error: stornoError }] = useMutation(STORNO_ORDER,{	
		update(cache,response) { 

            let order = new OrderLib(this);
            order.updateAfterStorno(cache, response,listVariables);

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyStorno"),true,false);
            setShowStornoNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    const [refundOrder, { data:refundData,loading: refundLoading, error: refundError }] = useMutation(REFUND_ORDER,{	
		update(cache,response) { 

            let order = new OrderLib(this);
            order.updateAfterRefund(cache, response,listVariables);

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyRefunded"),true,false);
            setShowStornoNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    /**
     * smazání štítků
     * @param {*} deleteOrders funkce, která posílá data na server
     * @param {*} orderID ID orderu
     */
    
    const initiateDeleteOrder = (e,orderID) => {

        var checked = e.target.checked;
        var arr = [...selectedOrders];

        if(!checked){
            for(let i in selectedOrders){
                if(selectedOrders[i] == orderID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [orderID,...arr];
        }

        setSelectedOrders(arr);
  
    }

    const delOrder = (action) => {

        if(action){

            let orderIDs = selectedOrders.join(",");

            deleteOrder({
                variables:{
                    orderIDs: orderIDs
                }
            })
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    }

    const showDelNotifi = () => {

        if(selectedOrders.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const showStornoNotifiFun = (orderID) => {

        setStornoOrderID(orderID);
        setShowStornoNotifi(true);
        
    }
    const showRefundNotifiFun = (orderID) => {
        
        setRefundOrderID(orderID);
        setShowRefundNotifi(true);
        
    }

    const stornoOrd = (action) => {

        if(action){

            stornoOrder({
                variables:{
                    orderID: stornoOrderID
                }
            })
        }
        setShowStornoNotifi(false);
        setStornoOrderID(null);
    }
    const refundOrd = (action) => {

        if(action){

            refundOrder({
                variables:{
                    orderID: refundOrderID
                }
            })
        }
        setShowRefundNotifi(false);
        setRefundOrderID(null);
    }

    const openCloseModal = (open,orderID) => {
        setSelectedOrderID(orderID);
        setShowAdd(open);
    }

    
    if(getError || deleteError){
        var error = getError ? getError : (deleteError ? deleteError : stornoError);
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }
    return(

            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t("OrderList")}
                            {false && <button className="btn btn-danger btn-thiner ml-auto" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button> }
                        </div>
                        <div className="card-body">

                            {!error ?
                                <div>
                                    <div className="data-list">
                                        
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("OrderNumber")}</div>
                                                <div className="text-center">{translate.t("PrintNumber")}</div>
                                                <div className="text-center">{translate.t("TotalPrice")}</div>
                                                <div className="text-center">{translate.t("CreatedDate")}</div>
                                                <div className="text-center">{translate.t("Status")}</div>
                                                <div className="text-center">{translate.t("Shipping")}</div>
                                                {orderListData && orderListData.settings && orderListData.settings.deliveryTypeID == 1 &&
                                                    <div className="text-center">{translate.t("Driver")}</div>
                                                }
                                                <div className="text-center">{translate.t("Name")}</div>
                                                <div className="text-center">{translate.t("Address")}</div>
                                                <div className="text-center">{translate.t("Branch")}</div>
                                                <div className="text-center">{translate.t("PaymentType")}</div>
                                                <div className="text-center">{translate.t("UsedCredit")}</div>
                                                <div className="text-center">{translate.t("UsedVoucher")}</div>
                                                <div className="text-center">{translate.t("TimeDelivered")}</div>
                                                <div className="text-center">{translate.t("OrderRating")}</div>
                                                <div className="text-center">{translate.t("Refunded")}</div>
                                                <div className="text-right static small">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {orderListData && orderListData.allOrders && orderListData.allOrders.map((item,index) => {

                                                var checked = false;
                                                for(let i in selectedOrders){
                                                    if(selectedOrders[i] == item.orderID)checked = true;
                                                }                                            
                                                return (
                                                
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{item.orderNumber}</div>
                                                        <div className="text-center">{item.printNumber}</div>
                                                        <div className="text-center">{item.totalPrice} {item.country.currency.code}</div>
                                                        <div className="text-center">{(item.dateAdd != "0000-00-00 00:00:00" ? moment(item.dateAdd).format("DD.MM.YYYY HH:mm") : "")}</div>
                                                        
                                                        <div className="text-center">{(item.orderStatus ? translate.t(item.orderStatus.name) : "")}</div>
                                                        <div className="text-center">
                                                            {(item.shipping ? translate.t(item.shipping.name) : "" )}
                                                            {!!item.deliverySubject ? <div>{`(${item.deliverySubject.title})`}</div> : ""}
                                                        </div>
                                                        {orderListData.settings && orderListData.settings.deliveryTypeID == 1 &&
                                                            <div className="text-center">{(item.driver && item.driver.user ? item.driver.user.name + " " + item.driver.user.surname : "")}</div>
                                                        }
                                                        <div className="text-center">{item.name} {item.surname}</div>
                                                        <div className="text-center">{item.street} <br /> {item.zip} {item.city}</div>
                                                        <div className="text-center">{item.branch && item.branch.name}</div>
                                                        <div className="text-center">{item.payment && translate.t(item.payment.name)}</div>
                                                        <div className="text-center">{item.creditToUse}</div>
                                                        <div className="text-center">{item.voucherCode != "" ? item.voucherCode + (item.voucherValue > 0 ? " (-" + item.voucherValue + " " + item.country.currency.code + ")" : "") : ""}</div>
                                                        <div className="text-center">{(item.deliveredDate ? moment(item.deliveredDate).format("DD.MM.YYYY HH:mm") : "")}</div>

                                                        <div className="text-center">{!item.rating ? "-" : [...Array(Math.round(item.rating))].map((item,index) => (<img key={index} src={starIcon} className="order-rating-star" />))}</div>
                                                        <div className="text-center">{item.refunded ? translate.t("Yes") : translate.t("No")}</div>
                                                       
                                                        <div className="text-right static small text-nowrap">
                                                            <img onClick={() => openCloseModal(true,item.orderID)} title="Úprava objednávky" className="edit-icon" src={edit} />
                                                            {props.showInvoice &&
                                                                <a target="_blank" href={SERVER_URL + "/generate/invoice/" + item.hash}><img title="Faktura objednávky" className="invoice-icon" src={invoice} /></a>
                                                            }
                                                            { false && <input title="Smazání objednávky" className="delete-checked" type="checkbox" name="deleteOrder[]" checked={checked} onChange = {(e) => initiateDeleteOrder(e,item.orderID)} />}
                                                            {[1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) !== -1 && item.orderStatusID != 7 && item.orderStatusID != 8 &&
                                                                <img onClick={() => showStornoNotifiFun(item.orderID)} className="edit-icon ml-1" title="Storno objednávky" src={storno} />
                                                            }
                                                            {[1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) !== -1 && item.orderStatusID == 7 && item.refunded == 0 && item.payuStatusID == "COMPLETED" &&
                                                                <img onClick={() => showRefundNotifiFun(item.orderID)} className="edit-icon ml-1" title="Refundace objednávky" src={refund} />
                                                            }
                                                        </div>
                                                    </div>
                                                            
                                                )
                                                
                                            })} 
                                        </Fragment>
                                    </div>
                                    {!loading ? 
                                        <Fragment>
                                            {orderListData && orderListData.allOrders && orderListData.allOrders.length == 0 ?
                                                <NoItems text={translate.t("NoItemsAddedYet")} />
                                                :  
                                                <Fragment>     
                                                    {!loading && orderListData && orderListData.allOrders.length < orderListData.orderListCount &&                                 
                                                        <div className="text-center p-5">
                                                            <button className="btn btn-primary" onClick={() => fetchMore({
                                                                variables: {...listVariables,
                                                                    offset: orderListData.allOrders.length
                                                                },
                                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                                    if (!fetchMoreResult) return prev;
                                                                    return Object.assign({}, prev, {
                                                                        allOrders: [...prev.allOrders, ...fetchMoreResult.allOrders]
                                                                    });
                                                                }
                                                            })}> {translate.t("LoadMore")} </button>
                                                        </div> 
                                                    }
                                                </Fragment>
                                            }
                                        </Fragment>
                                        :                                    
                                        <Loading />                                    
                                    }
                                    
                                </div>
                                :
                                <Fragment>
                                    {error &&  <Error text={error} />}
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>

                {showAdd &&
                    <AddEditOrder openCloseModal={openCloseModal} selectedOrderID={selectedOrderID} listVariables={listVariables} />
                }
                {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delOrder} /> }
                {showStornoNotifi && <ModalNotification yesNo={true} text={translate.t("Storno?")} callback={stornoOrd} /> }
                {showRefundNotifi && <ModalNotification yesNo={true} text={translate.t("Refund?")} callback={refundOrd} /> }
                
            </div> 	
            	
        
    );

}

export default withApollo(Orders);