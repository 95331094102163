import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {GET_CUSTOMER,ADD_EDIT_CUSTOMER, GET_CUSTOMERS} from '../Queries/customer';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import CustomerLib from '../Library/customer';
import { ADMIN_USER } from '../../../GlobalQueries/globalQueries';
import AddCreditToCustomer from './AddCreditToCustomer';
import RemoveCreditFromCustomer from './RemoveCreditFromCustomer';


const AddEditCustomer = (props) => {

    const customerLib = new CustomerLib(props);

    const [showAddCredit,setShowAddCredit]              = useState(false);
    const [showRemoveCredit,setShowRemoveCredit]        = useState(false);
    const [selectedCreditToUse,setSelectedCreditToUse]  = useState(0);
    const [selectedCountryID,setSelectedCountryID]      = useState(0);
    const [selectedCustomerID,setSelectedCustomerID]    = useState(0);
    const [selectedTab, setTab]                         = useState(1);
    const [formData,setFormData]                        = useState({
        
        customerID:props.selectedCustomerID,
        name:"",
        surname:"",
        email:"",
        phone:"",
        allCredits:[]
    });

    const {data:customerData,loading:getLoading,error:getError,refetch} = useQuery(GET_CUSTOMER,{
        variables:{customerID: formData.customerID},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted(data){

            customerLib.getData(data,formData.customerID,formData,setFormData);
            
        },
    })

    const [addEditCustomer,{data:addCustomerData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_CUSTOMER,{
        onCompleted(data){

			props.openCloseModal(false);
            
		},update(cache,response) {    
            
            customerLib.updateList(cache,response,props.listVariables,formData.customerID);
            
            let notify = new Notification();
            if(formData.customerID && formData.customerID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},refetchQueries(data){
            return [{
                query:GET_CUSTOMERS,
                variables: props.listVariables
            }]
        },onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    })
    const getCountryTitle = (countryID) => {
        if(customerData && customerData.allActiveCountries){
            for(const country of customerData.allActiveCountries){
                if(country.countryID == countryID){
                    return country.name;
                }
            }
        }
        return "";
    }
    
    const openCloseAddCreditModal = (open,creditItem) => {
        if(open){
            setSelectedCustomerID(formData.customerID);
            setSelectedCountryID(creditItem.countryID);
            setSelectedCreditToUse(creditItem.credit - creditItem.unprocessedOrdersCredit);
        }else{
            setSelectedCustomerID(0);
            setSelectedCountryID(0);
            setSelectedCreditToUse(0);
        }
        setShowAddCredit(open);
    }
    
    const openCloseRemoveCreditModal = (open,creditItem) => {
        if(open){
            setSelectedCustomerID(formData.customerID);
            setSelectedCountryID(creditItem.countryID);
            setSelectedCreditToUse(creditItem.credit - creditItem.unprocessedOrdersCredit);
        }else{
            setSelectedCustomerID(0);
            setSelectedCountryID(0);
            setSelectedCreditToUse(0);
        }
        setShowRemoveCredit(open);
    }

    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError)
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.customerID && formData.customerID != 0 ? translate.t("EditCustomer") : translate.t("AddCustomer"))}</h4>
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                <Fragment>  
                                    <div className="tab-content" id="myTabContent">
                                        
                                            <div className="row">    
                                                                                    
                                                
                                                                                             
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("Email")}</label>
                                                        <div className="form-group">
                                                            {formData.email}
                                                        </div>
                                                    </div>                                                
                                                </div>                                               
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("Phone")}</label>
                                                        <div className="form-group">
                                                            {formData.phone}
                                                        </div>
                                                    </div>                                                
                                                </div>                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("Name")}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="name" value={formData.name} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>                                                
                                                </div>                                              
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("Surname")}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="surname" value={formData.surname} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>                                                
                                                </div>                
                                            </div>  
                                            <div className="row" style={{flexWrap:'wrap'}}>

                                                <div className="col-12">
                                                    <div className="card statistic main form-group">
                                                
                                                        <div className="card-header d-flex align-items-center flex-row bold">
                                                            {translate.t("Credit")}
                                                        </div>
                                                        <div className="card-body "> 
                                                            <div className="row">                           
                                                                {formData.allCredits && formData.allCredits.map((item,index) => ( 
                                                                    <div className="col-6" key={index}>
                                                                        <div className="form-group">
                                                                            <label className="input-label">{getCountryTitle(item.countryID)}</label>
                                                                            <table className='table table-bordered'>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className='text-center'>{translate.t("Credit")}</th>
                                                                                        <th className='text-center'>{translate.t("UnproccessedOrdersCredit")}</th>
                                                                                        <th className='text-center'>{translate.t("UsableCredit")}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className='text-center'>{item.credit}</td>
                                                                                        <td className='text-center'>{-item.unprocessedOrdersCredit}</td>
                                                                                        <td className='text-center'>{item.credit - item.unprocessedOrdersCredit}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <div className='d-flex flex-row align-items-center'>
                                                                                <button className='btn btn-primary mr-2' type='button' onClick={() => openCloseAddCreditModal(true,item)}>{translate.t("AddCredit")}</button>
                                                                                <button className='btn btn-danger' type='button' onClick={() => openCloseRemoveCreditModal(true,item)}>{translate.t("RemoveCredit")}</button>
                                                                            </div>
                                                                        </div> 
                                                                    </div> 
                                                                ))}
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div> 
                                            <div className="row" style={{flexWrap:'wrap'}}>

                                                <div className="col-12">
                                                    <div className="card statistic main form-group">
                                                
                                                        <div className="card-header d-flex align-items-center flex-row bold">
                                                            {translate.t("Statistic")}
                                                        </div>
                                                        <div className="card-body ">  
                                                            {customerData.specificCustomerStatistic && customerData.specificCustomerStatistic.map((item,index) => ( 
                                                                <div className="row" key={index}>                          
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label className="input-label">{translate.t("FinishedOrderCount")}</label>
                                                                            <div className="form-group">
                                                                                {item.orderCount}
                                                                            </div>
                                                                        </div> 
                                                                    </div> 
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label className="input-label">{translate.t("PriceForFinishedOrder")}</label>
                                                                            <div className="form-group">
                                                                                {item.allOrdersPrice} {item.code}
                                                                            </div>
                                                                        </div> 
                                                                    </div> 
                                                                </div> 
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>                                      

                                    </div>

                                </Fragment> 
                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => customerLib.addCustomer(addEditCustomer,formData)}>{(formData.customerID && formData.customerID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>	
            {showAddCredit &&
                <AddCreditToCustomer openCloseModal={openCloseAddCreditModal} customerID={selectedCustomerID} countryID={selectedCountryID} creditToUse={selectedCreditToUse} updated={() => refetch()} />
            }
            {showRemoveCredit &&
                <RemoveCreditFromCustomer openCloseModal={openCloseRemoveCreditModal} customerID={selectedCustomerID} countryID={selectedCountryID} creditToUse={selectedCreditToUse} updated={() => refetch()} />
            }
        </Modal>
    )

}

export default withApollo(AddEditCustomer);