/**
 * soubor se seznamem veškerých graphql dotazů
 */
import gql from 'graphql-tag';


export const DATA_FOR_STATISTIC = gql`
    query DataForStatistic($filterData:StatisticFilterData){

        ordersForStatistics(filterData:$filterData){
            orderID
            hash
            branchID
            orderStatusID
            paymentTypeID
            shippingTypeID
            deliveryAreaID
            deliveryDate
            printNumber
            totalPrice
            deliveryPrice
            deliveryPriceVat
            creditToUse
            voucherValue
            dateAdd
            items{
                orderItemID
                name
                price
                vat
                packagePrice
                packagePriceVat
                count
                isFree
                orderSideDishes{
                    name
                    price
                    vat
                }                
                orderDrinks{
                    name
                    price
                    vat
                }
                orderOptions{
                    name
                    price
                    vat
                    packagePrice
                    packageVat
                }
            }
            
        }

        allDrivers(offset:0,limit:100000){
            driverID
            adminUserID
            user{                
                adminUserID
                name
                surname
            }
        }        
        allActiveCountries(fromAdmin:1){
            countryID
            name
            currencyID
            currency{
                code
            }
        }
    }
`;

export const DATA_FOR_STATISTIC_MEALS = gql`
    query DataForStatisticMeals($filterData:StatisticFilterData){

        mealStatistic(filterData:$filterData){
            meals{
                name
                soldCount
            }
            sideDishes{
                name
                soldCount
            }
            drinks{
                name
                soldCount
            }
            options{
                name
                soldCount
            }
        }

        allDrivers(offset:0,limit:100000){
            driverID
            adminUserID
            user{                
                adminUserID
                name
                surname
            }
        }         
        allActiveCountries(fromAdmin:1){
            countryID
            name
            currencyID
            currency{
                code
            }
        }
    }
`;
export const DATA_FOR_STATISTIC_MAP_ORDERS = gql`
    query DataForStatistic($filterData:StatisticFilterData){

        ordersForStatistics(filterData:$filterData){
            orderID
            hash     
            lat
            lng
            distance       
            shippingTypeID
        }

        allDrivers(offset:0,limit:100000){
            driverID
            adminUserID
            user{                
                adminUserID
                name
                surname
            }
        }            
        allActiveCountries(fromAdmin:1){
            countryID
            name
            currencyID
            currency{
                code
            }
        }
    }
`;
export const DATA_FOR_STATISTIC_CREDIT_AND_VOUCHERS = gql`
    query VoucherCreditStatistic($filterData:StatisticFilterData){

        creditStatistic(filterData:$filterData){
            creditsAddedTotal
            creditsRemovedTotal
            creditsAddedForPeirod
            creditsRemovedForPeirod
        }
        voucherStatistic(filterData:$filterData){
            voucherUsedPriceTotal
            voucherUsedPricePeirod 
            vouchers{
                code
                price
            }    
        }

        allDrivers(offset:0,limit:100000){
            driverID
            adminUserID
            user{                
                adminUserID
                name
                surname
            }
        }            
        allActiveCountries(fromAdmin:1){
            countryID
            name
            currencyID
            currency{
                code
            }
        }
    }
`;

export const DATA_FOR_STATISTIC_CUSTOMERS = gql`
    query CustomerStatistic($filterData:StatisticFilterData){

        customerStatistic(filterData:$filterData){      
            name
            surname
            price
            count
        }

        allDrivers(offset:0,limit:100000){
            driverID
            adminUserID
            user{                
                adminUserID
                name
                surname
            }
        }        
        allActiveCountries(fromAdmin:1){
            countryID
            name
            currencyID
            currency{
                code
            }
        }
    }
`;

export const BRANCHES_FOR_STATISTICS = gql`
    query AllBranchesForStatistics($countryID:ID){
        allBranches(offset:0,limit:100000,onlyActive:true,countryID:$countryID){            
            branchID
            name
        }
    }
`;