import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {ADD_EDIT_DRIVER, GET_DRIVER} from '../Queries/driver';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import DriverLib from '../Library/driver';
import OrdersToDelivery from './OrdersToDelivery';
import OrdersHistory from './OrdersHistory';
import { ADMIN_USER } from '../../../GlobalQueries/globalQueries';


const AddEditDriver = (props) => {

    const driverLib = new DriverLib(props);

    const adminUserData = props.client.readQuery({ query:ADMIN_USER });
    console.log(adminUserData);
    const [selectedTab, setTab] = useState(1);
    const [formData,setFormData] = useState({
        
        driverID:props.selectedDriverID,
        driverTypeID:0,
        adminUserID:0,
        deliveryAreaID:0,
        description:"",
        phone:"",
        realTimeMealsCount:0

    });

    const {data:driverData,loading:getLoading,error:getError} = useQuery(GET_DRIVER,{
        variables:{driverID: formData.driverID},
        fetchPolicy: 'network-only',
        onCompleted(data){

            driverLib.getData(data,formData.driverID,formData,setFormData);
            
        },
    })

    const [addEditDriver,{data:addDriverData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_DRIVER,{
        onCompleted(data){

			props.openCloseModal(false);
            
		},update(cache,response) {    
            
            driverLib.updateList(cache,response,props.listVariables,formData.driverID);
            
            let notify = new Notification();
            if(formData.driverID && formData.driverID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    })

    const driverTypeChanged = (driverTypeID) => {

        setFormData({...formData,deliveryAreaID:0,driverTypeID});
    }

    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError)
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.driverID && formData.driverID != 0 ? translate.t("EditDriver") : translate.t("AddDriver"))}</h4>
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                <Fragment>  

                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className={(selectedTab == 1 ? "nav-link active" : "nav-link")} href="#main" onClick={(e) => setTab(1)}>{translate.t("MainData")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 2 ? "nav-link active" : (formData.driverID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.driverID)setTab(2)}}>{translate.t("OrdersToDelivery")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 3 ? "nav-link active" : (formData.driverID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.driverID)setTab(3)}}>{translate.t("OrdersHistory")}</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        
                                        {selectedTab == 1 &&
                                            <div className="row">    
                                                                                    
                                                
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="input-label">*{translate.t("DriverType")} </label>
                                                        <div className="form-group">
                                                            <select className="form-control" name="driverTypeID"  value={formData.driverTypeID} onChange={(e) => driverTypeChanged(e.target.value)}>
                                                                <option value="0"> --- {translate.t("ChooseType")} --- </option>
                                                                {driverData && driverData.allDriverTypes.map((it,ind) => (
                                                                    <option key={ind} value={it.driverTypeID}>{it.title}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>                                                
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="input-label">*{translate.t("Driver")} </label>
                                                        <div className="form-group">
                                                            <select className="form-control" name="adminUserID"  value={formData.adminUserID} onChange={(e) => driverLib.formHandle(e,formData,setFormData)}>
                                                                <option value="0"> --- {translate.t("ChooseDriver")} --- </option>
                                                                {driverData && driverData.allUnusedAdminUserDrivers.map((it,ind) => (
                                                                    <option key={ind} value={it.adminUserID}>{it.name} {it.surname}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>                                                
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="input-label">*{translate.t("DeliveryArea")} </label>
                                                        <div className="form-group">
                                                                <select className="form-control" name="deliveryAreaID"  value={formData.deliveryAreaID} onChange={(e) => driverLib.formHandle(e,formData,setFormData)}>
                                                                    <option value="0"> --- {translate.t("ChooseDeliveryArea")} --- </option>
                                                                    {formData.driverTypeID == 1 &&
                                                                        <>
                                                                            {driverData && driverData.allNoBranchDeliveryAreas.map((it,ind) => (
                                                                                <option key={ind} value={it.deliveryAreaID}>{it.name}</option>
                                                                            ))}
                                                                        </>
                                                                    }
                                                                    {formData.driverTypeID == 2 &&
                                                                        <>
                                                                            {driverData && driverData.allBranchDeliveryAreas.map((it,ind) => (
                                                                                <option key={ind} value={it.deliveryAreaID}>{it.name}</option>
                                                                            ))}
                                                                        </>
                                                                    }
                                                                </select>
                                                        </div>
                                                    </div>                                                
                                                </div> 

                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="input-label">*{translate.t("Phone")}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="phone" value={formData.phone} onChange={(e) => driverLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>                                                
                                                </div> 
                                                {formData.driverTypeID == 1 &&
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="input-label">*{translate.t("NumberOfRealTimeMeal")}</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="number" name="realTimeMealsCount" value={formData.realTimeMealsCount} onChange={(e) => driverLib.formHandle(e,formData,setFormData)} />
                                                            </div>
                                                        </div>                                                
                                                    </div> 
                                                }

                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("InternalCarDescription")}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="description" value={formData.description} onChange={(e) => driverLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>                                                
                                                </div>                                 
                                                
                                                                        
                                            </div> 
                                        }

                                        {selectedTab == 2 &&

                                            <OrdersToDelivery driverID = {formData.driverID} />

                                        }

                                        {selectedTab == 3 &&

                                            <OrdersHistory driverID = {formData.driverID} />

                                        }

                                    </div>

                                </Fragment> 
                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button athlete="button" className="btn btn-primary flex-fill" onClick={() => driverLib.addDriver(addEditDriver,formData)}>{(formData.driverID && formData.driverID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )

}

export default withApollo(AddEditDriver);