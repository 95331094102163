
 
import {ADMIN_USER} from '../GlobalQueries/globalQueries';

/**
 *  Pomocná třída s obecnými funkcemi
 */
class Helper{

    getApolloErrorText(error){

        error = JSON.stringify(error);
        error = JSON.parse(error);

        if(error.graphQLErrors[0])return error.graphQLErrors[0].message;
        if(error.networkError && error.networkError.result.errors){
            return error.networkError.result.errors[0].message;
        }

    }

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    goToInitialPage(history){
        history.push("/orders");
    }

    checkAdminRights(props,roleIDRestrictionArray){
        const adminUserData = props.client.readQuery({ query:ADMIN_USER });
        if(!(adminUserData && roleIDRestrictionArray.includes(adminUserData.adminUser.roleID))){
            this.goToInitialPage(props.history);
        }
        return adminUserData;
    }

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

}

export default Helper;