import {GET_CUSTOMERS} from '../Queries/customer';
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';

class Customer {


    addCustomer(addEditCustomer,formData){
        formData.lang = translate.locale;

           
        if(formData.name != ""){
            if(formData.surname != ""){

                let addData = {                    
                    customerID:formData.customerID,
                    name:formData.name,
                    surname:formData.surname,
                    email:formData.email,
                    phone:formData.phone
                };

                addEditCustomer({
                    variables:addData
                }); 
            }else{
                let notify = new Notification();
                notify.setNotification(null,translate.t("InvalidCustomerSurname"),false,true,this.props.client);
            }
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidCustomerName"),false,true,this.props.client);
        }

    }

    getData(data,customerID,formData,setFormData){

        if(customerID != 0){
            
            let allCredits = [];
            for(const country of data.allActiveCountries){
                let creditObj = {
                    countryID:country.countryID,
                    credit:0,
                    unprocessedOrdersCredit:0
                };
                for(let creditItem of data.customer.allCredits){
                    if(country.countryID == creditItem.countryID){
                        creditObj.credit                    = creditItem.credit
                        creditObj.unprocessedOrdersCredit   = creditItem.unprocessedOrdersCredit
                    }
                } 
                allCredits.push(creditObj);
            }

            setFormData({...formData,
                name: data.customer.name,
                surname: data.customer.surname,
                email: data.customer.email, 
                phone:  data.customer.phone, 
                allCredits, 
            });
        }
    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateList(cache, response,variables,customerID){

        const { allCustomers,customerListCount } = cache.readQuery({ query: GET_CUSTOMERS ,variables});

        
        if(customerID && customerID != 0){

            var arr = [...allCustomers];

            arr.forEach((item,index) => {
                
                if(item.customerID == response.data.addEditCustomer.customerID){
                    arr[index] = response.data.addEditCustomer;
                }

            });

            cache.writeQuery({ 
                query:GET_CUSTOMERS,
                variables,
                data:{
                    allCustomers: arr,
                    customerListCount :customerListCount
                } 
            });

        }else{
            var arr = [response.data.addEditCustomer,...allCustomers];

            cache.writeQuery({ 
                query:GET_CUSTOMERS,
                variables,
                data:{
                    allCustomers: arr,
                    customerListCount :customerListCount
                } 
            });
        }

    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteCustomers.split(",");

        const { allCustomers,customerListCount } = cache.readQuery({ query: GET_CUSTOMERS ,variables});
        var arr = [...allCustomers];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.customerID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_CUSTOMERS,
            variables,
            data:{
                allCustomers: [...arr],
                customerListCount :customerListCount - resp.length
            } 
        });

    }
    
    formCreditHandle(countryID,e,formData,setFormData){
	  
        const creditValue = e.target.value;
        
        let allCredits = [...formData.allCredits];

        for(let credit of allCredits){
            if(countryID == credit.countryID){
                credit.credit = creditValue;
            }
        }

        setFormData({...formData,allCredits});
              
    }
    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }
}

export default Customer;