import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {ADD_EDIT_BRANCH, GET_BRANCH,GET_BRANCH_MEALS_OPENING_TIMES} from '../Queries/branch';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import BranchLib from '../Library/branch';
import BranchMeals from './BranchMeals';
import BranchBlockedDays from './BranchBlockedDays';
import BranchOpeningTimes from './BranchOpeningTimes';
import {BRANCH_IMAGE_URL,SERVER_URL,MAX_UPLOAD_FILE_LIMIT} from '../../../Config/index';
import AddressSearch from '../../../GlobalComponents/AddressSearch';
import remove from '../../../Public/Images/remove.svg';


const AddEditBranch = (props) => {

    const branchLib = new BranchLib(props);

    const [selectedTab, setTab]         = useState(1);
    const [formData,setFormData]        = useState({
        
        branchID:props.selectedBranchID,
        name:"",
        street:"",
        city:"",
        zip:"",
        lat:0,
        lng:0,
        photo:"",
        phone:"",
        invoiceCompany:"",
        invoiceStreet:"",
        invoiceZip:"",
        invoiceCity:"",
        invoiceCountry:"",
        invoiceIC:"",
        invoiceDIC:"",
        countryID:60,
        deliveryAreaID:"0",
        selectedMeals:[],
        openTimes:[],
        hideTakeAwayOption:"0",
        hideEatInOption:"0",
        useStaticDeliveryPrice:"1",
        staticDeliveryPrice:"0",
        freeDeliveryPriceValue:"0",
        deliveryUnitPrice:"0",
        unitSize:"0",
        freeUnitCount:"0",
        maxDeliveryPrice:"0",
        active:"1",
        ownDeliveryEnabled:"1",
        woltDeliveryEnabled:"0",
        minimumOrderAmount:"0",
        minimumMealPreparationTimeForWolt:"15",
        cashPayForDeliveryEnabled:"1",
        cashPayForEatInAndTakeAwayEnabled:"1"
    });

    const [mainPhoto, setMainPhoto] = useState("");

    const {data:branchData,loading:getLoading,error:getError,refetch:refetchBranch} = useQuery(GET_BRANCH,{
        variables:{branchID: formData.branchID},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted(data){

            branchLib.getData(data,formData.branchID,formData,setFormData);

            if(formData.branchID){
                setMainPhoto(SERVER_URL + "/" + BRANCH_IMAGE_URL + "/b-" + formData.branchID + "/velka_" + data.branch.photo);
            }
            
        },
    })

    const {data,loading, error:opError, refetch} = useQuery(GET_BRANCH_MEALS_OPENING_TIMES,{
        variables:{
            branchID: formData.branchID
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted(data){

            var meals = [];
            for(let i in data.allBranchMealsForAdmin){
                meals.push(data.allBranchMealsForAdmin[i].mealID);
            }

            var arr =  data.allBranchOpeningTimes;

            for(let val of arr){
                if(val.times.length > 0){
                    for(let t of val.times){
                        delete t["__typename"];
                    }
                }
                delete val["__typename"];
            }
            setFormData({...formData,selectedMeals:meals,openTimes:data.allBranchOpeningTimes});

        }
    });

    const [addEditBranch,{data:addBranchData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_BRANCH,{
        onCompleted(data){
            if(!formData.branchID){
                setFormData({...formData,branchID:data.addEditBranch.branchID,photo:""})
                setTab(2);
            }else{
                props.openCloseModal(false);
            }
		},update(cache,response) {  
            branchLib.updateList(cache,response,props.listVariables,formData.branchID);

            refetch();
            refetchBranch();
            let notify = new Notification();
            if(formData.branchID && formData.branchID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            console.log(err);
            
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
            
        }
    })

    const openMainImage = (e) => {

        var file = e.target.files[0];
        if(file){
            var reader = new FileReader();
            reader.onload = (onLoadE) => {
                if((onLoadE.total / 1000000) <= MAX_UPLOAD_FILE_LIMIT){
                    var dataURL = reader.result;

                    setMainPhoto(dataURL);
                    setFormData({...formData,photo:file});
                }else{
                    let notify      = new Notification();
                    notify.setNotification(null,translate.t("MaxFileSizeExceeded"),false,true,props.client);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    const addressSelected = (addressItem) => {
        console.log(addressItem);
        setFormData({...formData,street:addressItem.street + " " + addressItem.number,city:addressItem.city,zip:addressItem.zip,lat:addressItem.lat,lng:addressItem.lng});
    }

    const getSelectedCurrencyCode = () => {
        if(!!branchData && !!branchData.allActiveCountries){
            for(const country of branchData.allActiveCountries){
                if(country.countryID == formData.countryID && !!country.currency){
                    return country.currency.code;
                }
            }
        }else{
            return "";
        }
    }

    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError)
    }
    console.log(formData.freeUnitCount);
    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.branchID && formData.branchID != 0 ? translate.t("EditBranch") : translate.t("AddBranch"))}</h4>
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                <Fragment>  

                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className={(selectedTab == 1 ? "nav-link active" : "nav-link")} href="#main" onClick={(e) => setTab(1)}>{translate.t("MainData")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 2 ? "nav-link active" : (formData.branchID ? 'nav-link' : 'nav-link disabled'))} href="#meals" onClick={(e) => {e.preventDefault();if(formData.branchID)setTab(2)}}>{translate.t("Meals")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 3 ? "nav-link active" : (formData.branchID ? 'nav-link' : 'nav-link disabled'))} href="#blocked-days" onClick={(e) => {e.preventDefault();if(formData.branchID)setTab(3)}}>{translate.t("OpeningTimes")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 4 ? "nav-link active" : (formData.branchID ? 'nav-link' : 'nav-link disabled'))} href="#opening-times" onClick={(e) => {e.preventDefault();if(formData.branchID)setTab(4)}}>{translate.t("BlockedDays")}</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        
                                        {selectedTab == 1 &&

                                            <div className="row">

                                                <div className="col-6">

                                                    <label className="input-label">*{translate.t("MainPhoto")}</label>

                                                    <div className="form-group input-group">
                                                        <div className="custom-file">
                                                            <input type="file" accept=".jpg, .jpeg" className="custom-file-input" id="customFile" required onChange={(e) => openMainImage(e)} />
                                                            <label className="custom-file-label" htmlFor="customFile">{translate.t("ChoosePhoto")}<small className=" ml-2">(JPG/JPEG, {translate.t("Max")} {MAX_UPLOAD_FILE_LIMIT}Mb)</small></label>
                                                        </div>                                                        
                                                    </div>
                                                    {mainPhoto != "" &&
                                                        <div className="form-group main-photo-container">
                                                            
                                                            <div className="one-image">            
                                                                <img id="main-image" src={mainPhoto} />
                                                            </div>

                                                        </div>
                                                    }
                                                    
                                                    <div className="row" >
                                                        <div className="col-12 mt-5">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    {translate.t("BillingInformation")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row" >
                                                                        <div className="col-4">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("Company")}</label>
                                                                                <div className="form-group">
                                                                                    <input className="form-control" type="text" name="invoiceCompany" value={formData.invoiceCompany} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                </div>
                                                                            </div>                                                
                                                                        </div> 
                                                                        <div className="col-4">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("Street")}</label>
                                                                                <div className="form-group">
                                                                                    <input className="form-control" type="text" name="invoiceStreet" value={formData.invoiceStreet} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                </div>
                                                                            </div>                                                
                                                                        </div> 
                                                                        <div className="col-4">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("City")}</label>
                                                                                <div className="form-group">
                                                                                    <input className="form-control" type="text" name="invoiceCity" value={formData.invoiceCity} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                </div>
                                                                            </div>                                                
                                                                        </div> 
                                                                        <div className="col-4">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("Zip")}</label>
                                                                                <div className="form-group">
                                                                                    <input className="form-control" type="text" name="invoiceZip" value={formData.invoiceZip} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                </div>
                                                                            </div>                                                
                                                                        </div> 
                                                                        <div className="col-4">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("Country")}</label>
                                                                                <div className="form-group">
                                                                                    <input className="form-control" type="text" name="invoiceCountry" value={formData.invoiceCountry} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                </div>
                                                                            </div>                                                
                                                                        </div> 
                                                                        <div className="col-4">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("IC")}</label>
                                                                                <div className="form-group">
                                                                                    <input className="form-control" type="text" name="invoiceIC" value={formData.invoiceIC} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                </div>
                                                                            </div>                                                
                                                                        </div> 
                                                                        <div className="col-4">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("DIC")}</label>
                                                                                <div className="form-group">
                                                                                    <input className="form-control" type="text" name="invoiceDIC" value={formData.invoiceDIC} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                </div>
                                                                            </div>                                                
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-6">
                                                    <div className="row">    
                                                                                            
                                                    
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <label className="input-label">*{translate.t("Name")}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="name" value={formData.name} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div> 
                                                        <div className="col-4">
                                                            <label className="input-label">{translate.t("DeliveryArea")}</label>
                                                            <select className="form-control" name="deliveryAreaID" value={formData.deliveryAreaID} onChange={(e) => branchLib.formHandle(e,formData,setFormData)}>
                                                                <option value="0">-- {translate.t("ChooseDeliveryArea")} --</option>
                                                                {branchData && branchData.allDeliveryAreasForBranchAdmin && branchData.allDeliveryAreasForBranchAdmin.map((item,index) => (
                                                                    <option key={index} value={item.deliveryAreaID}> {item.name} </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-4">
                                                            <label className="input-label">{translate.t("Active")}</label>
                                                            <div className="form-group">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(formData.active == 1 ?  true : false)} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                    <label className="form-check-label" htmlFor="active1">
                                                                        Ano
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(formData.active == 0 ?  true : false)} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                    <label className="form-check-label" htmlFor="active2">
                                                                        Ne
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>                                                         
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <div className="form-group">
                                                                        <label className="input-label">*{translate.t("Country")}</label>
                                                                        <select className="form-control" name="countryID" value={formData.countryID} onChange={(e) => branchLib.formHandle(e,formData,setFormData)}>
                                                                            {branchData && branchData.allActiveCountries && branchData.allActiveCountries.map((item,index) => (
                                                                                <option key={index} value={item.countryID}> {item.name} </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="form-group">
                                                                <label className="input-label">*{translate.t("Address")}</label>
                                                                <div className="form-group align-items-center d-flex">                                                                    
                                                                    {formData.street ?
                                                                        <>
                                                                            {formData.street}, {formData.zip} {formData.city}
                                                                            <img onClick={() => setFormData({...formData,street:"",city:"",zip:"",lat:0,lng:0})} className="remove-icon ml-2" src={remove} title={translate.t("Clear")} />
                                                                        </>
                                                                        :          
                                                                        <>                                                              
                                                                            <AddressSearch onSelected={(addressItem) => addressSelected(addressItem)} />
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>                               
                                                        </div> 
                                                        {false &&
                                                        <>
                                                            <div className="col-4">
                                                                <div className="form-group">
                                                                    <label className="input-label">*{translate.t("Street")}</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="street" value={formData.street} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>                                                
                                                            </div> 
                                                            <div className="col-4">
                                                                <div className="form-group">
                                                                    <label className="input-label">*{translate.t("City")}</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="city" value={formData.city} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>                                                
                                                            </div> 
                                                            <div className="col-4">
                                                                <div className="form-group">
                                                                    <label className="input-label">{translate.t("Zip")}</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="zip" value={formData.zip} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>                                                
                                                            </div> 
                                                        </>  
                                                        }
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("Phone")}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="phone" value={formData.phone} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div>                                 
                                                        
                                                        <div className="col-12 mt-5">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    {translate.t("Settings")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row" >
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("MinimumOrderAmount")}</label>
                                                                                <div className="input-group mb-3">
                                                                                    <input className="form-control" type="number" name="minimumOrderAmount" step="1" value={formData.minimumOrderAmount} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                    <span className="input-group-text">{getSelectedCurrencyCode()}</span>
                                                                                </div>
                                                                            </div>                                                
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("MinimumMealPreparationTimeForWolt")}</label>
                                                                                <div className="input-group mb-3">
                                                                                    <input className="form-control" type="number" name="minimumMealPreparationTimeForWolt" step="1" value={formData.minimumMealPreparationTimeForWolt} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                    <span className="input-group-text">min</span>
                                                                                </div>
                                                                            </div>                                                
                                                                        </div>                                                                        
                                                                    </div>
                                                                    <hr/>
                                                                    <div className="row" >
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("CashPayForDeliveryEnabled")}</label>
                                                                                <div className="form-group">
                                                                                    <select className="form-control" name="cashPayForDeliveryEnabled" value={formData.cashPayForDeliveryEnabled} onChange={(e) => branchLib.formHandle(e,formData,setFormData)}>
                                                                                        <option value="1">{translate.t("Yes")}</option>
                                                                                        <option value="0">{translate.t("No")}</option>
                                                                                    </select>
                                                                                    
                                                                                </div>
                                                                            </div>  
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("CashPayForEatInAndTakeAwayEnabled")}</label>
                                                                                <div className="form-group">
                                                                                    <select className="form-control" name="cashPayForEatInAndTakeAwayEnabled" value={formData.cashPayForEatInAndTakeAwayEnabled} onChange={(e) => branchLib.formHandle(e,formData,setFormData)}>
                                                                                        <option value="1">{translate.t("Yes")}</option>
                                                                                        <option value="0">{translate.t("No")}</option>
                                                                                    </select>
                                                                                    
                                                                                </div>
                                                                            </div>                                                
                                                                        </div> 
                                                                    </div>
                                                                    <hr/>
                                                                    <div className="row" >
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("HideEatInOption")}</label>
                                                                                <div className="form-group">
                                                                                    <select className="form-control" name="hideEatInOption" value={formData.hideEatInOption} onChange={(e) => branchLib.formHandle(e,formData,setFormData)}>
                                                                                        <option value="1">{translate.t("Yes")}</option>
                                                                                        <option value="0">{translate.t("No")}</option>
                                                                                    </select>
                                                                                    
                                                                                </div>
                                                                            </div>                                                
                                                                        </div>  

                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("HideTakeAwayOption")}</label>
                                                                                <div className="form-group">
                                                                                    <select className="form-control" name="hideTakeAwayOption" value={formData.hideTakeAwayOption} onChange={(e) => branchLib.formHandle(e,formData,setFormData)}>
                                                                                        <option value="1">{translate.t("Yes")}</option>
                                                                                        <option value="0">{translate.t("No")}</option>
                                                                                    </select>
                                                                                    
                                                                                </div>
                                                                            </div>                                                
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt-5">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    {translate.t("DeliverySettings")}
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row" >
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("FreeDeliveryPriceValue")}</label>
                                                                                <div className="input-group mb-3">
                                                                                    <input className="form-control" type="number" name="freeDeliveryPriceValue" step="1" value={formData.freeDeliveryPriceValue} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                    <span className="input-group-text">{getSelectedCurrencyCode()}</span>
                                                                                </div>
                                                                            </div>                                                
                                                                        </div>
                                                                    </div>
                                                                    <hr/>
                                                                    <div className="row" >
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("EnableOwnDelivery")}</label>
                                                                                <div className="form-group">
                                                                                    <select className="form-control" name="ownDeliveryEnabled" value={formData.ownDeliveryEnabled} onChange={(e) => branchLib.formHandle(e,formData,setFormData)}>
                                                                                        <option value="1">{translate.t("Yes")}</option>
                                                                                        <option value="0">{translate.t("No")}</option>
                                                                                    </select>
                                                                                    
                                                                                </div>
                                                                            </div>                                                
                                                                        </div>  
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("EnableWoltDelivery")}</label>
                                                                                <div className="form-group">
                                                                                    <select className="form-control" name="woltDeliveryEnabled" value={formData.woltDeliveryEnabled} onChange={(e) => branchLib.formHandle(e,formData,setFormData)}>
                                                                                        <option value="1">{translate.t("Yes")}</option>
                                                                                        <option value="0">{translate.t("No")}</option>
                                                                                    </select>
                                                                                    
                                                                                </div>
                                                                            </div>                                                
                                                                        </div>                                                 
                                                                    </div>  
                                                                    <hr/>
                                                                    <div className="row" >
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{translate.t("UseStaticDeliveryPrice")}</label>
                                                                                <div className="form-group">
                                                                                    <select className="form-control" name="useStaticDeliveryPrice" value={formData.useStaticDeliveryPrice} onChange={(e) => branchLib.formHandle(e,formData,setFormData)}>
                                                                                        <option value="1">{translate.t("Yes")}</option>
                                                                                        <option value="0">{translate.t("No")}</option>
                                                                                    </select>
                                                                                    
                                                                                </div>
                                                                            </div>                                                
                                                                        </div>  
                                                                        {formData.useStaticDeliveryPrice == 1 ?
                                                                            <div className="col-6">
                                                                                <div className="form-group">
                                                                                    <label className="input-label">{translate.t("StaticDeliveryPrice")}</label>
                                                                                    <div className="input-group mb-3">
                                                                                        <input className="form-control" type="number" name="staticDeliveryPrice" step="1" value={formData.staticDeliveryPrice} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                        <span className="input-group-text">{getSelectedCurrencyCode()}</span>
                                                                                    </div>
                                                                                </div>                                                
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                <div className="col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="input-label">{translate.t("DeliveryUnitPrice")}</label>
                                                                                        <div className="input-group mb-3">
                                                                                            <input className="form-control" type="number" name="deliveryUnitPrice" step="1" value={formData.deliveryUnitPrice} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                            <span className="input-group-text">{getSelectedCurrencyCode()}</span>
                                                                                        </div>
                                                                                    </div>                                                
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="input-label">{translate.t("UnitSize")}</label>
                                                                                        <div className="input-group mb-3">
                                                                                            <input className="form-control" type="number" name="unitSize" step="1" value={formData.unitSize} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                            <span className="input-group-text">m</span>
                                                                                        </div>
                                                                                    </div>                                                
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="input-label">{translate.t("FreeUnitCount")}</label>
                                                                                        <div className="input-group mb-3">
                                                                                            <input className="form-control" type="number" name="freeUnitCount" step="1" value={formData.freeUnitCount} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                        </div>
                                                                                    </div>                                                
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="input-label">{translate.t("MaxDeliveryPrice")}</label>
                                                                                        <div className="input-group mb-3">
                                                                                            <input className="form-control" type="number" name="maxDeliveryPrice" step="1" value={formData.maxDeliveryPrice} onChange={(e) => branchLib.formHandle(e,formData,setFormData)} />
                                                                                            <span className="input-group-text">{getSelectedCurrencyCode()}</span>
                                                                                        </div>
                                                                                    </div>                                                
                                                                                </div>
                                                                            </>
                                                                        } 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>           
                                                    </div> 
                                                </div>
                                            </div>
                                        }

                                        {selectedTab == 2 &&

                                            <BranchMeals branchID = {formData.branchID} formData = {formData} setFormData={setFormData} branchData={branchData} />

                                        }

                                        {selectedTab == 3 &&

                                            <BranchOpeningTimes branchID = {formData.branchID} formData = {formData} setFormData={setFormData} />

                                        }

                                        {selectedTab == 4 &&

                                            <BranchBlockedDays branchID = {formData.branchID} />

                                        }

                                    </div>

                                </Fragment> 
                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button athlete="button" className="btn btn-primary flex-fill" onClick={() => branchLib.addBranch(addEditBranch,formData)}>{(formData.branchID && formData.branchID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )

}

export default withApollo(AddEditBranch);