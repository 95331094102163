import translate from '../../Translations/index';

class Category {

    constructor(props){
        this.props = props;
    }

    addCategory(addCategory,formData){
           
        formData.active = parseInt(formData.active);
        
        addCategory({
            variables:formData
        }); 
        
                                  

    }

    getData(data,categoryID,formData,setFormData){

        if(categoryID == 0){

            let langs = this.completeLangsArray([],data.allLanguageMutations); 
            setFormData({...formData,langs:langs});

        }else{
            
            setFormData({...formData,
                active: data.categoryWithLangs.active,
                categoryID: data.categoryWithLangs.categoryID,
                langs: this.completeLangsArray(data.categoryWithLangs.langs,data.allLanguageMutations)
            });
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(lang.__typename)delete(lang["__typename"]);
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    name:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e,formData,setFormData){

        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        var langs = formData.langs;

        for(const lang of langs){
            if(lang.lang == formData.selectedLang){
                lang[n] = v;
            }
        }

        setFormData({...formData,langs: langs});
         
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} propertyName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (formData,propertyName) => {
        for(const lang of formData.langs){
            if(lang.lang == formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }

}

export default Category;