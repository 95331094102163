import translate from '../../Translations/index';
import Notification from '../../../Library/notification';

class DrinkPrice {

    constructor(props){
        this.props = props;
    }

    addDrinkPrice(addDrinkPrice,formData){

        if(formData.price !== ""){
            
            formData.price          = parseFloat(formData.price);
            addDrinkPrice({
                variables:{
                    drinkPriceInputs:formData
                }
            }); 

        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidPrice"),false,true,this.props.client);
        }                     

    }

    getData(data,drinkPriceID,formData,setFormData){
        console.log(drinkPriceID);
        if(drinkPriceID != 0){            
            setFormData({...formData,
                drinkPriceID: data.drinkPrice.drinkPriceID,
                countryID: data.drinkPrice.countryID,
                price: data.drinkPrice.price,
                vatID: data.drinkPrice.vatID,
            });
        }
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default DrinkPrice;