import gql from 'graphql-tag';

export const GET_OPTION_PRICES = gql`
    query AllOptionPrices($optionID: ID!){
        allOptionPrices(optionID:$optionID){
            optionPriceID
            optionID
            countryID
            price
            packagePrice
            vatID
            packageVatID
            country{
                name
                currencyID
                currency{
                    code
                }
            }
            vat{
                value
            }
            packageVat{
                value
            }
        }

    }
`;
export const GET_OPTION_PRICE = gql`
    query OptionPrice($optionPriceID: ID!){
        optionPrice(optionPriceID:$optionPriceID){
            optionPriceID
            countryID
            price
            packagePrice
            vatID
            packageVatID
        }
        allActiveCountries(fromAdmin:1){
            countryID
            currencyID
            name
            currency{
                title
            }
        }        
        allVats{
            vatID
            value
        }
    }
`;

export const ADD_EDIT_OPTION_PRICE = gql`
    mutation AddEditOptionPrice($optionPriceInputs:OptionPriceInputs) {
        addEditOptionPrice(optionPriceInputs:$optionPriceInputs){
            optionPriceID
            optionID
            countryID
            price
            packagePrice
        }
    }
`;

export const DELETE_OPTION_PRICES = gql`
    mutation DeleteOptionPrices($optionPriceIDs:[ID]!) {
        deleteOptionPrices(optionPriceIDs:$optionPriceIDs)
    }
`;




