import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {ADD_EDIT_APPBANNER, GET_APPBANNER,GET_APPBANNERS} from '../Queries/appBanner';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import AppBannerLib from '../Library/appBanner';
import {APPBANNER_IMAGE_URL,SERVER_URL} from '../../../Config/index';


const AddEditAppBanner = (props) => {

    const appBannerLib = new AppBannerLib(props);

    const [formData,setFormData] = useState({
        selectedLang:"cz",
        appBannerID:props.selectedAppBannerID,
        langs:[],
        active:1,
        photo:"",
    });

    const [allLanguageMutations, setAllLanguageMutations] = useState([]);
    const [mainPhoto, setMainPhoto] = useState("");

    const {data:appBannerData,loading:getLoading,error:getError} = useQuery(GET_APPBANNER,{
        variables:{
            appBannerID: formData.appBannerID,
            lang:"cz"
        },
        fetchPolicy: 'network-only',
        onCompleted(data){

            appBannerLib.getData(data,formData.appBannerID,formData,setFormData);
            setAllLanguageMutations(data.allLanguageMutations);

            if(formData.appBannerID){
                setMainPhoto(SERVER_URL + "/" + APPBANNER_IMAGE_URL + "/ab-" +formData.appBannerID + "/velka_" + data.appBannerWithLangs.photo);
            }

        },
    })

    const [addEditAppBanner,{data:addAppBannerData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_APPBANNER,{
        onCompleted(data){

           props.openCloseModal(false);
            
		},update(cache,response) {            
            
            let notify = new Notification();
            if(formData.appBannerID && formData.appBannerID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        },
        refetchQueries(data){
            return [{
                query:GET_APPBANNERS,
                variables: { lang:"cz" }
            }]
        }
    })

    const openMainImage = (e) => {

        var file = e.target.files[0];

        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;

            setMainPhoto(dataURL);
            setFormData({...formData,photo:file});
        };
        reader.readAsDataURL(file);
    }

    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError)
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.appBannerID && formData.appBannerID != 0 ? translate.t("EditAppBanner") : translate.t("AddAppBanner"))}</h4>
                    {allLanguageMutations && allLanguageMutations.length > 1 &&
                        <div className="ml-auto">
                            <select className="form-control" name="selectedLang" value={formData.selectedLang} onChange={(e) => appBannerLib.formHandle(e,formData,setFormData)}>
                                {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                    <option key={index} value={item.suffix}> {item.suffix} </option>
                                ))}
                            </select>
                        </div>
                    }
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                  
                                <div className="row">    
                                    <div className="col-12">
                                        <label className="input-label">Aktivní</label>
                                        <div className="form-group">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(formData.active == 1 ?  true : false)} onChange={(e) => appBannerLib.formHandle(e,formData,setFormData)} />
                                                <label className="form-check-label" htmlFor="active1">
                                                    Ano
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(formData.active == 0 ?  true : false)} onChange={(e) => appBannerLib.formHandle(e,formData,setFormData)} />
                                                <label className="form-check-label" htmlFor="active2">
                                                    Ne
                                                </label>
                                            </div>
                                        </div>
                                    </div>    


                                    <div className="col-12">

                                        <label className="input-label">Fotka</label>

                                        <div className="form-group input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="customFile" required onChange={(e) => openMainImage(e)} />
                                                <label className="custom-file-label" htmlFor="customFile">Vyberte fotku</label>
                                            </div>
                                        </div>
                                        {mainPhoto != "" &&
                                            <div className="form-group main-photo-container">
                                                
                                                <div className="one-image">            
                                                    <img id="main-image" src={mainPhoto} />
                                                </div>

                                            </div>
                                        }

                                    </div>

                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("Text")} ({formData.selectedLang})</label>
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="text" value={appBannerLib.getLangValue(formData,"text")} onChange={(e) => appBannerLib.formLangHandle(e,formData,setFormData)} />
                                            </div>
                                        </div>                                                
                                    </div> 
                                                                                           
                                </div> 
  
                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => appBannerLib.addAppBanner(addEditAppBanner,formData)}>{(formData.appBannerID && formData.appBannerID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )

}

export default withApollo(AddEditAppBanner);