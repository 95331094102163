import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Modal from 'react-modal';
import OptionPrice from '../Library/optionPrice';
import { ADD_EDIT_OPTION_PRICE, GET_OPTION_PRICE, GET_OPTION_PRICES } from '../Queries/optionPrices';
import { GET_OPTIONS } from '../Queries/option';


const AddEditOptionPrice = (props) => {

    const optionPriceLib  = new OptionPrice(props);
    const helper        = new Helper(); 
    let notify          = new Notification();

    const [formData,setFormData] = useState({
        optionPriceID:props.optionPriceID,
        optionID:props.optionID,
        countryID:60,
        price:0,
        packagePrice:0,
        vatID:3,
        packageVatID:4,
        lang:translate.locale
    });

    const {data:getData,loading:getLoading,error:getError} = useQuery(GET_OPTION_PRICE,{
        variables:{optionPriceID: formData.optionPriceID},
        fetchPolicy: 'network-only',
        onCompleted(data){
            optionPriceLib.getData(data,formData.optionPriceID,formData,setFormData);
        },
        onError(err){
            err             = helper.getApolloErrorText(err);
            notify.setNotification(null,err,false,true,props.client);
        },
    })

    const [addEditOptionPrice,{data:addOptionPricekData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_OPTION_PRICE,{
        onCompleted(data){
			props.openCloseModal(false);
            
		},update(cache,response) {            
            
            let notify = new Notification();
            if(formData.athleteID && formData.athleteID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            err             = helper.getApolloErrorText(err);
            notify.setNotification(null,err,false,true,props.client);
        },
        refetchQueries(data){
            return [{
                query:GET_OPTION_PRICES,
                variables: { optionID:props.optionID }
            },
            {
               query:GET_OPTIONS,
               variables: {lang:translate.locale}
            }]
        }
    })

    const getCurrency = () => {
        if(getData && getData.allActiveCountries){
            const selectedCountry = getData.allActiveCountries.filter(item => item.countryID == formData.countryID);
            if(selectedCountry[0] && selectedCountry[0].currency && selectedCountry[0].currency.title){
                return selectedCountry[0].currency.title
            }
        }
        return "";
    }
    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.optionPriceID && formData.optionPriceID != 0 ? translate.t("EditOptionPrice") : translate.t("AddOptionPrice"))}</h4>
                    
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div>                            
                        {!getLoading && !addLoading ?
                            <Fragment>  
                                <div className="row">   
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("Country")} </label>
                                            <div className="form-group">
                                                <select className="form-control" name="countryID"  value={formData.countryID} onChange={(e) => optionPriceLib.formHandle(e,formData,setFormData)}>
                                                    {getData && getData.allActiveCountries && getData.allActiveCountries.map((it,ind) => (
                                                        <option key={ind} value={it.countryID}>{it.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div className="row">   
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("Price")} </label>
                                            <div className="input-group">
                                                <input className="form-control" type="number" name="price" value={formData.price} onChange={(e) => optionPriceLib.formHandle(e,formData,setFormData)} />
                                                        
                                                <div className="input-group-append">
                                                    <div className="input-group-text">{getCurrency()}</div>
                                                </div>
                                            </div>
                                        </div>                                              
                                    </div>   
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("PackagePrice")} </label>
                                            <div className="input-group">
                                                <input className="form-control" type="number" name="packagePrice" value={formData.packagePrice} onChange={(e) => optionPriceLib.formHandle(e,formData,setFormData)} />
                                                           
                                                <div className="input-group-append">
                                                    <div className="input-group-text">{getCurrency()}</div>
                                                </div>
                                            </div>
                                        </div>                                                
                                    </div>   
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("Vat")} </label>
                                            <div className="form-group">
                                                <select className="form-control" name="vatID"  value={formData.vatID} onChange={(e) => optionPriceLib.formHandle(e,formData,setFormData)}>
                                                    {getData && getData.allVats && getData.allVats.map((it,ind) => (
                                                        <option key={ind} value={it.vatID}>{it.value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>                                                
                                    </div> 
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("PackageVat")} </label>
                                            <div className="form-group">
                                                <select className="form-control" name="packageVatID"  value={formData.packageVatID} onChange={(e) => optionPriceLib.formHandle(e,formData,setFormData)}>
                                                    {getData && getData.allVats && getData.allVats.map((it,ind) => (
                                                        <option key={ind} value={it.vatID}>{it.value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>                                                
                                    </div>  
                                </div> 
                            </Fragment> 
                        :
                        <Loading />  
                        }  
                    </div>
                </div>
                <div className="modal-footer">                                
                    <button athlete="button" className="btn btn-primary flex-fill" onClick={() => optionPriceLib.addOptionPrice(addEditOptionPrice,formData)}>{(formData.optionPriceID && formData.optionPriceID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )

}

export default withApollo(AddEditOptionPrice);