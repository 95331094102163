import React,{ Component, Fragment, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import translate from '../../Translations/index';
import {BRANCHES_FOR_STATISTICS, DATA_FOR_STATISTIC} from '../Queries/statistics';
import Loading from '../../../GlobalComponents/Loading';
import StatisticLib from '../Library/statistics';
import DatePicker,{ registerLocale } from 'react-datepicker';
import { ADMIN_USER } from '../../../GlobalQueries/globalQueries';
import { SERVER_URL } from '../../../Config';
const moment             = require("moment");

const StatisticSales = (props) => {

    const statisticLib = new StatisticLib(props)

	const adminUserData = props.client.readQuery({ query:ADMIN_USER });

    const [dateFrom,setDateFrom] = useState(moment().toDate());
    const [dateTo,setDateTo] = useState(moment().toDate());

    const [filters,setFilters] = useState({
        countryID:adminUserData.adminUser.branchCountryID ? adminUserData.adminUser.branchCountryID : 60,
        dateFrom:moment().format("YYYY-MM-DD"),
        dateTo:moment().format("YYYY-MM-DD"),
        driverID:0,
        branchID:adminUserData.adminUser.roleID == 5 ? adminUserData.adminUser.branchID : 0
    });
    const [salesData,setSalesData] = useState({
        sales:{
            delivery:{
                byCardNoVat:0,
                byCardVat:0,
                byCardTaxBaseVatValues:{},
                byCardTaxBaseNoVatValues:{},
                byCardVatValues:{},
                cashNoVat:0,
                cashVat:0,
                cashTaxBaseVatValues:{},
                cashTaxBaseNoVatValues:{},
                cashVatValues:{},
                totalNoVat:0,
                totalVat:0,
                totalTaxBaseVatValues:{},
                totalTaxBaseNoVatValues:{},
                totalVatValues:{},
            },            
            eatInTakeAway:{
                byCardNoVat:0,
                byCardVat:0,
                byCardTaxBaseVatValues:{},
                byCardTaxBaseNoVatValues:{},
                byCardVatValues:{},
                cashNoVat:0,
                cashVat:0,
                cashTaxBaseVatValues:{},
                cashTaxBaseNoVatValues:{},
                cashVatValues:{},
                totalNoVat:0,
                totalVat:0,
                totalTaxBaseVatValues:{},
                totalTaxBaseNoVatValues:{},
                totalVatValues:{},
            }
        },
        canceled:{
            delivery:{
                byCardNoVat:0,
                byCardVat:0,
                byCardTaxBaseVatValues:{},
                byCardTaxBaseNoVatValues:{},
                byCardVatValues:{},
                cashNoVat:0,
                cashVat:0,
                cashTaxBaseVatValues:{},
                cashTaxBaseNoVatValues:{},
                cashVatValues:{},
                totalNoVat:0,
                totalVat:0,
                totalTaxBaseVatValues:{},
                totalTaxBaseNoVatValues:{},
                totalVatValues:{},
            },            
            eatInTakeAway:{
                byCardNoVat:0,
                byCardVat:0,
                byCardTaxBaseVatValues:{},
                byCardTaxBaseNoVatValues:{},
                byCardVatValues:{},
                cashNoVat:0,
                cashVat:0,
                cashTaxBaseVatValues:{},
                cashTaxBaseNoVatValues:{},
                cashVatValues:{},
                totalNoVat:0,
                totalVat:0,
                totalTaxBaseVatValues:{},
                totalTaxBaseNoVatValues:{},
                totalVatValues:{},
            }
        }
    });
    const {loading, error, data, refetch}   = useQuery(DATA_FOR_STATISTIC,{
        fetchPolicy:"network-only",
        variables:{
            filterData:filters
        },
        onCompleted(data){
            console.log(data);
            const statisticData = JSON.parse(JSON.stringify(data));
            const newSales = statisticLib.calculateSales(statisticData.ordersForStatistics);       
            setSalesData(newSales); 
        },
    });
    const {loading:branchesLoading, error:branchesError, data:branchesData}   = useQuery(BRANCHES_FOR_STATISTICS,{
        fetchPolicy:"network-only",
        variables:{
            countryID:filters.countryID
        }
    });

    const setDateFromFilter = (date) => {
        setFilters({...filters,dateFrom:moment(date).format("YYYY-MM-DD")});
        setDateFrom(date);
    }
    const setDateToFilter = (date) => {
        setFilters({...filters,dateTo:moment(date).format("YYYY-MM-DD")});
        setDateTo(date);
    }

    const countryChanged = (e) => {
        setFilters({...filters,branchID:0,countryID:e.target.value});
    }
    const exportReceipts = () => {

        const params = {
            dateFrom:moment(filters.dateFrom).format("YYYY-MM-DD"),
            dateTo:moment(filters.dateTo).format("YYYY-MM-DD"),
            branchID:filters.branchID,
            driverID:filters.driverID,
            countryID:filters.countryID,
            lang:translate.locale
        }

        const base64Hash = Buffer.from(JSON.stringify(params)).toString("base64");

        window.open(SERVER_URL + "/generate/receipts/" + base64Hash, '_blank').focus();
    }
    const getCurrency = () => {
        if(data && data.allActiveCountries){
            const selectedCountry = data.allActiveCountries.filter(item => item.countryID == filters.countryID);
            console.log(selectedCountry);
            if(selectedCountry[0] && selectedCountry[0].currency && selectedCountry[0].currency.code){
                return selectedCountry[0].currency.code
            }
        }
        return "";
    }    
    const currency = getCurrency();
    return (
        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main form-group">
                        
                        <div className="card-header d-flex justify-content-center flex-column">
                            <div className="fs20 mb-3 bold">{translate.t("Statistics")} / <span className="fs14">{translate.t("Sales")}</span></div>
                            <div className="d-flex align-items-center flex-row">
                                {[1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) !== -1 &&
                                    <div className="ml-auto d-flex align-items-center mr-5">
                                        {translate.t("Country")} 
                                        <select className="form-control ml-2" name="countryID" value={filters.countryID} onChange={(e) => countryChanged(e)}>
                                            {false && <option value={0}>-- {translate.t("ChooseCountry")} --</option>}
                                            {data && data.allActiveCountries && data.allActiveCountries.map((item,index) => (
                                                <option key={index} value={item.countryID}> {item.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                }
                                {[1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) !== -1 &&
                                    <div className="ml-auto d-flex align-items-center mr-5">
                                        {translate.t("Branch")} 
                                        <select className="form-control ml-2" name="branchID" value={filters.branchID} onChange={(e) => setFilters({...filters,branchID:e.target.value})}>
                                            <option value={0}>-- {translate.t("SelectBranch")} --</option>
                                            {branchesData && branchesData.allBranches && branchesData.allBranches.map((item,index) => (
                                                <option key={index} value={item.branchID}> {item.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                }
                                {[1,2,5].indexOf(parseInt(adminUserData.adminUser.roleID)) !== -1 &&
                                    <div className="d-flex align-items-center mr-5">
                                        {translate.t("Driver")} 
                                        <select className="form-control ml-2" name="driverID" onChange={(e) => setFilters({...filters,driverID:e.target.value})}>
                                            <option value={0}>-- {translate.t("SelectDriver")} --</option>
                                            {data && data.allDrivers && data.allDrivers.map((item,index) => (
                                                <option key={index} value={item.driverID}> {item.user && item.user.name + " " + item.user.surname} </option>
                                            ))}
                                        </select>
                                    </div>
                                }
                                <div className={"d-flex align-items-center mr-5 " + ([1,2].indexOf(parseInt(adminUserData.adminUser.roleID)) === -1 ? "ml-auto" : "" )}>
                                    {translate.t("DateFrom")}
                                    <div className="ml-2">
                                        <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={dateFrom} onChange={date => setDateFromFilter(date)} />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    {translate.t("DateTo")}
                                    <div className="ml-2">
                                        <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={dateTo} onChange={date => setDateToFilter(date)} />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex mt-3'>
                                <button type='button' className='ml-auto btn btn-primary' onClick={() => exportReceipts()}>{translate.t("ExportReceipt")}</button>
                            </div>
                        </div>
                        
                    </div>
                    <div>
                                
                        {!loading ? 
                            <div className="row" style={{flexWrap:'wrap'}}>

                                <div className="col-12 col-sm-6">
                                    <div className="card main form-group">
                                
                                        <div className="card-header d-flex align-items-center flex-row bold">
                                            {translate.t("DeliverySales")}
                                        </div>
                                        <div className="card-body">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>{translate.t("WithoutDPH")}</th>
                                                        <th>{translate.t("WithDPH")}</th>
                                                        <th>{translate.t("DPH")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>{translate.t("ByCard")}</th>
                                                        <td>                                                        
                                                            <div className='bold'>{(Math.round(salesData.sales.delivery.byCardNoVat * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.delivery.byCardTaxBaseNoVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.delivery.byCardTaxBaseNoVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))}                                                     
                                                        </td>
                                                        <td>
                                                        <div className='bold'>{(Math.round(salesData.sales.delivery.byCardVat * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.delivery.byCardTaxBaseVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.delivery.byCardTaxBaseVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))} 
                                                        </td>
                                                        <td>
                                                            <div className='bold'>{(Math.round((salesData.sales.delivery.byCardVat - salesData.sales.delivery.byCardNoVat) * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.delivery.byCardVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.delivery.byCardVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>{translate.t("Cash")}</th>
                                                        <td>
                                                            <div className='bold'>{(Math.round(salesData.sales.delivery.cashNoVat * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.delivery.cashTaxBaseNoVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.delivery.cashTaxBaseNoVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))} 
                                                        </td>
                                                        <td>
                                                            <div className='bold'>{(Math.round(salesData.sales.delivery.cashVat * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.delivery.cashTaxBaseVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.delivery.cashTaxBaseVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))} 
                                                        </td>
                                                        <td>
                                                            <div className='bold'>{(Math.round((salesData.sales.delivery.cashVat - salesData.sales.delivery.cashNoVat) * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.delivery.cashVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.delivery.cashVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>{translate.t("Total")}</th>
                                                        <td>
                                                            <div className='bold'>{(Math.round(salesData.sales.delivery.totalNoVat * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.delivery.totalTaxBaseNoVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.delivery.totalTaxBaseNoVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))} 
                                                        </td>
                                                        <td>
                                                            <div className='bold'>{(Math.round(salesData.sales.delivery.totalVat * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.delivery.totalTaxBaseVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.delivery.totalTaxBaseVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))} 
                                                        </td>
                                                        <td>
                                                            <div className='bold'>{(Math.round((salesData.sales.delivery.totalVat - salesData.sales.delivery.totalNoVat) * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.delivery.totalVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.delivery.totalVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="card main form-group">
                                
                                        <div className="card-header d-flex align-items-center flex-row bold">
                                            {translate.t("EatInTakeAwaySales")}
                                        </div>
                                        <div className="card-body">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>{translate.t("WithoutDPH")}</th>
                                                        <th>{translate.t("WithDPH")}</th>
                                                        <th>{translate.t("DPH")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>{translate.t("ByCard")}</th>
                                                        <td>
                                                            <div className='bold'>{(Math.round(salesData.sales.eatInTakeAway.byCardNoVat * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.eatInTakeAway.byCardTaxBaseNoVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.eatInTakeAway.byCardTaxBaseNoVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))} 
                                                        </td>
                                                        <td>
                                                            <div className='bold'>{(Math.round(salesData.sales.eatInTakeAway.byCardVat * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.eatInTakeAway.byCardTaxBaseVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.eatInTakeAway.byCardTaxBaseVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))} 
                                                        </td>
                                                        <td>
                                                            <div className='bold'>{(Math.round((salesData.sales.eatInTakeAway.byCardVat - salesData.sales.eatInTakeAway.byCardNoVat) * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.eatInTakeAway.byCardVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.eatInTakeAway.byCardVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>{translate.t("Cash")}</th>
                                                        <td>
                                                            <div className='bold'>{(Math.round(salesData.sales.eatInTakeAway.cashNoVat * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.eatInTakeAway.cashTaxBaseNoVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.eatInTakeAway.cashTaxBaseNoVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))} 
                                                        </td>
                                                        <td>
                                                            <div className='bold'>{(Math.round(salesData.sales.eatInTakeAway.cashVat * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.eatInTakeAway.cashTaxBaseVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.eatInTakeAway.cashTaxBaseVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))}
                                                        </td>
                                                        <td>
                                                            <div className='bold'>{(Math.round((salesData.sales.eatInTakeAway.cashVat - salesData.sales.eatInTakeAway.cashNoVat) * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.eatInTakeAway.cashVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.eatInTakeAway.cashVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>{translate.t("Total")}</th>
                                                        <td>
                                                            <div className='bold'>{(Math.round(salesData.sales.eatInTakeAway.totalNoVat * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.eatInTakeAway.totalTaxBaseNoVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.eatInTakeAway.totalTaxBaseNoVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))} 
                                                        </td>
                                                        <td>
                                                            <div className='bold'>{(Math.round(salesData.sales.eatInTakeAway.totalVat * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.eatInTakeAway.totalTaxBaseVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.eatInTakeAway.totalTaxBaseVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))} 
                                                        </td>
                                                        <td>
                                                            <div className='bold'>{(Math.round((salesData.sales.eatInTakeAway.totalVat - salesData.sales.eatInTakeAway.totalNoVat) * 100) / 100)} {currency}</div>
                                                            {Object.keys(salesData.sales.eatInTakeAway.totalVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.sales.eatInTakeAway.totalVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <div className="card main form-group">
                                
                                        <div className="card-header d-flex align-items-center flex-row bold">
                                            {translate.t("DeliverySalesCanceledByAdmin")}
                                        </div>
                                        <div className="card-body">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>{translate.t("WithoutDPH")}</th>
                                                        <th>{translate.t("WithDPH")}</th>
                                                        <th>{translate.t("DPH")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>{translate.t("ByCard")}</th>
                                                        <td>{(Math.round(salesData.canceled.delivery.byCardNoVat * 100) / 100)} {currency}</td>
                                                        <td>{(Math.round(salesData.canceled.delivery.byCardVat * 100) / 100)} {currency}</td>
                                                        <td>
                                                            {Object.keys(salesData.canceled.delivery.byCardVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.canceled.delivery.byCardVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>{translate.t("Cash")}</th>
                                                        <td>{(Math.round(salesData.canceled.delivery.cashNoVat * 100) / 100)} {currency}</td>
                                                        <td>{(Math.round(salesData.canceled.delivery.cashVat * 100) / 100)} {currency}</td>
                                                        <td>
                                                            {Object.keys(salesData.canceled.delivery.cashVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.canceled.delivery.cashVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>{translate.t("Total")}</th>
                                                        <td>{(Math.round(salesData.canceled.delivery.totalNoVat * 100) / 100)} {currency}</td>
                                                        <td>{(Math.round(salesData.canceled.delivery.totalVat * 100) / 100)} {currency}</td>
                                                        <td>
                                                            {Object.keys(salesData.canceled.delivery.totalVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.canceled.delivery.totalVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="card main form-group">
                                
                                        <div className="card-header d-flex align-items-center flex-row bold">
                                            {translate.t("EatInTakeAwaySalesCanceledByAdmin")}
                                        </div>
                                        <div className="card-body">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>{translate.t("WithoutDPH")}</th>
                                                        <th>{translate.t("WithDPH")}</th>
                                                        <th>{translate.t("DPH")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>{translate.t("ByCard")}</th>
                                                        <td>{(Math.round(salesData.canceled.eatInTakeAway.byCardNoVat * 100) / 100)} {currency}</td>
                                                        <td>{(Math.round(salesData.canceled.eatInTakeAway.byCardVat * 100) / 100)} {currency}</td>
                                                        <td>
                                                            {Object.keys(salesData.canceled.eatInTakeAway.byCardVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.canceled.eatInTakeAway.byCardVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>{translate.t("Cash")}</th>
                                                        <td>{(Math.round(salesData.canceled.eatInTakeAway.cashNoVat * 100) / 100)} {currency}</td>
                                                        <td>{(Math.round(salesData.canceled.eatInTakeAway.cashVat * 100) / 100)} {currency}</td>
                                                        <td>
                                                            {Object.keys(salesData.canceled.eatInTakeAway.cashVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.canceled.eatInTakeAway.cashVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>{translate.t("Total")}</th>
                                                        <td>{(Math.round(salesData.canceled.eatInTakeAway.totalNoVat * 100) / 100)} {currency}</td>
                                                        <td>{(Math.round(salesData.canceled.eatInTakeAway.totalVat * 100) / 100)} {currency}</td>
                                                        <td>
                                                            {Object.keys(salesData.canceled.eatInTakeAway.totalVatValues).map((percentIndex,i) => (
                                                                <div key={i}>{(Math.round(salesData.canceled.eatInTakeAway.totalVatValues[percentIndex] * 100) / 100)} ({percentIndex}%)</div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <div className="card statistic main form-group">
                                                    
                                        <div className="card-header d-flex align-items-center flex-row bold">
                                            {translate.t("FinishedOrdersStatistic")}
                                        </div>
                                        <div className="card-body ">   
                                            <div className="row">                          
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("FinishedOrderCount")}</label>
                                                        <div className="form-group">
                                                            {salesData.orderCount}
                                                        </div>
                                                    </div> 
                                                </div> 
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("PriceForFinishedOrder")}</label>
                                                        <div className="form-group">
                                                            {salesData.orderPriceSum} {currency}
                                                        </div>
                                                    </div> 
                                                </div> 
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("AveragePriceForFinishedOrder")}</label>
                                                        <div className="form-group">
                                                            {salesData.averageOrderPrice} {currency}
                                                        </div>
                                                    </div> 
                                                </div> 
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="card statistic main form-group">
                                                    
                                        <div className="card-header d-flex align-items-center flex-row bold">
                                            {translate.t("NotinishedOrdersStatistic")}
                                        </div>
                                        <div className="card-body ">   
                                            <div className="row">                          
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("NotFinishedOrderCount")}</label>
                                                        <div className="form-group">
                                                            {salesData.notFinishedOrderCount}
                                                        </div>
                                                    </div> 
                                                </div> 
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("PriceForNotFinishedOrder")}</label>
                                                        <div className="form-group">
                                                            {salesData.notFinishedOrderPriceSum} {currency}
                                                        </div>
                                                    </div> 
                                                </div> 
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("AveragePriceForNotFinishedOrder")}</label>
                                                        <div className="form-group">
                                                            {salesData.notFinishedAverageOrderPrice} {currency}
                                                        </div>
                                                    </div> 
                                                </div> 
                                            </div> 
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <div className="card statistic main form-group">
                                                    
                                        <div className="card-header d-flex align-items-center flex-row bold">
                                            {translate.t("OrdersStatisticInTime")}
                                        </div>
                                        <div className="card-body ">   
                                            <div className="row">                          
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>{translate.t("Time")}</th>
                                                            <th className="text-center">{translate.t("Count")}</th>
                                                            <th className="text-right">{translate.t("Price")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>                                                
                                                        <tr>
                                                            <td>07:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["7"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["7"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>08:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["8"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["8"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>09:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["9"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["9"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>10:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["10"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["10"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>11:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["11"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["11"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>12:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["12"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["12"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>13:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["13"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["13"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>14:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["14"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["14"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>15:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["15"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["15"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>16:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["16"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["16"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>17:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["17"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["17"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>18:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["18"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["18"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>19:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["19"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["19"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>20:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["20"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["20"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>21:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["21"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["21"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>22:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["22"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["22"].price} {currency}</td>                                                        
                                                        </tr>                                            
                                                        <tr>
                                                            <td>23:00</td>
                                                            <td className="text-center">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["23"].count}</td>
                                                            <td className="text-right">{salesData.orderDistributedOverTime && salesData.orderDistributedOverTime["23"].price} {currency}</td>                                                        
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :                                    
                            <Loading />                                    
                        }
                        
                    </div>
                </div>
            </div> 	
	
        </div>
    )

}

export default withApollo(StatisticSales);