import gql from 'graphql-tag';

export const GET_MEAL_OPTION_PRICE = gql`
    query MealOptionPrice($mealOptionPriceID: ID!){
        mealOptionPrice(mealOptionPriceID:$mealOptionPriceID){            
            mealOptionPriceID
            countryID
            optionPrice
            vatID
            packageVatID
            packagePrice
        }
        allActiveCountries(fromAdmin:1){
            countryID
            currencyID
            name
            currency{
                title
            }
        }       
        allVats{
            vatID
            value
        }
    }
`;

export const ADD_EDIT_MEAL_OPTION_PRICE = gql`
    mutation AddEditMealOptionPrice($mealOptionPriceInputs:MealOptionPriceInputs) {
        addEditMealOptionPrice(mealOptionPriceInputs:$mealOptionPriceInputs){
            mealOptionPriceID
            mealOptionID
            countryID
            optionPrice
            vatID
            packageVatID
            packagePrice
        }
    }
`;

export const DELETE_MEAL_OPTION_PRICE = gql`
    mutation DeleteMealOptionPrice($mealOptionPriceID:[ID]!) {
        deleteMealOptionPrice(mealOptionPriceID:$mealOptionPriceID)
    }
`;




