import React,{ Component, Fragment, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import GreenInfoBannerLib from '../Library/greenInfoBanner';
import { GET_GREEN_INFO_BANNER_DATA,ADDEDIT_GREEN_INFO_BANNER_DATA, GET_GREEN_INFO_BANNERS } from '../Queries/greenInfoBanner';
import Modal from 'react-modal';

const GreenInfoBanner = (props) => {

    const helper = new Helper();
    helper.checkAdminRights(props,["1","2"]);
    console.log(props.selectedGreenInfoBannerID);
    const greenInfoBannerLib = new GreenInfoBannerLib(props);
    const [formData,setFormData] = useState({
        greenInfoBannerID:props.selectedGreenInfoBannerID,
        selectedLang:"cz",
        langs:[],
        active:1,
        showInCart:1,
        showInRealtimeDelivery:1,
        showInRestaurantDelivery:1,
        showInBranch:1,
        countryID:60
    });

    
    const [allLanguageMutations, setAllLanguageMutations] = useState([]);
    const {loading, error:getError, data:homepageBannersData, refetch}   = useQuery(GET_GREEN_INFO_BANNER_DATA,{
        variables:{
            greenInfoBannerID:formData.greenInfoBannerID
        },
        fetchPolicy: 'cache-and-network',
        onCompleted(data){
            console.log(data);
            setAllLanguageMutations(data.allLanguageMutations);   
            greenInfoBannerLib.getData(data,formData.greenInfoBannerID,formData,setFormData);
              
        },
    });
    
    const [editGreenInfoBannerData,{data:addGreenInfoData,loading:addGreenInfoLoading,error:addGreenInfoError}] = useMutation(ADDEDIT_GREEN_INFO_BANNER_DATA,{
        onCompleted(data){
            
           props.openCloseModal(false);
		},
        refetchQueries(data){
            return [{
                query:GET_GREEN_INFO_BANNERS,
                variables: props.listVariables
            }]
        },update(cache,response) {            
            
            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    })
    
    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.greenInfoBannerID && formData.greenInfoBannerID != 0 ? translate.t("EditAppBanner") : translate.t("AddAppBanner"))}</h4>
                    {allLanguageMutations && allLanguageMutations.length > 1 &&
                        <div className="ml-auto">
                            <select className="form-control" name="selectedLang" value={formData.selectedLang} onChange={(e) => greenInfoBannerLib.formHandle(e,formData,setFormData)}>
                                {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                    <option key={index} value={item.suffix}> {item.suffix} </option>
                                ))}
                            </select>
                        </div>
                    }
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">  
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <label className="input-label">{translate.t("Text")} ({formData.selectedLang})</label>
                            <div className="form-group">
                                <textarea className="form-control" type="text" name="text" value={greenInfoBannerLib.getLangValue(formData,"text")} onChange={(e) => greenInfoBannerLib.formLangHandle(e,formData,setFormData)} />
                            </div>
                        </div>
                        <div className="col-12 col-sm-2">
                            <label className="input-label">{translate.t("Active")}</label>
                            <div className="form-group">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(formData.active == 1 ?  true : false)} onChange={(e) => greenInfoBannerLib.formHandle(e,formData,setFormData)} />
                                    <label className="form-check-label" htmlFor="active1">
                                    {translate.t("Yes")}
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(formData.active == 0 ?  true : false)} onChange={(e) => greenInfoBannerLib.formHandle(e,formData,setFormData)} />
                                    <label className="form-check-label" htmlFor="active2">
                                    {translate.t("No")}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-2">
                            <label className="input-label">{translate.t("ShowIn")}</label>
                            
                            
                            <div className="form-check">
                                <input className="form-check-input" name="showInRealtimeDelivery" type="checkbox"  checked={formData.showInRealtimeDelivery} onChange={(e) => greenInfoBannerLib.formHandle(e,formData,setFormData)} id="showInRealtimeDelivery" />
                                <label className="form-check-label" htmlFor="showInRealtimeDelivery">
                                    {translate.t("ShowInRealtimeDeliveryLabel")}
                                </label>
                            </div>
                            
                            <div className="form-check">
                                <input className="form-check-input" name="showInRestaurantDelivery" type="checkbox"  checked={formData.showInRestaurantDelivery} onChange={(e) => greenInfoBannerLib.formHandle(e,formData,setFormData)} id="showInRestaurantDelivery" />
                                <label className="form-check-label" htmlFor="showInRestaurantDelivery">
                                    {translate.t("ShowInDeliveryLabel")}
                                </label>
                            </div>
                            
                            <div className="form-check">
                                <input className="form-check-input" name="showInBranch" type="checkbox" checked={formData.showInBranch} onChange={(e) => greenInfoBannerLib.formHandle(e,formData,setFormData)} id="showInBranch" />
                                <label className="form-check-label" htmlFor="showInBranch">
                                    {translate.t("ShowInTakeAwayLabel")}
                                </label>
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" name="showInCart" type="checkbox"  checked={formData.showInCart} onChange={(e) => greenInfoBannerLib.formHandle(e,formData,setFormData)} id="showInCart" />
                                <label className="form-check-label" htmlFor="showInCart">
                                    {translate.t("ShowInCartLabel")}
                                </label>
                            </div>
                        </div>
                        <div className="col-12 col-sm-2">
                            <label className="input-label">{translate.t("ShowInCountry")}</label>
                            <select className="form-control" name="countryID" value={formData.countryID} onChange={(e) => greenInfoBannerLib.formHandle(e,formData,setFormData)}>
                                {homepageBannersData && homepageBannersData.allActiveCountries && homepageBannersData.allActiveCountries.map((item,index) => (
                                    <option key={index} value={item.countryID}> {item.name} </option>
                                ))}
                            </select>
                        </div>
                    </div> 
                </div>
                <div className="modal-footer">                                
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => greenInfoBannerLib.saveGreenInfoDataData(editGreenInfoBannerData,formData)}>{(formData.greenInfoBannerID && formData.greenInfoBannerID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )

}

export default withApollo(GreenInfoBanner);